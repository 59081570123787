import React, { useEffect } from "react";
import { useState } from "react";
import { Button, FloatingLabel, Form, Modal, Spinner } from "react-bootstrap";
import { useAuth } from "../../../Services/UserAuth";
import "./Transaction.css";
import { ToastContainer, toast } from "react-toastify";
import { format } from "date-fns";
import axios from "axios";
import { database } from "../../../Services/firebase/firebase";
import firebase from "firebase";
import Dataprice from "../../../Dataprice.json";
import { formatNumber, version_control } from "../../../Services/Utility";
import { SendNotification, getimcome } from "../../../Services/GetUser.service";
import { getRandomString } from "../../../Services/GetRandomNumber";
import { Link, useHistory } from "react-router-dom";
import { backendUrl } from "../../../Services/firebase/firebase";
import { detectNetwork } from "../../../Services/getMobileNetworkType";
function Airtimecomponent() {
  const [amount, setamount] = useState();
  const [selectednetwork, setselectednetwork] = useState("");
  const [number, setphone] = useState("");
  const [loading, setloading] = useState(false);
  const { loginuser, userwallet } = useAuth();
  const [responspayload, setresponsepayload] = useState("");
  const [show, setShow] = useState(false);
  const [payload, setpayload] = useState([]);
  const [com, setcom] = useState(0);
  const [showprint, setShowprint] = useState(false);
  const [res, setrepayload] = useState([]);
  const [list, setdatapriceList] = useState([]);
  const [ selectedPlan, setselectedplan] = useState([]);
  const [boundlePlan, setboudlePlan] = useState(0);
  const [selectedpricec, setselectedprice] = useState(0);
  const [custcomission, setcustomercommssion] =  useState(0)
  const [buyingprice, setbuyingprice] = useState(0)
  const [code, setboundlecode] = useState("")
  const [selectedsjplan,setplan ] =  useState("")
  const history =  useHistory()
  const handleClosePrint = () => {
    window.location.reload();
    setShowprint(false);
  };
  const handleShowprint = () => setShowprint(true);
  const handleClose = () => {
    setShow(false);
  };

  const handlegetPaln = async(e)=>{
    setboudlePlan(0)
    const provider = e.target.value
    setselectednetwork(provider)
    await axios.get(backendUrl+ `getPrice/price/service/data/${provider}`).then((res)=>{
      setselectedplan(res.data[0].boundle.PRODUCT)
    })
   }

   const version = version_control()
  useEffect(() => {

    const result = selectedPlan.filter((name) => name.PRODUCT_ID === boundlePlan);
    if (result.length > 0) {
     axios.post(backendUrl+ `getPrice/price/service`,{
      network:selectednetwork,
      provider:boundlePlan
    }).then((res)=>{
      const data = res.data[0]
      if (data){
        const price = boundlePlan !== 0 ?  data.selling : 0;
        const boundlecode =  data.PRODUCT_ID
        const buyinrice = data.price
        const plan = data.plan
        setplan(plan)
        // const buyingprice = selectednetwork ===  "mtn" ?  parseFloat(result.selling) : 0;
        setcom(parseFloat(parseFloat(price) - parseFloat(buyinrice)))
        setboundlecode(boundlecode)
        setbuyingprice(buyinrice)
        setselectedprice(parseFloat(price));
      }
    })
    }

  }, [selectednetwork, boundlePlan, selectedpricec]);

  const handleShow = () => setShow(true);

  const date = format(new Date(), "dd/MM/yyyy hh:mm a");



  const handleselctprond = (e) => {
    const d = e.target.value;
    setboudlePlan(d)
      // if (d){
      //   setboudlePlan(d);
      // }else{
      //   selectedPlan("")
      // }
  };

  
  const limit = loginuser.transactionLimit - selectedpricec
  const TransactionLimit = limit <= 0 ? 0 : limit
   
  const handleverifier = () => {
    if (selectednetwork === "") {
      toast.error("Please select network");
      return;
    }
    if (boundlePlan === 0) {
      toast.error("Please select Boundle");
      return;
    }

    if (limit <= 0){
      toast.error("Limit Error");
      return;
    }
    const num = number 
    if (num === "" || !num){
      toast.error("Enter Mobile number");
      return 
    }
    if (num.length !== 11){
      toast.error("Vat-Bad Format")
      return
    }

    const firstFourDigits = number.substring(0, 4);
    const network =   detectNetwork(firstFourDigits).toLocaleLowerCase()
    if (network ==="unknown network"){
      toast.error("unknown Network");
      return;
    }
    if(network !== selectednetwork){
      toast.error(`Invalid network type. We dictated ${network} network, Please selected the correct network and try again`);
      return;
    }
    const data = {
      number: num,
      amount: parseFloat(selectedpricec),
      selectednetwork: selectednetwork,
      boundle:code
    };

    setpayload(data);
    handleShow();
  };

  const handlepayment = async (e) => {
    e.preventDefault();
    if (userwallet.wallet < payload.amount) {
      toast.error("Insuffient Fund");
      return;
    }
    const ref = database.collection("_").doc().id;
    const UniquieNum = getRandomString(20, "1234567890");
    if (limit <= 0){
      toast.error("Limit Error");
      return;
    }
    try {
      setloading(true);
      setresponsepayload("connecting");
      await axios
        .post(
          backendUrl+ `debitSuperAgentAccount/debit/user`,
          {
            id: loginuser.id,
            amount: payload.amount,
            trackNo: UniquieNum,
            version:version
          }
        )
        .then(async (res) => {
         
          setresponsepayload("sending value");
          if (res.data.res === "success") {
            const Token = res.data.token
            await axios
              .post(
                backendUrl+ "buymobiledata/data",
                {
                  network: payload.selectednetwork,
                  bundle: payload.boundle,
                  number: payload.number,
                  referrence: ref,
                  Token,
                  boundlePlan
                }
              )
              .then(async (res) => {
               
                setresponsepayload("Finilizing transaction");

                const data = {
                  id: ref,
                  company: selectedsjplan,
                  customername: number,
                  address: "no value",
                  userID: loginuser.id,
                  AccountNumber: number,
                  walletbalance: userwallet.wallet - payload.amount,
                  Prewalletbalance: userwallet.wallet,
                  amount: payload.amount,
                  phone: number,
                  payloadres: res.data,
                  Debitamount: payload.amount,
                  customermisson:0,
                  com: com,
                  TranRef:UniquieNum,
                  type: "Data",
                  status: "success",
                  isToday: true,
                  isClose:true,
                  created: firebase.firestore.FieldValue.serverTimestamp(),
                };
                const dataw = {
                  id: ref,
                  company: selectedsjplan,
                  customername: number,
                  address: "no value",
                  userID: loginuser.id,
                  AccountNumber: number,
                  walletbalance: userwallet.wallet,
                  amount: payload.amount,
                  phone: number,
                  Debitamount: payload.amount,
                  customermisson:0,
                  com: com,
                  payloadres: res.data,
                  type: "Data",
                  TranRef:UniquieNum,
                  status: "error",
                  isToday: true,
                  isClose:false,
                  created: firebase.firestore.FieldValue.serverTimestamp(),
                };
                
                if (res.data.status === "success" || res.data.status === "ORDER RECIEVED") {
                  const promises = [
                    axios.post(
                      backendUrl + "userlogsuserlogs/transactions",
                      {
                        data: data,
                      }
                    ),
                    database.doc(`/income/vRgRWLBNzwo7IM1pUhcl`).update({
                      income: firebase.firestore.FieldValue.increment(com),
                      Data: firebase.firestore.FieldValue.increment(com),
                    }),
                  ];
                  setrepayload(data);
                  await Promise.all(promises)
                  history.push(`/reciept/view/${data.id}`)
                } else {
                  setrepayload(dataw);
                 await axios.post(
                  backendUrl + "userlogsuserlogs/transactions",
                  {
                    data: dataw,
                  }
                )
                  toast.error("Unexpected Error")
                  history.push(`/reciept/view/${dataw.id}`)
                }
              });
          } else {
            toast.error(res.data.message);
          }
        });
    } catch (error) {
      toast.error(error.message);
    }
    setloading(false);
  };

  const handleprint = ()=>{
    history.push(`/transaction/${res.id}`)
  }



  return (
    <div>
      <ToastContainer />

      <Modal
        show={showprint}
        onHide={handleClosePrint}
        backdrop="static"
        keyboard={false}
      >
        <div className="tramsactiondetyaions">
          <div className="transactioncokatne">
            <div
              className="transajea"
              style={{
                backgroundColor: res.status === "success" ? "green" : "red",
              }}
            >
              <h1>{res.status === "success" ? "SUCCESS" : "ERROR"}</h1>
              <span>Transaction Reciept</span>
            </div>
            <div className="transactioncontent">
              <div className="transcotebnr">
                <div className="contbename">Company</div>
                <div className="conetnenvakeb sjshdjdhd">{selectednetwork.toUpperCase()}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">Amount</div>
                <div className="conetnenvakeb">{res.amount}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">Bundle</div>
                <div className="conetnenvakeb">{selectedsjplan}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">number</div>
                <div className="conetnenvakeb">{res.AccountNumber}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">Status</div>
                <div className="conetnenvakeb">{res.status}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">Data</div>
                <div className="conetnenvakeb">{date}</div>
              </div>
            </div>
          </div>
        </div>
        <Modal.Footer>
        <Link to="/services">
          <Button variant="secondary" >
            Another Transaction
          </Button>
          </Link>
          
          <Button variant="primary" onClick={handleprint}>Reciept</Button>
          <Button variant="secondary" onClick={handleClosePrint}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="p-0">
          <div className="tramsactiondetyaions">
            <div className="transactioncokatne">
              <div className="transajea">
                <h1>In-Porgress</h1>
                <span>
                  Kindly confirm the information below before you proceed with
                  this payment.
                </span>
              </div>
              <div className="transactioncontent">
                <div className="transcotebnr">
                  <div className="contbename">Company</div>
                  <div className="conetnenvakeb sjshdjdhd">
                    {payload.selectednetwork}
                  </div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">Bundle</div>
                  <div className="conetnenvakeb">{selectedsjplan}</div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">Amount</div>
                  <div className="conetnenvakeb">{payload.amount}</div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">number</div>
                  <div className="conetnenvakeb">{payload.number}</div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">Status</div>
                  <div className="conetnenvakeb">Processing</div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">Data</div>
                  <div className="conetnenvakeb">{date}</div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" disabled={loading} onClick={handleClose}>
            Close
          </Button>
          <Button
            variant={loading ? "warning" : "primary"}
            disabled={loading}
            onClick={handlepayment}
            type="submit"
          >
            {loading ? (
              <>
                {" "}
                <Spinner animation="border" size="sm" /> {responspayload}
              </>
            ) : (
              "Pay with Wallet"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="transactionconatiner">
        <div className="transactioncontenrt">
          <div className="transactiohead">
            <h2>
              <center className="text-dark">Data</center>
            </h2>
          </div>
          <div className="formtransactionsecton">
            <div className="formtrnacruondjdjd">
              <div className="productsed">
                <Form.Label className="text-dark ">Select Network</Form.Label>
                <FloatingLabel
                  controlId="floatingSelect"
                  onChange={handlegetPaln}
                  // onChange={(event) => setselectednetwork(event.target.value)}
                >
                  <Form.Select aria-label="Floating label select example">
                    <option>select network</option>
                    <option value="mtn">MTN</option>
                    <option value="glo">GLO</option>
                    <option value="airtel">AIRTEL</option>
                    <option value="etisalat">9Mobile</option>
                  </Form.Select>
                </FloatingLabel>
                <div className="optionshhd">
                  <Form.Label className="text-dark ">
                    Select Data Plan
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={handleselctprond}
                    value={boundlePlan}
                    required
                  >
                    <option key="">Select Plan</option>
                    {selectedPlan.map((b)=>{
                      
                    return(
                      <>
                      <option value={b.PRODUCT_ID} key={b.PRODUCT_ID}>{b.plan}</option>
                      </>
                    )
                  })}
                  </Form.Select>
                  
                  <Form.Label className="text-dark my-1">
                    Price : &nbsp; &#8358; {boundlePlan === 0 ? 0 :formatNumber(selectedpricec)}
                  </Form.Label>

                  <Form.Group controlId="formBasicNumber">
                    <Form.Label className="text-dark my-2">
                      Phone Number
                    </Form.Label>
                    <Form.Control
                      placeholder={loginuser.number}
                      type="tel"
                      maxLength={11}
                      onChange={(event) => {
                        setphone(event.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Text className="text-muted">
                   Transaction Limit: {TransactionLimit}
                  </Form.Text>

                  <Button
                    variant={loading ? "warning" : "primary"}
                    disabled={loading || limit <=0 }
                    onClick={handleverifier}
                    className="my-2 w-100"
                    type="submit"
                  >
                    {loading  ? (
                      <>
                        {" "}
                        <Spinner animation="border" size="sm" />{" "}
                        {responspayload}
                      </>
                    ) : (
                      "Buy Data"
                    )}
                  </Button>

                  <Button
                    variant={loading ? "warning" : "danger"}
                    disabled={loading || limit <=0}
                    onClick={history.goBack}
                    className="w-100"
                  >
                    {loading ? (
                      <>
                        {" "}
                        <Spinner animation="border" size="sm" />{" "}
                        {responspayload}
                      </>
                    ) : (
                      "Back"
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Airtimecomponent;
