import React from "react";
import { Link } from "react-router-dom";
import "./Global.css";

function Global() {
  return (
    <div>
      <div className="easywayconytainer">
        <div className="easywaycontentr">
          <div className="easyheader">
            <h1>Easy Step to Get start with us</h1>
          </div>
          <div className="allsjjdhdhd">
            <div className="steps-main ">
              <div className="tabs">
                <div aria-label="Basic example" role="group" className="btn-group w-100  flexmajsjdhjd">
                  <button
                    type="button"
                    className="active  btn btn-secondary  "
                    data-node-index="0"
                    
                  >
                    <img
                      src="https://brand.workingsolutions.com/components/images/cubes-duotone.svg"
                      alt="tab-icon"
                    />
                    <div className="text-dark p-3">
                      Create a Kitipay Account
                    </div>
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary "
                    data-node-index="1"
                  >
                    <img
                      src="https://brand.workingsolutions.com/components/images/address-book-duotone.svg"
                      alt="tab-icon"
                    />
                    <div className="p-3">
                      Verify Account
                      
                    </div>
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary "
                    data-node-index="2"
                  >
                    <img
                      src="https://brand.workingsolutions.com/components/images/face-glasses-duotone.svg"
                      alt="tab-icon"
                    />
                    <div className="p-3">
                      Experience the best
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <center>
           <Link to="/register"><button>Get Started</button></Link>
          </center>
        </div>
      </div>
    </div>
  );
}

export default Global;
