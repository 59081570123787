import React, { useCallback, useEffect, useState } from "react";
import { Button, ButtonGroup, Form, Modal } from "react-bootstrap";
import {
  SendNotification,
  getstauser,
  gettransactin,
  getuserwallet,
} from "../../../Services/GetUser.service";
import moment from "moment";
import { useHistory } from "react-router-dom";
import "./User.css";
import { useAuth } from "../../../Services/UserAuth";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { database } from "../../../Services/firebase/firebase";
import { formatNumber, timeStamptoDate } from "../../../Services/Utility";
import { format } from "date-fns";
import { backendUrl } from "../../../Services/firebase/firebase";
import { getRandomString } from "../../../Services/GetRandomNumber";
import firebase from "firebase";
function UserLog() {
  const [usecollection, setusercollection] = useState([]);
  const [showview, setShowview] = useState(false);
  const { loginuser } = useAuth();
  const handleCloseview = () => setShowview(false);
  const [search, setsearch] = useState("");
  const handleShowview = () => setShowview(true);
  const history = useHistory();
  const [item, setitem] = useState([]);
  const [loading, setloading] = useState(false);
  const [userwallet, setuserwallet] = useState([]);
  const [showmezu, setShowmezu] = useState(false);
  const handleClosemezu = () => setShowmezu(false);
  const handleShowmezu = () => setShowmezu(true);
  const [currentPage, setcurrentpage] = useState(1);
  const recordpage = 100;
  const lastIndex = currentPage * recordpage;
  const firstIndex = lastIndex - recordpage;
  const records = usecollection.slice(firstIndex, lastIndex);
  const npage = Math.ceil(usecollection.length / recordpage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [res, setres] = useState("");
  const [showuser, setShowuser] = useState(false);
  const [userdata, setuserdatacollection] =  useState([])
  const handleCloseuser = () => setShowuser(false);
  const handleShowuser = () => setShowuser(true);

  useEffect(() => {
    gettransactin((result) => {
      setusercollection(result);
    });
  }, []);

  const prepage = () => {
    if (currentPage !== 1) {
      setcurrentpage(currentPage - 1);
    }
  };

  const Nextpage = () => {
    if (currentPage !== npage) {
      setcurrentpage(currentPage + 1);
    }
  };

  const changecurrentPage = (id) => {
    setcurrentpage(id);
  };

  

  const Togglemodalview = useCallback(
    (item) => () => {
      getuserwallet(item.userID, (res) => {
        setuserwallet(res);
      });
      getstauser((result) => {
       
      const user = result.filter((u)=>u.id === item.userID)
        const userd = user[0]
        if(!userd){
          // handleCloseview()
          setuserdatacollection([])
          // toast.error("No user Mapping")
          return
        }
        setuserdatacollection(userd);
      });
      setitem(item);
      handleShowview();
    },
    []
  );

  // const resu = async (inport, NotMezue) => {
  //   const payload = NotMezue ? item : inport;
  //   if (!payload) {
  //     toast.error("Invalid payload");
  //     return;
  //   }

  //   if (item.status === "success" || item.status === "Reverse") {
  //     toast.error("Transaction already resolved");
  //     return;
  //   }

  //   if (item.status !== "success" && payload.status === "success") {
  //     await database.doc(`/Transaction/${item.id}`).update({
  //       isClose: true,
  //       status: "success",
  //     });
  //     toast.success("Transaction Resolved successfully");
  //     const nofifyID = getRandomString(6, "1234567890");
  //     const notificationBody = {
  //       message: `Transaction #${item.TranRef} is now resolved, kindly confirm status`,
  //       created: firebase.firestore.FieldValue.serverTimestamp(),
  //       isread: false,
  //       id: nofifyID,
  //       userID: "",
  //       isRi: 0,
  //     };
  //     await SendNotification(notificationBody, item.userID, "user");
  //   } else if (item.status !== "success" && payload.status === "Reverse") {
  //     const data = {
  //       id: item.userID,
  //       role: loginuser.role,
  //       amount: item.amount,
  //     };
  //     const c = backendUrl + "credituser/users/wallet";
  //     await axios
  //       .post(c, {
  //         data,
  //       })
  //       .then(async (r) => {
  //         await database.doc(`/Transaction/${payload.id}`).update({
  //           isClose: true,
  //           status: "Reverse",
  //         });
  //         toast.success(r.data.message);
  //         handleCloseview();
  //         const nofifyID = getRandomString(6, "1234567890");
  //         const notificationBody = {
  //           message: `Transaction #${item.TranRef} is now reversed, kindly confirm status`,
  //           created: firebase.firestore.FieldValue.serverTimestamp(),
  //           isread: false,
  //           id: nofifyID,
  //           userID: "",
  //           isRi: 0,
  //         };
  //         await SendNotification(notificationBody, payload.userID, "user");
         
  //       });
  //   }
  // };

  const handlemanueresolve = async () => {
    if (loginuser.role !== "admin") {
      toast.error("Auth Error");
      return;
    }
    if (item.isClose === true) {
      toast.error("already Treated");
      return;
    }

    if (item.status === "success") {
      toast.error("Action not allowed");
      return;
    }
    setloading(true)
    await database.doc(`/Transaction/${item.id}`).update({
      isClose: true,
      status: "success",
    });
    toast.success("Transaction Resolved successfully");
    const nofifyID = getRandomString(6, "1234567890");
    const notificationBody = {
      message: `Transaction #${item.TranRef} is now resolved, kindly confirm status`,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      isread: false,
      id: nofifyID,
      userID: "",
      isRi: 0,
    };
    await SendNotification(notificationBody, item.userID, "user");
    setloading(false);
  };

  // const handlemanueresolve = async () => {

  // };
  // console.log(item)
  const handlerevser = async (e) => {
    e.preventDefault();

    if (loginuser.role !== "admin") {
      toast.error("Auth Error");
      return;
    }
    if (item.isClose === true) {
      toast.error("already Treated");
      return;
    }

    if (item.status === "success") {
      toast.error("Action not allowed");
      return;
    }

    // setres("verifying Transaction with Mezuflex");
    setloading(true);
    await axios.post(backendUrl + "credituser/users/wallet", {
        id: item.userID,
        role: loginuser.role,
        amount: item.amount,
      })
      .then(async (r) => {
        await database.doc(`/Transaction/${item.id}`).update({
          isClose: true,
          status: "Reverse",
        });
        // toast.success(r.data.message);
        const nofifyID = getRandomString(6, "1234567890");
        const notificationBody = {
          message: `Transaction #${item.TranRef} is now reversed, kindly confirm status`,
          created: firebase.firestore.FieldValue.serverTimestamp(),
          isread: false,
          id: nofifyID,
          userID: "",
          isRi: 0,
        };
        await SendNotification(notificationBody, item.userID, "user");
        window.location.reload();
      });
    // await axios
    //   .get(backendUrl + "debitadnubAccount/debit/user")
    //   .then(async (response) => {
    //     const resvalue = response.data.payload;
    //     const fetchdata = resvalue.filter(
    //       (e) => e.mexuflexRef === item.mezuflex_id
    //     );
    //     if (
    //       item.company === "Wallet Transfer" ||
    //       item.type === "Gift-card" ||
    //       item.type === "Payment Receipt"
    //     ) {
    //       handleShowmezu();
    //     }
    //     if (fetchdata.length > 0) {
    //       const d = fetchdata[0];
    //      const message = await resu(d);
    //      setres(message)
    //      toast.info(message)
    //     } else {
    //       setres(
    //         "Transaction not on mezuflex list, fetching normal transaction"
    //       );
    //     }
    //   });
    setloading(false);
  };
  
  return (
    <div>
      <ToastContainer />

      {/* <Modal
        show={showmezu}
        onHide={handleClosemezu}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <p>Please make your decision</p>
          <span className="my-2">
            Make Sure you have verify the status of this transaction before you
            proceed with any of the option decision below
          </span>
          <center>
            <Button
              variant="secondary"
              className="ms-1"
              onClick={handlemanueresolve}
            >
              Reverse
            </Button>
            <Button
              variant="primary"
              className="ms-1"
              onClick={getMezuflextransaction}
            >
              Resolve
            </Button>
          </center>
        </Modal.Body>
      </Modal> */}
   <Modal show={showuser} onHide={handleCloseuser}>
        <Modal.Body>
        <div className="biewproductcontinaer">
            <div className="productineofnfjf">
            <span className="text-danger">View User Account Summary/Information</span>
              <div className="productdetaund">
                
                <div className="producttitle">
                  <strong>Full Name</strong>
                </div>
                <div className="productonfprnf">{userdata.firstname} {userdata.lastname}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Email</strong>
                </div>
                <div className="productonfprnf">{userdata.email}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Mobile Number</strong>
                </div>
                <div className="productonfprnf">{userdata.number}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>wallet Balance</strong>
                </div>
                <div className="productonfprnf">{formatNumber(userwallet.wallet)}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>status</strong>
                </div>
                <div className="productonfprnf">{userdata.isBlock ? "Suspended" : "active"}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Date Join</strong>
                </div>
                <div className="productonfprnf">
                  {item.created ? moment(userdata.created).fromNow() : ""}
                </div>
              </div>
      
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseuser}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showview}
        onHide={handleCloseview}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton className="modalheader">
          <div className="allowallfix">
            <p>Transaction Details</p>
            <button onClick={handleShowuser}>View user</button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="biewproductcontinaer">
            <div className="productineofnfjf">
              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Transaction Type</strong>
                </div>
                <div className="productonfprnf">{item.type}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Service Type</strong>
                </div>
                <div className="productonfprnf">{item.company}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Amount</strong>
                </div>
                <div className="productonfprnf">{item.amount}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Account Number</strong>
                </div>
                <div className="productonfprnf">{item.AccountNumber}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>status</strong>
                </div>
                <div className="productonfprnf">{item.status}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Reference</strong>
                </div>
                <div className="productonfprnf">{item.TranRef}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Date</strong>
                </div>
                <div className="productonfprnf">
                  {item.created ? moment(item.created).fromNow() : ""}
                </div>
              </div>
              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Row Data</strong>
                </div>
                <div className="productonfprnf">{JSON.stringify(item)}</div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <center>{res}</center>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handlerevser}
            style={{ display: item.isClose === true ? "none" : "block" }}
            disabled={item.isClose === true || loading}
          >
            {loading ? "Proccessing" : "Reverse"}
          </Button>

          <Button
              variant="success"
              className="ms-1"
              onClick={handlemanueresolve}
              style={{ display: item.isClose === true ? "none" : "block" }}
              disabled={item.isClose === true || loading}
            >
               {loading ? "Proccessing" : "Resolve"}
            </Button>

          <Button variant="secondary" onClick={handleCloseview}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="giftcardocne">
        <h1>Transaction History</h1>
        <div className="girckahdicbd">
          <button onClick={history.goBack}>Back</button>

          <button className="ms-1 bg-info">
            {usecollection.length} Transaction
          </button>

          <Form.Control
            type="text"
            placeholder="Search by Name"
            className="w-25 ms-1"
            onChange={(event) => {
              setsearch(event.target.value);
            }}
          />
        </div>
        <div className="cardshowarea">
          <div className="cardvaljsjs">
            <table>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th className="hidetable">Created</th>
                  <th>Type</th>
                  <th className="hidetable">Service Type</th>
                  <th className="hidetable">Amount</th>
                  <th className="hidetable">Account Number</th>
                  <th>Validity</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {records.length === 0 ? (
                  <>No Data Found</>
                ) : (
                  <>
                    {records
                      .filter((value) => {
                        if (value === "") {
                          return value;
                        } else if (
                          value.company
                            .toLowerCase()
                            .includes(search.toLowerCase())
                        ) {
                          return value;
                        } else if (
                          value.type
                            .toLowerCase()
                            .includes(search.toLowerCase())
                        ) {
                          return value;
                        } else if (
                          value.TranRef.toLowerCase().includes(
                            search.toLowerCase()
                          )
                        ) {
                          return value;
                        }
                      })
                      .map((item, id) => {
                        const d = timeStamptoDate(item);
                        const date = moment(d.created).fromNow();
                        return (
                          <tr key={id}>
                            <td>{id + 1}</td>
                            <td className="hidetable">
                              {item.created
                                ? format(item.created, "dd/MM/yyyy hh:mm a")
                                : ""}
                            </td>
                            <td>{item.type}</td>
                            <td className="hidetable">{item.company}</td>
                            <td className="hidetable">{item.amount}</td>
                            <td className="hidetable">{item.AccountNumber}</td>
                            <td>{item.isClose === true ? "Closed" : "Open"}</td>
                            <td>{item.status}</td>
                            <td>
                              <ButtonGroup aria-label="Basic example">
                                <Button
                                  variant="success"
                                  onClick={Togglemodalview(item)}
                                >
                                  View
                                </Button>
                              </ButtonGroup>
                            </td>
                          </tr>
                        );
                      })}
                  </>
                )}
              </tbody>
            </table>
            <nav>
              <ul className="pageination">
                <li className="page-item">
                  <span className="page-link" onClick={prepage}>
                    Prev
                  </span>
                </li>

                {numbers.slice(0, 10).map((n, i) => {
                  return (
                    <li
                      key={i}
                      className={
                        currentPage === n ? "activenumber" : "page-item"
                      }
                    >
                      <span
                        className="page-link"
                        onClick={() => changecurrentPage(n)}
                      >
                        {n < 1 ? 1 : n}
                      </span>
                    </li>
                  );
                })}

                <li className="page-item">
                  <span className="page-link" onClick={Nextpage}>
                    Next
                  </span>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserLog;
