import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import "./Settings.css";
import { ToastContainer, toast } from "react-toastify";
import firebase from "firebase";
import { useAuth } from "../../../Services/UserAuth";
import axios from "axios";
import { FaClosedCaptioning } from "react-icons/fa";
import { getRandomString } from "../../../Services/GetRandomNumber";
import { backendUrl } from "../../../Services/firebase/firebase";
function SettingCom() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setres("");
  };

  const [showpin, setShowpin] = useState(false);
  const handleClosepin = () => setShowpin(false);
  const handleShowpin = () => {
    setShowpin(true);
    setres("");
  };
  const [oldpassword, setoldpassword] = useState("");
  const [password, setpassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");
  const [loading, setloading] = useState(false);
  const [res, setres] = useState(false);
  const { loginuser } = useAuth();
  const [oldpin, setoldpin] = useState("");
  const [pin, setpin] = useState("");
  const [confirmPin, setconfirmpin] = useState("");
  const user = firebase.auth().currentUser;
  const { logout } = useAuth();

  const handleChane = (e) => {
    e.preventDefault();
    if (password !== confirmpassword) {
      toast.error("Password Mismatch", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    setloading(true);

    const cred = firebase.auth.EmailAuthProvider.credential(
      loginuser.email,
      oldpassword
    );

    if (!cred) {
      setres("No Record Found");
      return;
    }
    user
      .reauthenticateWithCredential(cred)
      .then((e) => {
        setres("Validation in progress");

        return user.updatePassword(password);
      })
      .then(async () => {
        setres("Password updated Successfully");
        toast.success("Password updated Successfully");
        setloading(false);
        const UniquieNum = getRandomString(30, "1234567890");
        console.log("1234");
        await axios.post(
          backendUrl+"userlogs/user/actions",
          {
            action: `user ${loginuser.firstname} ${loginuser.lastname} reset password successfully`,
            action_type: "password reset",
            process_id: UniquieNum,
            changes: "password reset",
            created: firebase.firestore.FieldValue.serverTimestamp(),
            userid: loginuser.id,
            type: "password reset",
          }
        );
      })
      .catch((error) => {
        toast.success(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setloading(false);
      });
  };

  const handlechanegPind = async (e) => {
    e.preventDefault();
    if (confirmPin !== pin) {
      toast.error("Password Mismatch", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (oldpin === pin) {
      toast.error("error, try again", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (loginuser.pin !== oldpin) {
      toast.error("v-error of null", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (!loginuser.pin) {
      toast.error("yet to create pin. please create one now", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (loginuser.status === "restricted") {
      toast.error("Account restricted try again later", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    setloading(true);
    await axios
      .put(
        backendUrl+`sendVerificationsmskitipay/${loginuser.id}`,
        {
          newpin: pin,
        }
      )
      .then((resu) => {
        if (resu.data.status === "success") {
          toast.success(resu.data.message);
          return;
        }
        toast.error(resu.data.message);
      });
    setloading(false);
  };

  const handleCloseAccounrt = async () => {
    const confirm = window.confirm(
      "Are you sure you want to close your account. Please note that proceeding with this process will lead to lost of data."
    );
    if (!confirm) {
      return;
    }
    setloading(true)
    await axios
      .post(
        backendUrl+`deleteAccoiut/delete/account`,
        {
          id: loginuser.id,
        }
      )
      .then(async(e) => {
        console.log(e)
        await logout();
        window.location.href = "/login";
      });
    setloading(false);
  };

  return (
    <div>
      <ToastContainer />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
        keyboard={false}
      >
        <Modal.Header closeButton className="bg-primary text-white">
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="reactpashallnew">
            <div className="allvalierestpass">
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Old Password</Form.Label>
                <Form.Control
                  type="password"
                  onChange={(event) => {
                    setoldpassword(event.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  onChange={(event) => {
                    setpassword(event.target.value);
                  }}
                />
                <Form.Text className="text-danger">
                  Please don't share your password with anyone..
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Confirm New Password</Form.Label>
                <Form.Control
                  type="password"
                  onChange={(event) => {
                    setconfirmpassword(event.target.value);
                  }}
                />
              </Form.Group>

              <center>{res}</center>

              <Button
                variant={loading ? "warning" : "primary"}
                disabled={loading}
                onClick={handleChane}
                className="w-100"
                type="submit"
              >
                {loading ? "processing" : "Reset Password"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showpin}
        onHide={handleClosepin}
        backdrop="static"
        centered
        keyboard={false}
      >
        <Modal.Header closeButton className="bg-primary text-white">
          <Modal.Title>Reset Transaction Pin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="reactpashallnew">
            <div className="allvalierestpass">
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Old Pin</Form.Label>
                <Form.Control
                  type="password"
                  maxLength={4}
                  onChange={(event) => {
                    setoldpin(event.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>New Pin</Form.Label>
                <Form.Control
                  type="password"
                  maxLength={4}
                  onChange={(event) => {
                    setpin(event.target.value);
                  }}
                />
                <Form.Text className="text-danger">
                  Please don't share your password with anyone..
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Confirm New Pin</Form.Label>
                <Form.Control
                  type="password"
                  maxLength={4}
                  onChange={(event) => {
                    setconfirmpin(event.target.value);
                  }}
                />
              </Form.Group>

              <center>{res}</center>

              <Button
                variant={loading ? "warning" : "primary"}
                disabled={loading}
                onClick={handlechanegPind}
                className="w-100"
                type="submit"
              >
                {loading ? "processing" : "Reset Pin"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="securitycontainer">
        <div className="secusriconejfjf">
          <div className="securinahshh">
            <h1>Account Security</h1>
            <span>
              Kitipay maintains high standards for account security. That's why
              we've provided a couple of ways for you to protect your account.
            </span>
          </div>
          <hr />
          <div className="maonatsujdhd">
            <div className="textseidnd">
              <h1>Password Reset</h1>
              <span>
                Keep your password safe. We recommend you change your password
                periodically.
              </span>
            </div>
            <button onClick={handleShow}>Change Password</button>
          </div>

          <hr />

          <div className="maonatsujdhd">
            <div className="textseidnd">
              <h1> Pin reset</h1>
              <span>
                Keep your pin safe. We recommend you change your pin
                periodically.
              </span>
            </div>
            <button onClick={handleShowpin}>Change Pin</button>
          </div>

          <hr />
          <div className="maonatsujdhd">
            <div className="textseidnd">
              <h1>Two-Factor Authentication</h1>
              <span>
                Add extra security on your account to authenticate your login
                and transactions.
              </span>
            </div>
            <button>Get Started</button>
          </div>

          <hr />
          <div
            
            className="maonatsujdhd"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <button disabled={loading} onClick={handleCloseAccounrt}>Close Account</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SettingCom;
