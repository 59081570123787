import React from 'react'
import NewsLetter from '../NewsLetter'
import Banner from './Banner'
import Becomevendor from './Becomevendor'
import DeliverPassion from './DeliverPassion'
import Footer from './Footer'
import GiftcardLayer from './GiftcardLayer'
import Global from './Global'
import Navbar from "./Navbar"
import OneBest from './OneBest'
import Partners from './Partners'

function Home() {
  return (
    <div>
      <Navbar/>
      <Banner/>
      <Becomevendor/>
      <Global/>
      <OneBest/>
      {/* <DeliverPassion/> */}
      {/* <Partners/> */}
      <Footer/>
    </div>
  )
}

export default Home