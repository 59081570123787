import AdminSidemenu from "../AdminComponent/AdminSidemenue";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import "../../Sidemiindex.css";
import { SendNotification, getuserdispute } from "../../../Services/GetUser.service";
import { Button, Form } from "react-bootstrap";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { storage } from "../../../Services/firebase/firebase";
import { backendUrl } from "../../../Services/firebase/firebase";
import firebase from "firebase";
import { getRandomString } from "../../../Services/GetRandomNumber";

function Dashboard() {
  const history = useHistory();
  const [statusvalue, setstatusvalue] = useState("");
  const [loading, setloading] = useState(false);
  const id = useParams().id;
  const [dispute, setdispute] = useState([]);
  const [image, setimage]=useState([])
  const [progress, setProgress]=  useState()
  useEffect(() => {
    getuserdispute(id, (res) => {
      setdispute(res);
    });
  }, []);

  if (dispute.isDispute === false){
    return (
        <>
         Invalide Request
        </>
    )
  }

  const p =  parseFloat(statusvalue)

  const handleChange = (e) => {
    if (image === null){
     toast.error("empty image not allowed")
     return
    }else{
     for (let i = 0; i < e.target.files.length; i++) {
       const newImage = e.target.files[i];
       newImage["id"] = Math.random();
       setimage((prevState) => [...prevState, newImage]);
     }
    }
   };

   const handleUpload = (img) => {
    return new Promise((resolve) => {
      // not working as expected
      const uploadTask = storage.ref(`disputDOc/${img.name}`).put(img);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          
        },
        async () => {
          storage
            .ref("disputDOc")
            .child(img.name)
            .getDownloadURL()
            .then((url) => {
              resolve(url);
            });
        }
      );
    });
  };


  const handleresoved = async (e) => {
    if (statusvalue === "") {
      toast.error("Make a decision");
      return;
    }
  
    if (p === 3){
        setloading(true);
        try {
          const b =  backendUrl+ "DisputeREsolved/report/user"
          await axios
            .post(
              b,
              {
                id: dispute.id,
                status: parseFloat(statusvalue),
              }
            )
            .then((res) => {
              toast.success("Disput resolved");
            });

            const nofifyID = getRandomString(6, "1234567890")
            const notificationBody = {
              message: `Action required on ${dispute.productname} dispute created`,
              created: firebase.firestore.FieldValue.serverTimestamp(),
              isread: false,
              id: nofifyID,
              userID:"",
              isRi: 0
            }
          await SendNotification(notificationBody, dispute.customerID, "user") 
        } catch (error) {
          toast.error(error.message);
        }
        setloading(false)
        return
    }
    setloading(true);
    const urls = await Promise.all(image.map((img) => handleUpload(img)));
    image.map((j, i) => {
      const filename = j.name;
      const imageSize = Math.round(j.size / 1024 / 1024);
      if (imageSize > 200) {
        toast.error("file to large. Max:200mb");
        return;
      }
      const fileExtension = filename.substring(filename.lastIndexOf(".") + 1);
      const ImageExtension = ["JPG", "JPEG", "PNG", "jpg", "jpeg", "png", "pdf", "doc" ,"docx", "xls", "xlsx"];
      const valeinc = ImageExtension.includes(fileExtension);

      if (!valeinc) {
        toast.error("file not supported");
        setloading(false);
        return
      }

    });

    try {
      const v =  backendUrl+ "DisputeREsolved/report/user"
      await axios
        .post(
         v,
          {
            id: dispute.id,
            status: parseFloat(statusvalue),
            evidentimage:urls
          }
        )
        .then((res) => {
          toast.success(res.data.message);
        });
        
        const nofifyID = getRandomString(6, "1234567890")
        const notificationBody = {
          message: `Action required on ${dispute.productname} dispute created`,
          created: firebase.firestore.FieldValue.serverTimestamp(),
          isread: false,
          id: nofifyID,
          userID:"",
          isRi: 0
        }
      await SendNotification(notificationBody, dispute.VendorID, "user")
    } catch (error) {
      toast.error(error.message);
    }

    setloading(false);
  };


  return (
    <div>
      <ToastContainer />
      <div className="pagecon">
        <div className="pagecontent">
          <div className="headersisecoadmin">
            <AdminSidemenu />
          </div>
          <div className="otherssecoth">
            <div className="dashboardconteier">
              <div className="crallsjcoindjjd">
                <div className="reportcontainer">
                  <div className="reporcteme">Dispute Resolution</div>
                  <div className="discbdhdhd">
                    Base on the finding, the following decision can be made.
                    Please not that dont act on duress. make sure all fate are
                    intact and correct before making any of the following
                    decision. if your decision is not list below.. dont act
                    otherwise. make sure you get document to back your decision
                    where neccessary as advise by the legal team
                  </div>
                  <div className="decisoncdjdjd">
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(event) => {
                        setstatusvalue(event.target.value);
                      }}
                    >
                      <option>Open this make decision</option>
                      <option value="1">
                        vendor Account Will be credited and transaction close
                      </option>
                      <option value="2">
                        customer Account will be credited and transaction close
                      </option>
                      <option value="3">
                        Allow the customer to close the transaction
                      </option>
                    </Form.Select>

                    <Form.Group controlId="formFileMultiple" className="mb-3" style={{display: p === 1 || p === 2 ? "block" : "none"}}>
                      <Form.Label>Upload Supporting  document </Form.Label>
                      <Form.Control disabled={progress} type="file" multiple  onChange={handleChange}/>
                    </Form.Group>
                    <Button
                      onClick={handleresoved}
                      variant={loading ? "warning" : "primary"}
                      disabled={loading}
                      className="w-100"
                    >
                      {loading ? "Resolving Command ...." : "Proceed"}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
