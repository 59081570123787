import React, { useState } from "react";
import { useEffect } from "react";
import {
  FaTh,
  FaBars,
  FaUserAlt,
  FaRegChartBar,
  FaGift,
  FaServicestack,
  FaListUl
} from "react-icons/fa";
import {
  MdTransferWithinAStation,
  MdSupportAgent,
  MdPayment,
} from "react-icons/md";
import { FiLogOut } from "react-icons/fi";
import { AiOutlineBell } from "react-icons/ai";
import { NavLink, useHistory } from "react-router-dom";
import { useAuth } from "../../../Services/UserAuth";
import firebase from "firebase";
import "./Amin.css";
import { database } from "../../../Services/firebase/firebase";
import {
  getuser,
  getuserNotificationr,
} from "../../../Services/GetUser.service";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Toast from "react-bootstrap/Toast";
import moment from "moment";

const Sidebar = ({ children }) => {
  const { uid } = useAuth().currentUser;
  const { loginuser, logout } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [show, setShow] = useState(false);
  const [correntpassword, setcurrentpassword] = useState("");
  const [newpassord, setnewpassword] = useState("");
  const [comfirmpassword, setcomfirmpassword] = useState("");
  const [pin, setpin] = useState(0);
  const [error, seterror] = useState("");
  const [message, setmessage] = useState("");
  const history = useHistory();
  const [valid, setvalid] = useState(false);
  const [notification, setnotification] = useState([]);
  var user = firebase.auth().currentUser;
  const [shownotify, setShownotification] = useState(false);
  const handleClose = () => {
    history.push("/auth/logout");
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const handleShowclosr = () => setShow(false);

  useEffect(() => {
    getuser(uid, (result) => {
      if (result.role !== "admin") {
        history.push("/auth/login");
      }
    });

    getuserNotificationr(uid, (result) => {
      const readN = result.filter((e) => e.isread === false);
      setnotification(readN);
    });
  }, []);

  const handleMarkasread = async () => {
    if (notification.length > 0) {
      for (let index = 0; index < notification.length; index++) {
        const element = notification[index];
        await database.doc(`notification/${element.id}`).update({
          isread: true,
        });
      }
      setShownotification(false);
      return;
    }
  };
  const handlecloaseNoid = async () => {
    setShownotification(false);
  };

  const handleupdate = (e) => {
    e.preventDefault();

    if (
      correntpassword === "" ||
      newpassord === "" ||
      comfirmpassword === "" ||
      pin === ""
    ) {
      return seterror("Missing Value");
    }
    if (newpassord !== comfirmpassword) {
      return seterror("New Password MisMatch");
    }
    if (pin.length > 4) {
      return seterror("Max Pin length is 4 and should be a number");
    }
    if (isNaN(pin)) {
      return seterror("Only Number required");
    }
    const cred = firebase.auth.EmailAuthProvider.credential(
      loginuser.email,
      correntpassword
    );
    user
      .reauthenticateWithCredential(cred)
      .then((e) => {
        // console.log(e)
        return user.updatePassword(newpassord);
      })
      .then(async () => {
        await database.doc(`users/${loginuser.id}`).update({
          access: true,
          pin: pin,
          created: firebase.firestore.FieldValue.serverTimestamp(),
        });
        handleShowclosr();
      })
      .catch((error) => {
        setmessage(error.message);
      });
    seterror("");
    setmessage("");
  };

  const menuItem = [
    {
      path: `/admin/dashboard`,
      name: "Dashboard",
      icon: <FaTh />,
    },
    {
      path: "/log/user",
      name: "User",
      icon: <FaUserAlt />,
    },
    {
      path: "/transaction",
      name: "Transaction",
      icon: <MdTransferWithinAStation />,
    },

    {
      path: "/payment/transaction/dashboard",
      name: "Payment",
      icon: <MdPayment />,
    },

    {
      path: "/gift-card-management",
      name: "G-Services",
      icon: <FaGift />,
    },
    {
      path: "/Vendor-Management",
      name: "V-Services",
      icon: <FaServicestack />,
    },
    {
      path: "/admin/support-center/",
      name: "Support",
      icon: <MdSupportAgent />,
    },
    {
      path: "/control/system-center",
      name: "Control",
      icon: <FaRegChartBar />,
    },

    {
      path: "/logs",
      name: "Logs",
      icon: <FaListUl/>,
    },
    {
      path: "/auth/logout",
      name: "Logout",
      icon: <FiLogOut />,
    },
  ];
  return (
    <>
      <div className="containersss">
        <div
          style={{ width: isOpen ? "200px" : "50px", zIndex: isOpen ? 3 : 0 }}
          className="sidebaradmin"
        >
          <div className="top_section11">
            <h1
              style={{ display: isOpen ? "block" : "none" }}
              className="logo1"
            >
              <img src="/img/v1.png" alt="" />
            </h1>
            <div
              style={{ marginLeft: isOpen ? "50px" : "0px" }}
              className="bars"
            >
              <FaBars onClick={toggle} />
            </div>
          </div>
          {menuItem.map((item, index) => (
            <NavLink
              to={item.path}
              key={index}
              className="link"
              activeclassName="active"
            >
              <div className="icon">{item.icon}</div>
              <div
                style={{ display: isOpen ? "block" : "none" }}
                className="link_text"
              >
                {item.name}
              </div>
            </NavLink>
          ))}
        </div>
        <main>{children}</main>
        <div className="sidtopbajsjd">
          <div className="tocontaone">
            <button
              type="button"
              class="icon-button"
              onClick={() => setShownotification(true)}
            >
              <span class="material-icons">
                <AiOutlineBell />
              </span>
              <span
                class="icon-button__badge"
                style={{
                  display: notification.length === 0 ? "none" : "block",
                }}
              >
                {notification.length}
              </span>
            </button>

            <div className="notficbody">
              <div className="notiicaidod">
                <Col xs={6}>
                  <Toast
                    onClose={handlecloaseNoid}
                    show={shownotify}
                    // delay={3000}
                    // autohide
                    className="backinshs"
                  >
                    <Toast.Header>
                      <strong className="me-auto text-dark">
                        Notification
                      </strong>
                    </Toast.Header>
                    <Toast.Body>
                      {notification.length === 0 ? (
                        <>
                          <center>No message</center>
                        </>
                      ) : (
                        <>
                          {notification.slice(0,5).map((item) => {
                            const date = moment(item.created).fromNow();
                            return (
                              <>
                                <p>{item.message}</p>
                                <small className="text-warning">{date}</small>
                                <hr />
                              </>
                            );
                          })}
                          <Button variant="outline-success" className="my-2" onClick={handleMarkasread}>Mark all as read</Button>
                        </>
                      )}
                    </Toast.Body>
                  </Toast>
                </Col>
              </div>
            </div>
            <div className="usesjsd">
              {loginuser.firstname} {loginuser.lastname}
            </div>
            <div className="iconsiexcoj">
              <img src={loginuser.image} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
