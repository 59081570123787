import { database, examePrce } from "../Services/firebase/firebase";
import { TimestampDate } from "timestamp-date";
import axios from "axios";
import firebase from "firebase";

const timestampDate = new TimestampDate();

export const getuser = (id, cd) => {
  const ref = database.doc(`users/${id}`);
  ref.onSnapshot((queryproduct) => {
    const user = queryproduct.data();
    cd(user);
  });
};

export const getuserdispute = (id, cd) => {
  const ref = database.doc(`VendorRequest/${id}`);
  ref.onSnapshot((queryproduct) => {
    const user = queryproduct.data();
    cd(user);
  });
};

export const getExamPrice = (cd) => {
  const ref = database.doc(`income/${examePrce}`);
  ref.onSnapshot((queryproduct) => {
    const user = queryproduct.data();
    cd(user);
  });
};


export const getimcome = (id, cd) => {
  const ref = database.doc(`getimcome/${id}`);
  ref.onSnapshot((queryproduct) => {
    const user = queryproduct.data();
    cd(user);
  });
};

export const getuserwallet = (id, cd) => {
  const ref = database.doc(`wallet/${id}`);
  ref.onSnapshot((queryproduct) => {
    const user = queryproduct.data();
    cd(user);
  });
};

export const getUsersEmail = (email) => {
  return new Promise((resolve) => {
    const ref = database.collection("newsletter");
    let reference = ref;

    if (email) {
      reference = reference.where("email", "==", email);
    }
    reference.onSnapshot((queryproduct) => {
      const items = [];
      queryproduct.forEach((doc) => {
        const item = doc.data();
        item.id = doc.id;
        items.push(timestampDate.parseTimestampToDate(item));
      });

      resolve(items);
    });
  });
};

export const getstauser = (cb) => {
  const ref = database.collection("users").orderBy("created", "desc");
  let reference = ref;
  reference.onSnapshot((queryproduct) => {
    const items = [];
    queryproduct.forEach((doc) => {
      const item = doc.data();
      item.id = doc.id;
      items.push(timestampDate.parseTimestampToDate(item));
    });
    cb(items);
  });
};

export const getGiftcard = (cb) => {
  const ref = database.collection("GiftCardRequest").orderBy("created", "desc");
  let reference = ref;

  reference.onSnapshot((queryproduct) => {
    const items = [];
    queryproduct.forEach((doc) => {
      const item = doc.data();
      item.id = doc.id;
      items.push(timestampDate.parseTimestampToDate(item));
    });
    cb(items);
  });
};

export const getUserByUrl=(url, cd) =>{
  const ref = database.collection("resetpassword");
  let reference = ref;
  if (url) {
    reference = reference.where("url", "==", url);
  }
  reference.onSnapshot((queryproduct) => {
    const items = [];
    queryproduct.forEach((doc) => {
      const item = doc.data();
      item.id = doc.id;
      items.push(timestampDate.parseTimestampToDate(item));
    });
    cd(items);
  });
}

export const getGiftcarduser = (id, cb) => {
  const ref = database.collection("GiftCardRequest").orderBy("created", "desc");
  let reference = ref;
  if (id) {
    reference = reference.where("userID", "==", id);
  }
  reference.onSnapshot((queryproduct) => {
    const items = [];
    queryproduct.forEach((doc) => {
      const item = doc.data();
      item.id = doc.id;
      items.push(timestampDate.parseTimestampToDate(item));
    });
    cb(items);
  });
};

export const gettransactin = (cb) => {
  const ref = database.collection("Transaction").orderBy("created", "desc");
  let reference = ref;
  reference.onSnapshot((queryproduct) => {
    const items = [];
    queryproduct.forEach((doc) => {
      const item = doc.data();
      item.id = doc.id;
      items.push(timestampDate.parseTimestampToDate(item));
    });
    cb(items);
  });
};

export const getProducts = (cb) => {
  const ref = database.collection("product").orderBy("created", "desc");
  let reference = ref;
  reference.onSnapshot((queryproduct) => {
    const items = [];
    queryproduct.forEach((doc) => {
      const item = doc.data();
      item.id = doc.id;
      items.push(timestampDate.parseTimestampToDate(item));
    });
    cb(items);
  });
};

export const getOrder = (cb) => {
  const ref = database.collection("Oder").orderBy("created", "desc");
  let reference = ref;
  reference.onSnapshot((queryproduct) => {
    const items = [];
    queryproduct.forEach((doc) => {
      const item = doc.data();
      item.id = doc.id;
      items.push(timestampDate.parseTimestampToDate(item));
    });
    cb(items);
  });
};

export const getincome = (cb) => {
  const ref = database.collection("income");
  let reference = ref;
  reference.onSnapshot((queryproduct) => {
    const items = [];
    queryproduct.forEach((doc) => {
      const item = doc.data();
      item.id = doc.id;
      items.push(timestampDate.parseTimestampToDate(item));
    });
    cb(items);
  });
};

export const getsupport = (cb) => {
  const ref = database.collection("SupportMessage").orderBy("created", "desc");
  let reference = ref;
  reference.onSnapshot((queryproduct) => {
    const items = [];
    queryproduct.forEach((doc) => {
      const item = doc.data();
      item.id = doc.id;
      items.push(timestampDate.parseTimestampToDate(item));
    });
    cb(items);
  });
};

export const getVendorMessage = (cb) => {
  const ref = database.collection("VendorMessage").orderBy("created", "desc");
  let reference = ref;
  reference.onSnapshot((queryproduct) => {
    const items = [];
    queryproduct.forEach((doc) => {
      const item = doc.data();
      item.id = doc.id;
      items.push(timestampDate.parseTimestampToDate(item));
    });
    cb(items);
  });
};

export const getsinglesupport = (id, cb) => {
  const ref = database.collection("SupportMessage").orderBy("created", "desc");
  let reference = ref;
  if (id) {
    reference = reference.where("id", "==", id);
  }

  reference.onSnapshot((queryproduct) => {
    const items = [];
    queryproduct.forEach((doc) => {
      const item = doc.data();
      item.id = doc.id;
      items.push(timestampDate.parseTimestampToDate(item));
    });
    cb(items);
  });
};

export const getusertransactiondetails = (id, cb) => {
  const ref = database.collection("Transaction").orderBy("created", "desc");
  let reference = ref;
  if (id) {
    reference = reference.where("id", "==", id);
  }
  reference.onSnapshot((queryproduct) => {
    const items = [];
    queryproduct.forEach((doc) => {
      const item = doc.data();
      item.id = doc.id;
      items.push(timestampDate.parseTimestampToDate(item));
    });
    cb(items);
  });
};

export const getCardschecker = (card, cb) => {
  const ref = database.collection("GiftcarName");
  let reference = ref;
  if (card) {
    reference = reference.where("name", "==", card);
  }
  reference.onSnapshot((queryproduct) => {
    const items = [];
    queryproduct.forEach((doc) => {
      const item = doc.data();
      item.id = doc.id;
      items.push(timestampDate.parseTimestampToDate(item));
    });
    cb(items);
  });
};

export const getCards = (card, cb) => {
  const ref = database.collection("GiftcarName").orderBy("name", "asc");
  let reference = ref;
  if (card) {
    reference = reference.where("name", "==", card);
  }
  reference.onSnapshot((queryproduct) => {
    const items = [];
    queryproduct.forEach((doc) => {
      const item = doc.data();
      item.id = doc.id;
      items.push(timestampDate.parseTimestampToDate(item));
    });
    cb(items);
  });
};

export const getprocessTransaction = (cb) => {
  const ref = database
    .collection("ProccessTransfer")
    .orderBy("created", "desc");
  let reference = ref;
  reference.onSnapshot((queryproduct) => {
    const items = [];
    queryproduct.forEach((doc) => {
      const item = doc.data();
      item.id = doc.id;
      items.push(timestampDate.parseTimestampToDate(item));
    });
    cb(items);
  });
};

export const SendNotification = async (message, user_id, pipe) => {
  if (pipe === "both") {
    await axios
      .get(
        `https://us-central1-kitipay-stagging.cloudfunctions.net/sendVerificationsmskitipay`
      )
      .then(async (result) => {
        const admins = result.data;
        const selected_ad = admins.filter((e) => e.permissions === "admin");
        for (let index = 0; index < selected_ad.length; index++) {
          const element = selected_ad[index];
          const notificationBody = {
            message: message.message,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            isread: false,
            id: message.id,
            userID: element.id,
            isRi: 0,
          };
          await database.collection("notification").add(notificationBody);
        }
      });
    message.userID = user_id;
    await database.collection("notification").add(message);
    return;
  }

  if (pipe === "admin") {
    await axios
      .get(
        `https://us-central1-kitipay-stagging.cloudfunctions.net/sendVerificationsmskitipay`
      )
      .then(async (result) => {
        const admins = result.data;
        const selected_ad = admins.filter((e) => e.permissions === "admin");
        for (let index = 0; index < selected_ad.length; index++) {
          const element = selected_ad[index];
          if (element.permissions !=="admin"){
            return
          }
          const notificationBody = {
            message: message.message,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            isread: false,
            id: message.id,
            userID: element.id,
            isRi: 0,
          };
          await database.collection("notification").add(notificationBody);
        }
      });
    return;
  }

  if (pipe === "user") {
    message.userID = user_id;
    await database.collection("notification").add(message);
    return;
  }
};

export const getuserNotificationr = (id, cb) => {
  const ref = database.collection("notification").orderBy("created", "desc");
  let reference = ref;
  if (id) {
    reference = reference.where("userID", "==", id);
  }
  reference.onSnapshot((queryproduct) => {
    const items = [];
    queryproduct.forEach((doc) => {
      const item = doc.data();
      item.id = doc.id;
      items.push(timestampDate.parseTimestampToDate(item));
    });
    cb(items);
  });
};

export const getversion = (cd) => {
  const ref = database.collection("version");
  let reference = ref;
  reference.onSnapshot((queryproduct) => {
    const items = [];
    queryproduct.forEach((doc) => {
      const item = doc.data();
      item.id = doc.id;
      items.push(item);
    });

    cd(items);
  });
};

export const getOrderRecieved = (id, cd) => {
  const ref = database.doc(`Oder/${id}`);
  ref.onSnapshot((queryproduct) => {
    const user = queryproduct.data();
    cd(user);
  });
};

export const getCurrentPayload = (payload) => {
  if (!payload) {
    return [false, []];
  }

  if (
    payload.company === "abuja_electric_prepaid" &&
    payload.status === "success"
  ) {
    const d = payload.payload;
    const c = d.data;
    const e = c.rawOutput;
    const dataarray = {
      token: e.token,
      units: e.units,
      accountBalance: e.accountBalance,
      debtAmount: e.debtAmount,
    };
    return [true, dataarray];
  }

  if (
    payload.company === "portharcourt_electric_prepaid" &&
    payload.status === "success"
  ) {
    const d = payload.payload;
    const c = d.data;
    const dataarray = {
      token: c.tokenCode,
      units: "",
      accountBalance: "0",
      debtAmount: "0",
    };
    return [true, dataarray];
  }

  if (
    payload.company === "benin_electric_prepaid" &&
    payload.status === "success"
  ) {
    const d = payload.payload;
    const c = d.data;
    const e = c.rawOutput;
    const dataarray = {
      token: e.token,
      units: e.amountOfPower,
      accountBalance: e.accountBalance,
      debtAmount: e.debtAmount,
    };
    return [true, dataarray];
  }

  if (
    payload.company === "eko_electric_prepaid" &&
    payload.status === "success"
  ) {
    const d = payload.payload;
    const c = d.data;
    const e = c.rawOutput;
    const dataarray = {
      token: e.creditToken,
      units: e.amountOfPower,
      accountBalance: "0",
      debtAmount: e.debtAmount,
    };
    return [true, dataarray];
  }

  if (
    payload.company === "ikeja_electric_prepaid" &&
    payload.status === "success"
  ) {
    const d = payload.payload;
    const c = d.data;
    const e = c.rawOutput;
    const dataarray = {
      token: e.creditToken,
      units: e.amountOfPower,
      accountBalance: "0",
      debtAmount: e.debtAmount,
    };
    return [true, dataarray];
  }

  if (
    payload.company === "jos_electric_prepaid" &&
    payload.status === "success"
  ) {
    const d = payload.payload;
    const c = d.data;
    const e = c.rawOutput;
    const dataarray = {
      token: e.token,
      units: e.units,
      accountBalance:e.outstandingPaid,
      debtAmount: e.debtAmount,
    };
    return [true, dataarray];
  }

  
  if (
    payload.company === "enugu_electric_prepaid" &&
    payload.status === "success"
  ) {
    const d = payload.payload;
    const c = d.data;
    const e = c.rawOutput;
    const dataarray = {
      token: e.token,
      units: e.units,
      accountBalance:e.arrearsBalance,
      debtAmount: e.appliedToArrears,
    };
    return [true, dataarray];
  }

  if (
    payload.company === "kaduna_electric_prepaid" &&
    payload.status === "success"
  ) {
    const d = payload.payload;
    const c = d.data;
    const e = c.rawOutput;
    const dataarray = {
      token: e.token,
      units: e.units,
      accountBalance:null,
      debtAmount: e.debtAmount,
    };
    return [true, dataarray];
  }

  if (
    payload.company === "ibadan_electric_prepaid" &&
    payload.status === "success"
  ) {
    const d = payload.payload;
    const c = d.data;
    const e = c.rawOutput;
    const dataarray = {
      token: e.token,
      units: e.units,
      accountBalance:null,
      debtAmount: e.debtAmount,
    };
    return [true, dataarray];
  }

  if (
    payload.company === "kedco_electric_prepaid" &&
    payload.status === "success"
  ) {
    const d = payload.payload;
    const c = d.data;
    const e = c.rawOutput;
    const dataarray = {
      token: e.token,
      units: e.units,
      accountBalance:null,
      debtAmount: e.debtAmount,
    };
    return [true, dataarray];
  }

  return[false, "Error"]

};
