import React, { useCallback, useEffect, useState } from "react";
import { ButtonGroup, Form, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { database, storage } from "../../Services/firebase/firebase";
import { getRandomString } from "../../Services/GetRandomNumber";
import "../Admin/AdminComponent/Gift.css";
import StaffHeader from "./StaffHeader"
import Gift from "../../gift.json";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { formatNumber } from "../../Services/Utility";
import { useHistory } from "react-router-dom";
import { backendUrl } from "../../Services/firebase/firebase";

function Giftcardcomponent() {
  const [search, setsearch] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setloading] = useState(false);
  const [image, setimage] = useState(null);
  const [card, setcard] = useState("");
  const [cardname, setcardname] = useState("");
  const [price, setamount] = useState(0);
  const [getcarddata, setgetcard] = useState([]);
  const [showview, setShowview] = useState(false);
  const handleCloseview = () => setShowview(false);
  const handleShowview = () => setShowview(true);
  const [getloading, setgetloading] = useState(false);
  const [showprice, setShowprice] = useState(false);
  const handleCloseprice = () => setShowprice(false);
  const handleShowprice = () => setShowprice(true);
  const [newPrice, setnewprice] = useState(0);
  const [item, setitem] = useState([]);
  // const handleChange = (e) => {
  //   if (e.target.files[0]) {
  //     setimage(e.target.files[0]);
  //   }
  // };

  const [currentPage, setcurrentpage] = useState(1);
  // const handleChange = (e) => {
  //   if (e.target.files[0]) {
  //     setimage(e.target.files[0]);
  //   }
  // };

  const history = useHistory();
  const getcard = async () => {
    await axios
      .get(
        backendUrl+ "creategiftHandler/create/gift-card"
      )
      .then((e) => {
        setgetcard(e.data);
      });
  };

  useEffect(() => {
    getcard();
  }, []);

  const recordpage = 20;
  const lastIndex = currentPage * recordpage;
  const firstIndex = lastIndex - recordpage;
  const records = getcarddata.slice(firstIndex, lastIndex);
  const npage = Math.ceil(getcarddata.length / recordpage);
  const numbers = [...Array(npage + 1).keys()].slice(1);



  const prepage = () => {
    if (currentPage !== 1) {
      setcurrentpage(currentPage - 1);
    }
  };

  const Nextpage = () => {
    if (currentPage !== npage) {
      setcurrentpage(currentPage + 1);
    }
  };

  const changecurrentPage = (id) => {
    setcurrentpage(id);
  };



  const handleCard = async (e) => {
    e.preventDefault();
  
    setloading(true);

    // const image = await handleUpload();

    const image = "";
    try {
      setloading(true);

      const id = database.collection("_").doc().id;
      const Roll = getRandomString(5, "1234567890");
      const data = {
        RollNumber: Roll,
        price: parseFloat(price),
        cardname,
        image,
        card,
      };
      await axios
        .post(
          backendUrl+ "creategiftHandler/create/gift-card",
          { data: data }
        )
        .then((e) => {
          toast.success(e.data.message);
          handleClose();
        });
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    setimage(null);
    setamount(0);
    setcard("");
    setcardname("");
    setloading(false);
  };

  const handleswt = useCallback(
    (item) => () => {
      setgetloading(true);
      axios
        .get(
          backendUrl+ `UpdateCard/card/${item.id}`
        )
        .then((e) => {
          toast.success(e.data.message);
          window.location.reload();
        });
      setgetloading(false);
    },
    []
  );

  const Togglemodal = useCallback(
    (item) => () => {
      setitem(item);
      handleShowprice();
    },
    []
  );

  const Togglemodalview = useCallback(
    (item) => () => {
      setitem(item);
      handleShowview();
    },
    []
  );

  const handlechangeprice = async (e) => {
    e.preventDefault();
    const type = "EditPrice";
    setgetloading(true);
    axios
      .put(
        backendUrl+ `UpdateCard/card/${item.id}`,
        {
          amount: newPrice,
        }
      )
      .then((e) => {
        toast.success(e.data.message);
        window.location.reload();
      });
    setgetloading(false);
  };



  return (
    <div>
      <ToastContainer />
<StaffHeader/>
      <Modal
        show={showprice}
        onHide={handleCloseprice}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Card Rate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Rate</Form.Label>
            <Form.Control
              type="tel"
              onChange={(evnt) => setnewprice(evnt.target.value)}
            />
            <Form.Text className="text-muted">
              Enter New Card Rate.. Please note that any change made will
              reflect acrose all apps
            </Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseprice}>
            Close
          </Button>
          <Button
            variant="primary"
            disabled={getloading}
            onClick={handlechangeprice}
          >
            {getloading === true ? "Loading..." : "Proceed.."}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showview} onHide={handleCloseview}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="biewproductcontinaer">
            <h1>Product Information</h1>

            <div className="productineofnfjf">
              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Rate</strong>
                </div>
                <div className="productonfprnf">{formatNumber(item.price)}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Card Name </strong>
                </div>
                <div className="productonfprnf">{item.card}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Sub Category </strong>
                </div>
                <div className="productonfprnf">{item.cardname}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>In-stock</strong>
                </div>
                <div className="productonfprnf">
                  {item.status === true ? "active" : "Disable"}
                </div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Image</strong>
                </div>
                <div className="productonfprnf">
                  <img src={item.image} alt="" />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseview}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>MY CARD</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Select
            aria-label="Default select example"
            onChange={(event) => setcard(event.target.value)}
          >
            <option>Select Card</option>
            {Gift.map((image) => {
              return <option value={image.name}>{image.name}</option>;
            })}
          </Form.Select>

          <Form.Group className="mb-3">
            <Form.Label>Enter Card Name</Form.Label>
            <Form.Control
              type="text"
              onChange={(event) => setcardname(event.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="tel"
              onChange={(event) => setamount(event.target.value)}
            />
          </Form.Group>

          {/* <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Upload Card Image</Form.Label>
            <Form.Control type="file" onChange={handleChange} />
          </Form.Group> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCard} disabled={loading}>
            {loading ? "Loading..." : "Create Card"}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="giftcardocne">
        <div className="cardvaljsjs">
          <h1>Gift Card History</h1>
          <div className="girckahdicbd">
         
            <button className="ms-1" onClick={history.goBack}>
              Back
            </button>
           
            <button className="ms-1" onClick={handleShow}>
              Create Gift Card
            </button>

            <Form.Control
              type="text"
              placeholder="Search by Name"
              className="w-25 ms-1"
              onChange={(event) => {
                setsearch(event.target.value);
              }}
            />
          </div>

          <div className="cardshowarea">
            <table className="clsjhdjdjdjd">
              <thead>
                <tr>
                  <th>S/N</th>
                  <th className="hidetable">Card ID</th>
                  <th>Card Name</th>
                  <th className="hidetable">Sub Category</th>
                  <th className="hidetable">Price</th>
                  <th>Avalability</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {records
                  .filter((value) => {
                    if (value === "") {
                      return value;
                    } else if (
                      value.card.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return value;
                    } else if (
                      value.cardname
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return value;
                    } else if (value.RollNumber.includes(search)) {
                      return value;
                    }
                  })
                  .map((item, id) => {
                    return (
                      <tr key={id}>
                        <td>{id + 1}</td>
                        <td className="hidetable">{item.RollNumber}</td>

                        <td>{item.card}</td>
                        <td className="hidetable">{item.cardname}</td>
                        <td className="hidetable">
                          {formatNumber(item.price)}
                        </td>

                        <td>
                          {getloading ? (
                            <Spinner animation="border" size="sm" />
                          ) : (
                            <Form>
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                checked={item.status === true}
                                label={
                                  item.status === true
                                    ? "Active Card"
                                    : "Disable Card"
                                }
                                onChange={handleswt(item)}
                              />
                            </Form>
                          )}
                        </td>
                        <td>
                          <ButtonGroup aria-label="Basic example">
                            <Button
                              variant="success"
                              onClick={Togglemodalview(item)}
                            >
                              View
                            </Button>
                            <Button
                              variant="primary"
                              onClick={Togglemodal(item)}
                            >
                              Edit
                            </Button>
                          </ButtonGroup>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            <nav>
              <ul className="pageination">
                <li className="page-item">
                  <span className="page-link" onClick={prepage}>
                    Prev
                  </span>
                </li>

                {numbers.map((n, i) => {
                  return (
                    <li
                      key={i}
                      className={
                        currentPage === n ? "activenumber" : "page-item"
                      }
                    >
                      <span className="page-link" onClick={() => changecurrentPage(n)}>
                        {n<1 ? 1 : n}
                      </span>
                    </li>
                  );
                })}

                <li className="page-item">
                  <span className ="page-link" onClick={Nextpage}>
                    Next
                  </span>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Giftcardcomponent;
