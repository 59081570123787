import React, { useEffect } from "react";
import { useState } from "react";
import { Button, FloatingLabel, Form, Modal, Spinner } from "react-bootstrap";
import { useAuth } from "../../../Services/UserAuth";
import "./Transaction.css";
import { ToastContainer, toast } from "react-toastify";
import { format } from "date-fns";
import axios from "axios";
import { database } from "../../../Services/firebase/firebase";
import firebase from "firebase";
import ElectricityProviders from "../../../Electricity.json";
import { Link, useHistory } from "react-router-dom";
import { formatNumber, version_control } from "../../../Services/Utility";
import { getRandomString } from "../../../Services/GetRandomNumber";
import { backendUrl } from "../../../Services/firebase/firebase";
import {
  SendNotification,
  getCurrentPayload,
} from "../../../Services/GetUser.service";
function Airtimecomponent() {
  const [amount, setamount] = useState(0);
  const history = useHistory();
  const power = ElectricityProviders.data;
  const [selectedpower, setselectedpower] = useState("");
  const [number, setphone] = useState("");
  const [loading, setloading] = useState(false);
  const { loginuser, userwallet } = useAuth();
  const [responspayload, setresponsepayload] = useState("");
  const [show, setShow] = useState(false);
  const [payload, setpayload] = useState([]);
  const [com, setcom] = useState(0);
  const [showprint, setShowprint] = useState(false);
  const [res, setrepayload] = useState([]);
  const [boundlePlan, setboudlePlan] = useState("");
  const [meternumber, setmeternumber] = useState("");

  const handleClosePrint = () => {
    window.location.reload();
    setShowprint(false);
  };
  const handleShowprint = () => setShowprint(true);
  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if (
      selectedpower === "portharcourt_electric_prepaid" ||
      selectedpower === "portharcourt_electric_prepaid"
    ) {
      const charge = 0.005;
      const charges = parseFloat(amount) * charge;
      setcom(charges);
    }
    if (
      selectedpower === "ikeja_electric_prepaid" ||
      selectedpower === "ikeja_electric_postpaid"
    ) {
      const charge = 0.005;
      const charges = parseFloat(amount) * charge;
      setcom(charges);
    }

    if (
      selectedpower === "enugu_electric_prepaid" ||
      selectedpower === "enugu_electric_postpaid"
    ) {
      const charge = 0.008;
      const charges = parseFloat(amount) * charge;
      setcom(charges);
    }
    if (
      selectedpower === "eko_electric_prepaid" ||
      selectedpower === "eko_electric_postpaid"
    ) {
      const charge = 0.005;
      const charges = parseFloat(amount) * charge;
      setcom(charges);
    }

    if (
      selectedpower === "ibadan_electric_prepaid" ||
      selectedpower === "ibadan_electric_postpaid"
    ) {
      const charge = 0.003;
      const charges = parseFloat(amount) * charge;
      setcom(charges);
    }

    if (
      selectedpower === "abuja_electric_postpaid" ||
      selectedpower === "abuja_electric_prepaid"
    ) {
      const charge = 0.008;
      const charges = parseFloat(amount) * charge;
      setcom(charges);
    }

    if (
      selectedpower === "jos_electric_prepaid" ||
      selectedpower === "jos_electric_postpaid"
    ) {
      const charge = 0.005;
      const charges = parseFloat(amount) * charge;
      setcom(charges);
    }

    if (
      selectedpower === "benin_electric_postpaid" ||
      selectedpower === "benin_electric_prepaid"
    ) {
      const charge = 0.01;
      const charges = parseFloat(amount) * charge;
      setcom(charges);
    }

    if (
      selectedpower === "kedco_electric_postpaid" ||
      selectedpower === "kedco_electric_postpaid"
    ) {
      const charge = 0.005;
      const charges = parseFloat(amount) * charge;
      setcom(charges);
    }

    if (
      selectedpower === "kaduna_electric_postpaid" ||
      selectedpower === "kaduna_electric_prepaid"
    ) {
      const charge = 0.005;
      const charges = parseFloat(amount) * charge;
      setcom(charges);
    }
  }, []);

  const limit = loginuser.transactionLimit - amount;
  const TransactionLimit = limit <= 0 ? 0 : limit;
  const version = version_control()
  const handleShow = () => setShow(true);

  const date = format(new Date(), "dd/MM/yyyy hh:mm a");

  //   const handleselctprond = (e) => {
  //     const d = e.target.value;
  //       if (d){
  //         setboudlePlan(d);
  //       }else{
  //         selectedPlan("")
  //       }
  //   };
  const num = number ? number : loginuser.number;

  const handleverifier = async () => {
    if (selectedpower === "") {
      toast.error("Please select network");
      return;
    }
    if (amount < 200) {
      toast.error("minimum vending amount is 100");
      return;
    }

    if (limit <= 0) {
      toast.error("Limit Error");
      return;
    }

    const num = number 
    if (num === "" || !num){
      toast.error("Enter Mobile number");
      return 
    }
    if (meternumber === "") {
      toast.error("Enter meter number");
      return;
    }
    setloading(true);

    try {
      await axios
        .post(backendUrl + "verifyelectricityuser/verify/electricity-user", {
          service_type: selectedpower,
          account_number: meternumber,
        })
        .then((res) => {
          if (res.data.message === "Successful") {
            setpayload(res.data.data.user);
            handleShow();
            return;
          }
          toast.error("user account not found");
        });
    } catch (error) {}
    setloading(false);
  };

  const handlepayment = async (e) => {
    e.preventDefault();
    if (userwallet.wallet < amount) {
      toast.error("Insuffient Fund");
      return;
    }
    const ref = database.collection("_").doc().id;
    setboudlePlan(ref);
    const UniquieNum = getRandomString(20, "1234567890");
    if (limit <= 0) {
      toast.error("Limit Error");
      return;
    }
    try {
      setloading(true);
      setresponsepayload("connecting");
      await axios
        .post(backendUrl + `debitSuperAgentAccount/debit/user`, {
          id: loginuser.id,
          amount: amount,
          trackNo: UniquieNum,
          version:version
        })
        .then(async (res) => {
          setresponsepayload("sending value");
          if (res.data.res === "success") {
            const Token = res.data.token;
            await axios
              .post(backendUrl + "vendelectricy/electricity", {
                service_type: selectedpower,
                account_number: meternumber,
                amount: parseFloat(amount),
                phone: num,
                agentReference: ref,
                customername: payload.name,
                address: payload.address,
                Token,
              })
              .then(async (res) => {
                
                setresponsepayload("Finilizing transaction");
                if (res.data.status === "success") {
                  const [payloadres, payloadData] = await getCurrentPayload(
                    res.data
                  );
                 
                  if (!payloadres) {
                    toast.error(
                      "Unexpected error occure, please contact support for token"
                    );
                    return;
                  }
                  const response = payloadData.token;
                  const token = response ? response : "";
                  let chunks = [];
                  for (let i = 0; i < token.length; i += 4) {
                    chunks.push(token.substr(i, 4));
                  }
                  const arranToken = chunks.join("-");

                  const data = {
                    id: ref,
                    company: selectedpower,
                    customername: payload.name,
                    userID: loginuser.id,
                    token:
                      selectedpower === "jos_electric_prepaid" ||
                      selectedpower === "kaduna_electric_prepaid"
                        ? payloadData.token
                        : arranToken,
                    unit: payloadData.units ? payloadData.units : "",
                    debtAmount: payloadData.debtAmount
                      ? payloadData.debtAmount
                      : "",
                    AccountNumber: meternumber,
                    address: payload.address,
                    AccountBallance: payloadData.accountBalance
                      ? payloadData.accountBalance
                      : "",
                    walletbalance: userwallet.wallet - amount,
                    Prewalletbalance: userwallet.wallet,
                    amount: amount,
                    TranRef: UniquieNum,
                    phone: num,
                    payloadres: res.data,
                    mezuflex_id:res.data.ref,
                    Debitamount: amount,
                    customermisson: 0,
                    com: com,
                    type: "Electricity",
                    status: "success",
                    isToday: true,
                    isClose: true,
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                  };

                  const message = `Dear Customer!! 
Your ${selectedpower.service_type} Token: ${arranToken}. Thank you for choosen Kitipay`;
                  const url = backendUrl + "KitiSendingkitipay";
                  const promises = [
                    axios.post(
                      backendUrl + "userlogsuserlogs/transactions",
                      {
                        data: data,
                      }
                    ),
                    database.doc(`/income/vRgRWLBNzwo7IM1pUhcl`).update({ 
                      income: firebase.firestore.FieldValue.increment(com),
                      electricity: firebase.firestore.FieldValue.increment(com),
                    }),
                    axios.post(url, {
                      message: message,
                      recipient: num,
                      senderId: "KITIPAY",
                    }),
                  ];
                  setrepayload(data);
                  await Promise.all(promises);
                  history.push(`/reciept/view/${data.id}`)
                } else {
                  const dataw = {
                    id: ref,
                    company: selectedpower,
                    customername: payload.name,
                    address: "no value",
                    userID: loginuser.id,
                    AccountNumber: meternumber,
                    walletbalance: userwallet.wallet,
                    amount: amount,
                    TranRef: UniquieNum,
                    phone: number,
                    Debitamount: amount,
                    customermisson: 0,
                    com: com,
                    payloadres: res.data,
                    mezuflex_id:res.data.ref,
                    type: "Electricity",
                    status: "error",
                    isToday: true,
                    isClose: false,
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                  };
                  setrepayload(dataw);
                  await axios.post(
                    backendUrl + "userlogsuserlogs/transactions",
                    {
                      data: dataw,
                    }
                  )
                  // await database
                  //   .collection("Transaction")
                  //   .doc(dataw.id)
                  //   .set(dataw);
                  // const nofifyID = getRandomString(6, "1234567890");
                  // const notificationBody = {
                  //   message: `Transaction #${dataw.TranRef} failed `,
                  //   created: firebase.firestore.FieldValue.serverTimestamp(),
                  //   isread: false,
                  //   id: nofifyID,
                  //   userID: "",
                  //   isRi: 0,
                  // };
                  // await SendNotification(
                  //   notificationBody,
                  //   null,
                  //   "admin"
                  // );
                  toast.error("Unexpected Error");
                  history.push(`/reciept/view/${dataw.id}`)
                }
              });
          } else {
            toast.error(res.data.message);
          }
        });
    } catch (error) {
      toast.error(error.message);
    }
    setloading(false);
  };

  const handleprint = () => {
    history.push(`/transaction/${res.id}`);
  };

  return (
    <div>
      <ToastContainer />

      <Modal
        show={showprint}
        onHide={handleClosePrint}
        backdrop="static"
        keyboard={false}
      >
        <div className="tramsactiondetyaions">
          <div className="transactioncokatne">
            <div
              className="transajea"
              style={{
                backgroundColor: res.status === "success" ? "green" : "red",
              }}
            >
              <h1>{res.status === "success" ? "SUCCESS" : "ERROR"}</h1>
              <span>Transaction Reciept</span>
            </div>
            <div className="transactioncontent">
              <div className="transcotebnr">
                <div className="contbename">Company</div>
                <div className="conetnenvakeb sjshdjdhd">{res.company}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">Customer Name</div>
                <div className="conetnenvakeb">{payload.name}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">Address</div>
                <div className="conetnenvakeb">{payload.address}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">Meter Number</div>
                <div className="conetnenvakeb">{res.AccountNumber}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">Token</div>
                <div className="conetnenvakeb">{res.token}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">Unit</div>
                <div className="conetnenvakeb">{res.unit}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">Amount</div>
                <div className="conetnenvakeb">{formatNumber(res.amount)}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">Debt</div>
                <div className="conetnenvakeb">{res.debtAmount}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">Debt-Balance</div>
                <div className="conetnenvakeb">{res.AccountBallance}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">Status</div>
                <div className="conetnenvakeb">{res.status}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">Date</div>
                <div className="conetnenvakeb">{date}</div>
              </div>
            </div>
          </div>
        </div>
        <Modal.Footer>
          <Link to="/services">
            <Button variant="secondary">Another Transaction</Button>
          </Link>
          <Button variant="primary" onClick={handleprint}>
            Print
          </Button>
          <Button variant="secondary" onClick={handleClosePrint}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="p-0">
          <div className="tramsactiondetyaions">
            <div className="transactioncokatne">
              <div className="transajea">
                <h1>In-Porgress</h1>
                <span>
                  Kindly confirm the information below before you proceed with
                  this payment.
                </span>
              </div>
              <div className="transactioncontent">
                <div className="transcotebnr">
                  <div className="contbename">Company</div>
                  <div className="conetnenvakeb sjshdjdhd">{selectedpower}</div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">Customer Name</div>
                  <div className="conetnenvakeb">{payload.name}</div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">Amount</div>
                  <div className="conetnenvakeb">{formatNumber(amount)}</div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">Address</div>
                  <div className="conetnenvakeb">{payload.address}</div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">meter number</div>
                  <div className="conetnenvakeb">{payload.accountNumber}</div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">Status</div>
                  <div className="conetnenvakeb">Processing</div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">Data</div>
                  <div className="conetnenvakeb">{date}</div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant={loading ? "warning" : "primary"}
            disabled={loading}
            onClick={handlepayment}
            type="submit"
          >
            {loading ? (
              <>
                {" "}
                <Spinner animation="border" size="sm" /> {responspayload}
              </>
            ) : (
              "Pay with Wallet"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="transactionconatiner">
        <div className="transactioncontenrt">
          <div className="transactiohead">
            <h2>
              <center className="text-dark">Electricity</center>
            </h2>
          </div>
          <div className="formtransactionsecton">
            <div className="formtrnacruondjdjd">
              <div className="productsed">
                <Form.Label className="text-dark ">Select Provider</Form.Label>
                <FloatingLabel
                  controlId="floatingSelect"
                  onChange={(event) => setselectedpower(event.target.value)}
                >
                  <Form.Select aria-label="Floating label select example">
                    <option>Select Provider</option>
                    {power.map((e) => {
                      return (
                        <option value={e.service_type}>
                          {e.name.toLocaleUpperCase()}
                        </option>
                      );
                    })}
                  </Form.Select>
                </FloatingLabel>
                <div className="optionshhd">
                  <Form.Group controlId="formBasicNumber">
                    <Form.Label className="text-dark my-2">
                      Meter Number / Account Number
                    </Form.Label>
                    <Form.Control
                      type="tel"
                      onChange={(event) => {
                        setmeternumber(event.target.value);
                      }}
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicNumber">
                    <Form.Label className="text-dark my-2">Amount</Form.Label>
                    <Form.Control
                      type="tel"
                      onChange={(event) => {
                        setamount(event.target.value);
                      }}
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicNumber">
                    <Form.Label className="text-dark my-2">
                      Phone Number
                    </Form.Label>
                    <Form.Control
                      placeholder={loginuser.number}
                      type="tel"
                      maxLength={11}
                      onChange={(event) => {
                        setphone(event.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Text className="text-muted">
                    Transaction Limit: {TransactionLimit ? TransactionLimit : 0}
                  </Form.Text>

                  <Button
                    variant={loading ? "warning" : "primary"}
                    disabled={loading || limit <= 0}
                    onClick={handleverifier}
                    className="my-2 w-100"
                    type="submit"
                  >
                    {loading ? (
                      <>
                        {" "}
                        <Spinner animation="border" size="sm" />{" "}
                        {responspayload}
                      </>
                    ) : (
                      "Vend Electricity"
                    )}
                  </Button>

                  
                  <Button
                    variant={loading ? "warning" : "danger"}
                    disabled={loading || limit <=0}
                    onClick={history.goBack}
                    className="w-100"
                  >
                    {loading ? (
                      <>
                        {" "}
                        <Spinner animation="border" size="sm" />{" "}
                        {responspayload}
                      </>
                    ) : (
                      "Back"
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Airtimecomponent;
