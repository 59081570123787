import React, { useCallback, useEffect, useState } from "react";
import { Button, ButtonGroup, Form, Modal } from "react-bootstrap";

import moment from "moment";
import { useHistory } from "react-router-dom";
import "./User.css";
import { useAuth } from "../../../Services/UserAuth";
import axios from "axios";
import { ToastContainer} from "react-toastify";
import { timeStamptoDate } from "../../../Services/Utility";
import { format } from "date-fns";
import { backendUrl } from "../../../Services/firebase/firebase";

function UserLog() {
  const [usecollection, setusercollection] = useState([]);
  const [showview, setShowview] = useState(false);
  const handleCloseview = () => setShowview(false);
  const [search, setsearch] = useState("");
  const handleShowview = () => setShowview(true);
  const history = useHistory();
  const [item, setitem] = useState([]);

  const [currentPage, setcurrentpage] = useState(1);
  const recordpage = 20;
  const lastIndex = currentPage * recordpage;
  const firstIndex = lastIndex - recordpage;
  const records = usecollection.slice(firstIndex, lastIndex);
  const npage = Math.ceil(usecollection.length / recordpage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  // const sortDataByDate = (resvalue) => {
  //   return resvalue.sort((first, second) => {
  //     // console.log(moment(first.created).isSam(second.created))
  //     if (moment(first.created) > second.created) {
  //       return -1;
  //     } else if (moment(first.created) < second.created) {
  //       return -1;
  //     } else {
  //       return 1;
  //     }
  //   });
  // };

  // const sortDataByDate = (items) => {
  //     return items.sort((first, second) => {
  //         console.log(first.created, second.created)
  //     //   if (moment(first.items[0].date).isSame(second.items[0].date)) {
  //     //     return -1;
  //     //   } else if (moment(first.items[0].date).isBefore(second.items[0].date)) {
  //     //     return -1;
  //     //   } else {
  //     //     return 1;
  //     //   }
  //     });
  //   };

  useEffect(() => {
    axios.get(backendUrl + "debitadnubAccount/debit/user").then((response) => {
      const resvalue = response.data.payload;
      setusercollection(resvalue);
    });
  }, []);

  const prepage = () => {
    if (currentPage !== 1) {
      setcurrentpage(currentPage - 1);
    }
  };

  const Nextpage = () => {
    if (currentPage !== npage) {
      setcurrentpage(currentPage + 1);
    }
  };

  const changecurrentPage = (id) => {
    setcurrentpage(id);
  };

  const Togglemodalview = useCallback(
    (item) => () => {
      setitem(item);
      handleShowview();
    },
    []
  );

  return (
    <div>
      <ToastContainer />

      <Modal
        show={showview}
        onHide={handleCloseview}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton className="modalheader">
          Transaction Details
        </Modal.Header>
        <Modal.Body>
          <div className="biewproductcontinaer">
            <div className="productineofnfjf">
              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Transaction Type</strong>
                </div>
                <div className="productonfprnf">{item.type}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Service Type</strong>
                </div>
                <div className="productonfprnf">{item.company}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Amount</strong>
                </div>
                <div className="productonfprnf">{item.amount}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Account Number</strong>
                </div>
                <div className="productonfprnf">{item.AccountNumber}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>status</strong>
                </div>
                <div className="productonfprnf">{item.status}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Reference</strong>
                </div>
                <div className="productonfprnf">{item.mexuflexRef}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Date</strong>
                </div>
                <div className="productonfprnf">
                  {item.created
                    ? format(item.created, "dd/MM/yyyy hh:mm a")
                    : ""}
                </div>
              </div>

              {/* <div className="productdetaund">
                <div className="producttitle">
                  <strong>Row Data</strong>
                </div>
                <div className="productonfprnf">{JSON.stringify(item)}</div>
              </div> */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseview}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="giftcardocne">
        <h1>Transaction History</h1>
        <div className="girckahdicbd">
          <button onClick={history.goBack}>Back</button>
          <button className="ms-1">{usecollection.length} Transaction</button>

          <Form.Control
            type="text"
            placeholder="Search by Name"
            className="w-25 ms-1"
            onChange={(event) => {
              setsearch(event.target.value);
            }}
          />
        </div>
        <div className="cardshowarea">
          <div className="cardvaljsjs">
            <table>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th className="hidetable">Created</th>
                  <th>Type</th>
                  <th className="hidetable">Service Type</th>
                  <th className="hidetable">Amount</th>
                  <th className="hidetable">Account Number</th>
                  <th>Mezu_id</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {records.length === 0 ? (
                  <>No Data Found</>
                ) : (
                  <>
                    {records
                      .filter((value) => {
                       
                        if (value === "") {
                          return value;
                        } else if (
                          value.type
                            .toLowerCase()
                            .includes(search.toLowerCase())
                        ) {
                          return value;
                        } else if (
                          value.mexuflexRef.toLowerCase().includes(search.toLowerCase())
                        ) {
                          return value;
                        }
                      })
                      .map((item, id) => {
                        const d = timeStamptoDate(item);
                        return (
                          <tr key={id}>
                            <td>{id + 1}</td>

                            <td className="hidetable">
                              {item.created
                                ? format(item.created, "dd/MM/yyyy hh:mm a")
                                : ""}
                            </td>
                            <td>{item.type}</td>
                            <td className="hidetable">{item.company}</td>
                            <td className="hidetable">{item.amount}</td>
                            <td className="hidetable">{item.AccountNumber}</td>
                            <td>{item.mexuflexRef}</td>
                            <td>{item.status}</td>
                            <td>
                              <ButtonGroup aria-label="Basic example">
                                <Button
                                  variant="success"
                                  onClick={Togglemodalview(item)}
                                >
                                  View
                                </Button>
                              </ButtonGroup>
                            </td>
                          </tr>
                        );
                      })}
                  </>
                )}
              </tbody>
            </table>
            <nav>
              <ul className="pageination">
                <li className="page-item">
                  <span className="page-link" onClick={prepage}>
                    Prev
                  </span>
                </li>
                {numbers.slice(0,10).map((n, i) => {
                  return (
                    <li
                      key={i}
                      className={
                        currentPage === n ? "activenumber" : "page-item"
                      }
                    >
                      <span
                        className="page-link"
                        onClick={() => changecurrentPage(n)}
                      >
                        {n < 1 ? 1 : n}
                      </span>
                    </li>
                  );
                })}

                <li className="page-item">
                  <span className="page-link" onClick={Nextpage}>
                    Next
                  </span>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserLog;
