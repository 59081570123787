import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../Services/UserAuth'
import Sidebar from '../ClientComponent/Sidemenue'
import { formatNumber } from '../../../Services/Utility'
import { gettransactin } from '../../../Services/GetUser.service'
import "../ClientComponent/Complaince.css";
import { useHistory } from 'react-router-dom'
function UserprofilePage() {
const {loginuser, userwallet} =  useAuth()
const { uid } = useAuth().currentUser;
const [userTransaction, setusercollection] =  useState([])
const history =  useHistory()

useEffect(() => {
    gettransactin((result)=>{
    const data = result.filter((e)=>e.userID ===  uid)
     setusercollection(data)
    })
  }, [])

  const handleSettins = ()=>{
    history.push("/setting")
  }
  return (
    <div>
        <div className="pagecon">
        <div className="pagecontent">
          <div className="headersisecoadmin">
            <Sidebar/>
          </div>
          <div className="otherssecoth">
            <div className="dashboardconteier">
              <div className="topmenuecontrenr11">
                
              <section className="vh-100" >
  <div className="container py-5 h-100">
    <div className="row d-flex justify-content-center align-items-center h-100">
      <div className="col-md-12 col-xl-4">

        <div className="card" >
          <div className="card-body text-center">
            <div className="mt-3 mb-4 imagesixsdsls">
              <img src={loginuser.image ? loginuser.image : "/img/v1.png"}
                className="rounded-circle img-fluid"/>
            </div>
            <h4 className="mb-2">{loginuser.lastname} {loginuser.firstname}</h4>
            <p className="text-muted mb-4">  {loginuser.role } <span className="mx-2">| {loginuser.userverified === false ? "Not Verified"  : "Verified" } </span> </p>
            <div className="mb-4 pb-2">
           {loginuser.email} <br/>
           {loginuser.number}
            </div>
            <button type="button" className="btn btn-primary btn-rounded btn-sm" onClick={handleSettins}>
              Account Settings
            </button>
            <div className="d-flex justify-content-between text-center mt-5 mb-2">
              <div>
                <p className="mb-2 h5">{formatNumber(userwallet.wallet)}</p>
                <p className="text-muted mb-0">Wallets</p>
              </div>
              <div className="px-3">
                <p className="mb-2 h5">{formatNumber(userwallet.coin)}</p>
                <p className="text-muted mb-0">Income</p>
              </div>
              <div>
                <p className="mb-2 h5">{formatNumber(userTransaction.length)}</p>
                <p className="text-muted mb-0">Transactions</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>
          {/* <div className="aformconteinr">
                  <div className="informatronpobody">
                    <div className="inforalteader">Name :</div>
                    <div className="headerbodycontainer">
                      {loginuser.lastname} {loginuser.firstname}
                    </div>
                  </div>

                  <div className="informatronpobody">
                    <div className="inforalteader">Mobile Number :</div>
                    <div className="headerbodycontainer">
                      {loginuser.number}
                    </div>
                  </div>

                  <div className="informatronpobody">
                    <div className="inforalteader"> Email :</div>
                    <div className="headerbodycontainer">
                      {loginuser.email}
                    </div>
                  </div>

                  <div className="informatronpobody">
                    <div className="inforalteader">Account Type:</div>
                    <div className="headerbodycontainer">
                      {loginuser.role }
                    </div>
                  </div>
                  <div className="informatronpobody">
                    <div className="inforalteader">Wallet Balance :</div>
                    <div className="headerbodycontainer">
                      {userwallet.wallet}
                    </div>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
           
          </div>
        </div>
  
    </div>
  )
}

export default UserprofilePage