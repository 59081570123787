import { Button } from 'react-bootstrap'
import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { ToastContainer, toast } from "react-toastify";
import { database, storage } from '../../../Services/firebase/firebase';
import firebase from 'firebase';
import { getRandomString } from "../../../Services/GetRandomNumber";
import "./Product.css"
import { useAuth } from '../../../Services/UserAuth';
import { useHistory } from 'react-router-dom';
function CreateProductcom() {
    const [productname, setProductname] = useState("")
    const [price, setprice] = useState(0)
    const [description, setDescription] = useState("")
    const [loading, setloading] = useState(false)
    const [file, setfile] = useState(null)
    const {loginuser} = useAuth()
    const [progress, setProgress] = useState()
    const [message, setmessage] = useState("")
    const [extension, setFIleExtension] = useState("")
    const [image, setimage] = useState([])
    const [imageUrl, seturl] = useState([])
    const history = useHistory()

    const handlechangeImage = (e) => {
        if (e.target.files[0]) {
            setfile(e.target.files[0]);
            return;
        }
    }
    const handleChange = (e) => {
        if (image === null) {
            toast.error("empty image not allowed")
            return
        } else {
            for (let i = 0; i < e.target.files.length; i++) {
                const newImage = e.target.files[i];
                newImage["id"] = Math.random();
                setimage((prevState) => [...prevState, newImage]);
            }
        }
    };

    const handleUpload = (img) => {
        return new Promise((resolve) => {
            // not working as expected
            const uploadTask = storage.ref(`ProductImage/${img.name}`).put(img);
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setProgress(progress);
                },
                (error) => {
                },
                async () => {
                    storage
                        .ref("ProductImage")
                        .child(img.name)
                        .getDownloadURL()
                        .then((url) => {
                            resolve(url);
                        });
                }
            );
        });
    };

    function handleUploadimage() {
        return new Promise((resolve) => {
            const uploadTask = storage.ref(`ProductImage/${file.name}`).put(file);
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setProgress(progress);
                },
                (error) => {
                    setmessage(error);
                },
                () => {
                    storage
                        .ref("ProductImage")
                        .child(file.name)
                        .getDownloadURL()
                        .then((url) => {
                            resolve(url);
                        });
                }
            );
        });
    }


    const handlecreateitem = async (e) => {
        e.preventDefault();

        if (productname === "" || price === 0 || description === "") {
            toast.error("Missisng Value")
            return
        }

        if (loginuser.role !== "vendor"){
            toast.error("v-500 permit")
            return
        }

        if (loginuser.isverified !== true){
            toast.error("v-500 verification err")
            return
        }

        if (isNaN(price) || price < 0){
            toast.error("v-amount err")
            return
        }

        setloading(true);
        const urls = await Promise.all(image.map((img) => handleUpload(img)));
        image.map((j, i) => {
            const filename = j.name;
            const imageSize = Math.round(j.size / 1024 / 1024);
            if (imageSize > 200) {
                toast.error("file to large. Max:200mb");
                return;
            }
            const fileExtension = filename.substring(filename.lastIndexOf(".") + 1);
            setFIleExtension(fileExtension);
            const ImageExtension = ["JPG", "JPEG", "PNG", , "jpg", "jpeg", "png"];
            const valeinc = ImageExtension.includes(fileExtension);
            if (!valeinc) {
                toast.error("file not supported");
                setloading(false);
                return;
            }
        });
        const coverImage = await handleUploadimage()
        try {
            setloading(true);
            const id = database.collection("_").doc().id;
            seturl(urls)
            const Roll = getRandomString(5, "1234567890")
            const data = {
                id: id,
                ProductCode: Roll,
                ProductImages: urls,
                price: parseFloat(price),
                coverimage: coverImage,
                description,
                isApproved: false,
                inPromo: false,
                PromoAmount: 0,
                Like: 0,
                OrderNo:0,
                comment: [],
                productname,
                status:"Processing",
                instock: true,
                agentID:loginuser.agentID,
                userID: loginuser.id,
                created: firebase.firestore.FieldValue.serverTimestamp(),
            };
            await database
                .doc(`product/${id}`)
                .set(data)
                .then(() => {
                    toast.success("product created successfully", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    window.location.reload()
                })
                .catch((error) => {
                    toast.error(error.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                });
            setloading(false);
        } catch (error) {
            toast.error(error.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        setimage(null);
        setloading(false)
    };

    return (
        <div>
            <ToastContainer />
            <button  onClick={history.goBack}>Back</button>
            <div className="creatpromotitydhd">
                <div className="creatrodconsjsd">
                <div className="instricyareahds allwoanshdb d-none">
                        <h1>
                            Instruction
                        </h1>
                        <p>
                            kindly showcase the best product the best describe the item.. please dont use false information for any item you upload..
                        </p>
                    </div>
                    <div className="pridhfornsjs">
                        <h1>Create Product</h1>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Product Name</Form.Label>
                            <Form.Control type="text" onChange={(event) => {
                                setProductname(event.target.value)
                            }} maxLength={30}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicNumber">
                            <Form.Label>Price</Form.Label>
                            <Form.Control type="tel" onChange={(event) => {
                                setprice(event.target.value)
                            }} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" rows={3} maxLength={150} onChange={(event) => {
                                setDescription(event.target.value)
                            }} />
                            <Form.Text className="text-muted">
                                Kindly enter full description of the product/item you want to showcase.. Include the size, quantity, color, etc
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Cover Image</Form.Label>
                            <Form.Control type="file" onChange={handlechangeImage} />
                        </Form.Group>

                        <Form.Group controlId="formFileMultiple" className="mb-3">
                            <Form.Label>Product Images</Form.Label>
                            <Form.Control type="file" multiple onChange={handleChange} />
                            <Form.Text className="text-muted">
                                Upload images that best describe the item you want to showcase
                            </Form.Text>
                        </Form.Group>
                        <Button variant="success" disabled={loading} onClick={handlecreateitem}>{loading? "Creating Product" : "Save"}</Button>
                    {progress ? "Please Wait": ""}
                    </div>
                   
                    <div className="instricyareahds">
                        <h1>
                            Instruction
                        </h1>
                        <p>
                            kindly showcase the best product the best describe the item.. please dont use false information for any item you upload..
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateProductcom