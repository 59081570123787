import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { Link, useHistory, useParams } from "react-router-dom";
import { backendUrl } from "../../Services/firebase/firebase";

function Userverify() {
  const [loading, setloading] = useState(false);
  const [user, setuser] = useState([]);
  const [remessage, setremessage] = useState("");
  const history = useHistory();
  const [gohome, setgohome] = useState(false)

  const id = useParams();
  const baseURL = backendUrl + `getUserkitipay/users/${id.id}`;
  const getuser = async () => {
    axios.get(baseURL).then((response) => {
      const user = response.data[0]
      if (user) {
        setuser(response.data[0]);
        return
      } else {
        setuser([])
      }
    });
  }
  useEffect(() => {
    getuser()
   
  }, []);

  const handlemydashoba = ()=>{
    window.location.href = "https://kitipay.com/login";
  }

  const handleVerify = async (e) => {
    e.preventDefault();
    setgohome(true)
    try {
     
      await axios
        .put(
          backendUrl + `getUserkitipay/users/${id.id}`
        )
        .then(async (res) => {
          toast.success(res.data.mess);

          await axios.post(
            backendUrl + "CreateProvidusAccount",
            {
              account_name: `${user.firstname} ${user.lastname}`,
              id: user.id
            }
          ).then((res) => {
            setremessage("Account Ready to use.. welcome to kitipay")
            setgohome(true)
            setInterval(() => {
              window.location.href = "https://kitipay.com/login";
            }, 2000);
          });
        });

    } catch (error) {
      toast.error(error.message);
      setgohome(false)
    }
    setloading(false)
  };

  if (user.length === 0) {
    return <>loading</>;
  }

  return (
    <div>
      <ToastContainer />
      <div className="verifycontainer">
        <div className="verifycontent">
          <div className="verfyvalue">
            <div>
              <div className="welcome1">
                <div className="welcomeman">
                  <div className="indv">
                    <img src="/img/logo.png" alt="" />
                  </div>
                  Welcome <strong></strong> {user.lastname} {user.firstname}
                  <div className="welcomegrand">
                    <div className="welcomeparent">
                      <div className="welcomechil">
                        Please click on the Button below to {gohome ? "go to your dashboard" : "verify your Email"}
                      </div>{" "}
                      <br />
                    </div>
                  </div>
                  <div className="restbuton">
                    {gohome ? <button onClick={handlemydashoba}>
                      My Dashboard
                    </button> : <button onClick={handleVerify} disabled={loading}>
                      {loading ? "Redirecting.." : "Verify My Account"}{" "}
                    </button>}

                    <br />
                    <center className="text-success">{remessage}</center>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Userverify;
