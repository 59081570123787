import React from "react";
import Sidemenue from "../ClientComponent/Sidemenue";
import Ordermessage from "../ClientComponent/Ordermessage";

function Giftcardpage() {
  return (
    <div>
      <div className="pagecon">
        <div className="pagecontent">
          <div className="headersisecoadmin">
            <Sidemenue />
          </div>
          <div className="otherssecoth">
            <div className="dashboardconteier">
              <div className="topmenuecontrenr">
                <Ordermessage />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Giftcardpage;
