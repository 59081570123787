import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import "quill/dist/quill.snow.css";
import { useQuill } from "react-quilljs";
import { useParams } from 'react-router-dom';
import {Form}from "react-bootstrap"
import { getsinglesupport } from '../../../Services/GetUser.service';
function Sendmessagecompanent() {
    const [productDescription, setProductDescription] = useState("");
    const { quill, quillRef } = useQuill();
    const [mess , setmess] =  useState([])
    const [firstname, setfirstname]= useState("")

    useEffect(() => {
        if (quill) {
          quill.on("text-change", () => {
            setProductDescription(quillRef.current.firstChild.innerHTML);
          });
        }
      }, [quill]);
      const id = useParams().id
      useEffect(() => {
        getsinglesupport(id,(result) => {
         const mess =  result[0]
         if (mess){
            setmess(mess)
         }else{
            setmess([])
         }
        })
      }, [])

  return (
    <div>
        <div className="messagashead">
            <div className="messagssxdjdcd">
                <div className="messajdjd">
                    Send Message
                </div>

                <div className="messagcomdd">
                <Form.Group className="mb-3">
                    <Form.Label>Recipient Email</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={mess.email}
                      disabled
                    />
                  </Form.Group>
              
                  <Form.Group className="mb-3">
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                      type="text"
                    //   placeholder={}
                      onChange={(event) => {
                        setfirstname(event.target.value);
                      }}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formGridEmail">
                    <Form.Label>Enter Message</Form.Label>
                    <div>
                      <div ref={quillRef} />
                    </div>
                  </Form.Group>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Sendmessagecompanent