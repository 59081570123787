import React, { useState } from 'react'
import './Contact.css'
import Footer from './Footer'
import Navbar from "./Navbaronly"
import { database } from "../../Services/firebase/firebase";
import countries from "../../Services/Country.js";
import validator from "validator";
import {FiPhoneCall} from "react-icons/fi"
import {MdEmail} from "react-icons/md"
import firebase from 'firebase';


function Contact() {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [country, setCountry] = useState("");
  const [loader, setLoader] = useState(false);
  const [emailStatus, setEmailStatus] = useState({color: 'green', msg: ''});
  const [phoneError, setphoneError] = useState({color: 'green', msg:''});


  const handleSubmit = async(e) => {
    e.preventDefault();
    setLoader(true);

    const id = database.collection("_").doc().id;
    const data = {
      id,
      report:message,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      customername: name,
      email:email,
      number:phone,
      userID:"anonymous",
      isTreated: false,
    };
  await database
          .collection("SupportMessage")
          .doc(data.id)
          .set(data).then(() => {
        setLoader(false);
        alert("Your message has been submitted👍");
      })
      .catch((error) => {
       
        alert(error.message);
        setLoader(false);
      });

    setName("");

    setMessage("");
  };

  const validateEmail = (e) => {
    var email = e.target.value;

    if (validator.isEmail(email)) {
      setEmailStatus({color: 'green', msg: "Valid Email :)"});
    } else {
      setEmailStatus({color: 'red', msg: "Enter valid Email!"});
    }

    setEmail(email);
  };

  const validatePhone = (e) => {
    var phone = e.target.value;

    if (validator.isMobilePhone(phone)) {
      setphoneError({color:'green' , msg : 'valid phone number'});
    } else {
      setphoneError({color:'red', msg : 'invalid number'});
    }

    setPhone(phone);
  };

  return (

           <>
           <Navbar/>
            <div className="contactuswrapeer">
              <div className="jheskdjdkdcenter">
              <center>Contact Us</center>
              </div>
              <div className="contactuslaks">
                <div className="isfndnhdhd">
                  <div className="imagesidecontact">
                      <h4><FiPhoneCall/></h4>
                      <h2>Phone</h2>
                      <p>You can Call/Whatsapp us at anytime</p>
                      <span>09115630280 (Whatsapp)</span>
                  </div>

                  <div className="imagesidecontact">
                      <h4><MdEmail/></h4>
                      <h2>Email</h2>
                      <p>Send us a detailed message.</p>
                      <span>kitipay.official@gmail.com</span>
                  </div>
                </div>
                <div className="contactiforjrj">
                <div className="formapp">
      <form className="form" onSubmit={handleSubmit}>
        <h1>Send us a Message </h1>

        <label>Name</label>
        <input
          placeholder="Name"
          value={name}
          type="text"
          onChange={(e) => setName(e.target.value)}
          required
        />

        <label>Phone Number</label>
        <input
          placeholder="Phone Number"
          value={phone}
          type="text"

          onChange={(e) => {
            validatePhone(e);
            if (e.target.value.length < 11) {
              setphoneError({color:'green', msg:'almost there'});
            }

            if (e.target.value.length < 5) {
              setphoneError({color:'red' , msg:'invalid format'});
            }
          }}
        
          required
        />

        <span
          style={{
            fontWeight: "bold",
            color: phoneError.color,
          }}
        >
          {phoneError.msg}
        </span>

        <label>Email</label>
        <input
          placeholder="Email"
          value={email}
          onChange={(e) => validateEmail(e)}
          required
        />
        <span
          style={{
            fontWeight: "bold",
            color: emailStatus.color,
          }}
        >
          {emailStatus.msg}
        </span>
        <label>Country: </label>
        <select
          name="country"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          required
        >
          <option key=""></option>
          {countries.map((country) => (
            <option key={country}>{country}</option>
          ))}
        </select>

        <label>Message</label>
        <textarea
          placeholder="Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        ></textarea>

        <button
          type="submit"
          style={{ background: loader ? "#ccc" : " #ed1846" }}
        >
          Submit
        </button>
      </form>
    </div>
                </div>
              </div>
            </div>
         <Footer/>
           </>
        
 
        
  )
}

export default Contact