import React from 'react'
import StaffHeader from "./StaffHeader"
import "./Staffheader.css"
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../Services/UserAuth'
import { formatNumber } from '../../Services/Utility'

function StaffIndexPage() {
const histroy  =  useHistory()
const { loginuser } = useAuth();

const handleuserroute = ()=>{
    if (loginuser.role !== "Sale"){
        
        return
    }
    histroy.push("/management/gift-card-manager")
}


    
  return (
    <div>
        <StaffHeader/>
        <div className="menueselction">
            <div className="muneclaldjd">
            <div className="admindashboard">
        <div className="admincontainer">
          <div className="admineader">
            <center className='py-2'><h1>Dashboard</h1></center>
            <p>
              Welcome Back {loginuser.firstname} {loginuser.lastname}
            </p>
          </div>

          <div className="asminconahdnd">
            <div className="conatnndnd">
              <div className="contandadminrapper" onClick={handleuserroute} style={{display : loginuser.role !=="Sale" ? "none" : "block"}}>
                <div className="titileconentn">Gift Card Center</div>
                <div className="admindfigr">

                <div className="admindfigr">
                  <span className="text-primary fw-bolder">Card Count ({formatNumber(0)})</span> <br/>
                  {/* <span className="text-success fw-bolder">Card on request ({formatNumber(giftcardLengnt.length)})</span> */}
                </div>
                </div>
                <p>
                  View All Gift Card registered, including rate edit, close card, you can
                  aswell filter Cards base on Name and category
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>
            </div>
        </div>
    </div>
  )
}

export default StaffIndexPage