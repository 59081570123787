
import AdminSidemenu from "../AdminComponent/AdminSidemenue"
import React from "react";

import "../../Sidemiindex.css";
import MezoTransactioncom from "../AdminComponent/MezoTransactioncom";

function Dashboard() {

  return (
    <div>
    <div className="pagecon">
      <div className="pagecontent">
        <div className="headersisecoadmin">
        <AdminSidemenu/>
        </div>
        <div className="otherssecoth">
        <div className="dashboardconteier">
        <MezoTransactioncom/>
      </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Dashboard;