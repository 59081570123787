import React, { useEffect, useState } from "react";
import "./Banner.css";
import { MdOutlineElectricBolt } from "react-icons/md";
import { Link } from "react-router-dom";

function Banner() {
  return (
    <>
      <div className="bannnerconatinermew">
        <div className="bannmerconsjdhdhd">
          <div className="bannerejeadd">
            Kitipay services
            <hr />
            <p>
              {" "}
              Kitipay.com helps you make payments for services you enjoy right
              from the comfort of your home or office. Experience  total
              convenience, fast service delivery and easy payment at
              your fingertips.
            </p>
            
          </div>
          <div className="servicsjdjd">
            <div className="toolasonolene">
              {/* <div className="serviceicone">
                <h1>Sell Gift Card</h1>
                <Link to="/gift-card-calculator">Check our Rate</Link>
              </div> */}

              <div className="serviceicone">
                <h1>Merchant Payment</h1>
                <Link to="/register">Get Started</Link>
              </div>

              <div className="serviceicone">
                <h1>Pay Electricity Bill</h1>
                <Link to="/register">Buy Now</Link>
              </div>

              <div className="serviceicone">
                <h1>Pay Airtime</h1>
                <Link to="/register">Buy Now</Link>
              </div>

              <div className="serviceicone">
                <h1>Pay Mobile Data </h1>
                <Link to="/register">Buy Now</Link>
              </div>

              <div className="serviceicone">
                <h1> Cable Tv Subscription</h1>
                <Link to="/register">Pay now</Link>
              </div>

              <div className="serviceicone">
                <h1>Fund Betting Wallet</h1>
                <Link to="/register">Fund now</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Banner;
