import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";


function Footer() {

  const d = new Date();
   let year = d.getFullYear();
  return (
    <div>
      <footer id="footer">
        <div class="container">
          <div class="row12345678">
            <div class=" claasjjsjsimage">
              <Link to="/">
                <img src="/img/logo1.jpg" alt="" class="img-fluid logo-footer" />
              </Link>
              
              <ul>
                <li>Careers</li>
                <Link to="/privacy-policy"><li>Terms of service</li></Link>
                <Link to="/privacy-policy"><li>Privacy Policy</li></Link>
              </ul>
               
               
            </div>
          </div>
        </div>
      </footer>

      <section id="copy-right">
        <div class="copy-right-sec">
          <i class="fa-solid fa-copyright"></i>
          Kitihub Software Solution © {year} All Rights Reserved.
        </div>
      </section>
    </div>
  );
}

export default Footer;
