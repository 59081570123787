import React from "react";
import "./ClientOder.css";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import {
  SendNotification,
  getProducts,
  getuser,
} from "../../Services/GetUser.service";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../Services/UserAuth";
import { database } from "../../Services/firebase/firebase";
import { getRandomString } from "../../Services/GetRandomNumber";
import axios from "axios";
import { backendUrl } from "../../Services/firebase/firebase";
import firebase from "firebase";
import { AiOutlineSearch } from "react-icons/ai";
function ClientOrder() {
  const [deliveryMethod, setdeliveryMethod] = useState("");
  const [loading, setloading] = useState(false);
  const [address, setaddress] = useState("");
  const [quantity, setquantity] = useState(1);
  const id = useParams().id;
  const [collection, setusercollection] = useState([]);
  const [vendordata, setvendor] = useState([]);
  const [firstname, setfirstname] = useState("")
  const [lastname, setlastname] = useState("")
  const [mobilenumber, setMobileNumer] = useState("");
  const [emaill, setEmail] = useState("");
  const [product, setproduct] = useState([]);
  const [topping, setTopping] = useState("is_user");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [message, setmessage] = useState("");
  const [email, setemail] = useState("");
  const [phone, setnumber] = useState("");
  const [name, setname] = useState("");
  const [showverify, setShowverity] = useState(false);
  const handleCloseshowverify = () =>{
    window.location.reload()
    setShowverity(false)
  } ;
  const handleShowverify = () => setShowverity(true);
  const [password, setpassword] = useState("");
  const [loginEmail, setlogine] = useState("")
  const [res, setres]= useState("")
  const history =  useHistory()
  const onOptionChange = (e) => {
    setTopping(e.target.value);
  };

  useEffect(() => {
    getProducts((result) => {
      const data = result.filter((e) => e.id === id && e.instock === true);
      const prod = result.filter((e) => e.agentID === data[0].agentID);
      setproduct(prod);
      if (data.length > 0) {
        axios
          .get(
            `https://us-central1-kitipay-stagging.cloudfunctions.net/sendVerificationsmskitipay`
          )
          .then((result) => {
            const s = result.data;

            const vendor = s.filter((e) => e.agentID === data[0].agentID)[0];
            if (vendor) {
              setvendor(vendor);
            }
          });
        setusercollection(data[0]);
      } else {
        return <> No product found</>;
      }
    });
  }, []);

  const handlePlaceOder = async(e) => {
    e.preventDefault();

    if (topping === "is_user") {
        if (
            address === "" ||
            firstname === " " ||
            lastname === " " ||
            quantity === "" ||
            emaill === "" ||
            deliveryMethod === ""
          ){
              toast.error("Provide all required information")
              return
          }
        if (loginEmail === "" || password === ""){
            toast.error("Enter email and password")
            return
        }

        if (isNaN(quantity)){
            toast.error("only number allowed")
            return
        }

        setloading(true)
        setres("Fetching user informarion")
       await axios
        .get(
          `https://us-central1-kitipay-stagging.cloudfunctions.net/sendVerificationsmskitipay`
        )
        .then(async(result) => {
        setres('Validating Information')
          const s = result.data;
          const userData = s.filter((e)=>e.email === loginEmail)[0]
          if (userData){
            if (userData.number !== mobilenumber || userData.isBlock !== false){
                setres("Error: Place your order with your verified mobile number on kitipay")
                toast.error("Error: Place your order with your verified mobile number on kitipay")
                setloading(false)
                return
            }
            const id = database.collection("_").doc().id; 
            const UniquieNum = getRandomString(12, "1234567890");
            const data = {
                customername: `${firstname} ${lastname}`,
                cusmerMobile:mobilenumber,
                customerEmail:emaill,
                customerAddress:address,
                ordercode:UniquieNum,
                productname:collection.productname,
                productquantity:quantity,
                productamount:parseFloat(collection.price * quantity),
                productdetails:collection.description,
                deliverymethod:deliveryMethod,
                customerID: userData.id,
                orderType:topping,
                customernumber:userData.number,
                is_treated:false,
                is_closed:false,
                is_accepted:false,
                isUserverifed:userData.isverified ? "account Verified" : "Account Created but not verified",
                vendorID:vendordata.id,
                vendemail:vendordata.email,
                vendorMobile:vendordata.number,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                vendorfullname: `${vendordata.businessname}`,
                id
            }
             await database
             .collection("Oder")
             .doc(data.id)
             .set(data).then(()=>{
                toast.success("Order Place successfuly.. you will be contacted shortly")
                setres("Order Place successfuly.. you will be contacted shortly")
             })
             const nofifyID = getRandomString(6, "1234567890")
                  const notificationBody = {
                    message: `Order #${data.ordercode} created by ${firstname} ${lastname} please act on it`,
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                    isread: false,
                    id: nofifyID,
                    userID: "",
                    isRi: 0
                  }
                  await SendNotification(notificationBody, vendordata.id, "both")
            setloading(false)
            return
          }
         setres("failed: No user account found.")
        })

        setloading(false)
      return;
    }

    if (topping === "no_user"){
            if (mobilenumber < 11 || mobilenumber === "") {
              toast.error("Unexpected error on mobile number");
              return;
            }
            if (
              password === "" ||
              emaill === ""  ||
              deliveryMethod ===""
            ) {
              toast.error("required input value missing");
              return;
            }
            setloading(true)
            setres("creating account")
            await axios
              .get(
                backendUrl + `createReport/report/user/${mobilenumber}`
              )
              .then(async (res) => {
                setres("validating account information")
                const payload = res.data.length;
                if (payload !== 0) {
                  toast.error("Mobile Already in use by another user");
                  setloading(false)
                  return;
                }
                const UniquieNum = getRandomString(7, "1234567890");
                const data = {
                  UniquieNum,
                  email:emaill,
                  password,
                  number:mobilenumber,
                  firstname,
                  lastname,
                  wallet: 0,
                  coin: 0,
                  created: firebase.firestore.FieldValue.serverTimestamp(),
                };

          
                const baseURL = backendUrl + "createNewUserKitiPay";
                await axios
                  .post(baseURL, { data: data })
                  .then(async (e) => {
                    setres("placing order")
                    if (e.data.uid){
                        setres("Account Created Successfully... ");
                    const id = database.collection("_").doc().id; 
                    const UniquieNum = getRandomString(12, "1234567890");
                    const data = {
                        customername: `${firstname} ${lastname}`,
                        cusmerMobile:mobilenumber,
                        customerEmail:emaill,
                        customerAddress:address,
                        ordercode:UniquieNum,
                        productname:collection.productname,
                        productquantity:quantity,
                        productamount:parseFloat(collection.price * quantity),
                        productdetails:collection.description,
                        deliverymethod:deliveryMethod,
                        customerID: e.data.uid,
                        orderType:topping,
                        customernumber:mobilenumber,
                        is_treated:false,
                        is_closed:false,
                        is_accepted:false,
                        isUserverifed:e.data.action,
                        vendorID:vendordata.id,
                        vendemail:vendordata.email,
                        vendorMobile:vendordata.number,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        vendorfullname: `${vendordata.businessname}`,
                        id
                    }
        
                     await database
                     .collection("Oder")
                     .doc(data.id)
                     .set(data).then(async()=>{
                        toast.success("Order Place successfuly.. you will be contacted shortly")
                        setres("Order Place successfuly.. you will be contacted shortly")
                        const nofifyID = getRandomString(6, "1234567890")
                        const notificationBody = {
                          message: `Order #${data.ordercode} created by ${firstname} ${lastname} please act on it`,
                          created: firebase.firestore.FieldValue.serverTimestamp(),
                          isread: false,
                          id: nofifyID,
                          userID: "",
                          isRi: 0
                        }
                        await SendNotification(notificationBody, vendordata.id, "both")
                     })  
                    }else{
                        setres("Failed registration Try again")
                        toast.error("Failed registration")
                    }
                  
                  })
                  .catch((e) => {
                    toast.error(e.message, {
                      position: toast.POSITION.TOP_RIGHT,
                    });
                  });
               
              });
            setloading(false);
            setres("")
            setMobileNumer("")
            setfirstname("")
            setlastname("")
            setEmail("")
            setaddress("")

     return
    }
  };

  const handleshowmessae = async (e) => {
    e.preventDefault();

    if (message === "") {
      toast.error("Enter your message");
      return;
    }
    setloading(true);
    const id = database.collection("_").doc().id;
    const data = {
      id,
      report: message,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      customername: name,
      email: email,
      number: phone,
      userID: "anonymous",
      isTreated: false,
      vendorid: vendordata.id,
      agentID: vendordata.agentID,
    };
    await database
      .collection("VendorMessage")
      .doc(data.id)
      .set(data)
      .then(() => {
        toast.success("Your message has been submitted👍");
      });
    setloading(false);
  };

  const routetostore = ()=>{
    history.push(`/store/${vendordata.businessname}/${vendordata.agentID}`)
  }

  return (
    <div>
      <div>
        <ToastContainer />

        <Modal
          show={showverify}
          onHide={handleCloseshowverify}
          animation={false}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Body>
            {topping === "is_user" ? (
              <>
                <p>
                  Provide your login information to proceed with this order.. If
                  account not found we will advise you to use the second option
                  to place this order. Note that this not a login page but it is
                  use to verify identity with kitipay
                </p>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" placeholder="Enter email" onChange={(e)=>setlogine(e.target.value)}/>
                  <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                  </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" placeholder="Password" onChange={(e)=>setpassword(e.target.value)} />
                </Form.Group>
              </>
            ) : (
              <>
              <p>
                Kindly provide the following informarion to create account. the marchant will like to know you
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" placeholder="Enter email" onChange={(e)=>setlogine(e.target.value)}/>
                  <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                  </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" placeholder="Password" onChange={(e)=>setpassword(e.target.value)} />
                </Form.Group>
             </p>
              </>
            )}
          </Modal.Body>
          <center className="text-success">{res}</center>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseshowverify}>
              Close
            </Button>
            <Button
              variant="primary"
              disabled={loading}
              onClick={handlePlaceOder}
            >
              {loading ? "Placing Order" : "Order Now"}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={show} onHide={handleClose} animation={false}>
          <Modal.Body>
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setname(e.target.value)}
            />
            <Form.Text className="text-muted">
              We will get back to you with the information provided.
            </Form.Text>{" "}
            <br />
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              onChange={(e) => setemail(e.target.value)}
            />
            <Form.Label>Mobile number</Form.Label>
            <Form.Control
              type="tel"
              onChange={(e) => setnumber(e.target.value)}
            />
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Enter message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                maxLength={1000}
                onChange={(e) => setmessage(e.target.value)}
              />
            </Form.Group>
            <span>OR</span> <br />
            <strong>{vendordata.number}</strong> <br />
            <strong>{vendordata.email}</strong>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              disabled={loading}
              onClick={handleshowmessae}
            >
              {loading ? "Please wait" : " Send message"}
            </Button>
          </Modal.Footer>
        </Modal>
       
        <div className="headersect">
          <div className="headerskdjf">
            <div className="logosideadmind">
              <img src={vendordata.logo} alt="" />
            </div>

            <div className="contactcenter">
              <div className="contactsecintat">
                <div class="wrap">
                  <div class="searchsss">
                    <input
                      type="text"
                      class="searchTerm"
                      placeholder="What are you looking for?"
                    />
                    <button type="submit" class="searchButton">
                      <AiOutlineSearch />
                    </button>
                  </div>
                </div>
                <button className="bixsakdhdjdj" onClick={handleShow}>
                  Contact
                </button>
              </div>
            </div>
          </div>
        </div>
        <Button variant="primary" className="m-2" onClick={routetostore}>Back to store</Button>
        <div className="vendorqcindhdhf">
          <div className="recojdjojnetehd">
            <div className="headerrequstload">Create Order Form</div>

            <div className="formcontineconehhd">
              <h1>Customer Information</h1>
              <Form.Group className="mb-3">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  onChange={(event) => {
                    setfirstname(event.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Last name</Form.Label>
                <Form.Control
                  onChange={(event) => {
                    setlastname(event.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  onChange={(event) => {
                    setMobileNumer(event.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label> Email</Form.Label>
                <Form.Control
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Customer's Address</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  required
                  onChange={(event) => {
                    setaddress(event.target.value);
                  }}
                />
                <Form.Text className="text-muted">
                  Share with us full full Address
                </Form.Text>
              </Form.Group>
              <hr />
              <h1>Product Information</h1>
              <Form.Group className="mb-3">
                <Form.Label>Product name</Form.Label>
                <Form.Control disabled value={collection.productname} />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Quantity</Form.Label>
                <Form.Control
                  type="tel"
                  onChange={(event) => {
                    setquantity(event.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  disabled
                  value={
                    collection.price * quantity
                      ? collection.price * quantity
                      : 0
                  }
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Product Details</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  disabled
                  value={collection.description}
                />
                <Form.Text className="text-muted">
                  Berief information of the item
                </Form.Text>
              </Form.Group>
              <hr />
              <h1>Delivery Method</h1>
              <Form.Select
                aria-label="Default select example"
                onChange={(event) => {
                  setdeliveryMethod(event.target.value);
                }}
              >
                <option value="">Open this select Delivery Method</option>
                <option value="Logistics">Logistics Company</option>
                <option value="Self Delivery">Self Delivery</option>
              </Form.Select>
              <input
                type="radio"
                name="topping"
                value="no_user"
                id="regular"
                checked={topping === "no_user"}
                onChange={onOptionChange}
              />
              <label htmlFor="regular">I dont have a kitipay account</label>{" "}
              <br />
              <input
                type="radio"
                name="topping"
                value="is_user"
                id="medium"
                checked={topping === "is_user"}
                onChange={onOptionChange}
              />
              <label htmlFor="medium">I have a kitipay account</label> <br />
              <Button
                disabled={loading}
                variant={loading ? "warning" : "primary"}
                onClick={handleShowverify}
                className="w-100"
                type="submit"
              >
                {loading ? "Placing Order.." : "Create Order..."}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientOrder;
