import React from 'react'
import Sidebar from '../ClientComponent/Sidemenue'
import "../ClientComponent/Complaince.css"
import WalletTransercom from '../ClientComponent/WalletTransercom'

function ComplainceGeneral() {
  return (
    <div>
          <div className="pagecon">
      <div className="pagecontent">
        <div className="headersisecoadmin">
        <Sidebar/>
        </div>
        <div className="otherssecoth">
            <WalletTransercom/>
        </div>
      </div>
    </div>
    </div>
  )
}

export default ComplainceGeneral
