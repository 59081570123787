import React, { useEffect, useState } from "react";
import { Button, Modal} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import "./Complaince.css";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../../Services/UserAuth";


function ComplainceCom() {
  const history =  useHistory()
  const { loginuser} = useAuth();
  const [showalert, setShowalert] = useState(false);
  const handleClosealert = () => setShowalert(false);
  const handleShowalert = () => setShowalert(true);


  const handleHome = ()=>{
    history.push(`/user/dashboard/${loginuser.id}`)
  }

  useEffect(() => {


    if (loginuser.userverified === false){
      handleShowalert()
      return
    }
  }, []);


  const handleprofileInformation = ()=>{
    history.push("/compliance-verify/profile")
  }

  const handleBuusinessRepresentative = ()=>{
    history.push("/compliance-verify/business-representative")
  }

  const handleBusiness = ()=>{
    history.push("/compliance-verify/business-information")
  }

  const handleBankdetails = ()=>{
    history.push("/compliance-verify/bank-information")
  }

  const handleDocument = ()=>{
    history.push("/compliance-verify/document-upload")
  }
  return (
    <div>
      <ToastContainer />
      <Modal 
        show={showalert}
        onHide={handleClosealert }
        backdrop="static"
        keyboard={false}
        centered
      >
    
        <Modal.Body>
          Dear {loginuser.firstname}. Kindly Verify your account before proceeding with this process. 
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHome}>
            Back Home
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="headersentskdjd">
        <div className="headeconatiner">
          <Link to="/dashboard/Compliance/general">General</Link>
          <Link
            to="/dashboard/Compliance"
            className="theactiveone text-success"
          >
            Compliance
          </Link>
          {/* <Link to="/dashboard/Compliance/doc/verification">
            Verification
          </Link> */}
          <Link to="/dashboard/Compliance/settlement">Settlement</Link>
        </div>
        <center>
          <hr className="w-50 " />
        </center>
      </div>

      <div className="headersecek">
        {/* <h1>Compliance</h1> */}
        <p className="d-flex">
          Go live Status : &nbsp;{" "}
          {loginuser.isverified !== true ? (
            <div className="text-danger"> Pending</div>
          ) : (
            <div className="text-success">Verified</div>
          )}
        </p>
      </div>

      <span>
        Here you will find information about your Account's compliance and
        verification status . Please keep in mind that any modifications you
        make below will need to be double-checked for compliance.
      </span>
      

      <div className="complainacncedcoatoe">
        <div className="complaincecona">
          <div className="compliancesdjd" onClick={handleprofileInformation}>
            <h1>My Profile Information</h1>
            status: {loginuser.userverified === false ? "Not Verified" : "Verified"}
          </div>

          <div className="compliancesdjd" onClick={handleBusiness}>
           <h1>Business Information</h1>
            <p>status: {!loginuser.isbuisnessInformationVerified || loginuser.isbuisnessInformationVerified === false? "Not Verified" : "Verified"}</p>
           <p>{!loginuser.isbusinessInfoSubmited || loginuser.isbuisnessInformationVerified === false? "Pending" : "Submited"}</p>
          </div>

          <div className="compliancesdjd" onClick={handleBuusinessRepresentative}>
           <h1>Business Representative Information</h1>
            <p>status: {!loginuser.isbusineRepVerify ||loginuser.isbusineRepVerify === false ? "Not Verified" : "Verified"}</p>
            <p>{!loginuser.isbusineRepVerify || loginuser.isbusinessRepSubmited === false ? "Pending" : "Submited"}</p>

          </div>

          <div className="compliancesdjd" onClick={handleBankdetails}>
           <h1>Bank Detail</h1>
            <p>status: {!loginuser.isbusineDocVerify ||loginuser.isbusineDocVerify === false ? "Not Verified" : "Verified"}</p>
            <p>{!loginuser.isbusineDocVerify || loginuser.isbusinessBankSubmited === false ? "Pending" : "Submited"}</p>
          </div>

          <div className="compliancesdjd" onClick={handleDocument}>
           <h1>Document Verification</h1>
           <p> status: {!loginuser.isbusineDocumentVerify ||loginuser.isbusineDocumentVerify === false ? "Not Verified" : "Verified"}</p>
           <p>{!loginuser.isbusineDocumentVerify || loginuser.isbusinessDocumentSubmited === false ? "Pending" : "Submited"}</p>
          </div>
        </div>
      </div>

      {/* <div className="useridodnsidd">
        <Tabs
          defaultActiveKey={nextfill}
          id="fill-tab-example"
          className="mb-3"
          fill
        >
          <Tab eventKey="home" title="Profile" style={{ background: "none" }}>
          <div className="aformconteinr">
                  <div className="informatronpobody">
                    <div className="inforalteader">Name :</div>
                    <div className="headerbodycontainer">
                      {loginuser.lastname} {loginuser.firstname}
                    </div>
                  </div>

                  <div className="informatronpobody">
                    <div className="inforalteader">Mobile Number :</div>
                    <div className="headerbodycontainer">
                      {loginuser.number}
                    </div>
                  </div>

                  <div className="informatronpobody">
                    <div className="inforalteader"> Email :</div>
                    <div className="headerbodycontainer">
                      {loginuser.email}
                    </div>
                  </div>

                  <div className="informatronpobody">
                    <div className="inforalteader">Account Type:</div>
                    <div className="headerbodycontainer">
                      {loginuser.role }
                    </div>
                  </div>
                  <div className="informatronpobody">
                    <div className="inforalteader">Wallet Balance :</div>
                    <div className="headerbodycontainer">
                      {userwallet.wallet}
                    </div>
                  </div>
                </div>
          </Tab>
          <Tab
            eventKey="Business Details"
            title="Business Details "
            style={{ background: "none" }}
          >
            {loginuser.isbusinessInfoSubmited === true ? (
              <>
                <div className="aformconteinr">
                  <div className="albs">
                    {loginuser.isverified !== true ? (
                      <div className="text-danger"> Awaitn Admin Approval</div>
                    ) : (
                      <div className="text-success">Verified</div>
                    )}
                  </div>
                  <div className="informatronpobody">
                    <div className="inforalteader">Business Name :</div>
                    <div className="headerbodycontainer">
                      {loginuser.businessname}
                    </div>
                  </div>

                  <div className="informatronpobody">
                    <div className="inforalteader">Business Address :</div>
                    <div className="headerbodycontainer">
                      {loginuser.businessaddress}
                    </div>
                  </div>

                  <div className="informatronpobody">
                    <div className="inforalteader">Business Email :</div>
                    <div className="headerbodycontainer">
                      {loginuser.businessemail}
                    </div>
                  </div>

                  <div className="informatronpobody">
                    <div className="inforalteader">Business Reg No :</div>
                    <div className="headerbodycontainer">
                      {loginuser.businessreg ? loginuser.businessreg : ""}
                    </div>
                  </div>
                  <div className="informatronpobody">
                    <div className="inforalteader">Business Description :</div>
                    <div className="headerbodycontainer">
                      {loginuser.businessdiscription}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="aformconteinr">
                <Form.Select
                  aria-label="Default select example"
                  onChange={(event) => {
                    settype(event.target.value);
                  }}
                >
                  <option value="">Open this Business Type</option>
                  <option value="Registered">Registered Business </option>
                  <option value="Non Registered">
                    Non-Registered Business
                  </option>
                </Form.Select>
                <Form.Text id="passwordHelpBlock" muted>
                  Select your Business Type
                </Form.Text>

                <div className="businessregisterconatiner">
                  <div
                    className="buinswesconehf"
                    style={{ display: type === "" ? "none" : "block" }}
                  >
                    <p>Business Information</p>
                    <Form.Group className="mb-3">
                      <Form.Label>Business Name</Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(event) => {
                          setbusinessname(event.target.value);
                        }}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      style={{
                        display: type === "Non Registered" ? "none" : "block",
                      }}
                    >
                      <Form.Label>RC/BN No.</Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(event) => {
                          setbusinesregno(event.target.value);
                        }}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Business Email Address</Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(event) => {
                          setbusinessemail(event.target.value);
                        }}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label> Business Address</Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(event) => {
                          setbusinessaddress(event.target.value);
                        }}
                      />
                    </Form.Group>

                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Business Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        onChange={(event) => {
                          setbusinessdescription(event.target.value);
                        }}
                      />
                      <Form.Text id="passwordHelpBlock" muted>
                        Tell us More about your business
                      </Form.Text>

                      <Form.Text id="passwordHelpBlock" muted>
                        {res}
                      </Form.Text>
                    </Form.Group>

                    <Button
                      variant={loading ? "warning" : "dark"}
                      className="w-100"
                      onClick={handlesenddbusiness}
                    >
                      {loading ? "Wait" : "Send Request"}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </Tab>
          <Tab
            eventKey="Business Representative"
            title="Business Representative"
            style={{ background: "none" }}
          >
            {loginuser.isbusinessRepSubmited === true ? (
              <>
                <div className="aformconteinr">
                  <div className="albs">
                    {message ? message : ""}
                    {loginuser.isverified !== true ? (
                      <div className="text-danger"> Awaitn Admin Approval</div>
                    ) : (
                      <div className="text-success">Verified</div>
                    )}
                  </div>
                  <div className="informatronpobody">
                    <div className="inforalteader"> Representative Name :</div>
                    <div className="headerbodycontainer">
                      {loginuser.BusinessRepFullName}
                    </div>
                  </div>

                  <div className="informatronpobody">
                    <div className="inforalteader">
                      Representative Address :
                    </div>
                    <div className="headerbodycontainer">
                      {loginuser.resAddress}
                    </div>
                  </div>

                  <div className="informatronpobody">
                    <div className="inforalteader">Representative Email :</div>
                    <div className="headerbodycontainer">
                      {loginuser.RepEmailaddress}
                    </div>
                  </div>

                  <div className="informatronpobody">
                    <div className="inforalteader">
                      Representative Mobile Number :
                    </div>
                    <div className="headerbodycontainer">
                      {loginuser.Repmobilenumber
                        ? loginuser.Repmobilenumber
                        : ""}
                    </div>
                  </div>

                  <div className="informatronpobody">
                    <div className="inforalteader">Country :</div>
                    <div className="headerbodycontainer">
                      {loginuser.repscountry ? loginuser.repscountry : ""}
                    </div>
                  </div>

                  <div className="informatronpobody">
                    <div className="inforalteader">State :</div>
                    <div className="headerbodycontainer">
                      {loginuser.repselectedState
                        ? loginuser.repselectedState
                        : ""}
                    </div>
                  </div>

                  <div className="informatronpobody">
                    <div className="inforalteader"> Region :</div>
                    <div className="headerbodycontainer">
                      {loginuser.RepselectedRegion
                        ? loginuser.RepselectedRegion
                        : ""}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="aformconteinr">
                <p>Contact Person Information</p>

                <Form.Group className="mb-3">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(event) => {
                      setfullname(event.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Mobile Number</Form.Label>
                  <Form.Control
                    type="tel"
                    onChange={(event) => {
                      setmobilenumber(event.target.value);
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>BVN</Form.Label>
                  <Form.Control
                    type="tel"
                    onChange={(event) => {
                      setBvn(event.target.value);
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(event) => {
                      setemailaddress(event.target.value);
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Residential Address</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(event) => {
                      setResAddress(event.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Label>Country</Form.Label>
                <Form.Select
                  onChange={(event) => {
                    setRescountry(event.target.value);
                  }}
                >
                  <option>Open this select Country</option>
                  <option value="Nigeria">Nigeria</option>
                </Form.Select>

                <Form.Label>State</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(event) => {
                    setselectedstate(event.target.value);
                  }}
                >
                  <option value="">Open to select state</option>
                  {nigeriastate.map((e) => {
                    return <option value={e.alias}>{e.name}</option>;
                  })}
                </Form.Select>
                <Form.Label>Region</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(event) => {
                    setselelectedRegion(event.target.value);
                  }}
                >
                  {nigeriaregion
                    ? nigeriaregion.map((e) => {
                        return <option value={e.alias}>{e.name}</option>;
                      })
                    : []}
                </Form.Select>

                <Button
                  variant={loading ? "warning" : "dark"}
                  className="w-100"
                  onClick={handleRepInfomration}
                >
                  {loading ? "Wait" : "Send Request"}
                </Button>
              </div>
            )}
          </Tab>

          <Tab
            eventKey="Bank Account"
            title="Bank Account"
            style={{ background: "none" }}
          >
            {loginuser.isbusinessBankSubmited === true ? (
              <>
                <div className="aformconteinr">
                  <div className="albs">
                    {message ? message : ""}
                    {loginuser.isverified !== true ? (
                      <div className="text-danger"> Awaitn Admin Approval</div>
                    ) : (
                      <div className="text-success">Verified</div>
                    )}
                  </div>
                  <div className="informatronpobody">
                    <div className="inforalteader"> Bank Name :</div>
                    <div className="headerbodycontainer">
                      {loginuser.BusinessBankName}
                    </div>
                  </div>

                  <div className="informatronpobody">
                    <div className="inforalteader">Account Name :</div>
                    <div className="headerbodycontainer">
                      {loginuser.BusinessAcccountName}
                    </div>
                  </div>

                  <div className="informatronpobody">
                    <div className="inforalteader">Account Number :</div>
                    <div className="headerbodycontainer">
                      {loginuser.BusinessAcccountNumber}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="aformconteinr">
                <p>Bank Information</p>

                <Form.Label>Bank</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(event) => {
                    setselectedBank(event.target.value);
                  }}
                >
                  <option value="">Open to select Bank</option>
                  {bank.map((e) => {
                    return <option value={e.bankCode}>{e.name}</option>;
                  })}
                </Form.Select>

                <Form.Group className="mb-3">
                  <Form.Label>Account Number</Form.Label>
                  <Form.Control type="text" onChange={getAccountNumber} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Account Name</Form.Label>
                  <Form.Control type="text" disabled value={accountHolder} />
                </Form.Group>

                <Button
                  variant={ norecordfund ? "warning" : "dark"}
                  className="w-100"
                  onClick={handleAccouuntInfo}
                >
                  { norecordfund ? "Waiting..." : "Send Request"}
                </Button>
              </div>
            )}
          </Tab>
        </Tabs>
      </div> */}
    </div>
  );
}

export default ComplainceCom;
