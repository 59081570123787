import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import "./User.css";
import { format } from "date-fns";
import { timeStamptoDate } from "../../../Services/Utility";
import { Link, useHistory } from "react-router-dom";

function UserLogs() {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const history = useHistory()

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await axios
        .get(
          "https://us-central1-kitipay-stagging.cloudfunctions.net/userlogs/user/actions"
        )
        .then((res) => {
          const data = res.data;
          setItems((prevItems) => [...prevItems, ...data]);
          setPage((prevPage) => prevPage + 1);
        });
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      isLoading
    ) {
      return;
    }
    fetchData();
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading]);
const handlehome = ()=>{
    history.push('/admin/dashboard')
  }
  return (
    <div>
        <button onClick={handlehome}>Back</button>
      <div>
        {items.map((item, id) => {
          const d = timeStamptoDate(item);
          return (
            <>
              <div className="allLogscontainer" key={id}>
                <div className="allcontent">
                  <hr />
                  <div className="conterollercontaine">
                    <div className="contendjdd">
                      <p>Log-Date-time</p>
                    </div>
                    <div className="conatewnevalue">
                      <p>
                        {" "}
                        {d.created
                          ? format(d.created, "dd/MM/yyyy hh:mm a")
                          : ""}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className="conterollercontaine">
                    <div className="contendjdd">
                      <p>Action</p>
                    </div>
                    <div className="conatewnevalue">
                      <p>{item.action}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="conterollercontaine">
                    <div className="contendjdd">
                      <p>Action type</p>
                    </div>
                    <div className="conatewnevalue">
                      <p>{item.action_type}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="conterollercontaine">
                    <div className="contendjdd">
                      <p>Action type</p>
                    </div>
                    <div className="conatewnevalue">
                      <p>{item.action_type}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="conterollercontaine">
                    <div className="contendjdd">
                      <p>Record_id</p>
                    </div>
                    <div className="conatewnevalue">
                      <p>{item.record_id}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="conterollercontaine">
                    <div className="contendjdd">
                      <p>payload</p>
                    </div>
                    <div className="conatewnevalue">
                      <p>{item.changes}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="conterollercontaine">
                    <div className="contendjdd">
                      <p>User ID</p>
                    </div>
                    <div className="conatewnevalue">
                      <Link className="text-danger p-1" to={`/profile/user-manager/${item.userId}`}>
                        {item.userId}
                      </Link>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </>
          );
        })}

        {isLoading && <p>Loading...</p>}
        {error && <p>Error: {error.message}</p>}
      </div>
    </div>
  );
}

export default UserLogs;
