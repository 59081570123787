import React from 'react'
import { useEffect } from 'react'
import { getProducts } from '../../Services/GetUser.service'
import { useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import "./Marc.css"
import { formatNumber } from '../../Services/Utility'
import { AiOutlineSearch } from "react-icons/ai"
import { Button, Form, Modal } from 'react-bootstrap'
import { useCallback } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import firebase from 'firebase'
import { database } from '../../Services/firebase/firebase'
import {IoMdExit} from "react-icons/io"


function Marhangstore() {
  const [collection, setusercollection] = useState([])
  const [vendor, setvendor] = useState([])
  const id = useParams().udID
  const history = useHistory()
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [message, setmessage] =  useState("")
  const [loading, setloading] =  useState(false)
  const [email,setemail]=useState("")
  const [phone, setnumber]= useState("")
  const [name,setname] =  useState("")
  



  useEffect(() => {
    getProducts((result) => {
      const data = result.filter((e) => e.agentID === id && e.instock === true)
      setusercollection(data)
    })
    axios.get(`https://us-central1-kitipay-stagging.cloudfunctions.net/sendVerificationsmskitipay`).then((result) => {
      const data = result.data
      const vendor = data.filter((e) => e.agentID === id)[0]
      if (vendor) {
        setvendor(vendor)
      } else {
        return (
          <>
            Missing link
          </>
        )
      }
    })
  }, [])

  const handleProduct = () => {
    history.push("")
  }

  const handleshowmessae = async(e) => {
    e.preventDefault()

    if (message === ""){
      toast.error("Enter your message")
      return
    }
    setloading(true)
    const id = database.collection("_").doc().id;
    const data = {
      id,
      report:message,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      customername: name,
      email:email,
      number:phone,
      userID:"anonymous",
      isTreated: false,
      vendorid:vendor.id,
      agentID:vendor.agentID
    };
  await database
          .collection("VendorMessage")
          .doc(data.id)
          .set(data).then(() => {
        toast.success("Your message has been submitted👍");
      })
    setloading(false)
  }


  const Togglemodalview = useCallback(
    (item) => () => {
  
      history.push(`/store/product/item/${item.id}`)
    },
    []
  );

  return (
    <div>
<ToastContainer/>
      <Modal show={show} onHide={handleClose} animation={false}>

        <Modal.Body>
        <Form.Label>Full Name</Form.Label>
        <Form.Control type="text" onChange={(e)=>setname(e.target.value)}/>
        <Form.Text className="text-muted">
          We will get back to you with the information provided.
        </Form.Text> <br/>

        <Form.Label>Email address</Form.Label>
        <Form.Control type="email"  onChange={(e)=>setemail(e.target.value)}/>

        <Form.Label>Mobile number</Form.Label>
        <Form.Control type="tel" onChange={(e)=>setnumber(e.target.value)}/>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Enter message</Form.Label>
            <Form.Control as="textarea" rows={3} maxLength={1000} onChange={(e)=>setmessage(e.target.value)} />
          </Form.Group>
            <span>OR</span> <br/>
          <strong>{vendor.number}</strong> <br/>
          <strong>{vendor.email}</strong>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" disabled={loading} onClick={handleshowmessae}>
           {loading? "Please wait" : " Send message"}
          </Button>
        </Modal.Footer>
      </Modal>
      <div className='nadlskcswescriodnd'>
        <div className="headersect">
          <div className="headerskdjf">
            <div className="logosideadmind">
              <img src={vendor.logo} alt="" />
            </div>

            <div className="contactcenter">
              <div className="contactsecintat">
                <div class="wrap">
                  <div class="searchsss">
                    <input type="text" class="searchTerm" placeholder="What are you looking for?" />
                    <button type="submit" class="searchButton">
                      <AiOutlineSearch />
                    </button>
                  </div>
                </div>
                <button className='bixsakdhdjdj' onClick={handleShow}>Contact</button>
              </div>
            </div>
          </div>
        </div>
        <div className="bannersectionsvendor">
          <div className="bannefcomajndkd">
            <img src={vendor.banner} alt="" />
          </div>
        </div>

        <div className="marcangecondhhd">
          <div className="margconahhdd">
            <div className="messagesaidbd">
              <div className="aboutconatiner">
                <div className="aboutvwendd">
                  <h1>About {vendor.businessname}</h1>
                  <hr />
                  <p>{vendor.welcomemessage}</p>
                </div>
                <hr />

                <div className="staticts">
                  Contact Person:  {vendor.BusinessRepFullName}
                </div>
                <hr />

                <div className="staticts">
                  Address :  {vendor.businessaddress}
                </div>
                <hr />

                <div className="staticts">
                  Product list ({collection.length})
                </div>
                <hr />
                <div className="staticts">
                  Status ({vendor.isverified ? "Verfied" : "Not-Verfied"})
                </div>
                <hr />

                <div className="staticts">
                  exist (<Link className='text-danger' to="/"><IoMdExit/></Link>)
                </div>
                <hr />
              </div>

              <div className="productcardprod">
                <div class="bestSelling-beers">
                  {collection.length === 0 ? <>
                    no product uploaded
                  </> :
                    <>
                      {collection.map((item, id) => {
                        return (
                          <div class="bs-card">
                            <div class="bs-card-img" >
                              <img src={item.coverimage} alt="" />
                            </div>
                            <div class="bs-card-body" >
                              <div class="bs-card-header">
                                <a href="#" class="bs-card-title">{item.productname}</a>
                              </div>
                              <div class="bs-beer-footer">
                                <span class="price">
                                  &#8358; {item.price ? formatNumber(item.price) : 0}
                                </span>
                                <span class="bs-card-btn" onClick={Togglemodalview(item)}>
                                  View Product
                                </span>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </>
                  }

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Marhangstore