import React from 'react'
import "./Single.css"
import { useHistory, useParams } from 'react-router-dom'
import { getProducts } from '../../Services/GetUser.service'
import { useEffect } from 'react'
import axios from 'axios'
import { AiOutlineSearch } from "react-icons/ai"
import { useState } from 'react'
import { database } from '../../Services/firebase/firebase'
import firebase from 'firebase'
import { toast, ToastContainer } from 'react-toastify'
import { Button, Carousel, Form, Modal } from 'react-bootstrap'
import { formatNumber } from '../../Services/Utility'
import { useCallback } from 'react'
function SingleProductPage() {
    const [collection, setusercollection] = useState([])
    const [vendordata, setvendor] = useState([])
    const id = useParams().id
    const history = useHistory()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [message, setmessage] = useState("")
    const [loading, setloading] = useState(false)
    const [email, setemail] = useState("")
    const [phone, setnumber] = useState("")
    const [name, setname] = useState("")
    const [product, setproduct]=useState([])

    useEffect(() => {
        getProducts((result) => {
            const data = result.filter((e) => e.id === id && e.instock === true)
            const prod =  result.filter((e)=> e.agentID === data[0].agentID)
            setproduct(prod)
            if (data.length > 0) {
                axios.get(`https://us-central1-kitipay-stagging.cloudfunctions.net/sendVerificationsmskitipay`).then((result) => {
                    const s = result.data
                    
                    const vendor = s.filter((e) => e.agentID === data[0].agentID)[0]
                    if (vendor) {
                        setvendor(vendor)
                    }
                })
                setusercollection(data[0])
            } else {
                return <> No product found</>
            }
        })
    }, [])

    const handleshowmessae = async (e) => {
        e.preventDefault()

        if (message === "") {
            toast.error("Enter your message")
            return
        }
        setloading(true)
        const id = database.collection("_").doc().id;
        const data = {
            id,
            report: message,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            customername: name,
            email: email,
            number: phone,
            userID: "anonymous",
            isTreated: false,
            vendorid: vendordata.id,
            agentID: vendordata.agentID
        };
        await database
            .collection("VendorMessage")
            .doc(data.id)
            .set(data).then(() => {
                toast.success("Your message has been submitted👍");
            })
        setloading(false)
    }

    const Togglemodalview = useCallback(
        (item) => () => {
    
         window.location.href=`/store/product/item/${item.id}`
        },
        []
      );

    const handlePlaceOrder = (e)=>{
        e.preventDefault()
        history.push(`/store/product/order/${id}`)
    }
    const routetostore = ()=>{
        history.push(`/store/${vendordata.businessname}/${vendordata.agentID}`)
      }

    return (
        <div>
            <ToastContainer />
            <Modal show={show} onHide={handleClose} animation={false}>

                <Modal.Body>
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control type="text" onChange={(e) => setname(e.target.value)} />
                    <Form.Text className="text-muted">
                        We will get back to you with the information provided.
                    </Form.Text> <br />

                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" onChange={(e) => setemail(e.target.value)} />

                    <Form.Label>Mobile number</Form.Label>
                    <Form.Control type="tel" onChange={(e) => setnumber(e.target.value)} />
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Enter message</Form.Label>
                        <Form.Control as="textarea" rows={3} maxLength={1000} onChange={(e) => setmessage(e.target.value)} />
                    </Form.Group>
                    <span>OR</span> <br />
                    <strong>{vendordata.number}</strong> <br />
                    <strong>{vendordata.email}</strong>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" disabled={loading} onClick={handleshowmessae}>
                        {loading ? "Please wait" : " Send message"}
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className="headersect">
                <div className="headerskdjf">
                    <div className="logosideadmind">
                        <img src={vendordata.logo} alt="" />
                    </div>

                    <div className="contactcenter">
                        <div className="contactsecintat">
                            <div class="wrap">
                                <div class="searchsss">
                                    <input type="text" class="searchTerm" placeholder="What are you looking for?" />
                                    <button type="submit" class="searchButton">
                                        <AiOutlineSearch />
                                    </button>
                                </div>
                            </div>
                            <button className='bixsakdhdjdj' onClick={handleShow}>Contact</button>
                        </div>
                    </div>
                </div>
            </div>
            
            <Button variant="primary" className="m-2" onClick={routetostore}>Back to store</Button>

            <div className="cardosms">
                <div className="productalsnd">
                    <div className="poandjnsosejnbd">
                        <Carousel>
                            {collection.ProductImages ? collection.ProductImages.map((i) => {

                                return (

                                    <Carousel.Item intervabl={1000}>
                                        <img
                                            className="d-block w-100 allshsimage"
                                            src={i}
                                            alt="First slide"
                                            height="350"
                                            width="400px"

                                        />
                                    </Carousel.Item>

                                )
                            }) : []}
                        </Carousel>
                    </div>
                    <div className="alkdjbdffhd">
                        <div className="promducakckjnkdd">
                            <div className="namejssejd">
                                <h1>Machant Name</h1>
                            </div>
                            <div className="prodyctname">
                                <h3> {vendordata.Repmobilenumber}</h3>
                            </div>
                        </div>
                        <hr />
                        <div className="promducakckjnkdd">
                            <div className="namejssejd">
                                <h1> Machant Mobile Number</h1>
                            </div>
                            <div className="prodyctname">
                                <h3> {vendordata.businessname}</h3>

                            </div>
                        </div>
                        <hr />
                        <div className="promducakckjnkdd">
                            <div className="namejssejd">
                                <h1> Product name</h1>
                            </div>
                            <div className="prodyctname">
                                <h3> {collection.productname}</h3>
                            </div>
                        </div>
                        <hr />
                        <div className="promducakckjnkdd">
                            <div className="namejssejd">
                                <h1> Product Detail</h1>
                            </div>
                            <div className="prodyctname">
                                <h3> {collection.description}</h3>
                            </div>
                        </div>
                        <hr />
                        <div className="promducakckjnkdd">
                            <div className="namejssejd">
                                <h1> Product Price</h1>
                            </div>
                            <div className="prodyctname">
                                <h3> {collection.price}</h3>
                            </div>
                        </div>
                        <hr />
                        <div className="promducakckjnkdd">
                            <div className="namejssejd">
                                <h1> Quantity</h1>
                            </div>
                            <div className="prodyctname">

                            </div>
                        </div>
                        <hr />
                        <br />
                        <Button variant="primary" disabled={loading} onClick={handlePlaceOrder}>Place Order</Button>
                    </div>
                    
                </div>
            </div>

            <div className="heaskcoojdjbdd">
                <h2>Top Selling Items</h2>
                <div className="productcardprod">
                <div class="bestSelling-beers">
                  {collection.length === 0 ? <>
                    no product uploaded
                  </> :
                    <>
                      {product.map((item, id) => {
                        return (
                          <div class="bs-card">
                            <div class="bs-card-img" >
                              <img src={item.coverimage} alt="" />
                            </div>
                            <div class="bs-card-body" >
                              <div class="bs-card-header">
                                <a href="#" class="bs-card-title">{item.productname}</a>
                              </div>
                              <div class="bs-beer-footer">
                                <span class="price">
                                  &#8358; {item.price ? formatNumber(item.price) : 0}
                                </span>
                                <span class="bs-card-btn" onClick={Togglemodalview(item)}>
                                  View Product
                                </span>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </>
                  }

                </div>
              </div>
            </div>

        </div>
    )
}

export default SingleProductPage