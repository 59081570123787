import React, { useEffect, useState } from "react";
import "./Supportcenter.css";
import { getsupport } from "../../../Services/GetUser.service";
import { useCallback } from "react";
import { database } from "../../../Services/firebase/firebase";
import { ToastContainer, toast } from "react-toastify";
import { Spinner, Toast } from "react-bootstrap";
import { useHistory } from "react-router-dom";
function SupportAdmin() {
  const [messagelog, setusermessage] = useState([]);
  const [loading, setloading] = useState(false)
  const history =  useHistory()
  useEffect(() => {
    getsupport((result) => {
      setusermessage(result);
    });
  }, []);


  const Togglemodalview = useCallback(
    (item) => async () => {
      
      if (item.isTreated ===  true){
        toast.error("Message marke as read already")
        return
      }
      setloading(true)
        await database
        .doc(`SupportMessage/${item.id}`)
        .update({
          isTreated:true
        }).then((e)=>{
          toast.success("Message mark as read")
        })
       setloading(false)
    },
    []
  );

  const Togglemodalviewe = useCallback(
    (item) => async () => {
      history.push(`/send-message/${item.id}`)
    },
    []
  );

  return (
    <div>
      <ToastContainer/>
      <div className="messageconayaind">
        <div className="messageconatje">
          <div className="meadermessahe">
            <h1>Message Center</h1>
            <span>
              Agent, Please be informed that all written responses should be
              well written and preferred before sending to the recipient.. Edit
              not allowed after sending
            </span>
          </div>


          <div className="messageconatiner">
            <div className="messageconatuner">
              <div className="messageboxalready">
                {messagelog.length === 0 ? (
                  <>No Record Found</>
                ) : (
                  <>
                    {messagelog.map((item, id) => {
                      return (
                        <>
                          <div id="event-log-container" key={id}>
                            <div id="event-log-header">
                              <button className="event-log-clear-btn bg-info text-white" onClick={Togglemodalviewe(item)}>Reply</button>
                              <button className="event-log-clear-btn text-white" style={{backgroundColor : item.isTreated === true  ? "red" : "green" }} onClick={Togglemodalview(item)} disabled = {loading}>
                               {loading ?   <Spinner animation="grow" size="sm" />  : <div>{item.isTreated === true ? "Treated" : "Make as read"}</div>} 
                              </button>
                            </div>
                            <div className="lakdallsjssj">
                              {item.customername} sent you a message{" "}
                            </div>
                            <div id="event-log-content">
                              <pre id="event-log-msg-container">
                             <div id="event-log-msg-text">{item.report}</div>
                              </pre>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupportAdmin;
