import React, { useEffect } from "react";
import { useState } from "react";
import { Button, FloatingLabel, Form, Modal, Spinner } from "react-bootstrap";
import { useAuth } from "../../../Services/UserAuth";
import "./Transaction.css";
import { ToastContainer, toast } from "react-toastify";
import { format } from "date-fns";
import axios from "axios";
import { database } from "../../../Services/firebase/firebase";
import firebase from "firebase";
import { Link, useHistory } from "react-router-dom";
import Bank from "../../../Bank.json";
import OtpInput from "react-otp-input";
import { backendUrl } from "../../../Services/firebase/firebase";
import { getRandomString } from "../../../Services/GetRandomNumber";
import { SendNotification } from "../../../Services/GetUser.service";
import { version_control } from "../../../Services/Utility";

function Airtimecomponent() {
  const [amount, setamount] = useState();
  const [selectednetwork, setselectednetwork] = useState("");
  const [number, setphone] = useState("");
  const [loading, setloading] = useState(false);
  const { loginuser, userwallet } = useAuth();
  const [responspayload, setresponsepayload] = useState("");
  const [show, setShow] = useState(false);
  const [payload, setpayload] = useState([]);
  const [showprint, setShowprint] = useState(false);
  const [res, setrepayload] = useState([]);
  const [transdiscription, setTransDiscription] = useState("");
  const [otp, setOtp] = useState("");
  const history = useHistory();
  const BankData = Bank.data;


  const handleClosePrint = () => {
    window.location.reload();
    setShowprint(false);
  };
  const handleShowprint = () => setShowprint(true);
  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if (loginuser.isPinSet === false) {
      history.push(`/user/set-transaction-pin/${loginuser.id}`);
      return;
    }

  }, [selectednetwork]);


  const limit = loginuser.transactionLimit - amount
  const TransactionLimit = limit <= 0 ? 0 : loginuser.transactionLimit
  const version = version_control()

  const handleShow = () => setShow(true);

  const date = format(new Date(), "dd/MM/yyyy hh:mm a");

  const handleverifier = async (e) => {
    e.preventDefault();

    if (otp.length !== 4) {
      toast.error("Enter Transaction Pin");
      return;
    }

    if (!amount) {
      toast.error("Enter amount");
      return;
    }

    if (loginuser.Trial <= 0){
      toast.error("Pin Attempt exceeded");
      return;
    }

    if (isNaN(amount)) {
      toast.error("Enter amount");
      return;
    }

    if (limit <= 0){
      toast.error("Limit Error");
      return;
    }
 
    if (otp !== loginuser.pin) {
      setloading(true);
      await axios
        .put(
          backendUrl+ `credituser/users/wallet/${loginuser.id}`
        )
        .then((e) => {
          toast.info(e.data.message);
        });
      setloading(false);
      return;
    }

    if (loginuser.status === "restricted") {
      toast.error("Debit not allowed");
      return;
    }

    if (selectednetwork === "Wallet Transfer" && number === "") {
      toast.error("Enter recipent number");
      return;
    }

    if (selectednetwork === "Wallet Transfer") {
      setloading(true);
      await axios
        .get(
          backendUrl+ `acceptVendorRequest/users/payment/request/${number}`
        )
        .then((res) => {
          const payloadres = res.data.payload[0];
          if (!payloadres) {
            toast.error("Invalid user record");
            return;
          }

          if (payloadres.id === loginuser.id) {
            toast.error("Invalid request");
            return;
          }

          if (payloadres.uniquinum === loginuser.uniquinum) {
            toast.error("Invalid request");
            return;
          }

          if (payloadres.role === "admin") {
            toast.error("Invalid permission request");
            return;
          }

          if (payloadres.number === "") {
            toast.error("Invalid number request");
            return;
          }
          const data = {
            Bank: "Kitipay Wallet",
            AccountNamee:`${payloadres.firstname} ${payloadres.lastname}`,
            AccountNumber:payloadres.number,
            amount: parseFloat(amount),
            describe: transdiscription ? transdiscription : "nill",
            bankcode: "null",
            selectednetwork: selectednetwork,
            number: number,
            recipientID: payloadres.id,
            senderID: loginuser.id,
          };
          setpayload(data);
          handleShow();
        });
      setloading(false);
    }

    if (selectednetwork === "Bank Transfer") {
      setloading(true);

      const selectedbank = BankData.filter(
        (e) => e.name === loginuser.BusinessBankName
      );
      const data = {
        Bank: loginuser.BusinessBankName,
        AccountNamee: loginuser.BusinessAcccountName,
        AccountNumber: loginuser.BusinessAcccountNumber,
        amount: parseFloat(amount),
        describe: transdiscription ? transdiscription : "nill",
        bankcode: selectedbank[0].bankCode,
        selectednetwork: selectednetwork,
        number: loginuser.number,
        recipientID: "",
        senderID: loginuser.id,
      };
      setpayload(data);
      handleShow();
      setloading(false);
    }
  };
  const handlepayment = async (e) => {
    e.preventDefault();

    if (userwallet.wallet < payload.amount) {
      toast.error("Insuffient Fund");
      return;
    }

    if (limit <= 0){
      toast.error("Limit Error");
      return;
    }

    
    if (loginuser.Trial <= 0){
      toast.error("Pin Attempt exceeded");
      return;
    }
    const ref = database.collection("_").doc().id;
    let amountvalue = 0;
    let charges = 35;
    if (selectednetwork === "Wallet Transfer") {
      amountvalue = payload.amount;
    }
    if (selectednetwork === "Bank Transfer") {
      amountvalue = payload.amount + charges;
    }


    if (amountvalue <= 0) {
      toast.error("Invalid request");
      return;
    }

    if (payload.recipientID === payload.senderID ){
      toast.error("V-500 Error");
      return;
    }
    
    const UniquieNum = getRandomString(20, "1234567890");
    try {
      setloading(true);
      setresponsepayload("connecting");
      await axios
        .post(
          backendUrl+ `debitSuperAgentAccount/debit/user`,
          {
            id: loginuser.id,
            amount: amountvalue,
            trackNo: UniquieNum,
            version:version
          }
        ).then(async(res)=>{
          if (res.data.res === "success"){
            const Token = res.data.token
            setresponsepayload("Finilizing transaction");
            const data = {
              id: ref,
              company: selectednetwork,
              customername: payload.AccountNamee,
              address: "no value",
              Bank: payload.Bank,
              userID: loginuser.id,
              AccountNumber: payload.AccountNumber,
              walletbalance: userwallet.wallet,
              description: payload.describe,
              amount: parseFloat(payload.amount),
              phone: payload.number,
              bankCode: payload.bankcode,
              payloadres: payload,
              mezuflex_id:"",
              recipientID: payload.recipientID,
              senderID: payload.senderID,
              com: 0,
              type: "Transfer",
              status: "success",
              TranRef:UniquieNum,
              isToday: true,
              isClose: true,
              created: firebase.firestore.FieldValue.serverTimestamp(),
            };

              const dataw = {
              id: ref,
              company: selectednetwork,
              customername: payload.AccountNamee,
              address: "no value",
              Bank: payload.Bank,
              userID: loginuser.id,
              AccountNumber: payload.AccountNumber,
              walletbalance: userwallet.wallet,
              description: payload.describe,
              amount: parseFloat(payload.amount),
              phone: payload.number,
              bankCode: payload.bankcode,
              payloadres: payload,
              mezuflex_id:"",
              recipientID: payload.recipientID,
              senderID: payload.senderID,
              com: 0,
              type: "Transfer",
              status: "error",
              TranRef:UniquieNum,
              isToday: true,
              isClose: false,
              created: firebase.firestore.FieldValue.serverTimestamp(),
            };

            await axios
              .post(
                backendUrl+ `ProcessTransfer/proccess/transfer`,
                {
                  data,
                  Token
                }
              ).then(async(c)=>{
                const status = c.data.status
                const c_id = c.data.id
                if (c.data.type ==="Wallet Transfer"){
                  if (c.data.status === "success"){
                    // await database.collection("/Transaction").doc(data.id).set(data);
                    // await database.collection("/Transaction").doc(dataa.id).set(dataa);
                    setrepayload(data);
                    history.push(`/reciept/view/${c_id}`)
                    return
                  }else{
                    // await database.collection("/Transaction").doc(dataw.id).set(dataw);
                    setrepayload(dataw);
                    const nofifyID = getRandomString(6, "1234567890")
                    const notificationBody = {
                      message: `Transaction #${dataw.id} failed please act on it`,
                      created: firebase.firestore.FieldValue.serverTimestamp(),
                      isread: false,
                      id: nofifyID,
                      userID: "",
                      isRi: 0
                    }
                    await SendNotification(notificationBody, loginuser.id, "admin")
                    toast.error("Unexpected Error")
                    history.push(`/reciept/view/${dataw.id}`)
                  }   
                }
             
                if (c.data.type ==="Bank Transfer"){
                  dataw.mezuflex_id = c.data.ref
                  data.mezuflex_id = c.data.ref
                  if (c.data.status === "success"){
                    // await database.collection("/Transaction").doc(data.id).set(data);
                    setrepayload(data);
                    history.push(`/reciept/view/${c_id}`)
                    return
                  }else{
                    await database.collection("/Transaction").doc(dataw.id).set(dataw);
                    setrepayload(dataw);
                    const nofifyID = getRandomString(6, "1234567890")
                    const notificationBody = {
                      message: `Transaction #${dataw.id} failed please act on it`,
                      created: firebase.firestore.FieldValue.serverTimestamp(),
                      isread: false,
                      id: nofifyID,
                      userID: "",
                      isRi: 0
                    }
                    await SendNotification(notificationBody, loginuser.id, "admin")
                    toast.error("Unexpected Error")
                    history.push(`/reciept/view/${dataw.id}`)
                  }   
                } 

                if (status === "error"){
                toast.error(c.data.message)
               }
              })
          }  else {
            toast.error(res.data.message);
          }
        })   
    } catch (error) {
   toast.error(error.message);
    }
    setloading(false);
  };

  const handleprint = () => {
    history.push(`/transaction/${res.id}`);
  };

  return (
    <div>
      <ToastContainer />

      <Modal
        show={showprint}
        onHide={handleClosePrint}
        backdrop="static"
        keyboard={false}
      >
        <div className="tramsactiondetyaions">
          <div className="transactioncokatne">
            <div
              className="transajea"
              style={{
                backgroundColor: res.status === "success" ? "green" : "red",
              }}
            >
              <h1>{res.status === "success" ? "SUCCESS" : "ERROR"}</h1>
              <span>Transaction Reciept</span>
            </div>
            <div className="transactioncontent">
              <div className="transcotebnr">
                <div className="contbename">Service</div>
                <div className="conetnenvakeb sjshdjdhd">{selectednetwork}</div>
              </div>
              <div className="transcotebnr">
                <div className="contbename">Amount</div>
                <div className="conetnenvakeb">{res.amount}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">Recipient Number</div>
                <div className="conetnenvakeb">{res.AccountNumber}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">Recipient Name</div>
                <div className="conetnenvakeb">{res.customername}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">Recipient Bank</div>
                <div className="conetnenvakeb">{res.Bank}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">number</div>
                <div className="conetnenvakeb">{res.phone}</div>
              </div>
              <div className="transcotebnr">
                <div className="contbename">Status</div>
                <div className="conetnenvakeb">{res.status}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">Data</div>
                <div className="conetnenvakeb">{date}</div>
              </div>
            </div>
          </div>
        </div>
        <Modal.Footer>
        <Link to="/services">
          <Button variant="secondary" >
            Another Transaction
          </Button>
          </Link>
          <Button variant="primary" onClick={handleprint}>
            Print
          </Button>
          <Button variant="secondary" onClick={handleClosePrint}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="p-0">
          <div className="tramsactiondetyaions">
            <div className="transactioncokatne">
              <div className="transajea">
                <h1>In-Porgress</h1>
                <span>
                  Kindly confirm the information below before you proceed with
                  this payment.
                </span>
              </div>
              <div className="transactioncontent">
                <div className="transcotebnr">
                  <div className="contbename">Service</div>
                  <div className="conetnenvakeb sjshdjdhd">
                    {payload.selectednetwork}
                  </div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">Bank</div>
                  <div className="conetnenvakeb">{payload.Bank}</div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">Account Number</div>
                  <div className="conetnenvakeb">{payload.AccountNumber}</div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">Account Name</div>
                  <div className="conetnenvakeb">{payload.AccountNamee}</div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">Amount</div>
                  <div className="conetnenvakeb">{payload.amount}</div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">number</div>
                  <div className="conetnenvakeb">{payload.number}</div>
                </div>

                <div
                  className="cmshdhd"
                  style={{ display: payload.describe === "nill" ? "none" : "" }}
                >
                  <div className="transcotebnr">
                    <div className="contbename">Description</div>
                    <div className="conetnenvakeb">{payload.describe}</div>
                  </div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">Status</div>
                  <div className="conetnenvakeb">Processing</div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">Data</div>
                  <div className="conetnenvakeb">{date}</div>
                </div>
              </div>
            </div>
          </div>
          Please note that only verified user transaction will be processed
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" disabled={loading} onClick={handleClose}>
            Close
          </Button>
          <Button
            variant={loading ? "warning" : "primary"}
            disabled={loading}
            onClick={handlepayment}
            type="submit"
          >
            {loading ? (
              <>
                {" "}
                <Spinner animation="border" size="sm" /> {responspayload}
              </>
            ) : (
              "Pay with Wallet"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="transactionconatiner">
        <div className="transactioncontenrt">
          <div className="transactiohead my-5">
            <h2>
              <center className="text-dark">Transfer</center>
            </h2>
          </div>
          <div className="formtransactionsecton">
            <div className="formtrnacruondjdjd">
              <div className="productsed">
                <FloatingLabel
                  controlId="floatingSelect"
                  onChange={(event) => {
                    setselectednetwork(event.target.value);
                  }}
                >
                  <Form.Select aria-label="Floating label select example">
                    <option value="">Select Transfer Type</option>
                    <option value="Wallet Transfer">
                      Send to Kitipay Wallet
                    </option>
                    {/* <option value="Bank Transfer">Withdraw to Bank</option> */}
                  </Form.Select>
                  <Button
                    variant={loading ? "warning" : "danger"}
                    disabled={loading || limit <=0}
                    onClick={history.goBack}
                    className="w-100"
                    style={{display: selectednetwork === "" ? "block" : "none" }}
                  >
                    {loading ? (
                      <>
                        {" "}
                        <Spinner animation="border" size="sm" />{" "}
                        {responspayload}
                      </>
                    ) : (
                      "Back"
                    )}
                  </Button>
                </FloatingLabel>
                <div
                  className="sendtokididpau"
                  style={{
                    display:
                      selectednetwork === "Wallet Transfer" ? "block" : "none",
                  }}
                >
                  <div className="optionshhd">
                    <Form.Group controlId="formBasicNumber">
                      <Form.Label className="text-dark my-2">
                        Recipent Mobile Number
                      </Form.Label>
                      <Form.Control
                        placeholder={loginuser.number}
                        type="tel"
                        maxLength={11}
                        onChange={(event) => {
                          setphone(event.target.value);
                        }}
                      />
                    </Form.Group>

                    <Form.Group controlId="formBasicNumber">
                      <Form.Label className="text-dark my-2">Amount</Form.Label>
                      <Form.Control
                        placeholder="Enter Amount"
                        type="tel"
                        onChange={(event) => {
                          setamount(parseFloat(event.target.value));
                        }}
                      />
                    </Form.Group>

                    <Form.Group controlId="formBasicText">
                      <Form.Label className="text-dark my-2">
                        Discription
                      </Form.Label>
                      <Form.Control
                        placeholder="e.g sender name"
                        type="text"
                        onChange={(event) => {
                          setTransDiscription(event.target.value);
                        }}
                      />
                    </Form.Group>

                    <div className="disflexoteje">
                      <div className="pincontenr">
                      <Form.Group controlId="formBasicPassword">
                      <Form.Label className="text-dark my-2">
                        Transaction Pin
                      </Form.Label>
                      <Form.Control
                        placeholder="****"
                        type="password"
                        maxLength={4}
                        className="text-center"
                        onChange={(event) => {
                          setOtp(event.target.value);
                        }}
                      />
                    </Form.Group>
                        {/* {otp.map((data, index) => {
                          return (
                            <input
                              className="otp-field"
                              type="password"
                              name="otp"
                              maxLength={1}
                              key={index}
                              value={data}
                              onChange={(e) => handleChange(e.target, index)}
                              onFocus={(e) => e.target.select()}
                            />
                          );
                        })} */}
                      </div>
                    </div>
                    <Form.Text className="text-muted">
                   Transaction Limit: {TransactionLimit ? TransactionLimit : 0}
                  </Form.Text>
                    <Button
                      variant={loading ? "warning" : "primary"}
                      disabled={loading || limit <=0}
                      onClick={handleverifier}
                      className="my-2 w-100"
                      type="submit"
                    >
                      {loading ? (
                        <>
                          {" "}
                          <Spinner animation="border" size="sm" />{" "}
                          {responspayload}
                        </>
                      ) : (
                        "Send"
                      )}
                    </Button>
                    <Button
                    variant={loading ? "warning" : "danger"}
                    disabled={loading || limit <=0}
                    onClick={history.goBack}
                    className="w-100"
                  >
                    {loading ? (
                      <>
                        {" "}
                        <Spinner animation="border" size="sm" />{" "}
                        {responspayload}
                      </>
                    ) : (
                      "Back"
                    )}
                  </Button>
                  </div>
                </div>

                <div
                  className="sendtokididpau"
                  style={{
                    display:
                      selectednetwork === "Bank Transfer" ? "block" : "none",
                  }}
                >
                  <div className="optionshhd">
                    <Form.Group controlId="formBasicNumber">
                      <Form.Label className="text-dark my-2">
                        Recipent Account Number
                      </Form.Label>
                      <Form.Control
                        placeholder={loginuser.BusinessAcccountNumber}
                        type="tel"
                        value={loginuser.BusinessAcccountNumber}
                        maxLength={11}
                        disabled
                      />
                    </Form.Group>

                    <Form.Group controlId="formBasicNumber">
                      <Form.Label className="text-dark my-2">
                        Recipent Account Name
                      </Form.Label>
                      <Form.Control
                        placeholder={loginuser.BusinessAcccountName}
                        type="tel"
                        value={loginuser.BusinessAcccountName}
                        maxLength={11}
                        disabled
                      />
                    </Form.Group>

                    <Form.Group controlId="formBasicNumber">
                      <Form.Label className="text-dark my-2">
                        Recipent Bank
                      </Form.Label>
                      <Form.Control
                        placeholder={loginuser.BusinessBankName}
                        type="tel"
                        value={loginuser.BusinessBankName}
                        maxLength={11}
                        disabled
                      />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="text-dark my-2">Amount</Form.Label>
                      <Form.Control
                        placeholder="Enter Amount"
                        type="tel"
                        onChange={(event) => {
                          setamount(parseFloat(event.target.value));
                        }}
                      />
                    </Form.Group>

                    <Form.Group controlId="formBasicText">
                      <Form.Label className="text-dark my-2">
                        Discription
                      </Form.Label>
                      <Form.Control
                        placeholder="e.g sender name"
                        type="text"
                        onChange={(event) => {
                          setTransDiscription(event.target.value);
                        }}
                      />
                    </Form.Group>

                    <div className="disflexoteje">
                      <div className="pi ncontenr">
                      <Form.Group controlId="formBasicPassword">
                      <Form.Label className="text-dark my-2">
                        Transaction Pin
                      </Form.Label>
                      <Form.Control
                        placeholder="****"
                        className="text-center"
                        type="password"
                        maxLength={4}
                        onChange={(event) => {
                          setOtp(event.target.value);
                        }}
                      />
                    </Form.Group>
                        {/* <OtpInput
                          value={otp}
                        
                          onChange={setOtp}
                          numInputs={4}
                          renderSeparator={<span>-</span>}
                          renderInput={(props) => <input {...props} />}
                        /> */}
                        {/* {otp.map((data, index) => {
                          return (
                            <input
                              className="otp-field"
                              type="password"
                              name="otp"
                              maxLength={1}
                              key={index}
                              value={data}
                              onChange={(e) => handleChange(e.target, index)}
                              onFocus={(e) => e.target.select()}
                            />
                          );
                        })} */}
                      </div>
                    </div>

                    <Form.Text className="text-muted">
                   Transaction Limit: {TransactionLimit}
                  </Form.Text>

                    <Button
                      variant={loading ? "warning" : "primary"}
                      disabled={loading || limit <=0}
                      onClick={handleverifier}
                      className="my-2 w-100"
                      type="submit"
                    >
                      {loading ? (
                        <>
                          {" "}
                          <Spinner animation="border" size="sm" />{" "}
                          {responspayload}
                        </>
                      ) : (
                        "Proceed..."
                      )}
                    </Button>

                    <Button
                    variant={loading ? "warning" : "danger"}
                    disabled={loading || limit <=0}
                    onClick={history.goBack}
                    className="w-100"
                  >
                    {loading ? (
                      <>
                        {" "}
                        <Spinner animation="border" size="sm" />{" "}
                        {responspayload}
                      </>
                    ) : (
                      "Back"
                    )}
                  </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Airtimecomponent;
