import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import "./Navbar.css";
import { MdClose } from "react-icons/md";
import { BiMenu } from "react-icons/bi";
import { Button, Carousel } from "react-bootstrap";

function Navbar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      {/* <Topbar/> */}

      <div>
        <div
          className="bannercontainer">
          <div className="allwarper">
            <nav className="navbar1">
              <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                <img src="/img/logo.png" alt="" />
              </Link>
              <div className="menu-icon" onClick={handleClick}>
                {click ? <MdClose /> : <BiMenu />}
                {/* <i className={click ? "fas fa-times" : "fas fa-bars"} /> */}
              </div>
              <ul className={click ? "nav-menu active" : "nav-menu"}>
                <li className="nav-item">
                  <Link
                    to="/contact"
                    className="nav-links"
                    onClick={closeMobileMenu}
                  >
                    Contact Us
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/login"
                    className="nav-links"
                    onClick={closeMobileMenu}
                  >
                    Login
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/register"
                    className="nav-links"
                    onClick={closeMobileMenu}
                  >
                    <Button variant="outline-primary" className="cmahsjhshsjs">
                      Sign up
                    </Button>
                  </Link>
                </li>
              </ul>
            </nav>
            <div className="conadhdhd">
              <Carousel fade>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="/img/bs.jpg"
                    alt="sell gift card"
                    height="550"
                  />
                </Carousel.Item>

                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="/img/bs1.jpg"
                    alt="sell gift card"
                    height="550"
                  />
                </Carousel.Item>

                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="/img/bs2.jpg"
                    alt="sell gift card"
                    height="550"
                  />
                </Carousel.Item>
               
              </Carousel>
            </div>

            <div className="conadhdhdmobile">
              <Carousel fade>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="/img/bs.jpg"
                    alt="sell gift card"
                    height="300"
                  />
                </Carousel.Item>

                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="/img/bs1.jpg"
                    alt="sell gift card"
                    height="300"
                  />
                </Carousel.Item>

                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="/img/bs2.jpg"
                    alt="sell gift card"
                    height="300"
                  />
                </Carousel.Item>
               
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
