import React, { useEffect, useState } from "react";
import Sidebar from "../ClientComponent/Sidemenue";
import { Link, useHistory } from "react-router-dom";
import "../../Sidemiindex.css";
import { useAuth } from "../../../Services/UserAuth";
import { formatNumber } from "../../../Services/Utility";
import axios from "axios";
import UserTransaction from "../ClientComponent/UserTransaction";
import { Button, Form, Modal } from "react-bootstrap";
import { storage } from "../../../Services/firebase/firebase";
import { ToastContainer, toast } from "react-toastify";
import firebase from "firebase";
import Bank from "../../../Bank.json";
import { getuserwallet } from "../../../Services/GetUser.service";
import LandingPageTransaction from "../ClientComponent/LandingPageTransaction";
import { FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon, TwitterShareButton, TwitterIcon } from "react-share";
import {
  EmailShareButton,
  LinkedinShareButton,
  TelegramShareButton,
} from "react-share";

import {
  EmailIcon,
  LinkedinIcon,
  TelegramIcon,

} from "react-share";
import { backendUrl } from "../../../Services/firebase/firebase";

function Dashboard() {
  const history = useHistory();
  const { loginuser } = useAuth();
  const [res, setres] = useState(false);
  const [resmessage, setresmessage] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [BVN, setBvn] = useState("");
  var user = firebase.auth().currentUser;
  const [residentialAddress, setresdentialAddress] = useState("");
  const [userpassport, setuserpassport] = useState(null);
  const [usermeansID, setuseridMeans] = useState(null);
  const [setaddress, setuserAddress] = useState(null);
  const [message, setmessage] = useState("");
  const [progressPassport, setProgresspassport] = useState();
  const [progressmeans, setProgressMeasnID] = useState();
  const [progressAddress, setProgressAddress] = useState();
  const [loading, setloading] = useState(false);
  const [oldpassword, setoldpassword] = useState("");
  const [password, setpassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");
  const [accountnumber, setaccouuntnumber] = useState("");
  const [accountholder, setHolderinfo] = useState("");
  const [showotp, setShowotp] = useState(false);
  const handleCloseotp = () => setShowotp(false);
  const handleShowotp = () => setShowotp(true);
  const [session, setsessionID] = useState("");
  const [otp, setotp] = useState("");
  const [userwallet, setuserWallet] = useState([]);
  const [wallet, setlwaalt] = useState([])

  const bank = Bank.data;
  const BankUrl =
    backendUrl + "getBankHolder2";
  const [bankcode, setselectedBank] = useState("");
  const id = user.uid;
  useEffect(() => {
    getuserwallet(id, (result) => {
      setlwaalt(result)
      if (result.ProvidusInfo) {
        setuserWallet(result.ProvidusInfo);
      } else {
        setuserWallet([]);
      }
    });
  }, []);

  const handleChane = (e) => {
    e.preventDefault();

    if (password !== confirmpassword) {
      toast.error("Password Mismatch", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setloading(true);
    setres("Authorization in progress");
    const cred = firebase.auth.EmailAuthProvider.credential(
      loginuser.email,
      oldpassword
    );
    user
      .reauthenticateWithCredential(cred)
      .then((e) => {
        setres("Validation in progress");

        return user.updatePassword(password);
      })
      .then(async () => {
        setres("Finalizing Request");
        await axios
          .put(
            backendUrl + `getuserverification/userVerification/${loginuser.id}`
          )
          .then(() => {
            toast.success("Password updated Successfully", {
              position: toast.POSITION.TOP_RIGHT,
            });

          });
      })
      .catch((error) => {
        toast.success(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  if (loginuser.ispasswordreset === false) {
    return (
      <>
        <ToastContainer />
        <div className="reactpashall">
          <div className="allvalierestpass">
            <div className="headerresteass ">Password Reset</div>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Old Password</Form.Label>
              <Form.Control
                type="text"
                onChange={(event) => {
                  setoldpassword(event.target.value);
                }}
              />
              <Form.Text className="text-warning">
                Enter Same password sent to your email.
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="text"
                onChange={(event) => {
                  setpassword(event.target.value);
                }}
              />
              <Form.Text className="text-warning">
                Please don't share your password with anyone..
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control
                type="email"
                onChange={(event) => {
                  setconfirmpassword(event.target.value);
                }}
              />
            </Form.Group>

            <Button
              variant={loading ? "warning" : "primary"}
              disabled={loading}
              onClick={handleChane}
              className="w-100"
              type="submit"
            >
              {loading ? "processing" : "Reset Password"}
            </Button>
          </div>
        </div>
      </>
    );
  }

  const selectba = bank.find((e) => e.bankCode === bankcode);
  const Referalcode = `/user-registration/gifted/${loginuser.ReferralCode}`;
  let myDate = new Date();
  let hours = myDate.getHours();
  let greet;

  if (hours < 12) greet = "morning";
  else if (hours >= 12 && hours <= 17) greet = "afternoon";
  else if (hours >= 17 && hours <= 24) greet = "evening";

  const handlecreateaccount = async (e) => {
    e.preventDefault();
    setres(true);
    setresmessage("Securing Connection");
    await axios.post(
      backendUrl + "CreateProvidusAccount",
      {
        account_name: `${loginuser.firstname} ${loginuser.lastname}`,
        id: loginuser.id
      }
    )
    // await axios
    //   .get(
    //     `https://us-central1-kitihub-app.cloudfunctions.net/CreateAccount/${loginuser.email}`
    //   )
    //   .then(async (e) => {
    //     setresmessage(
    //       "Connection Successfull.... Proccesing account information"
    //     );

    //     await axios
    //       .post(
    //         "https://us-central1-kitihub-app.cloudfunctions.net/CreateDedicatedAccount",
    //         {
    //           email: loginuser.email,
    //           first_name: loginuser.firstname,
    //           last_name: loginuser.lastname,
    //           phone: loginuser.number,
    //         }
    //       )
    //       .then((e) => {
    //         setresmessage("Creating Dedicated Account... ");
    //       });
    //   });
    setres(false);
    setresmessage("");
  };

  const getAccountNumber = async (e) => {
    const acc = e.target.value
    setaccouuntnumber(acc);
    setHolderinfo("Please enter Account Number");
    if (acc.length === 10) {
      setHolderinfo("Please Wait...");
      await axios
        .post(BankUrl, {
          bank_code: bankcode,
          account_number: acc,
        })
        .then((res) => {
          if (res.data.data.message === "Request failed with status code 400") {
            setHolderinfo("No Information Found.");
            return;
          }
          const resPaload = res.data.data.data;
          const info = resPaload.message.details
          setHolderinfo(info.account_name);
        });
    }
  };

  const handleverifyuser = () => {
    handleShow();
  };

  const handlechangepassport = (e) => {
    if (e.target.files[0]) {
      setuserpassport(e.target.files[0]);
    }
  };

  const handleID = (e) => {
    if (e.target.files[0]) {
      setuseridMeans(e.target.files[0]);
    }
  };



  function handleUploadpassort() {
    return new Promise((resolve) => {
      const uploadTask = storage
        .ref(`file/${userpassport.name}`)
        .put(userpassport);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgresspassport(progress);
        },
        (error) => {
          setmessage(error);
        },
        () => {
          storage
            .ref("file")
            .child(userpassport.name)
            .getDownloadURL()
            .then((url) => {
              resolve(url);
            });
        }
      );
    });
  }

  function handleUploadMeans() {
    return new Promise((resolve) => {
      const uploadTask = storage
        .ref(`file/${usermeansID.name}`)
        .put(usermeansID);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgressMeasnID(progress);
        },
        (error) => {
          setmessage(error);
        },
        () => {
          storage
            .ref("file")
            .child(usermeansID.name)
            .getDownloadURL()
            .then((url) => {
              resolve(url);
            });
        }
      );
    });
  }



  const getbvn = async (e) => {
    e.preventDefault();
    setres("BVN is required for verification Purpose");
     
    await handlecurrentuser();
    // if (BVN.length === 11) {
    //   setloading(true);
    //   // const url = backendUrl+ `sendUserBVNDATA/user/bvn/data/${BVN}`;
    //   // axios.get(url).then(async (e) => {
    //   //   const user = e.data[0];
    //   //   if (user) {
    //   //     toast.error("Record use by another user", {
    //   //       position: toast.POSITION.TOP_RIGHT,
    //   //     });
    //   //     setloading(false);
    //   //     return;
    //   //   }
    //   //   const url = backendUrl+ `sendUserBVNDATA/user/bvn/data/`;
    //   //   setmessage("connecting ...");
    //   //   await axios
    //   //     .post(url, {
    //   //       bvn: BVN,
    //   //     })
    //   //     .then(async (e) => {
    //   //       console.log(e.data.data);
    //   //       const sesss = e.data.data.session_id;

    //   //       // if (!sesss) {
    //   //       //   setmessage("Error initiating request");
    //   //       //   setloading(false);
    //   //       //   return;
    //   //       // }
    //   //       setsessionID(sesss);
    //   //       setmessage("Please Enter OTP set to your Mobile number");
    //   //       handleShowotp();
    //   //       setloading(false);
    //   //     });
    //   // });
      
    // } else {
    //   setloading(false);
    //   setres("runtime error ");
    // }
  
  };

  const handleGetdata = async (e) => {
    e.preventDefault();

    if (otp.length !== 6) {
      setmessage("invalid Otp");
      return;
    }

    if (otp === "" || session === "") {
      setmessage("invalid request");
      return;
    }
    setmessage("Please wait...");
    const token = session;

    await handlecurrentuser();
    handleCloseotp();
    handleClose();
    // try {
    //   setloading(true);
    //   await axios
    //     .post(
    //       backendUrl+ "FetchbvnInformation/user/bvn/data",
    //       {
    //         otp,
    //       },
    //       {
    //         headers: {
    //           session_id: token,
    //         },
    //       }
    //     )
    //     .then(async (result) => {
    //       console.log(result.data);
    //       const dp = result.data.payload;
    //       if (result.data.status === "success") {
    //         setmessage("Finalizing request please wait");
    //         await handlecurrentuser();
    //         handleCloseotp()
    //         handleClose()
    //         return
    //       }
    //       setmessage(result.data.message);
    //     })
    //     .catch((err) => {
    //       toast.error(err.message);
    //     });
    // } catch (error) {}
    setloading(false);
  };

  const handlecurrentuser = async () => {
    if (
      accountnumber === "" ||
      residentialAddress === "" ||
      !userpassport ||
      !usermeansID 
      // !setaddress
    ) {
      setmessage("Missing Required Information");
      return;
    }
    setloading(true);
    const image = await handleUploadpassort();
    const meansID = await handleUploadMeans();
    const data = {
      bvn: "nill",
      userAddress: residentialAddress,
      image: image,
      usermeansID: meansID,
      userProfAddress: "",
      BusinessAcccountName: accountholder,
      BusinessAcccountNumber: accountnumber,
      BusinessBankName: selectba.name,
      id: loginuser.id,
    };
    try {
      await axios
        .post(
          backendUrl + "getuserverification/userVerification",
          {
            data,
          }
        )
        .then((res) => {
        
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });

        });
    } catch (error) {
      setmessage(error.message);
    }

    setloading(false);
  };

  const handlenewprodfi = ()=>{
    history.push(`/marchant/profile/${loginuser.id}`)
  }
  const marchnageRoute = `/vendor/home/${loginuser.id}`

  const is_AccountReady = userwallet.requestSuccessful ? userwallet.requestSuccessful : []
  return (
    <div>
      <ToastContainer />
      <Modal
        show={showotp}
        onHide={handleCloseotp}
        backdrop="static"
        keyboard={false}
        centered
        className="bg-dark"
      >
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formPassword">
            <Form.Label>Enter OTP</Form.Label>
            <Form.Control
              type="password"
              onChange={(event) => setotp(event.target.value)}
              maxLength={6}
            />
          </Form.Group>

          <Form.Text className="text-success">
            {message
              ? message
              : "Please dont share any information with anyone."}
          </Form.Text>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant={loading ? "warning" : "primary"}
            onClick={handleGetdata}
            disabled={loading}
          >
            {loading ? "processing ..." : "Validate"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="bg-light">
          <div className="fomtmaocjhehe">
            <h1>Account Verification Form</h1>
            {/* <Form.Group className="mb-3" controlId="formBasicNumber">
              <Form.Label>BVN No.</Form.Label>
              <Form.Control
                type="text"
                onChange={(event) => {
                  setBvn(event.target.value);
                }}
              />
              <Form.Text className="text-muted">
                We'll never share your information with anyone else.
              </Form.Text>
            </Form.Group> */}

            <Form.Group className="mb-3">
              <Form.Label>Residential Address</Form.Label>
              <Form.Control
                type="Text"
                onChange={(event) => {
                  setresdentialAddress(event.target.value);
                }}
              />
            </Form.Group>

            <div className="aformconteinr11111">
              <Form.Label>Bank</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={(event) => {
                  setselectedBank(event.target.value);
                }}
              >
                <option value="">Open to select Bank</option>
                {bank.map((e) => {
                  return <option value={e.bankCode}>{e.name}</option>;
                })}
              </Form.Select>
              <Form.Text className="text-muted">
                Information will be use for deposit service only.
              </Form.Text>
              <Form.Group className="mb-3">
                <Form.Label>Account Number</Form.Label>
                <Form.Control type="text" onChange={getAccountNumber} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Account Name</Form.Label>
                <Form.Control type="text" disabled value={accountholder} />
              </Form.Group>
            </div>

            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Upload Passport</Form.Label>
              <Form.Control type="file" onChange={handlechangepassport} />
              {progressPassport ? (
                <span className="text-success">Uploaded</span>
              ) : (
                ""
              )}
            </Form.Group>

            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Upload Means of Identification</Form.Label>
              <Form.Control type="file" onChange={handleID} />
              {progressmeans ? (
                <span className="text-success">Uploaded</span>
              ) : (
                ""
              )}
            </Form.Group>
{/* 
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Upload Proof of Address</Form.Label>
              <Form.Control type="file" onChange={handleuserAddress} />
              {progressAddress ? (
                <span className="text-success">Uploaded</span>
              ) : (
                ""
              )}
              <br />
              <Form.Text className="text-muted">
                (Utility Bill, Water Bills Etc)
              </Form.Text>
            </Form.Group> */}
          </div>
          {message}
          <Button
            variant={loading ? "warning" : "success"}
            className="w-100"
            onClick={getbvn}
          >
            {loading ? "Sending Request" : "Proceed .. "}
          </Button>

          <Button variant="danger" className="w-100 my-2" onClick={handleClose}>
            Back
          </Button>
        </Modal.Body>
      </Modal>
      <div className="pagecon">
        <div className="pagecontent">
          <div className="headersisecoadmin">
            <Sidebar />
          </div>
          <div className="otherssecoth">
            <div className="dashboardconteier">
              <div className="topmenuecontrenr">
                <div className="dhealsjsjsallheader">
                  <h1>Dashboard</h1>
                  {loginuser.userverified !== true ? (
                    <>
                      {loginuser.userRequstVerificaton === true ? (
                        <span className=" text-success ">
                          Awaiting Approval
                        </span>
                      ) : (
                        <span
                          className=" text-info "
                          onClick={handleverifyuser}
                        >
                          Verifiy Account
                        </span>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="tiomendcontee">
                  <div className="caatopmend11">
                    <h1>
                      {greet} {loginuser.firstname} {loginuser.lastname}
                      &#128513;
                    </h1>
                    <span>
                      Welcome to your dashboard. See a quick navigation to our
                      services below.
                    </span>
                  </div>
                  <div className="caatopmend">
                    <div className="topsidemenueheader">FUND ACCOUNT</div>
                    <div className="topvalueside">
                      {is_AccountReady === true ? (
                        <>
                          <h1>{userwallet.account_number}</h1>
                          <p className="m-0">PROVIDUS BANK</p>
                          <span className="classforaccountname">
                            {userwallet.account_name}
                          </span>
                        </>
                      ) : (
                        <>
                          <button onClick={handlecreateaccount}>
                            {res ? "Loading..." : "Create Account"}

                          </button> <br />
                          <span className="fs-6 text-warning">Click on Create account to generate Account number, continue this process till the account is generated</span>
                        </>
                      )}
                    </div>
                    <span className="text-success">
                      {resmessage ? resmessage : ""}
                    </span>
                  </div>

                  <div className="caatopmend">
                    <div className="topsidemenueheader">WALLET (&#8358;)</div>
                    <div className="topvalueside">
                      {is_AccountReady === true ? (
                        <>
                          <p className="m-0">
                            {" "}
                            {formatNumber(wallet.wallet)}
                          </p>
                          <div className="newbannsksks">
                            <span>
                              Pending Balance :
                              {formatNumber(wallet.PendingBalance)}
                            </span>
                            <br />
                            <span>
                              Earning :{formatNumber(wallet.coin)}
                            </span>
                          </div>
                        </>
                      ) : (
                        0
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <QickService /> */}
            <div className="bellowballnsnd">
              <div className="nellowcondhf">
                <div className="quiclactionsection">
                  <div className="quicksections">
                    <div className="quicksectionconent">
                      <div className="qucisechhekeadd">
                        <h1>Quick Actions</h1>
                        <div className="quicksectionurl">
                          <ul>
                            <Link to={marchnageRoute}>Marchant Services </Link>
                            {/* <Link to="/service/Gift-Card">Gift Card</Link> */}
                            <Link to="/transaction/airtime">Buy Airtime</Link>
                            <Link to="/transaction/data">Buy Data Bundle</Link>
                            <Link to="/transaction/cable-tv-subcription">
                              Cable Tv Subscription
                            </Link>
                            <Link to="/transaction/vend/electricity">
                              Pay Electricity Bill
                            </Link>
                            <Link to="/transaction/games/betting">
                              Fund Betting Wallet
                            </Link>
                            <Link to="/transaction/exam/result-checker">
                              Result Checker
                            </Link>
                            <Link to="/transaction/wallet/transfer">
                              Transfer Money
                            </Link>
                          </ul>
                        </div>
                      </div>

                      <div className="accountmansgecondd">
                        <div className="manegscondgvfhd" style={{ display: loginuser.isverified === true ? "block" : "none" }}>

                          <h1>Marchant Home</h1>
                          <p>
                            To to your profile to upload your product and services. update your customers on promotions and sales. manage sales and so much more.
                          </p>
                          
                            <button onClick={handlenewprodfi}>Go to Profile</button>
                         
                        </div>
                        <div className="manegscondgvfhd" style={{ display: loginuser.isverified === true ? "none" : "block" }}>

                          <h1>Become A Merchant</h1>
                          <p>
                            Become one of our marchant today and use your
                            kitipay wallet account to accept payment. let us be
                            your intermediary to your transactions in order to
                            boost confidence to your customers.
                          </p>
                          <Link to="/dashboard/Compliance/general">
                            <button>Become a Marchant Today</button>
                          </Link>
                        </div>
                        {/* <div className="manegscondgvfhd">

                          <h1>Become A Merchant</h1>
                          <p>
                            Become one of our marchant today and use your
                            kitipay wallet account to accept payment. let us be
                            your intermediary to your transactions in order to
                            boost confidence to your customers.
                          </p>
                          <Link to="/dashboard/Compliance/general">
                            <button>Become a Marchant Today</button>
                          </Link>
                        </div> */}
                      </div>

                      <div className="accountmansgecondd">
                        <div className="manegscondgvfhd">
                          <h1>Earn With Referral</h1>
                          <p>
                            Share your
                            Referral Link with your friends and family and begin
                            earning commissions.
                          </p>
                          <FacebookShareButton
                            url={`https://kitipay.com${Referalcode}`}
                            quote={`Hello friends, i want you to register on kitipay.com. Kitipay is a Marchant Payment Solution System also kitipay is a bill payment platform for Airtime, Data, TV subscription, sell Gift-card, Electricity Bills and more. I will earn commissions if join through my referral link. Please click https://kitipay.com${Referalcode} to join.Thank you`}
                            hashtag="#payment"
                          >
                            <FacebookIcon size={32} round />
                          </FacebookShareButton>

                          <TwitterShareButton
                            url={`https://kitipay.com${Referalcode}`}
                            quote={`Hello friends, i want you to register on kitipay.com. Kitipay is a Marchant Payment Solution System also kitipay is a bill payment platform for Airtime, Data, TV subscription, sell Gift-card, Electricity Bills and more. I will earn commissions if join through my referral link. Please click https://kitipay.com${Referalcode} to join.Thank you`}
                            hashtag="#payment"
                          >

                            <TwitterIcon size={32} round />
                          </TwitterShareButton>

                          <LinkedinShareButton
                            url={`https://kitipay.com${Referalcode}`}
                            summary={`Hello friends, i want you to register on kitipay.com. Kitipay is a Marchant Payment Solution System also kitipay is a bill payment platform for Airtime, Data, TV subscription, sell Gift-card, Electricity Bills and more. I will earn commissions if join through my referral link. Please click https://kitipay.com${Referalcode} to join.Thank you`}
                            hashtag="#payment"
                          >

                            <LinkedinIcon size={32} round />
                          </LinkedinShareButton>

                          <EmailShareButton
                            url={`https://kitipay.com${Referalcode}`}
                            quote={`Hello friends, i want you to register on kitipay.com. Kitipay is a Marchant Payment Solution System also kitipay is a bill payment platform for Airtime, Data, TV subscription, sell Gift-card, Electricity Bills and more. I will earn commissions if join through my referral link. Please click https://kitipay.com${Referalcode} to join.Thank you`}
                            hashtag="#payment"
                          >

                            <EmailIcon size={32} round />
                          </EmailShareButton>

                          <TelegramShareButton
                            url={`https://kitipay.com${Referalcode}`}
                            quote={`Hello friends, i want you to register on kitipay.com. Kitipay is a Marchant Payment Solution System also kitipay is a bill payment platform for Airtime, Data, TV subscription, sell Gift-card, Electricity Bills and more. I will earn commissions if join through my referral link. Please click https://kitipay.com${Referalcode} to join.Thank you`}
                            hashtag="#payment"
                          >

                            <TelegramIcon size={32} round />
                          </TelegramShareButton>

                          <WhatsappShareButton
                            url={`https://kitipay.com${Referalcode}`}
                            quote={`Hello friends, i want you to register on kitipay.com. Kitipay is a Marchant Payment Solution System also kitipay is a bill payment platform for Airtime, Data, TV subscription, sell Gift-card, Electricity Bills and more. I will earn commissions if join through my referral link. Please click https://kitipay.com${Referalcode} to join.Thank you`}
                            hashtag="#payment"
                          >

                            <WhatsappIcon size={32} round />
                          </WhatsappShareButton>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="transactionsectiond">
                  <LandingPageTransaction />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
