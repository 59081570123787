import React, { useEffect, useState } from "react";
import { useAuth } from "../../Services/UserAuth";
import { database } from "../../Services/firebase/firebase";
import { useHistory} from "react-router-dom";
import firebase from "firebase";
import { Link } from "react-router-dom";
import Loading from "../../Loading";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { backendUrl } from "../../Services/firebase/firebase";

function WelcomePage() {
  const { currentUser } = useAuth();
  const [user, setUser] = useState(null);
  const [message, setmessage] = useState("");
  const { uid, emailVerified } = useAuth().currentUser;
  const [loading, setloading]= useState(false)
  const history =  useHistory()
  const getUser = async () => {

    try {
      const documentSnapshot = await database.collection("users").doc(uid).get();
      const userData = documentSnapshot.data();

      if (userData.permissions=== "admin" && userData.isAllowed === true &&  userData.isBlock === false) {
        history.push(`/admin/dashboard`);
        return;
      }
     
      if (userData.permissions=== "Sale" &&  userData.isAllowed === true &&  userData.isBlock === false) {
        history.push(`/auth/staff/${uid}`);
        return;
      }

      setUser(userData);
    } catch (e) {
      setmessage(e.message);
    }

   
  };
  useEffect(() => {
    getUser();

  }, []);


  
  const resetLink = async (e) => {
    e.preventDefault();
    setloading(true);
    if (user.isAllowed === true) {
      toast.warning("User already verified please procced to login.. Thanks");
      return;
    }

    if (user.isSendEmail === true) {
      toast.warning(
        "Email already sent, please check inbox for the verification link or check  span folder if email not found in inbox"
      );
  
      setloading(true);
      return;
    }

    try {
      const CampURL =backendUrl+ "sendEMAILkitipay"
      const url = `https://kitipay.com/users/${user.id}`;
      const homeUrl = "https://kitipay.com";
      const fullname = `${user.firstname} ${user.lastname}`;
      const logo = "https://i.ibb.co/5xqth9r/logo.png";
      const content = `<div marginheight="0" topmargin="0" marginwidth="0" style="margin: 0px; background-color: #f2f3f8;" leftmargin="0">
      <table cellspacing="0" border="0" cellpadding="0" width="100%" background-color="#f2f3f8"
          style="font-family: 'Open Sans', sans-serif;">
          <tr>
              <td>
                  <table style="background-color: #f2f3f8; max-width:670px;  margin:0 auto;" width="100%" border="0"
                      align="center" cellpadding="0" cellspacing="0">
                      <tr>
                          <td style="height:80px;">&nbsp;</td>
                      </tr>
                      <tr>
                          <td style="text-align:center;">
                            <a href=${homeUrl} title="logo" target="_blank">
                              <img width="130" src="https://i.ibb.co/5xqth9r/logo.png" title="logo" alt="logo">
                            </a>
                          </td>
                      </tr>
                      <tr>
                          <td style="height:20px;">&nbsp;</td>
                      </tr>
                      <tr>
                          <td>
                              <table width="95%" border="0" align="center" cellpadding="0" cellspacing="0"
                                  style="max-width:670px;background:#fff; border-radius:3px; text-align:center;-webkit-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);-moz-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);box-shadow:0 6px 18px 0 rgba(0,0,0,.06);">
                                  <tr>
                                      <td style="height:40px;">&nbsp;</td>
                                  </tr>
                                  <tr>
                                      <td style="padding:0 35px;">
                                          <h1 style="color:#1e1e2d; font-weight:500; margin:0;font-size:32px;font-family:'Rubik',sans-serif;">Email Verification</h1>
                                          <span
                                              style="display:inline-block; vertical-align:middle; margin:29px 0 26px; border-bottom:1px solid #cecece; width:100px;"></span>
                                          <p style="color:#455056; font-size:15px;line-height:24px; margin:0;">
                                          Welcome to the family ${fullname}!
                                          We just need to verify your email address before you can access your account. click on the button below to
                                          Verify your email address.
                                          </p>
                                          <a href=${url}
                                              style="background:#20e277;text-decoration:none !important; font-weight:500; margin-top:35px; color:#fff;text-transform:uppercase; font-size:14px;padding:10px 24px;display:inline-block;border-radius:50px;">Verify Email</a>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td style="height:40px;">&nbsp;</td>
                                  </tr>
                              </table>
                          </td>
                      <tr>
                          <td style="height:20px;">&nbsp;</td>
                      </tr>
                      <tr>
                          <td style="text-align:center;">
                              <p style="font-size:14px; color:rgba(69, 80, 86, 0.7411764705882353); line-height:18px; margin:0 0 0;">&copy; <strong>${homeUrl}</strong></p>
                          </td>
                      </tr>
                      <tr>
                          <td style="height:80px;">&nbsp;</td>
                      </tr>
                  </table>
              </td>
          </tr>
      </table>
     

</div>`;
      await axios
        .post(CampURL, {
          content,
          logo,
          fullname,
          email: user.email,
          firstname: user.firstname,
          url,
          userid: user.id
        })
        .then((e) => {
          toast.success("Email sent ");
        })
        .catch((e) => {});
    } catch (error) {
      toast.error(error.mesage);
    }
  };
  return !user ? (
    <div><Loading/></div>
  ) : (
    <div>

      <div className="welcome1">
        <div className="welcomeman">
          <div className="indv">
            <img src="/img/logo.png" alt="" />
          </div>
          Welcome <strong></strong> {user.firstname} {user.lastname}
          <div className="welcomegrand">
            <div className="welcomeparent">
              <div className="welcomechil">
                Your Account need To be Verified; <br />
                Please click on the link sent to your Email to verify your
                account with kitipay
              </div>{" "}
              <br />
              <p>If you didnt perform this operation please Contact us </p>{" "}
              <span>
                <Link to="/contact">Support</Link>
              </span>{" "}
            </div>
          </div>
          <div className="restbuton">
            <button onClick={resetLink} disabled={loading}> {loading ? "sending message.." : "Send verification Link"}</button> <br />
            {message}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WelcomePage;
