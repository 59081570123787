import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../Services/UserAuth";
import { formatNumber, timeStamptoDate } from "../../../Services/Utility";
import "./Complaince.css";
import { FacebookShareButton, FacebookIcon, WhatsappShareButton,WhatsappIcon,  TwitterShareButton, TwitterIcon } from "react-share";
import {
  EmailShareButton,
  LinkedinShareButton,
  TelegramShareButton,
} from "react-share";

import {
  EmailIcon,
  LinkedinIcon,
  TelegramIcon,

} from "react-share";

function Generalcomplaince() {
  const { loginuser, userwallet } = useAuth();

  const date = timeStamptoDate(loginuser.created);

  const Referalcode = `/user-registration/gifted/${loginuser.ReferralCode}`;
  const Providious =  userwallet.ProvidusInfo ? userwallet.ProvidusInfo : []
  return (
    <div>
      <div className="headersentskdjd">
        <div className="headeconatiner">
          <Link
            to="/dashboard/Compliance/general"
            className="theactiveone text-success"
          >
            General
          </Link>
          <Link to="/dashboard/Compliance">Compliance</Link>
          <Link to="/dashboard/Compliance/settlement">Settlement</Link>
        </div>
        <center>
          <hr className="w-50 " />
        </center>
      </div>

      <div className="profileuser">
        <div className="useridodnsidd">
          <div className="userimage">
            <img
              src={loginuser.image ? loginuser.image : "/img/i.png"}
              alt=""
            />
          </div>
          <div className="userinformation">
            <h1>Owner</h1>
            <h3>
              {loginuser.firstname} {loginuser.lastname}
            </h3>
          </div>
          <hr />
          <span>Email</span>
          <p>{loginuser.email}</p>
          <span>User Role</span>
          <p>{loginuser.role}</p>
          <span>Mobile Number</span>
          <p>{loginuser.number}</p>
          <span>Account Status</span>
          <p>{loginuser.isBlock === false || loginuser.status === "active" ? "Active" : "Suspended"}</p>
          <span>Account Level</span>
          <p>Tier {loginuser.tier}</p>
          <span>Transaction Limit</span>
          <p>{formatNumber(loginuser.transactionLimit)}</p>
          <span>Referal Link</span> <br/>
          <FacebookShareButton
            url={`https://kitipay.com${Referalcode}`}
            quote={`Hello friends, i want you to register on kitipay.com. Kitipay is a Marchant Payment Solution System also kitipay is a bill payment platform for Airtime, Data, TV subscription, sell Gift-card, Electricity Bills and more. I will earn commissions if join through my referral link. Please click https://kitipay.com${Referalcode} to join.Thank you`}
            hashtag="#payment"
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          <TwitterShareButton
             url={`https://kitipay.com${Referalcode}`}
             quote={`Hello friends, i want you to register on kitipay.com. Kitipay is a Marchant Payment Solution System also kitipay is a bill payment platform for Airtime, Data, TV subscription, sell Gift-card, Electricity Bills and more. I will earn commissions if join through my referral link. Please click https://kitipay.com${Referalcode} to join.Thank you`}
             hashtag="#payment"
          >
              
            <TwitterIcon size={32} round />
          </TwitterShareButton>

          <LinkedinShareButton
             url={`https://kitipay.com${Referalcode}`}
             summary ={`Hello friends, i want you to register on kitipay.com. Kitipay is a Marchant Payment Solution System also kitipay is a bill payment platform for Airtime, Data, TV subscription, sell Gift-card, Electricity Bills and more. I will earn commissions if join through my referral link. Please click https://kitipay.com${Referalcode} to join.Thank you`}
             hashtag="#payment"
          >
              
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>

          <EmailShareButton
             url={`https://kitipay.com${Referalcode}`}
             quote={`Hello friends, i want you to register on kitipay.com. Kitipay is a Marchant Payment Solution System also kitipay is a bill payment platform for Airtime, Data, TV subscription, sell Gift-card, Electricity Bills and more. I will earn commissions if join through my referral link. Please click https://kitipay.com${Referalcode} to join.Thank you`}
             hashtag="#payment"
          >
              
            <EmailIcon size={32} round />
          </EmailShareButton>

          <TelegramShareButton
             url={`https://kitipay.com${Referalcode}`}
             quote={`Hello friends, i want you to register on kitipay.com. Kitipay is a Marchant Payment Solution System also kitipay is a bill payment platform for Airtime, Data, TV subscription, sell Gift-card, Electricity Bills and more. I will earn commissions if join through my referral link. Please click https://kitipay.com${Referalcode} to join.Thank you`}
             hashtag="#payment"
          >
              
            <TelegramIcon size={32} round />
          </TelegramShareButton>

          <WhatsappShareButton
             url={`https://kitipay.com${Referalcode}`}
             quote={`Hello friends, i want you to register on kitipay.com. Kitipay is a Marchant Payment Solution System also kitipay is a bill payment platform for Airtime, Data, TV subscription, sell Gift-card, Electricity Bills and more. I will earn commissions if join through my referral link. Please click https://kitipay.com${Referalcode} to join.Thank you`}
             hashtag="#payment"
          >
              
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
          <p>
            
          </p>
        </div>

        <div className="useridodnsidd">
          <div className="generalcontenr">
            <div className="consyenatodhao">
              <h1>General Business Settings</h1>
              <p>Currencies</p>
              <span>
                These are all the currencies with which you are allowed to
                transact on Kitipay. Your currencies are determined by the
                compliance check done on your account. Please, contact your
                account manager to find out more.
              </span>

              <div className="buttonselfyc">
                <button disabled>Naira (NGN)</button>
              </div>

              <hr />

              <p>Products</p>
              <span>
                These are the products on Kitipay to which you have access. Your
                product offerings are determined by the compliance check that
                was done on your account. Please, contact your account manager
                to learn more.
              </span>
              <div className="buttonselfyc">
                <button disabled>Sell Gift Card</button>
                {/* <button disabled>Buy Gift Card</button> */}
                <button disabled>Buy Airtime/Data</button>
                <button disabled>Bill Payment</button>
                <button disabled>Vend Electricity</button>

                <span>
                  For More Benefit from our services{" "}
                  <Link to="/dashboard/Compliance">Complete Complaince</Link>
                </span>
              </div>
              <hr />

              <p>Reserved Bank Account</p>
              <span>
                Use your Reserved Bank Account (RBA) to fund your balance at any
                time.
              </span>
              <div className="buttonselfyc">
                <p>
                  {" "}
                  <strong>Bank</strong>: Providus
                </p>
                <p>
                  {" "}
                  <strong>Account Number</strong>: {Providious.account_number ? Providious.account_number : "" }
                </p>
                <p>
                  {" "}
                  <strong>Account Name</strong>: {Providious.account_name ? Providious.account_name : ""}
                </p>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Generalcomplaince;
