import React, { useState } from "react";
import "./MyCss.css";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../Services/UserAuth";
import firebase from "firebase";
import { getRandomString } from "../../Services/GetRandomNumber";
import "../Client/Navbaronly";
import Navbar from "../Client/Navbaronly";
import { Alert, Form, Button, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { backendUrl } from "../../Services/firebase/firebase";
function Register() {
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [number, setnumber] = useState("");
  const [loading, setloading] = useState(false);
  const [message, setmessage] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");
  const [exsist, setexsist] = useState(true);
  const history = useHistory();
  const [checked, setChecked] = useState(false);
  const baseURL = backendUrl + "createNewUserKitiPay";
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleLogin = () => {
    history.push("login");
  };

  const getphoneNumber = async (e) => {
    const num = e.target.value;
    setnumber(num);
  };

  const handleregister = async (e) => {
    e.preventDefault();
    if (number.length < 11 || number === "") {
      toast.error("Unexpected error on mobile number");
      return;
    }
    if (password !== confirmpassword) {
      toast.error("password Mismatch");
      return;
    }

    if (checked === false) {
      toast.error("Please accept our terms and conditions");
      return;
    }


    if (
      password === "" ||
      confirmpassword === "" ||
      firstname === "" ||
      lastname === "" ||
      email === ""
    ) {
      toast.error("required input value missing");
      return;
    }
    setloading(true)
    await axios
      .get(
        backendUrl + `createReport/report/user/${number}`
      )
      .then(async (res) => {
        const payload = res.data.length;
        if (payload !== 0) {
          toast.error("Mobile Already in use by another user");
          setloading(false)
          return;
        }
        const UniquieNum = getRandomString(7, "1234567890");
        const data = {
          UniquieNum,
          email,
          password,
          number,
          firstname,
          lastname,
          wallet: 0,
          coin: 0,
          created: firebase.firestore.FieldValue.serverTimestamp(),
        };
        await axios
          .post(baseURL, { data: data })
          .then(async (e) => {
            toast.success("Account Created Successfully... ");
            // const url = `https://kitipay.com/users/${e.data.uid}`;
            // const homeUrl = "https://kitipay.com";
            // const fullname = `${firstname} ${lastname}`;
            // const logo = "https://i.ibb.co/5xqth9r/logo.png";
            // const content = `<div marginheight="0" topmargin="0" marginwidth="0" style="margin: 0px; background-color: #f2f3f8;" leftmargin="0">
            //   <table cellspacing="0" border="0" cellpadding="0" width="100%" background-color="#f2f3f8"
            //       style="font-family: 'Open Sans', sans-serif;">
            //       <tr>
            //           <td>
            //               <table style="background-color: #f2f3f8; max-width:670px;  margin:0 auto;" width="100%" border="0"
            //                   align="center" cellpadding="0" cellspacing="0">
            //                   <tr>
            //                       <td style="height:80px;">&nbsp;</td>
            //                   </tr>
            //                   <tr>
            //                       <td style="text-align:center;">
            //                         <a href=${homeUrl} title="logo" target="_blank">
            //                           <img width="130" src=${logo} title="logo" alt="logo">
            //                         </a>
            //                       </td>
            //                   </tr>
            //                   <tr>
            //                       <td style="height:20px;">&nbsp;</td>
            //                   </tr>
            //                   <tr>
            //                       <td>
            //                           <table width="95%" border="0" align="center" cellpadding="0" cellspacing="0"
            //                               style="max-width:670px;background:#fff; border-radius:3px; text-align:center;-webkit-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);-moz-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);box-shadow:0 6px 18px 0 rgba(0,0,0,.06);">
            //                               <tr>
            //                                   <td style="height:40px;">&nbsp;</td>
            //                               </tr>
            //                               <tr>
            //                                   <td style="padding:0 35px;">
            //                                       <h1 style="color:#1e1e2d; font-weight:500; margin:0;font-size:32px;font-family:'Rubik',sans-serif;">Email Verification</h1>
            //                                       <span
            //                                           style="display:inline-block; vertical-align:middle; margin:29px 0 26px; border-bottom:1px solid #cecece; width:100px;"></span>
            //                                       <p style="color:#455056; font-size:15px;line-height:24px; margin:0;">
            //                                       Nice to meet you ${fullname}!
            //                                       We just need to verify your email address before you can access your account. click on the button below to
            //                                       Verify your email address.
            //                                       </p>
            //                                       <a href=${url}
            //                                           style="background:#01011f;text-decoration:none !important; font-weight:500; margin-top:35px; color:#fff;text-transform:uppercase; font-size:14px;padding:10px 24px;display:inline-block;border-radius:50px;">Verify Email</a>
            //                                   </td>
            //                               </tr>
            //                               <tr>
            //                                   <td style="height:40px;">&nbsp;</td>
            //                               </tr>
            //                           </table>
            //                       </td>
            //                   <tr>
            //                       <td style="height:20px;">&nbsp;</td>
            //                   </tr>
            //                   <tr>
            //                       <td style="text-align:center;">
            //                           <p style="font-size:14px; color:rgba(69, 80, 86, 0.7411764705882353); line-height:18px; margin:0 0 0;">&copy; <strong>${homeUrl}</strong></p>
            //                       </td>
            //                   </tr>
            //                   <tr>
            //                       <td style="height:80px;">&nbsp;</td>
            //                   </tr>
            //               </table>
            //           </td>
            //       </tr>
            //   </table>
             
        
            //   </div>`;
            // const CampURL =
            //   backendUrl + "sendEMAILkitipay";
            // await axios.post(CampURL, {
            //   content,
            //   logo,
            //   fullname,
            //   email: email,
            //   firstname: firstname,
            //   url,
            //   userid: e.data.uid,
            // });
    
          setInterval(() => {
            window.location.href = "/login";
          }, 2000);
          })
          .catch((e) => {
            toast.error(e.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
       
      });
    
   

    setloading(false);
  };

  const handlecheck = () => {
    handleShow()
    setChecked(!checked);
  };

  return (
    <div>
      <ToastContainer />
      <Navbar />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <article>
          <strong>Welcome to Kitipay!</strong>
          <p>
            The Kitipay service, our website and application (collectively, the
            “Service”) enables you to receive funds into your mobile money
            wallet and withdraw to your bank account, as well as to the wallet
            or account of another user. You may also use your supported mobile
            money wallet or account to pay for products, goods and services
            offered by Kitipay and third party merchants. The Service is
            operated by Kitipay. Kitipay is a product of Kitihub Software
            Solutions, which is registered under Nigeria CAC with Registration
            Number 6916801
          </p>
          <br />
          <strong>Terms of Service</strong>
          <p>
            Effective Date: May 15, 2023 This is a contract. These Terms
            constitute a contract between you and Kitipay. If you do not accept
            these Terms, please do not use the Service. By using any part of the
            Service you accept these Terms. If you are under eighteen (18) years
            of age, you may not use the Service. You must register an account
            with valid information to use the Service, you must: (a) provide a
            valid mobile phone number and email; (b) agree that an account
            associated with such number and email be created; (c) accept the
            current Terms; and (d) submit such other and additional information
            as Kitipay may request. You agree to provide true, accurate and
            complete information about yourself as prompted by Kitipay during
            the account registration process. You will be given the option of
            receiving a unique PIN for purposes of reusing your account. You are
            responsible for keeping your PIN secure.
          </p>

          <p>
            Third party merchants offer the goods & services. The Service
            provides an opportunity to discover offers from independent third
            party merchants. These Merchants sell their respective Products
            subject to their own terms and conditions. To illustrate: <br />
            ● Gift Cards: If you choose to purchase or sell gift cards, you do
            so pursuant to the relevant terms and conditions of the service
            provider. <br />
            ● Airtime and Data: If you choose to purchase airtime and data, you
            do so pursuant to the relevant terms and conditions of the service
            provider. <br />● Utilities and Pay Bills: If you choose to purchase
            pre-paid electricity or fund betting or cable tv account, you do so
            pursuant to the relevant terms and conditions of the utility
            provider.
          </p>

          <p>
            If you decide to purchase Products such as these from third party
            Merchants, you do so at your own risk and subject to the relevant
            Merchant’s terms and conditions. The Products are not investigated,
            monitored, or checked for accuracy, reliability, appropriateness or
            completeness by Kitipay. By permitting you to discover Products via
            the Service, Kitipay does not imply approval or endorsement of such
            Products. You agree that Kitipay is not responsible or liable for
            the Products you purchase from Merchants.
          </p>

          <p>
            Kitipay will assist to resolve all issues that may arise from our
            third party merchants through our customer support channels and
            we’re not responsible or liable for the products and services from
            our third party merchants.
          </p>

          <p>
            Payments are processed by third parties. In order to facilitate your
            purchases from Merchants, Kitipay has integrated its Service with
            the billing solutions of certain utility and business service
            providers. When you purchase a Product from a Merchant using your
            supported mobile money wallet or account, payments will be processed
            by such Payment Processors. Payment Processors have their own terms
            and conditions governing your use of their payment services. You
            understand and agree that Kitipay does not process payments and is
            not responsible or liable for any transactions in which you engage.
            By engaging in a purchase transaction using your mobile money
            account, you authorize the Merchant (or Kitipay on its behalf) to
            bill your account for the purchase amount.
          </p>

          <strong>Transfer Terms of Service</strong>

          <p>
            Kitipay transfer service can only be used when KYC is done. In
            partnership with Providus Bank, account numbers are made available
            for users with KYC to receive funds into a wallet or account. Funds
            can be transferred from one user to another. In partnership with a
            money processing merchant, users can withdraw funds into personal
            bank accounts only. In Payment of goods and services vendor and user
            have complete control, Kitipay will step in only when there’s a case
            of appeal. Kitipay is not responsible or liable for any transactions
            in which you engage. To be a vendor full KYC is needed. To be a user
            KYC is needed.
          </p>

          <br />

          <strong>Privacy Policy</strong>
          <p>
            Your privacy is important to us. Kitipay takes the matters of
            protection and security of its users’ information very seriously.
            Kitipay’s privacy policy governing the Service is attached to these
            Terms (“Privacy Policy”). The Privacy Policy is incorporated into
            these Terms by this reference. By using the Service, you agree to
            the use of your data in accordance with Kitipay’s Privacy Policy.
            The Privacy Policy addresses only the information collected by
            Kitipay in providing the Service to you. Merchants and Payment
            Processors have their own information gathering practices which
            apply when you choose to purchase their Products. Product inquiries
            should be submitted to the relevant Merchant.If you have any
            questions, concerns or requests related to a Product you have
            purchased from a Merchant, please contact the Merchant directly or
            contact us at support@kitipayapp.com . <br /> 
             Each Merchant maintains its
            own customer contact desk for handling such requests, however
            Kitipay will do its best to assist you. If you wish to submit a
            complaint about the practices of a Merchant offering its Products
            via the Service, you may contact us by email at
            support@kitipayapp.com. You must not use the Service to violate any
            laws. You must not use the Service to violate or infringe the rights
            of any other person, including the rights of other users, Kitipay’s
            rights in the Service or Merchants’ rights in their Products. You
            must not breach any laws or regulations when using the Service or
            attempt to disrupt or interfere with the security or functionality
            of the Service. In the event that Kitipay reasonably suspects that
            you are using the Services for illegal activities (particularly
            fraud, money laundering or other related illegalities), Kitipay
            reserves the right to block your account immediately without
            liability. <br />
             Such illegalities as mentioned above may be as a result
            of enacted laws, court judgments, rulings or pronouncements, or
            regulatory policies and guidelines to which Kitipay is bound. If a
            breach is associated with your account/wallet, you agree that we
            have the right to apply restrictions to your account/wallet and
            report to the appropriate law enforcement agencies or the respective
            regulator, in line with extant laws. Kitipay may contact you
            regarding your account or the Service. You expressly agree that, as
            part of the Service, you may, from time to time, receive
            communications from Kitipay via email, instant message, telephone,
            text message (SMS) or other means. You may stop receiving
            promotional messages by emailing your request to opt-out, along with
            your cell phone number to support@kitipayapp.com , or following the
            opt-out instructions in the message. Even if you choose to opt out
            of receiving promotional messages, you may not opt out of receiving
            service-related messages as these ensure that we are able to deliver
            accurate, relevant, sensitive and security-related services to you.
            Kitipay may discontinue the Service.  <br />
            
            Kitipay may in furtherance of
            regulatory, time-sensitive and security-related purposes terminate
            your access to the Service or discontinue providing the Service or
            any part of the Service, with due notice to you [or without notice
            where the suspension or termination is expedient to forestall, curb
            or extinguish some ongoing fraud, industry-wide compromise or an
            ongoing financial crime-related investigation]. Rest assured that we
            will provide as much notice as the circumstance allows, and restore
            the Service at the earliest convenience. You agree that in the event
            of the foregoing, Kitipay will not be responsible or liable to you
            or any third party. The Service is provided without any warranties
            or guarantees. The Service is provided “as is“ without warranty of
            any kind. Kitipay and its suppliers and affiliates disclaim all
            warranties with regard to the service, including all implied
            warranties of merchantability, fitness for a particular purpose,
            title, and non-infringement. <br />
            If you are dissatisfied with any
            portion of the service, or with any of these terms, your sole and
            exclusive remedy is to discontinue using the service. Kitipay is not
            liable for any damages you may incur as a result of using the
            Services. In no event shall Kitipay or its suppliers be liable for
            any direct, indirect, punitive, incidental, special, consequential
            damages, or any damages whatsoever arising out of, or in any way
            connected with the use or performance of the service, with the delay
            or inability to use the service, the provision of (or failure to
            provide services), or otherwise arising out of the use of the
            service, whether based on contract, tort, negligence, strict
            liability, or otherwise, even if Kitipay or any of its suppliers
            have been advised of the possibility of such damages. You agree to
            arbitrate any disputes. This Agreement is subject to, and shall be
            governed by, and construed in accordance with the laws of Nigeria,
            without reference to the principles of conflict of laws thereof. Any
            matters arising concerning the interpretation, validity or
            implementation of this Agreement not solved by mutual agreement
            between the Parties shall be submitted to arbitration in the English
            language before a sole arbitrator to take place in Nigeria as the
            seat of the arbitration, at a location that will be communicated to
            you. <br /> The arbitration shall be conducted pursuant to the Rules of
            Arbitration of the Nigerian Institute of Chartered Arbitrators. The
            arbitral decision shall be final and binding on the Parties and may
            be made an order of court. The Parties unconditionally consent and
            submit to the jurisdiction of the High Court of Nigeria for such
            purpose. Nothing in this Agreement will be deemed as preventing
            Kitipay from seeking injunctive relief (or any other provisional
            remedy) from any court having jurisdiction over the Parties and the
            subject matter of the dispute as is necessary to protect Kitipay’s
            name, proprietary information, trade secrets, know-how, or any other
            intellectual property rights. <br />
              Kitipay may modify these Terms. These
            Terms and related policies (including but not limited to the Privacy
            Policy) may be modified by Kitipay at any time in the future. Where
            this happens, we will communicate the changes to you. By continuing
            to use the Service, you agree to be bound by the latest version of
            these Terms. It is your responsibility to familiarize yourself with
            the communicated changes. Alteration. No alteration, variation or
            agreed cancellation of this agreement, and the Privacy Policy, shall
            be of any effect unless so directed by us. Whole Agreement. This
            Agreement constitutes the whole agreement between the parties in
            regard to the subject matter hereof and no warranties or
            representations of any nature whatsoever other than set out in this
            agreement have been given by any of the parties.  <br />
            Waiver/Relaxation.
            No relaxation or indulgence which Kitipay may show to you shall in
            any way prejudice or be deemed to be a waiver of its rights
            hereunder. Survival. Each and every provision of this Agreement
            (excluding only those provisions which are essential at law for a
            valid and binding Agreement to be constituted) shall be deemed to be
            separate and severable from the remaining provisions of this
            Agreement. If any of the provisions of this Agreement (excluding
            only those provisions which are essential at law for a valid and
            binding Agreement to be constituted) is found by any court of
            competent jurisdiction to be invalid and/or unenforceable then,
            notwithstanding such invalidity and/or unenforceability, the
            remaining provisions of this Agreement shall be (and remain) of full
            force and effect.
            </p>

          </article>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            I Accept
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="backgroisns">
        <div className="usercontainerbody">
          <div class="usercontainer">
            <center>
              <div class="d-inline-block auth-logo">
                <img src="/img/logo.png" alt="JSLPS image" height="50px" />
              </div>
              <h3 className="text-dark mt-3  fs-2">Create an Account</h3>

            </center>
            {message ? (
              <Alert variant="info">
                <p className="text-white">{message}</p>
              </Alert>
            ) : (
              ""
            )}

            <Form>
              <div className="useronbording">
                <div className="usercondhahd">
                  <Form.Group className="mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="First name"
                      onChange={(event) => {
                        setfirstname(event.target.value);
                      }}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Last name"
                      onChange={(event) => {
                        setlastname(event.target.value);
                      }}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      onChange={(event) => {
                        setemail(event.target.value);
                      }}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Enter Phone Number</Form.Label>
                    <Form.Control
                      type="tel"
                      max={11}
                      placeholder="080..."
                      onChange={getphoneNumber}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      onChange={(event) => {
                        setpassword(event.target.value);
                      }}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label> Confirm Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      onChange={(event) => {
                        setconfirmpassword(event.target.value);
                      }}
                      required
                    />
                  </Form.Group>
                </div>
              </div>

              <Form.Text className="text-muted">
                <span>Already have an account?</span>{" "}
                <span className="act" onClick={handleLogin}>
                  Login
                </span>
              </Form.Text>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="I agree to these Terms and Conditions/Privacy Policy."
                onChange={handlecheck}
                required
              />
            </Form.Group>

              <Button
                variant={loading ? "warning" : "primary"}
                type="submit"
                onClick={handleregister}
                className="w-100 py-2 my-2"
                disabled={loading}
              >
                {loading ? "Loading..." : "Register"}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
