import React, {useState } from 'react'
import { useAuth } from '../../../Services/UserAuth'
import Sidebar from '../ClientComponent/Sidemenue'
import "../ClientComponent/Complaince.css";
import { Button, Form } from 'react-bootstrap'
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

import { backendUrl } from "../../../Services/firebase/firebase";
import { useHistory } from 'react-router-dom';
function UserprofilePage() {
const {loginuser} =  useAuth()
const [businessname, setbusinessname] = useState("");
const [businessreg, setbusinesregno] = useState("");
const [businessemail, setbusinessemail] = useState("");
const [businessaddress, setbusinessaddress] = useState("");
const [businessdiscription, setbusinessdescription] = useState("");
const history =  useHistory()
const [loading, setloading] = useState(false);
const [res, setres] = useState("");

const [type, settype] = useState("");
const [nextfill, setNextfill] =  useState("profile")



  const handlesenddbusiness = async (e) => {
    e.preventDefault();
    if (type === "Registered" && businessreg === "") {
      toast.error("Business Registration Number is missing");
      return;
    }

    if (
      businessname === "" ||
      businessemail === "" ||
      businessaddress === "" ||
      businessdiscription === ""
    ) {
      toast.error("Business Information is missing");
      return;
    }

    const data = {
      id: loginuser.id,
      businessname,
      businessreg: type !== "Registered" ? "" : businessreg,
      businessemail,
      businessaddress,
      businessdiscription,
    };
    setloading(true);
    setres("Gathering information to process. Please wait");
    try {
      await axios
        .post(
          backendUrl+ "getUpatedUser/users",
          {
            data,
          }
        ).then(()=>{
          history.push("/compliance-verify/business-representative")
        })
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setres("");
    setloading(false);
  };



  return (
    <div>
        <ToastContainer/>
        <div className="pagecon">
        <div className="pagecontent">
          <div className="headersisecoadmin">
            <Sidebar/>
          </div>
          <div className="otherssecoth">
            <div className="dashboardconteier">
              <div className="topmenuecontrenr">
              <Button
                      variant={loading ? "warning" : "primary"}
                     
                      onClick={history.goBack}
                    >
                      {loading ? "Wait" : "Back"}
                    </Button>
              {loginuser.isbusinessInfoSubmited === true ? (
              <>
                <div className="aformconteinr">
                  <div className="albs">
                    {loginuser.isbuisnessInformationVerified !== true ? (
                      <div className="text-danger"> Awaitn Admin Approval</div>
                    ) : (
                      <div className="text-success">Verified</div>
                    )}
                  </div>
                  <div className="informatronpobody">
                    <div className="inforalteader">Business Name :</div>
                    <div className="headerbodycontainer">
                      {loginuser.businessname}
                    </div>
                  </div>

                  <div className="informatronpobody">
                    <div className="inforalteader">Business Address :</div>
                    <div className="headerbodycontainer">
                      {loginuser.businessaddress}
                    </div>
                  </div>

                  <div className="informatronpobody">
                    <div className="inforalteader">Business Email :</div>
                    <div className="headerbodycontainer">
                      {loginuser.businessemail}
                    </div>
                  </div>

                  <div className="informatronpobody">
                    <div className="inforalteader">Business Reg No :</div>
                    <div className="headerbodycontainer">
                      {loginuser.businessreg ? loginuser.businessreg : ""}
                    </div>
                  </div>
                  <div className="informatronpobody">
                    <div className="inforalteader">Business Description :</div>
                    <div className="headerbodycontainer">
                      {loginuser.businessdiscription}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="aformconteinr">
                <Form.Select
                  aria-label="Default select example"
                  onChange={(event) => {
                    settype(event.target.value);
                  }}
                >
                  <option value="">Select Business Type</option>
                  <option value="Registered">Registered Business </option>
                  <option value="Non Registered">
                    Non-Registered Business
                  </option>
                </Form.Select>
                <Form.Text id="passwordHelpBlock" muted>
                  Select your Business Type
                </Form.Text>

                <div className="businessregisterconatiner">
                  <div
                    className="buinswesconehf"
                    style={{ display: type === "" ? "none" : "block" }}
                  >
                    <p>Business Information</p>
                    <Form.Group className="mb-3">
                      <Form.Label>Business Name</Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(event) => {
                          setbusinessname(event.target.value);
                        }}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      style={{
                        display: type === "Non Registered" ? "none" : "block",
                      }}
                    >
                      <Form.Label>RC/BN No.</Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(event) => {
                          setbusinesregno(event.target.value);
                        }}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Business Email Address</Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(event) => {
                          setbusinessemail(event.target.value);
                        }}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label> Business Address</Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(event) => {
                          setbusinessaddress(event.target.value);
                        }}
                      />
                    </Form.Group>

                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Business Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        onChange={(event) => {
                          setbusinessdescription(event.target.value);
                        }}
                      />
                      <Form.Text id="passwordHelpBlock" muted>
                        Tell us More about your business
                      </Form.Text>

                      <Form.Text id="passwordHelpBlock" muted>
                        {res}
                      </Form.Text>
                    </Form.Group>

                    <Button
                      variant={loading ? "warning" : "dark"}
                      className="w-100"
                      onClick={handlesenddbusiness}
                    >
                      {loading ? "Wait" : "Send Request"}
                    </Button>
                  </div>
                </div>
              </div>
            )}
                </div>
              </div>
            </div>
           
          </div>
        </div>
  
    </div>
  )
}

export default UserprofilePage