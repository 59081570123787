import React from "react";
import { Link, useHistory } from "react-router-dom";
import "./Delivery.css";
import {BiSupport, BiLock} from "react-icons/bi"
import {BsRecycle} from "react-icons/bs"
import {RiExchangeFundsLine, RiSpeedMiniFill} from "react-icons/ri"

function DeliverPassion() {
  const history = useHistory();

  return (
    <div>
      <div className="allworjdhd">
        <div className="allsjdjhd">
         <div className="headersectun">
         <h1>Why Kitipay</h1>
          <p>
            Kitipay.com is Nigeria’s top payment solution platform providing fast, easy
            online payment solution for millions of people. We are impacting
            lives by ensuring payments for day-to-day services. with Kitipay you can perform quick transactions
            anytime and anywhere using any device.
          </p>
         </div>

         <div className="whykitipayservices">
          <div className="whykitipayserviceoconwr">
            <p><BiSupport/></p>
            <h1>24/7 support services</h1>
          </div>

          <div className="whykitipayserviceoconwr">
            <p><BiLock/></p>
            <h1>Secured Payment System</h1>
          </div>


          <div className="whykitipayserviceoconwr">
            <p><RiSpeedMiniFill/></p>
            <h1>Quick Service Delivery</h1>
          </div>

          <div className="whykitipayserviceoconwr">
            <p><RiExchangeFundsLine/></p>
            <h1>Best Digital Exchange Partner</h1>
          </div>

          <div className="whykitipayserviceoconwr">
            <p><BsRecycle/></p>
            <h1>Reliable Payment Processing Partner</h1>
          </div>
         </div>
        </div>

        
      </div>

      <div className="finalankdess">
          <div className="dincaconjdhd">
              <h1>Sign up and get started</h1>
              <Link to="/register"><button >Get Started</button></Link>
          </div>
        </div>
    </div>
  );
}

export default DeliverPassion;
