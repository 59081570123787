
import firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/storage'
import "firebase/auth"
import "firebase/firebase-functions"



const FirebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyCJMYtGaa2kiTw0ZczAySIsn-2USmc6N1Q",
  authDomain: "kitipay-app-web.firebaseapp.com",
  projectId: "kitipay-app-web",
  storageBucket: "kitipay-app-web.appspot.com",
  messagingSenderId: "269486622227",
  appId: "1:269486622227:web:55d1333cb9ea931418e1e6",
  measurementId: "G-49MPSQCJZL"
});

export const auth = FirebaseApp.auth()
export const database = FirebaseApp.firestore();
const storage = firebase.storage()
const functions = firebase.functions();
const backendUrl = 'https://us-central1-kitipay-app-web.cloudfunctions.net/';
const baseUrl = 'https://kitipay.com/';
const examePrce = "hEX1DUJ3HprStB5kFLIW"

export  {
  storage, functions, FirebaseApp as default,
  backendUrl, baseUrl ,examePrce
}