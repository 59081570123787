import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import "../Client/Navbar.css";
import { MdClose } from "react-icons/md";
import { BiMenu } from "react-icons/bi";
import { useAuth } from "../../Services/UserAuth";
import { getuser } from "../../Services/GetUser.service";


function Navbar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const history =  useHistory()
  const { loginuser, logout } = useAuth();
  const { uid } = useAuth().currentUser;
  useEffect(() => {
    getuser(uid,(result) => {
      if (result.role !=="Sale"){
        history.push("/auth/login")
      }
    });
  }, []);

  const handlecloase = ()=>{
    history.push("/auth/logout")
  }
  return (
    <>
      {/* <Topbar/> */}

      <div>
        <div
          className="bannercontainer "
        >
          <div className="allwarper0000">
            <nav className="navbar1 newbannasd">
              <Link to="/" className="navbar-logo newcllectyd" onClick={closeMobileMenu}>
                <img src={loginuser.image} alt="" />
              </Link>
              <div className="menu-icon" onClick={handleClick}>
                {click ? <MdClose /> : <BiMenu />}
                {/* <i className={click ? "fas fa-times" : "fas fa-bars"} /> */}
              </div>
              <ul className={click ? "nav-menu active" : "nav-menu "}>
        
                <li className="nav-item">
                  <button onClick={handlecloase}>Logout</button>
                </li>

            
              </ul>
            </nav>
    
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
