import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../Services/UserAuth";
import "./Docfile.css";
function Settlement() {
  const { loginuser, userwallet } = useAuth();

  return (
    <div>
      <div className="headersentskdjd">
        <div className="headeconatiner">
          <Link to="/dashboard/Compliance/general">General</Link>
          <Link to="/dashboard/Compliance">Compliance</Link>

          <Link
            to="/dashboard/Compliance/settlement"
            className="theactiveone text-success"
          >
            Settlement
          </Link>
        </div>
        <center>
          <hr className="w-50 " />
        </center>
      </div>

      <div className="useridodnsidd">
        <div className="setmentconent">
          <div className="fincoejdjhssttate">
            <h1>Settlements</h1>
            <p>
              Funds collected from your customers can either be settled into
              your Korapay Balance or your verified settlement account . You can
              get more details on the transactions Log Page
            </p>
            <Link to="/user/transaction">
              <span>Transaction History Page</span>
            </Link>
          </div>
          <hr />
          <div className="fincoejdjhssttate">
            <h1>Settlement Destination</h1>
            <p>
              Your settlement destination is where all your successful
              transactions will be settled. Currently, your settlements will be
              deposited in your Kitipay Wallet. Please not that all withdrawal
              will be process base on the company Terms and policy
            </p>
          </div>
          <hr />
          <div className="fincoejdjhssttate">
            <h1>Settlement Bank Account</h1>
            <p>
              This is your bank account into which you can receive settlements.
            </p>
            <table>
              <thead>
                <tr>
                  <th>Account Name</th>
                  <th>Account Number</th>
                  <th>Bank</th>
                  <th>Staus</th>
                </tr>
              </thead>
              {loginuser.isbusinessBankSubmited === true? 
              <tbody>
                <td>{loginuser.BusinessAcccountName}</td>
                <td>{loginuser.BusinessAcccountNumber}</td>
                <td>{loginuser.BusinessBankName}</td>
                <td
                  style={{
                    color:
                      loginuser.isbusineDocVerify === true ? "green" : "red",
                  }}
                >
                  {loginuser.isbusineDocVerify === true
                    ? "Approved"
                    : "Pending"}
                </td>
              </tbody> : <tbody>
                <td>No Record found <Link to="/dashboard/Compliance">Add Account Number</Link></td>
              </tbody>}
            </table>
            <hr />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settlement;
