import React, { useEffect } from "react";
import { useState } from "react";
import { Button, FloatingLabel, Form, Modal, Spinner } from "react-bootstrap";
import { useAuth } from "../../../Services/UserAuth";
import "./Transaction.css";
import { ToastContainer, toast } from "react-toastify";
import { format } from "date-fns";
import axios from "axios";
import { database } from "../../../Services/firebase/firebase";
import firebase from "firebase";
import BettingPro from "../../../BettingProviders.json";
import { getRandomString } from "../../../Services/GetRandomNumber";
import { Link, useHistory } from "react-router-dom";
import { backendUrl } from "../../../Services/firebase/firebase";
import { SendNotification } from "../../../Services/GetUser.service";
import { version_control } from "../../../Services/Utility";
function Airtimecomponent() {
  const [amount, setamount] = useState(0);
  const [selectedbet, setselectedBet] = useState("");
  const [number, setphone] = useState("");
  const [loading, setloading] = useState(false);
  const { loginuser, userwallet } = useAuth();
  const [responspayload, setresponsepayload] = useState("");
  const [show, setShow] = useState(false);
  const [payload, setpayload] = useState([]);
  const [com, setcom] = useState(0);
  const [showprint, setShowprint] = useState(false);
  const [res, setrepayload] = useState([]);
  const [userID, SetuserID] = useState("");
  const getprovider = BettingPro.data;
  const history = useHistory()
  const [payloadres, setpayloadres] = useState([])
  const handleClosePrint = () => {
    window.location.reload();
    setShowprint(false);
  };
  const handleShowprint = () => setShowprint(true);
  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const date = format(new Date(), "dd/MM/yyyy hh:mm a");
  
  const b = selectedbet ? selectedbet.toLowerCase() : "";
  const version = version_control()

  const limit = loginuser.transactionLimit - amount
  const TransactionLimit = limit <= 0 ? 0 : limit
  const handleverifier = async () => {
    if (selectedbet === "" || b === "") {
      toast.error("Please select network");
      return;
    }
    if (amount < 100) {
      toast.error("minimum vending amount is 100");
      return;
    }
    if (limit <= 0) {
      toast.error("Limit Error");
      return;
    }
    const num = number 
    if (num === "" || !num){
      toast.error("Enter Mobile number");
      return 
    }
    setloading(true);
    try {
      await axios
        .post(
          backendUrl + "verifybettinguser/verify/betting-user",
          {
            service_type: b,
            account_number: userID,
          }
        )
        .then((e) => {
          if (e.data.status === "success") {

            setpayload(e.data.data.user);
            handleShow();
            return
          }
          toast.error("user account not found")
        });
    } catch (error) { }
    setloading(false);
  };

  const handlepayment = async (e) => {
    e.preventDefault();
    if (userwallet.wallet < payload.amount) {
      toast.error("Insuffient Fund");
      return;
    }

    if (amount < 100) {
      toast.error("Minimum funding amount is 100")
    }

    if (amount > 50000) {
      toast.error("Maximum funding amount is 1,000,000")
    }
    if (selectedbet === "" || b === "") {
      toast.error("Please select network");
      return;
    }

    if (limit <= 0) {
      toast.error("Limit Error");
      return;
    }
    const ref = database.collection("_").doc().id;
    const UniquieNum = getRandomString(20, "1234567890");
    try {
      setloading(true);
      setresponsepayload("connecting");
      await axios
        .post(
          backendUrl + `debitSuperAgentAccount/debit/user`,
          {
            id: loginuser.id,
            amount: amount + 10,
            trackNo: UniquieNum,
            version: version
          }
        )
        .then(async (res) => {
          setresponsepayload("sending value");
          if (res.data.res === "success") {
            const Token = res.data.token
            await axios
              .post(
                backendUrl + "fundbettinguser/fund/betting-user",
                {
                  account_number: userID,
                  amount: amount,
                  service_type: b,
                  agentReference: ref,
                  customername: payload.name,
                  number: number,
                  Token
                }
              )
              .then(async (res) => {
                setpayloadres(res.data);
                setresponsepayload("Finilizing transaction");          
                const data = {
                  id: ref,
                  company: selectedbet,
                  customername: res.data.customername,
                  address: "no value",
                  userID: loginuser.id,
                  AccountNumber: res.data.account_number,
                  walletbalance: userwallet.wallet,
                  amount: amount,
                  Debitamount: amount + 10,
                  phone: number,
                  TranRef: UniquieNum,
                  payloadres: res.data,
                  mezuflex_id:res.data.ref,
                  customermisson: 0,
                  com: 10,
                  type: "Betting",
                  status: "success",
                  isToday: true,
                  isClose: true,
                  created: firebase.firestore.FieldValue.serverTimestamp(),
                };
                const dataw = {
                  id: ref,
                  company: selectedbet,
                  customername: res.data.customername,
                  address: "no value",
                  userID: loginuser.id,
                  AccountNumber: res.data.account_number,
                  walletbalance: userwallet.wallet - amount,
                  Prewalletbalance: userwallet.wallet,
                  amount: amount,
                  Debitamount: amount + 10,
                  phone: number,
                  payloadres: res.data,
                  customermisson: 0,
                  mezuflex_id:res.data.ref,
                  com: 10,
                  TranRef: UniquieNum,
                  type: "Betting",
                  status: "error",
                  isToday: true,
                  isClose: false,
                  created: firebase.firestore.FieldValue.serverTimestamp(),
                };

                if (res.data.status === "success") {
                  const promises = [
                    axios.post(
                      backendUrl+"userlogsuserlogs/transactions",
                      {
                        data: data,
                      }
                    ),
                    database.doc(`/income/vRgRWLBNzwo7IM1pUhcl`).update({
                      income: firebase.firestore.FieldValue.increment(10),
                      betting: firebase.firestore.FieldValue.increment(10),
                    }),
                  ];
                  setrepayload(data);
                  await Promise.all(promises)
                  history.push(`/reciept/view/${data.id}`)

                } else {
                
                  setrepayload(dataw);
                 await axios.post(
                  backendUrl+"userlogsuserlogs/transactions",
                    {
                      data: data,
                    }
                  )
                  history.push(`/reciept/view/${dataw.id}`)
                }
              });
          } else {
            toast.error(res.data.message);
          }
        });
    } catch (error) {

      toast.error(error.message);
    }
    setloading(false);
  };

  const handleprint = () => {
    history.push(`/transaction/${res.id}`)
  }

  return (
    <div>
      <ToastContainer />

      <Modal
        show={showprint}
        onHide={handleClosePrint}
        backdrop="static"
        keyboard={false}
      >
        <div className="tramsactiondetyaions">
          <div className="transactioncokatne">
            <div
              className="transajea"
              style={{
                backgroundColor: res.status === "success" ? "green" : "red",
              }}
            >
              <h1>{res.status === "success" ? "SUCCESS" : "ERROR"}</h1>
              <span>Transaction Reciept</span>
            </div>
            <div className="transactioncontent">
              <div className="transcotebnr">
                <div className="contbename">Company</div>
                <div className="conetnenvakeb sjshdjdhd">{selectedbet}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">Account/ID</div>
                <div className="conetnenvakeb">{payloadres.account_number}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">Customer Name</div>
                <div className="conetnenvakeb">{payloadres.customername}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">Amount</div>
                <div className="conetnenvakeb">&#8358; {payloadres.amount}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">Status</div>
                <div className="conetnenvakeb">{payloadres.status}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">Data</div>
                <div className="conetnenvakeb">{date}</div>
              </div>
            </div>
          </div>
        </div>
        <Modal.Footer>
          <Link to="/services">
            <Button variant="secondary" >
              Another Transaction
            </Button>
          </Link>

          <Button variant="primary" onClick={handleprint}>Reciept</Button>
          <Button variant="secondary" onClick={handleClosePrint}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="p-0">
          <div className="tramsactiondetyaions">
            <div className="transactioncokatne">
              <div className="transajea">
                <h1>In-Porgress</h1>
                <span>
                  Kindly confirm the information below before you proceed with
                  this payment.
                </span>
              </div>
              <div className="transactioncontent">
                <div className="transcotebnr">
                  <div className="contbename">Company</div>
                  <div className="conetnenvakeb sjshdjdhd">
                    {selectedbet}
                  </div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">Customer Name</div>
                  <div className="conetnenvakeb">{payload.name}</div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">Account Number/ID</div>
                  <div className="conetnenvakeb">{payload.accountNumber}</div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">Amount</div>
                  <div className="conetnenvakeb">{amount}</div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">number</div>
                  <div className="conetnenvakeb">{number}</div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">Status</div>
                  <div className="conetnenvakeb">Processing</div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">Data</div>
                  <div className="conetnenvakeb">{date}</div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" disabled={loading} onClick={handleClose}>
            Close
          </Button>
          <Button
            variant={loading ? "warning" : "primary"}
            disabled={loading}
            onClick={handlepayment}
            type="submit"
          >
            {loading ? (
              <>
                {" "}
                <Spinner animation="border" size="sm" /> {responspayload}
              </>
            ) : (
              "Pay with Wallet"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="transactionconatiner">
        <div className="transactioncontenrt">
          <div className="transactiohead">
            <h2>
              <center className="text-dark">Betting</center>
            </h2>
          </div>
          <div className="formtransactionsecton">
            <div className="formtrnacruondjdjd">
              <div className="productsed">
                <FloatingLabel
                  controlId="floatingSelect"
                  onChange={(event) => {
                    setselectedBet(event.target.value);
                  }}
                >
                  <Form.Select aria-label="Floating label select example">
                    <option>Select Company</option>
                    {getprovider.map((e) => {
                      return (
                        <option value={e.serviceName}>{e.serviceName}</option>
                      );
                    })}
                  </Form.Select>
                </FloatingLabel>
                <div className="optionshhd">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className="text-dark my-3">
                      Enter User ID/Account No.
                    </Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(event) => SetuserID(event.target.value)}
                      placeholder="Enter User ID/Account No."
                    />
                    <Form.Text className="text-muted">
                      Verification Required.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className="text-dark my-2">Amount</Form.Label>
                    <Form.Control
                      placeholder="Enter Amount (100 - 50,000)"
                      type="tel"
                      maxLength={11}
                      onChange={(event) => {
                        setamount(parseFloat(event.target.value));
                      }}
                    />
                  </Form.Group>
                  <Form.Text className="text-muted">
                    &#8358; 10 service charge
                  </Form.Text>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className="text-dark my-2">
                      Phone Number
                    </Form.Label>
                    <Form.Control
                      placeholder={loginuser.number}
                      type="email"
                      onChange={(event) => {
                        setphone(event.target.value);
                      }}
                    />
                  </Form.Group>

                  <Form.Text className="text-muted">
                    Transaction Limit: {TransactionLimit}
                  </Form.Text>
                  <Button
                    variant={loading ? "warning" : "primary"}
                    disabled={loading || limit <= 0}
                    onClick={handleverifier}
                    className="my-2 w-100"
                    type="submit"
                  >
                    {loading ? (
                      <>
                        {" "}
                        <Spinner animation="border" size="sm" />{" "}
                        {responspayload}
                      </>
                    ) : (
                      "Proceed"
                    )}
                  </Button>

                  <Button
                    variant={loading ? "warning" : "danger"}
                    disabled={loading || limit <=0}
                    onClick={history.goBack}
                    className="w-100"
                  >
                    {loading ? (
                      <>
                        {" "}
                        <Spinner animation="border" size="sm" />{" "}
                        {responspayload}
                      </>
                    ) : (
                      "Back"
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Airtimecomponent;
