import React, { useCallback, useEffect, useState } from "react";
import { Button, ButtonGroup, Form, Modal } from "react-bootstrap";
import {
  getstauser,
  gettransactin,
  getuser,
  getuserwallet,
} from "../../../Services/GetUser.service";
import moment from "moment";
import { useHistory } from "react-router-dom";
import "../../Admin/AdminComponent/User.css";
import { useAuth } from "../../../Services/UserAuth";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { database } from "../../../Services/firebase/firebase";
import { formatNumber, timeStamptoDate } from "../../../Services/Utility";
import { format } from "date-fns";
let userRef = database.collection("Transaction").orderBy("created", "desc");

function UserLog() {
  const [usecollection, setusercollection] = useState([]);
  const [showview, setShowview] = useState(false);
  const { loginuser } = useAuth();
  const handleCloseview = () => setShowview(false);
  const [search, setsearch] = useState("");
  const handleShowview = () => setShowview(true);
  const history = useHistory();
  const [item, setitem] = useState([]);
  const [loading, setloading] = useState(false);
  const [userwallet, setuserwallet] = useState([]);
  const [lastDocs, setlastDocs] = useState();
  const [isEmpty, setisEmpty] = useState(false);
  const { uid } = useAuth().currentUser;

  const [currentPage, setcurrentpage] = useState(1);
  const recordpage = 10;
  const lastIndex = currentPage * recordpage;
  const firstIndex = lastIndex - recordpage;
  const records = usecollection.slice(firstIndex, lastIndex);
  const npage = Math.ceil(usecollection.length / recordpage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  useEffect(() => {
    gettransactin((result)=>{
    const data = result.filter((e)=>e.userID ===  uid)
     setusercollection(data)
    })
  }, [])
  

  const prepage = () => {
    if (currentPage !== 1) {
      setcurrentpage(currentPage - 1);
    }
  };

  const Nextpage = () => {
    if (currentPage !== npage) {
      setcurrentpage(currentPage + 1);
    }
  };

  const changecurrentPage = (id) => {
    setcurrentpage(id);
  };


  const Togglemodalview = useCallback(
    (item) => () => {
      getuserwallet(item.id, (res) => {
        setuserwallet(res);
      });
      setitem(item);
      handleShowview();
    },
    []
  );


  const handlePrint = ()=>{
    history.push(`/transaction/${item.id}`)
  }

  return (
    <div>
      <ToastContainer />

      <Modal
        show={showview}
        onHide={handleCloseview}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton className="modalheader">
          Transaction Details
        </Modal.Header>
        <Modal.Body>
          <div className="biewproductcontinaer">
            <div className="productineofnfjf">
              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Transaction Type</strong>
                </div>
                <div className="productonfprnf">{item.type}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Service Type</strong>
                </div>
                <div className="productonfprnf">{item.company}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Amount</strong>
                </div>
                <div className="productonfprnf">{item.amount}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Account Number</strong>
                </div>
                <div className="productonfprnf">{item.AccountNumber}</div>
              </div>

              <div className="aritemhid" style={{display: item.type === "Cable TV" ? "block" : "none"}}>
            <div className="productdetaund">
                <div className="producttitle">
                  <strong>Parkage</strong>
                </div>
                <div className="productonfprnf">{item.Parkage}</div>
              </div>
            </div>

            <div className="aritemhid" style={{display: item.type === "airtime" ? "none" : "block"}}>
            <div className="productdetaund">
                <div className="producttitle">
                  <strong>Customer Name</strong>
                </div>
                <div className="productonfprnf">{item.customername}</div>
              </div>
            </div>

              <div className="showaedidata" style={{display: item.type === "Electricity" ? "block" : "none"}}>
                
              <div className="productdetaund" >
                <div className="producttitle">
                  <strong>Address</strong>
                </div>
                <div className="productonfprnf">{item.address}</div>
              </div>

              <div className="productdetaund" >
                <div className="producttitle">
                  <strong>Token</strong>
                </div>
                <div className="productonfprnf">{item.token}</div>
              </div>

              <div className="productdetaund" >
                <div className="producttitle">
                  <strong>Unit</strong>
                </div>
                <div className="productonfprnf">{item.unit}</div>
              </div>

              <div className="productdetaund" >
                <div className="producttitle">
                  <strong>Debt</strong>
                </div>
                <div className="productonfprnf">{item.debtAmount}</div>
              </div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>status</strong>
                </div>
                <div className="productonfprnf">{item.status}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Date</strong>
                </div>
                <div className="productonfprnf">
                  {item.created
                    ? format(item.created, "dd/MM/yyyy hh:mm a")
                    : ""}
                </div>
              </div>

            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handlePrint}>
            Print
          </Button>
          <Button variant="secondary" onClick={handleCloseview} >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="giftcardocne">
        <h1>Transaction History</h1>
       
        <div
          className="cardshowarea"
        >
          <div className="cardvaljsjs">
            <table>
              <thead>
                <tr>
                  <th className="hidetable">S/N</th>
                  <th>Created</th>
                  <th>Type</th>
                  <th className="hidetable">Service Type</th>
                  <th className="hidetable">Amount</th>
                  <th>Account Number</th>
                  <th className="hidetable">Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody >
                {records.length === 0 ? <>
                
                No Record foound
                
                </> :
                <>
                 {records
                  .filter((value) => {
                  
                    if (value === "") {
                      return value;
                    } else if (
                      value.company.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return value;
                    } else if (
                      value.type.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return value;
                    }
                  })
                  .map((item, id) => {
                    const d = timeStamptoDate(item);
                    const date = moment(d.created).fromNow();
                    let col = ""
                    if (item.status === "success"){
                      col = "green"
                    }
                    if (item.status === "error" ){
                      col = "red"
                    }
                    if (item.status === "Processing" ){
                      col = "orange"
                    }
                    return (
                      <tr key={id}>
                        <td className="hidetable" >{id + 1}</td>
                        <td>{date}</td>
                        <td>{item.type}</td>
                        <td className="hidetable">{item.company}</td>
                        <td style={{color :col}} className="hidetable"> &#8358; {formatNumber(item.amount)}</td>
                        <td>{item.AccountNumber} <br /> <span style={{color :item.status === "success" ? "green" : "red" }} className="showontabsksjsj"> &#8358;  {formatNumber(item.amount)}</span></td>
                    
                        <td className="hidetable">
                          {item.status}
                        </td>
                        <td>
                          <ButtonGroup aria-label="Basic example" >
                            <Button
                              variant="success"
                              onClick={Togglemodalview(item)}
                            >
                              View
                            </Button>
                            {/* <Button
                              variant="primary"
                              onClick={Togglemodal(item)}
                            >
                              Edit
                            </Button> */}
                          </ButtonGroup>
                        </td>
                      </tr>
                    );
                  })}
                
                </>
                } 
               
              </tbody>
            </table>

            <nav>
              <ul className="pageination">
                <li className="page-item">
                  <span className="page-link" onClick={prepage}>
                    Prev
                  </span>
                </li>

                {numbers.map((n, i) => {
                  return (
                    <li
                      key={i}
                      className={
                        currentPage === n ? "activenumber" : "page-item"
                      }
                    >
                      <span className="page-link" onClick={() => changecurrentPage(n)}>
                        {n<1 ? 1 : n}
                      </span>
                    </li>
                  );
                })}

                <li className="page-item">
                  <span className ="page-link" onClick={Nextpage}>
                    Next
                  </span>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserLog;
