import React from "react";
import Sidemenue from "../ClientComponent/Sidemenue"
import ResultCheckerCom from "../ClientComponent/ResultCheckerCom";



function Giftcardpage() {
 

  return (
    <div>     
      <div className="pagecon">
        <div className="pagecontent">
          <div className="headersisecoadmin">
            <Sidemenue />
          </div>
          <div className="otherssecoth">
            <div className="dashboardconteier">
              <div className="topmenuecontrenr">
                
                <ResultCheckerCom/>
                
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>

  
  );
}

export default Giftcardpage;
