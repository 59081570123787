import React, { useCallback, useEffect, useState } from "react";
import { Button, ButtonGroup, Form, Modal } from "react-bootstrap";
import {
  SendNotification,
  getstauser,
  getuserwallet,
} from "../../../Services/GetUser.service";
import moment from "moment";
import { useHistory } from "react-router-dom";
import "./User.css";
import { useAuth } from "../../../Services/UserAuth";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { formatNumber } from "../../../Services/Utility";
import { backendUrl } from "../../../Services/firebase/firebase";
import { getRandomString } from "../../../Services/GetRandomNumber";
import firebase from "firebase";
import { format } from "date-fns";
function UserLog() {
  const [usecollection, setusercollection] = useState([]);
  const [showview, setShowview] = useState(false);
  const { loginuser, userwallet } = useAuth();
  const handleCloseview = () => setShowview(false);
  const [search, setsearch] = useState("");
  const handleShowview = () => setShowview(true);
  const history = useHistory();
  const [item, setitem] = useState([]);
  const [loading, setloading] = useState(false);
  const [userwallets, setuserwallet] = useState([]);
  const [showfund, setShowfund] = useState(false);
  const handleClosefund = () => setShowfund(false);
  const handleShowfund = () => setShowfund(true);
  const [amount, setamount] = useState(0);
  const [bvnloading, setloadingbvn] = useState(false);
  const [message, setmessage] = useState("");
  const [BvnPayload, setdatafund] = useState([]);
  const [currentPage, setcurrentpage] = useState(1);


  useEffect(() => {
    getstauser((result) => {
      setusercollection(result);
    });
  }, []);


  
  const recordpage = 300;
  const lastIndex = currentPage * recordpage;
  const firstIndex = lastIndex - recordpage;
  const records = usecollection.slice(firstIndex, lastIndex);
  const npage = Math.ceil(usecollection.length / recordpage);
  const numbers = [...Array(npage + 1).keys()].slice(1);


  const prepage = () => {
    if (currentPage !== 1) {
      setcurrentpage(currentPage - 1);
    }
  };

  const Nextpage = () => {
    if (currentPage !== npage) {
      setcurrentpage(currentPage + 1);
    }
  };

  const changecurrentPage = (id) => {
    setcurrentpage(id);
  };

  const handlecreatestatf = ()=>{
    history.push("/create-new-staff/admin")
  }

  
  const Togglemodalview = useCallback(
    (item) => () => {
    
      getuserwallet(item.id, (res) => {
        setuserwallet(res);
      });
      setitem(item);
      handleShowview();
    },
    []
  );

  const handlealow = async (e) => {
    e.preventDefault();
    if (item.userRequstVerificaton !== true) {
      toast.error("Request can't be process");
      return;
    }

    // if (message !== "Record Retrived"){
    //   toast.error("No correspondent data found");
    //   return; 
    // }
    setloading(true);
    try {
      await axios
        .put(
         backendUrl+ `createReport/report/user/${item.id}`
        )
        .then(async(res) => {
          toast.success(res.data.message);
          setloading(true);
          const nofifyID = getRandomString(6, "1234567890")
          const notificationBody = {
            message: `Account verification now completed. Account upgraded successfully`,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            isread: false,
            id: nofifyID,
            userID:"",
            isRi: 0
          }
        await SendNotification(notificationBody, item.id, "user")  
        });
    } catch (error) {
      toast.error(error.message);
    }
    setloading(false);
  };

  const verifyUser = async(e)=>{
    e.preventDefault()

    if (item.userRequstVerificaton !== true) {
      toast.error("Request can't be process becouse user is yet to submit document");
      return;
    }

    setloading(true);
    try {
      await axios
        .get(
         backendUrl+ `getuserverification/userVerification/${item.id}`
        )
        .then((res) => {
          toast.success(res.data.message);
          setloading(true);
          setInterval(() => {
            window.location.reload()
          }, 2000);
        });
    } catch (error) {
      toast.error(error.message);
    }
    setloading(false);

  }

  const handlesuspendUser = async (e) => {
    e.preventDefault();
    if (item.userRequstVerificaton !== true) {
      toast.error("Request can't be process");
      return;
    }
    setloading(true);
    try {
      const d = backendUrl+ `sendnumberkitipay/${item.id}`
      await axios
        .get(
          d
        )
        .then((res) => {
          toast.success(res.data.message);
          setloading(true);
          setInterval(() => {
            window.location.reload()
          }, 2000);
        });
    } catch (error) {
      toast.error(error.message);
    }
    setloading(false);
  };

  const handlefundwallet = async (e) => {
    e.preventDefault();

    if (isNaN(amount)) {
      toast.error("Negetive value not allowed");
      return;
    }

    if (loginuser.role !== "admin") {
      toast.error("Auth Error");
      return;
    }

    if (userwallet.admin_totalcredite < amount){
      toast.error("Low balance");
      return;
    }

    setloading(true)
    const UniquieNum = getRandomString(20, "1234567890");
    try {
      await axios
      .post(
        backendUrl + `debitadnubAccount/debit/user`,
        {
          id: loginuser.id,
          amount: parseFloat(amount),
          trackNo: UniquieNum,
          type:"userfund",
          userID:item.id
        }
      ).then((res)=>{
        if (res.data.res === "success"){
          toast.success(res.data.message)
        }
        toast.error(res.data.message)
      })

    } catch (error) {
        toast.error(error.message)
    }
    setloading(false);
  };

  const rejectverifyUser =async(e)=>{
    e.preventDefault();
    if (item.userRequstVerificaton !== true) {
      toast.error("Request can't be process becouse user is yet to submit document");
      return;
    }
    setloading(true);
    try {
      const d = backendUrl+ `vendorAction/users/${item.id}`
      await axios.put(d)
        .then((res) => {
          toast.success(res.data.message);
          setloading(true);
          setInterval(() => {
            window.location.reload()
          }, 2000);
        });
    } catch (error) {
      toast.error(error.message);
    }
    setloading(false);
  }

  // const handlegetBVNRecord = async (e) => {
  //   e.preventDefault();
  //   if (loginuser.role !== "admin") {
  //     toast.error("Permission Error");
  //     return;
  //   }

  //   try {
  //     setloadingbvn(true);
  //     setmessage("fetching data");
  //     const v =  backendUrl+ "sendUserBVNDATA/user/bvn/data"
  //    await axios
  //       .post(
  //        v,
  //         {
  //           bvn: item.userBVN,
  //         }
  //       )
  //       .then((e) => {
  
  //         // const status = e.data.data;
  //         const va = e.data.message;
         
  //         if (va !== "User data retrived successfully") {
  //           setmessage("No data found");
  //           return
  //         }
  //         setdatafund(e.data.data[0].payload.data);
  //         setmessage("Record Retrived");
  //       });
  //   } catch (error) {
  //     console.log(error.message);
  //   }

  //   setloadingbvn(false);
  // };

  const handleViewLogs = ()=>{
    history.push(`/logs/${item.id}`)
  }
  
  const handleuserTransactionPage = ()=>{
    history.push(`/profile/user-manager/${item.id}`)
  }

  return (
    <div>
      <ToastContainer />

      <Modal
        show={showfund}
        onHide={handleClosefund}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Fund Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicNumber">
            <Form.Label>Enter Amount</Form.Label>
            <Form.Control
              type="tel"
              onChange={(event) => {
                setamount(event.target.value);
              }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosefund}>
            Close
          </Button>
          <Button
            variant={loading ? "warning" : "primary"}
            disabled={loading}
            onClick={handlefundwallet}
          >
            {loading ? "Processing ..." : "Fund Wallet"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showview}
        onHide={handleCloseview}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {BvnPayload.length === 0 ? (
            <>{message}</>
          ) : (
            <>
              <div className="productdetaund">
                <div className="producttitle">
                  <strong>User firstname / Lastname</strong>
                </div>
                <div className="productonfprnf">{BvnPayload.full_name}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Address </strong>
                </div>
                <div className="productonfprnf">
                  {BvnPayload.address_line_2} <br />
                  {BvnPayload.address_line_3}
                </div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Phone Number </strong>
                </div>
                <div className="productonfprnf">{BvnPayload.phone}</div>
              </div>

              

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Account Status </strong>
                </div>
                <div className="productonfprnf">
                  {BvnPayload.watchlisted === false
                    ? "Account OK"
                    : "Flag User"}
                </div>
              </div>
              <p className="text-danger">
                If Information of this user is not correct or suspicious, dont
                act on it. else use the allow button bellow to grant user access
                to services
              </p>
            </>
          )}
          <div className="biewproductcontinaer">
            <h1>User Information</h1>

            <div className="productineofnfjf">
              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Name</strong>
                </div>
                <div className="productonfprnf">
                  {item.firstname} {item.lastname}
                </div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Wallet Balance</strong>
                </div>
                <div className="productonfprnf">{formatNumber(userwallets.wallet)}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Wallet Pending Balance</strong>
                </div>
                <div className="productonfprnf">
                  {formatNumber(userwallets.PendingBalance)}
                </div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Mobile No</strong>
                </div>
                <div className="productonfprnf">{item.number}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Email</strong>
                </div>
                <div className="productonfprnf">{item.email}</div>
              </div>

              {/* <div className="productdetaund">
                <div className="producttitle">
                  <strong>Get BVN Record</strong>
                </div>
                <div className="productonfprnf">
                  
                  <button disabled={bvnloading} onClick={handlegetBVNRecord}>
                    {bvnloading ? "Fetching User Record" : "Get Record"}
                  </button> <br/>
                  <span className="text-info">{message}</span>
                </div>
              </div> */}

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Status</strong>
                </div>
                <div className="productonfprnf">
                  {item.isBlock === false && item.status === "active" ? "active" : "Disable"}
                </div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Verification Status</strong>
                </div>
                <div className="productonfprnf">
                  {item.userverified === false ? "Not Verified" : "Verified"}
                </div>
              </div>

              {item.userRequstVerificaton === true ? (
                <>
                  <div className="productdetaund">
                    <div className="producttitle">
                      <strong>Image</strong>
                    </div>
                    <div className="productonfprnf">
                      <a href={item.image} target="_blank">
                        User Passport
                      </a>
                    </div>
                  </div>

                  {/* <div className="productdetaund">
                    <div className="producttitle">
                      <strong>Address Evidence</strong>
                    </div>
                    <div className="productonfprnf">
                      <a href={item.userProfAddress} target="_blank">
                        User Address Evidence
                      </a>
                    </div>
                  </div> */}

                  <div className="productdetaund">
                    <div className="producttitle">
                      <strong>Means of Identification</strong>
                    </div>
                    <div className="productonfprnf">
                      <a href={item.usermeansID} target="_blank" >
                        User Address Evidence
                      </a>
                    </div>
                  </div>
                </>
              ) : (
                "user Yet to submite required document for this process"
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="success" onClick={handleuserTransactionPage}>
            View Transaction
          </Button>

          {item.userverified === false ? <>
            <Button variant="primary" disabled={loading && item.userRequstVerificaton !== true} onClick={verifyUser}>
              {loading ? "Processing Request" : "Verify User"}
            </Button>

            <Button variant="danger" disabled={loading && item.userRequstVerificaton !== true} onClick={rejectverifyUser}>
              {loading ? "Processing Request" : "Reject User Request"}
            </Button>
          </> : <>
          {item.isBlock === true ? (
            <Button variant="primary" disabled={loading} onClick={handlealow}>
              {loading ? "Processing Request" : "Allow User"}
            </Button>
          ) : (
            <Button variant="danger" onClick={handlesuspendUser}>
              {loading ? "Processing Request" : "Suspend User"}
            </Button>
          )}
          </>}


         

          <Button variant="primary" onClick={handleShowfund}>
            Fund Wallet
          </Button>

          <Button variant="warning" onClick={handleViewLogs}>
            View Logs
          </Button>


          <Button variant="secondary" onClick={handleCloseview}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="giftcardocne">
      <h1>User Register</h1>
            <div className="girckahdicbd">
              <button onClick={history.goBack}>Back</button>
              <button className="ms-1">{usecollection.length} users</button>
              <button className="ms-1" onClick={handlecreatestatf}>Create Staff</button>
              <Form.Control
                type="text"
                placeholder="Search by Name"
                className="w-25 ms-1"
                onChange={(event) => {
                  setsearch(event.target.value);
                }}
              />
            </div>
        <div className="cardshowarea">
          <div className="cardvaljsjs">
        
            <table>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th className="hidetable">Created</th>
                  <th>Name</th>
                  <th className="hidetable">Mobile No.</th>
                  <th className="hidetable">Role</th>
                  <th className="hidetable">Ac/Status</th>
                  <th>Verification</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {records
                  .filter((value) => {
                    if (value === "") {
                      return value;
                    } else if (
                      value.firstname
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return value;
                    } else if (
                      value.lastname
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return value;
                    } else if (value.number.includes(search)) {
                      return value;
                    }
                    else if (value.email.includes(search)) {
                      return value;
                    }
                  })
                  .map((item, id) => {
                    return (
                      <tr key={id}>
                        <td>{id + 1}</td>

                        <td className="hidetable">
                              {item.created
                                ? format(item.created, "dd/MM/yyyy hh:mm a")
                                : ""}
                            </td>
                        <td>
                          {item.firstname} {item.lastname}
                        </td>
                       
                        <td className="hidetable">{item.number}</td>
                        <td className="hidetable">{item.role}</td>
                        <td className="hidetable">{item.userverified ===  true ? "Ac/verified" : "Ac/Pending"}</td>
                        <td >{item.userRequstVerificaton === true ?  "Submitted" : "Pending"}</td>
                        <td>
                          {item.isBlock === false ? "active" : "suspended"}
                        </td>
                        <td>
                          <ButtonGroup aria-label="Basic example">
                            <Button
                              variant="success"
                              onClick={Togglemodalview(item)}
                            >
                              View
                            </Button>
                            {/* <Button
                              variant="primary"
                              onClick={Togglemodal(item)}
                            >
                              Edit
                            </Button> */}
                          </ButtonGroup>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <nav>
              <ul className="pageination">
                <li className="page-item">
                  <span className="page-link" onClick={prepage}>
                    Prev
                  </span>
                </li>

                {numbers.splice(0,10).map((n, i) => {
                  return (
                    <li
                      key={i}
                      className={
                        currentPage === n ? "activenumber" : "page-item"
                      }
                    >
                      <span className="page-link" onClick={() => changecurrentPage(n)}>
                        {n<1 ? 1 : n}
                      </span>
                    </li>
                  );
                })}

                <li className="page-item">
                  <span className ="page-link" onClick={Nextpage}>
                    Next
                  </span>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserLog;
