import React, { useState } from "react";
import {
  AiFillCreditCard,
  AiOutlineMobile,
  AiOutlineVideoCamera,
} from "react-icons/ai";
import { FaExchangeAlt } from "react-icons/fa";
import { MdCall, MdOutlineCreditCard } from "react-icons/md";
import { GiElectric, GiReceiveMoney, GiTakeMyMoney } from "react-icons/gi";
import { useHistory } from "react-router-dom";
import "./QuickService.css"
import { useAuth } from "../../../Services/UserAuth";
import { formatNumber } from "../../../Services/Utility";


function Dashboard() {
  const history = useHistory();
  const {userwallet, loginuser} =  useAuth()
  
  const handlepayforme = () => {
    history.push(`/vendor/home/${loginuser.id}`);
  };

  // const handlegiftcard = () => {
  //   history.push("/service/Gift-Card")
  // };

  const handlebuydata = () => {
    history.push("/transaction/data")
  };

  const handleairtime = () => {
    history.push("/transaction/airtime")
  };

  const handleElectriict = () => {
    history.push("/transaction/vend/electricity")
  };

  const handlecable = () => {
    history.push("/transaction/cable-tv-subcription")
  };

  const handlebetting = () => {
    history.push("/transaction/games/betting")
  };

  const handleexam = () => {
    history.push("/transaction/exam/result-checker")
  };

  const handletransaer= () => {
    history.push("/transaction/wallet/transfer")
  };
  return (
    <div>
      <div className="forjaserveheader">
        <div className="serbocesndnd">
          <h3>Wallet Summary</h3>
          <span>Account Type: Tier {loginuser.tier}</span> <br />
          <span>Available Balance : &#8358; {formatNumber(userwallet.wallet)}</span> <br/>
          <span>Pending Balance : &#8358; {formatNumber(userwallet.PendingBalance)}</span> <br />
          <span>Transaction Limit : &#8358; {formatNumber(loginuser.transactionLimit)} <span className="text-danger chanecjhsisd"> (To perform transaction above this limit, kindly upgrade account or contact admin for assistance)</span></span> <br />  
        </div>
      </div>
      <div className="qucksersicontainer">
        <div className="quickserviceconent">
        <div className="carsskdbeodmd" onClick={handletransaer}>
            <GiTakeMyMoney />
            <h1>Transfer</h1>
          </div>

          <div className="carsskdbeodmd" onClick={handlepayforme}>
            <FaExchangeAlt />
            <h1> {loginuser.role === "vendor" ?  "Marchant Pay-out" : "Marchant Payment"}  </h1>
          </div>

          {/* <div className="carsskdbeodmd" onClick={handlegiftcard}>
            <AiFillCreditCard />
            <h1> Gift Card</h1>
          </div> */}

          <div className="carsskdbeodmd" onClick={handlebuydata}>
            <AiOutlineMobile />
            <h1> Buy Internet Data</h1>
          </div>

          <div className="carsskdbeodmd" onClick={handleairtime}>
            <MdCall />
            <h1>Buy Mobile Airtime</h1>
          </div>

          <div className="carsskdbeodmd" onClick={handleElectriict}>
            <GiElectric />
            <h1>Pay Electricity Bill</h1>
          </div>

          <div className="carsskdbeodmd" onClick={handlecable}>
            <AiOutlineVideoCamera />
            <h1>Cable Tv Subscription</h1>
          </div>

       
          <div className="carsskdbeodmd" onClick={handlebetting}>
            <GiReceiveMoney />
            <h1>Betting</h1>
          </div>

          <div className="carsskdbeodmd" onClick={handleexam}>
            <MdOutlineCreditCard />
            <h1>Exam Card</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
