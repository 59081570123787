import React from 'react'
import { useEffect } from 'react'
import { useAuth } from '../Services/UserAuth';


export default function Logoutrout() {
    const { logout} = useAuth();
    

    const lof =async()=>{
        await logout().then(() => {
            window.location.href="/auth/login"  
          })
    }
    useEffect(() => {
        lof()  
    }, [])
    
  return (
    <div></div>
  )
}
