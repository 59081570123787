import React, { useEffect, useState } from "react";
import "./Gift.css";
import { Form, InputGroup } from "react-bootstrap";
import axios from "axios";
import { formatNumber } from "../../Services/Utility";
import { backendUrl } from "../../Services/firebase/firebase";
import { getCards } from "../../Services/GetUser.service";
function GiftcardLayer() {
  const [datacollection, setdatacollection] = useState([])
  const [card, setcard] = useState("")
  const [getsubcard, setsubcard] = useState([])
  const [subcategory, setsubcategorycard] = useState("")
  const [amount, setamount] = useState(1)
  const [currency, setcurrency] = useState("NG")
  const [icon, seticon] = useState("NG")
  const [cardcollection, setcardcollection] = useState([])
  const [search, setsearch] =  useState("")
  const [datacol, setdatacoll] =  useState([])

  const getcard = async () => {

    await axios
      .get(
        backendUrl + "creategiftHandler/create/gift-card"
      )
      .then((e) => {
        const datares =  e.data
        const v = datares.filter((e) => e.card === card)
        const subcard = v.filter((e) => e.status === true)
        setsubcard(subcard)
        const dara = datares.filter((e) => e.status === true)
        setdatacoll(dara)
        setdatacollection(dara.sort())
      })
      .catch((e) => { });
  };

  useEffect(() => {

  
    getcard();

    getCards("", (result) => {
      setcardcollection(result)
    })

    if (isNaN(amount)) {
      setamount(0.00)
    }
  }, [card, amount]);
  const getcardsale = getsubcard.filter((e) => e.cardname === subcategory)[0]

  const total = getcardsale ? parseFloat(amount) * parseFloat(getcardsale.price) : 0.00

  // const datasoyrt = getsubcard.sort(function (a, b) {
  //   if (a.name < b.name) {
  //     return -1;
  //   }
  //   if (a.name > b.name) {
  //     return 1;
  //   }
  //   return 0;
  // });
  const data = datacol.sort(() => Math.random() - 0.5);

  return (
    <div>
      <div className="giftcardsiald">

      </div>
      <div className="giftcardcalculatior">
        <h1> GiftCard Rate Calculator</h1>

        <center>
          <span>
            Enter your gift card details in each field below to calculate how
            much naira you will be paid for gift cards on
            Kitipay
          </span>
        </center>

        <div className="formcalculatrord">
          <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
            <Form.Label>Select Category</Form.Label>
            <Form.Select aria-label="Default select example" className="p-3 fs-5" onChange={(event) => {
              setcard(event.target.value)
            }}>
              <option value="">Select Category</option>

              {cardcollection.map((item) => {
                return <option value={item.name}>{item.name}</option>
              })}


            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
            <Form.Label>Select Sub-Category</Form.Label>
            <Form.Select aria-label="Default select example" className="p-3 fs-5" disabled={card === ""} onChange={(event) => {
              setsubcategorycard(event.target.value)
            }}>
              <option value="">Select Category</option>

              {getsubcard.map((item) => {
                return <option value={item.cardname}>{item.cardname}</option>
              })}


            </Form.Select>
          </Form.Group>

          <Form.Label>Currency</Form.Label>
          <Form.Select aria-label="Default select example" className="p-3 fs-5 " disabled={subcategory === "" || card === ""} onChange={(event) => setcurrency(event.target.value)}>
            <option>Select Currency</option>
            <option value="NG">Naira</option>
          </Form.Select>
          <Form.Label>Amount</Form.Label>


          <Form.Control
            disabled={subcategory === "" || card === ""}
            placeholder="O.00"
            aria-label="Username"
            aria-describedby="basic-addon1"
            onChange={(event) => setamount(event.target.value)}
            className="p-3 fs-5 "
          />

          <div className="totalcontner">
            <div className="totalsod">
              <p><strong className="text-primary fw-bolder">Total</strong>: {currency === "NG" ? <>&#8358;</> : <>&#8373;</>} {total ? formatNumber(total) : 0.00}</p>
            </div>

            <div className="totalsod">
              <button>Trade Now..</button>
            </div>
          </div>

        </div>

      </div>

      <div className="giftcardcalculatior totpssjdjhd">

        <h1>Kitipay have the best Gift Card Rate</h1>
        <center><span>Check our Gift card Price listing below</span></center>

        <div className="giftcardocne">
          <div className="cardshowarea">
            <div className="cardvaljsjs">
              <h1>Gift Card History</h1>
              <div className="seachgitfcar">
              <Form.Control
            type="text"
            placeholder="Search by card name"
            className="w-25 ms-1"
            onChange={(event) => {
              setsearch(event.target.value);
            }}
          />
              </div>
              <table className="sjdghdlhdjdd">
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Card Name</th>
                    <th>Sub Category</th>
                    <th >Price</th>

                  </tr>
                </thead>
                <tbody>
                  {data.slice(0, 20).map((item, id) => {
                    return (
                      <tr key={id}>
                        <td>{id + 1}</td>
                        <td>{item.card}</td>
                        <td>{item.cardname}</td>
                        <td>
                          {formatNumber(item.price)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default GiftcardLayer;
