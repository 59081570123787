import React from 'react'
import { Link } from 'react-router-dom'
import Generalcomplaince from '../ClientComponent/ComplainceCom'
import Sidebar from '../ClientComponent/Sidemenue'
import "../ClientComponent/Complaince.css"

function ComplainceGeneral() {
  return (
    <div>
          <div className="pagecon">
      <div className="pagecontent">
        <div className="headersisecoadmin">
        <Sidebar/>
        </div>
        <div className="otherssecoth">
            <Generalcomplaince/>
        </div>
      </div>
    </div>
    </div>
  )
}

export default ComplainceGeneral
