
import AdminSidemenu from "../AdminComponent/AdminSidemenue"
import React from "react";
import { useHistory } from "react-router-dom";

import "../../Sidemiindex.css";
import SupportAdmin from "../AdminComponent/SupportAdmin";

function Dashboard() {
const history = useHistory()
  return (
    <div>
    <div className="pagecon">
      <div className="pagecontent">
        <div className="headersisecoadmin">
        <AdminSidemenu/>
        </div>
        <div className="otherssecoth allalla">
        <div className="dashboardconteier">
        <SupportAdmin/>
      </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Dashboard;