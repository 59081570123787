import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Form, Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import {
  getOrder,
  getVendorMessage,
  gettransactin,
  getuser,
} from "../../../Services/GetUser.service";
import { useEffect } from "react";
import {
  backendUrl,
  baseUrl,
  storage,
} from "../../../Services/firebase/firebase";
import "./Machange.css";
import { useAuth } from "../../../Services/UserAuth";
import { formatNumber } from "../../../Services/Utility";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import OrderlOg from "./OrderlOg";
import { getProducts } from "../../../Services/GetUser.service";

function MarchantProfilePage() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [user, setuser] = useState([]);
  const [firstword, setfirstword] = useState("");
  const { userwallet } = useAuth();
  const history = useHistory();
  const id = useParams().id;
  const [file, setfile] = useState(null);
  const [file1, setfile1] = useState(null);
  const [progress, setProgress] = useState();
  const [message, setmessage] = useState("");
  const [welcomemessage, setwelcome] = useState("");
  const [loading, setloading] = useState(false);
  const [editedinformation, seteditedinfomration] = useState("");
  const [ordercount, setordercount] = useState([]);
  const [transcount, setTranscount] = useState([]);
  const [productCOunt, setproduct] = useState([]);
  const [vendorMessage, setvendorMessage] = useState([]);
  const [vendorrecipt, setusercollection] = useState([]);

  useEffect(() => {
    getuser(id, (result) => {
      if (result) {
        let myStr = result.businessname;
        let firstWord = myStr.split(" ")[0];
        setfirstword(firstWord);
        setuser(result);
        return;
      }
      setuser([]);
    });

    getOrder((result) => {
      const order = result.filter(
        (e) => e.vendorID === id && e.is_treated === false
      );
      setordercount(order);
    });

    gettransactin((trans) => {
      const Trans = trans.filter((e) => e.userID === id);
      setTranscount(Trans);
    });

    getProducts((trans) => {
      const Trans = trans.filter((e) => e.userID === id);
      setproduct(Trans);
    });
    getVendorMessage((trans) => {
      const Trans = trans.filter((e) => e.vendorid === id);
      setvendorMessage(Trans);
    });
  }, []);

  useEffect(() => {
    axios
      .get(backendUrl + `createVendorRequest/vendor/services/${id}`)
      .then((res) => {
        const v = res.data.payload;
        if (v) {
          setusercollection(res.data.payload);
        } else {
          setusercollection([]);
        }
      });
  }, []);

  const handleCKa = (e) => {
    e.preventDefault();
    history.push(`/store/${firstword}/${user.agentID}`);
  };

  const handlechangeImage = (e) => {
    if (e.target.files[0]) {
      setfile(e.target.files[0]);
      return;
    }
  };

  const handlechangeImage1 = (e) => {
    if (e.target.files[0]) {
      setfile1(e.target.files[0]);
      return;
    }
  };

  function handleUploadimage() {
    return new Promise((resolve) => {
      const uploadTask = storage.ref(`ProductImage/${file.name}`).put(file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          setmessage(error);
        },
        () => {
          storage
            .ref("ProductImage")
            .child(file.name)
            .getDownloadURL()
            .then((url) => {
              resolve(url);
            });
        }
      );
    });
  }

  function handleUploadimage1() {
    return new Promise((resolve) => {
      const uploadTask = storage.ref(`ProductImage/${file1.name}`).put(file1);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          setmessage(error);
        },
        () => {
          storage
            .ref("ProductImage")
            .child(file1.name)
            .getDownloadURL()
            .then((url) => {
              resolve(url);
            });
        }
      );
    });
  }

  const handlesetup = async (e) => {
    e.preventDefault();
    if (welcomemessage === "") {
      toast.error("Enter welcome message");
      return;
    }

    setloading(true);
    const logo = await handleUploadimage();
    const banner = await handleUploadimage1();
    await axios
      .post(
        `https://us-central1-kitipay-stagging.cloudfunctions.net/vendorsetup`,
        {
          welcomemessage: welcomemessage,
          bannerimage: banner,
          businessLogo: logo,
          userID: user.id,
        }
      )
      .then((res) => {
        toast.success("Store setup completed successfull");
      });
    setloading(false);
  };

  const handleEdit = async () => {
    if (editedinformation === "") {
      toast.error("Select Preferred action");
      return;
    }

    if (editedinformation === "1") {
      setloading(true);
      await axios
        .put(
          `https://us-central1-kitipay-stagging.cloudfunctions.net/vendorsetup/${user.id}`,
          {
            welcomemessage: welcomemessage,
            type: "welcom",
          }
        )
        .then((res) => {
          toast.success("update completed successfull");
        });
        setloading(false)
      return;
    }

    if (editedinformation === "2") {
      setloading(true);
      const banner = await handleUploadimage1(); 
      
      await axios
        .put(
          `https://us-central1-kitipay-stagging.cloudfunctions.net/vendorsetup/${user.id}`,
          {
            bannerimage: banner,
            type: "banner",
          }
        )
        .then((res) => {
      
          toast.success("Update completed successfull");
        });
        setloading(false);
      return;
    }

    if (editedinformation === "3") {
      
      setloading(true);
      const logo = await handleUploadimage();
      await axios
        .put(
          `https://us-central1-kitipay-stagging.cloudfunctions.net/vendorsetup/${user.id}`,
          {
            businessLogo: logo,
            type: "logo",
          }
        )
        .then((res) => {
         
          toast.success("update successfull");
        });
        setloading(false);
      return;
    }
  };



  return (
    <div>
      <ToastContainer />
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Body>
          <div className="imagesocnss">
            <h1>Edit Store Information</h1>

            <Form.Select
              aria-label="Default select example"
              onChange={(event) => {
                seteditedinfomration(event.target.value);
              }}
            >
              <option>Open </option>
              <option value="1"> Welcome Message</option>
              <option value="2">Banner</option>
              <option value="3">Logo</option>
            </Form.Select>

            <div
              className="selectedcpaksdjdnjd"
              style={{ display: editedinformation === "1" ? "block" : "none" }}
            >
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Welcome Message</Form.Label>
                <Form.Control
                  as="textarea"
                  onChange={(event) => setwelcome(event.target.value)}
                  maxLength={1000}
                  rows={3}
                />
              </Form.Group>
              <span>
                This message is what customer will see when the come to your
                store
              </span>
            </div>

            <div
              className="selectedcpaksdjdnjd"
              style={{ display: editedinformation === "3" ? "block" : "none" }}
            >
              <Form.Group
                controlId="formFile"
                className="mb-3"
                style={{
                  display: editedinformation === "3" ? "block" : "none",
                }}
              >
                <Form.Label>Upload business Logo</Form.Label>
                <Form.Control type="file" onChange={handlechangeImage} />
              </Form.Group>
            </div>

            <div
              className="selectedcpaksdjdnjd"
              style={{ display: editedinformation === "2" ? "block" : "none" }}
            >
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Upload business Banner</Form.Label>
                <Form.Control type="file" onChange={handlechangeImage1} />
              </Form.Group>
            </div>

            <Button
              variant="primary"
              onClick={handleEdit}
              disabled={loading}
              type="submit"
            >
              {loading ? "update in progress" : "Save"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <div className="headersectionsall">
        <div className="reactmanecond">
          <div className="margchageimformatuon">
            <div className="marsconahdd">
              <div className="prodifiuosjd">
                <div className="imagesoidndpasswkjs">
                  {user.logo ? (
                    <>
                      <div className="aothwrinfonr111">
                      <div class="containerp">
    
    <div class="row d-flex justify-content-center">
        
        <div class="col">
            
            <div class="card  py-4">
                
                <div class="text-center">
                    <img src={user.logo} width="100" alt="logo"/>
                </div>
                
                <div class="text-center mt-3">
                  
                    <h5 class="mt-2 mb-0">{user.businessname}</h5>
                    <span>{user.role}</span>
                    
                    <div class="px-4 mt-1">
                        <p class="fonts">{user.welcomemessage}</p>
                    
                    </div>
{/*                     
                     <ul class="social-list">
                        <li><i class="fa fa-facebook"></i></li>
                        <li><i class="fa fa-dribbble"></i></li>
                        <li><i class="fa fa-instagram"></i></li>
                        <li><i class="fa fa-linkedin"></i></li>
                        <li><i class="fa fa-google"></i></li>
                    </ul> */}
                    
                    <div class="buttons">
                        
                        <button class="btn btn-outline-primary px-4"onClick={handleCKa}>Go to store</button>
                        <button class="btn btn-primary px-4 ms-3"onClick={handleShow}>Edit store</button>
                    </div>
                    
                    
                </div>
                
               
                
                
            </div>
            
        </div>
        
    </div>
    
</div>
                        {/* <img src={user.logo} alt="" />
                      <p className='py-2'><strong className='text-dark'>Intro</strong> <br />{user.welcomemessage}</p>
                      <p className='text-success'></p>
                      <button onClick={handleCKa}>Go to store</button>
                      <button className='ms-2' onClick={handleShow}>Edit</button> */}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="imagesocnss">
                        <h1>Store Setup config</h1>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label>Welcome Message</Form.Label>
                          <Form.Control
                            as="textarea"
                            onChange={(event) => setwelcome(event.target.value)}
                            maxLength={1000}
                            rows={3}
                          />
                        </Form.Group>
                        <span>
                          This message is what customer will see when the come
                          to your store
                        </span>

                        <Form.Group controlId="formFile" className="mb-3">
                          <Form.Label>Upload business Logo</Form.Label>
                          <Form.Control
                            type="file"
                            onChange={handlechangeImage}
                          />
                        </Form.Group>

                        <Form.Group controlId="formFile" className="mb-3">
                          <Form.Label>Upload business Banner</Form.Label>
                          <Form.Control
                            type="file"
                            onChange={handlechangeImage1}
                          />
                        </Form.Group>

                        <Button
                          variant="primary"
                          onClick={handlesetup}
                          disabled={loading}
                          type="submit"
                        >
                          {loading ? "Setup in progress" : "Save"}
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="heacondaidjd">
            <h1>Menu</h1>
            <div className="menuesectionmsod">
              <Link to="/contact/order/message">
                message <span>{vendorMessage.length}</span>
              </Link>
              <Link to="/store/order">
                Order <span>{ordercount.length}</span>
              </Link>
              <Link to="/user/transaction">
                Transactions <span>{transcount.length}</span>
              </Link>
              <Link to="/marchant/product">
                Product <span>{productCOunt.length}</span>
              </Link>
            
            </div>
            <div className="forjaserveheader">
              <div className="serbocesndnd1">
                <h3 className="fs-5 fw-bold py-2">Wallet Summary</h3>
                <span>
                  Available Balance : &#8358; {formatNumber(userwallet.wallet)}
                </span>{" "}
                <br />
                <span>
                  Pending Balance : &#8358;{" "}
                  {formatNumber(userwallet.PendingBalance)}
                </span>
              </div>
            </div>
          </div>

          <div className="jiodudcommintt">
            <div className="poircomajdd">
              <h1>Quick Service Link</h1>
              <div className="menuesectionmsod">
                <Link to="/transaction/wallet/transfer">Transfer </Link>
                <Link to="/service/Gift-Card"> Buy Mobile Airtime </Link>
                <Link to="/transaction/data">Data Bundle </Link>
                <Link to="/transaction/cable-tv-subcription">
                  Cable Tv Subscription{" "}
                </Link>
                <Link to="/transaction/games/betting">Betting </Link>
                <Link to="/service/Gift-Card"> Sell Gift Card </Link>
                <Link to="/transaction/vend/electricity">
                  {" "}
                  Pay Electricity Bill
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="orderconayhdbf">
          <div className="porderodnd">
            <OrderlOg />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarchantProfilePage;
