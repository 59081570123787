import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "./Services.css";
import Progre from "./DeliverPassion";
import { MdFlight, MdOutlineSettings, MdComputer } from "react-icons/md";
import { TbShip } from "react-icons/tb";
import { BiWorld } from "react-icons/bi";
import { BsTruck } from "react-icons/bs";
import NewsLetter from "../NewsLetter";

function Services() {
  return (
    <div>
      <Navbar />
      <div className="servi" style={{ backgroundImage: "url(/img/b.jpg)" }}>
        <div className="servicecontent">
          <div className="serbocerid">
            <div className="serviceheader">Explore our services</div>
            <div className="gridcontainer">
              <div className="servicegrid">
                <div className="iconsectionservice">
                  <div className="iconservice">
                    <MdFlight />
                  </div>
                  <div className="icontittle">Buy and Sell Gift Cards</div>
                </div>
                <div className="inforservice">
                  Most profitable and customer-focused gift card trading app in
                  Nigeria. Stop trading your giftcard at a loss!
                </div>
              </div>

              <div className="servicegrid">
                <div className="iconsectionservice">
                  <div className="iconservice">
                    <TbShip />
                  </div>
                  <div className="icontittle">P2P Payments</div>
                </div>
                <div className="inforservice">
                  Tap into peer-to-peer trading network. Embrace more secure and
                  private transactions.
                </div>
              </div>

              <div className="servicegrid">
                <div className="iconsectionservice">
                  <div className="iconservice">
                    <BiWorld />
                  </div>
                  <div className="icontittle">Airtime and Data Sales</div>
                </div>
                <div className="inforservice">
                  Stay connected with the internet. Buy cheap data and resell
                  cheap SME data on Kiti App. Get cheapest data bundles for all
                  networks.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewsLetter/>
      <Progre />
      <Footer />
    </div>
  );
}

export default Services;
