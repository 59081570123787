import React from "react";
import "./MyCss.css";
import { Link, useHistory, useParams } from "react-router-dom";
import Navbar from "../Client/Navbaronly";
import { useState } from "react";
import { Alert, Form, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { backendUrl, database } from "../../Services/firebase/firebase";
import axios from "axios";
import { getRandomString } from "../../Services/GetRandomNumber";
import { getUserByUrl } from "../../Services/GetUser.service";
import { useEffect } from "react";
function Forgetpassword() {
  const history = useHistory();
  const baseURL = backendUrl + "createNewUserKitiPay";
  const [message, setmessage] = useState("");
  const [email, setemail] = useState();
  const [loading, setloading] = useState(false);
  const [password, setpassword] = useState();
  const [confirmpassword, setconfirmpassword] = useState();
  const [authuser, setAuthUser] = useState();
  const [OTP,setOTP] =  useState()

  const url = useParams().UniquieNum;

  useEffect(() => {
    getUserByUrl(url, (res) => {
      const response = res[0];
      if (!response) {
        return;
      }
      setAuthUser(response);
    });
  }, []);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (!password || !email || !confirmpassword || !OTP) {
      toast.error("fil and Email required");
      return;
    }

    if (email !== authuser.email) {
      toast.error("No user found,please try again");
      return;
    }

    if (password !== confirmpassword) {
      toast.error("password mismatch");
      return;
    }

    if (OTP !== authuser.code){
      toast.error("parse error,please try again")
      return
    }

    setloading(true);
   
    await database
      .doc(`resetpassword/${authuser.id}`)
      .update({
        password,
      })
      .then(async () => {
      
        await axios
          .get(
            backendUrl + `resetUserAccount/debit/user/${authuser.id}`
          )
          .then(async(res) => {
            toast.success("Operation Successful, Please login")
            setInterval(() => {
                window.location.href= "/login"
            }, 2000);
          });
      });

    setloading(false)
  };

  const loginpage = "/login"

  if (!authuser){
    return<>
      <div className="contacenter">
      expired link please contact admin for assistance or go back&nbsp; <Link to={loginpage}> Home</Link>
      </div>
    </>
  }

  return (
    <div>
      <ToastContainer />
      <div
        div
        className="backgroisns"
        style={{ backgroundImage: "url(/img/b.jpg)" }}
      >
        <div className="usercontainerbody">
          <div class="usercontainer">
            <center className="py-2">
              <div class="d-inline-block auth-logo">
                <img src="/img/logo.png" alt="JSLPS image" height="50" />
              </div>
            </center>
            {message ? (
              <Alert variant="info">
                <p>{message}</p>
              </Alert>
            ) : (
              ""
            )}

            <Form>
              <p>Please enter your email and your new password</p>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  onChange={(event) => {
                    setemail(event.target.value);
                  }}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicNumber">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  onChange={(event) => {
                    setpassword(event.target.value);
                  }}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicNumber">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  onChange={(event) => {
                    setconfirmpassword(event.target.value);
                  }}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicNumber">
                <p>Please check your register email for your OTP</p>
                <Form.Label>OTP</Form.Label>
                <Form.Control
                  type="password"
                  onChange={(event) => {
                    setOTP(event.target.value);
                  }}
                  required
                />
              </Form.Group>
              <Button
                variant={loading ? "warning" : "primary"}
                type="submit"
                onClick={handleResetPassword}
                disabled={loading}
                className="w-100 py-2 my-2"
              >
                {loading ? "Please Wait .." : "Proceed ..."}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Forgetpassword;
