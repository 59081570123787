import React, { useCallback, useEffect, useState } from "react";
import { Button, ButtonGroup, Form, Modal } from "react-bootstrap";
import { SendNotification, getGiftcard, getuser } from "../../../Services/GetUser.service";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../Services/UserAuth";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { formatNumber } from "../../../Services/Utility";
import { database } from "../../../Services/firebase/firebase";
import firebase from "firebase";
import { getRandomString } from "../../../Services/GetRandomNumber";
import { backendUrl } from "../../../Services/firebase/firebase"
import { format, set } from "date-fns";

function GiftcardRequest() {
  const [usecollection, setusercollection] = useState([]);
  const { loginuser, userwallet } = useAuth();
  const { uid } = useAuth().currentUser
  const [search, setsearch] = useState("");
  const history = useHistory();
  const [item, setitem] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [user, setuser] = useState([]);
  const [loading, setloading] = useState(false);
  const [res, setres] = useState("");
  const [currentPage, setcurrentpage] = useState(1);
  const recordpage = 20;
  const lastIndex = currentPage * recordpage;
  const firstIndex = lastIndex - recordpage;
  const records = usecollection.slice(firstIndex, lastIndex);
  const npage = Math.ceil(usecollection.length / recordpage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [sellitem, setsellitem] = useState([])
  const [showsellitem, setShowsetitem] = useState(false);
  const handleCloseseltietm = () => setShowsetitem(false);
  const handleShowsellitem = () => setShowsetitem(true);
  const [amountsold, setsoldamount] = useState(0)


  useEffect(() => {
    getGiftcard((res) => {
      setusercollection(res);
    });
  }, []);
  const prepage = () => {
    if (currentPage !== 1) {
      setcurrentpage(currentPage - 1);
    }
  };

  const Nextpage = () => {
    if (currentPage !== npage) {
      setcurrentpage(currentPage + 1);
    }
  };

  const changecurrentPage = (id) => {
    setcurrentpage(id);
  };




  const Togglemodalview = useCallback(
    (item) => () => {

      getuser(item.userID, (res) => {
        setuser(res);
      });
      setitem(item);
      handleShow();
    },
    []
  );

  const Togglemodalviewsell = useCallback(
    (item) => () => {
      if(item.adminsell ===  true){
        return
      }
      getuser(item.userID, (res) => {
        setuser(res);
      });
      setsellitem(item);
      handleShowsellitem();
    },
    []
  );




  const handleApprove = async (e) => {
    e.preventDefault()
 
    if (loginuser.role !== "admin") {
      toast.error("permission error");
      return;
    }

    if (item.isclosed === true) {
      toast.error("Request treated");
      return;
    }
    const UniquieNum = getRandomString(20, "1234567890");
    setloading(true);
    setres("Intializing")
    await axios
      .post(
        backendUrl + `debitadnubAccount/debit/user`,
        {
          id: loginuser.id,
          amount: parseFloat(item.total),
          trackNo: UniquieNum,
          type:"debit"
        }
      ).then(async (res) => {
      
        setres("Calculation in progress please wait")
        if (res.data.res === "success") {
          const ref = database.collection("_").doc().id;
          const data = {
            id: user.id,
            role: loginuser.role,
            amount: item.total,
          };
          const datas = {
            id: ref,
            company: item.card,
            network: item.subcategory,
            customername: "no value",
            address: "no value",
            userID: user.id,
            AccountNumber: user.number,
            amount: item.total,
            Debitamount: item.total,
            customermisson: 0,
            com: 0,
            TranRef: UniquieNum,
            phone: user.number,
            type: "Gift-card",
            status: "success",
            isclosed: true,
            created: firebase.firestore.FieldValue.serverTimestamp(),
          };

          const promises = [
            axios
              .post(
                backendUrl + "credituser/users/wallet",
                {
                  data,
                }
              )
              .then(async (r) => {
                await database
                  .doc(`/GiftCardRequest/${item.id}`)
                  .update({
                    isApproved: true,
                    isclosed: true,
                    message:
                      "Gift card verification is now completed, kindly check your wallet for the value.",
                  })
                  .then(async (e) => {
                    setres("Sending Payload to user");
                    const homeUrl = "https://kitipay.com/";
                    const fullname = `${user.firstname} ${user.lastname}`;
                    const logo = "https://i.ibb.co/5xqth9r/logo.png";
                    const content = `<div marginheight="0" topmargin="0" marginwidth="0" style="margin: 0px; background-color: #f2f3f8;" leftmargin="0">
              <table cellspacing="0" border="0" cellpadding="0" width="100%" background-color="#f2f3f8"
                  style="font-family: 'Open Sans', sans-serif;">
                  <tr>
                      <td>
                          <table style="background-color: #f2f3f8; max-width:670px;  margin:0 auto;" width="100%" border="0"
                              align="center" cellpadding="0" cellspacing="0">
                              <tr>
                                  <td style="height:80px;">&nbsp;</td>
                              </tr>
                              <tr>
                                  <td style="text-align:center;">
                                    <a href=${homeUrl} title="logo" target="_blank">
                                     KITIPAY 
                                    </a>
                                  </td>
                              </tr>
                              <tr>
                                  <td style="height:20px;">&nbsp;</td>
                              </tr>
                              <tr>
                                  <td>
                                      <table width="95%" border="0" align="center" cellpadding="0" cellspacing="0"
                                          style="max-width:670px;background:#fff; border-radius:3px; text-align:center;-webkit-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);-moz-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);box-shadow:0 6px 18px 0 rgba(0,0,0,.06);">
                                          <tr>
                                              <td style="height:40px;">&nbsp;</td>
                                          </tr>
                                          <tr>
                                              <td style="padding:0 35px;">
                                                  <h1 style="color:#1e1e2d; font-weight:500; margin:0;font-size:32px;font-family:'Rubik',sans-serif;">Gift card sales verifcation</h1>
                                                  <span
                                                      style="display:inline-block; vertical-align:middle; margin:29px 0 26px; border-bottom:1px solid #cecece; width:100px;"></span>
                                                  <p style="color:#455056; font-size:15px;line-height:24px; margin:0;">
                                                  Hope this email meet you well ${fullname} <br/>
                                                  kindly be informed that we have verified your gift card and your wallet will be credited shortly. we hope to see you more 
                                                  Thanks
                                                  <br/>
                                                  <br/>
                                                  Jennet Kalu
                                                  Sale Manager
                                                  </p>
                                                  
                                              </td>
                                          </tr>
                                          <tr>
                                              <td style="height:40px;">&nbsp;</td>
                                          </tr>
                                      </table>
                                  </td>
                              <tr>
                                  <td style="height:20px;">&nbsp;</td>
                              </tr>
                              <tr>
                                  <td style="text-align:center;">
                                      <p style="font-size:14px; color:rgba(69, 80, 86, 0.7411764705882353); line-height:18px; margin:0 0 0;">&copy; <strong>${homeUrl}</strong></p>
                                  </td>
                              </tr>
                              <tr>
                                  <td style="height:80px;">&nbsp;</td>
                              </tr>
                          </table>
                      </td>
                  </tr>
              </table>
             
        
              </div>`;
                    const CampURL =
                      backendUrl + "sendEmailCampkitipay";
                    await axios.post(CampURL, {
                      content,
                      logo,
                      fullname,
                      email: user.email,
                      firstname: user.firstname,
                      url: "",
                      subject: "Gift-card Confirmation",
                    });


                    toast.success("user Account Credited Successfully");
                  });
              }),
           await  database.collection("Transaction").doc(datas.id).set(datas),
          ];
          setres("Finalizing process");
          await Promise.all(promises)
          const nofifyID = getRandomString(6, "1234567890")
          const notificationBody = {
            message: `Gift card confirmation completed successfully.. kindly confirm recipent through your wallet`,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            isread: false,
            id: nofifyID,
            userID:"",
            isRi: 0
          }
        await SendNotification(notificationBody, user.id, "both") 
          toast.success("user Account Credited Successfully");
          handleClose()
        }
        setres(res.data.res)
        toast.error(res.data.res)
        setloading(false)

      })


  };

  const handleReject = async () => {
    if (loginuser.role !== "admin") {
      toast.error("permission error");
      return;
    }

    if (item.isclosed === true) {
      toast.error("Request treated");
      return;
    }
    // if (user.isverified !== true || user.userverified !== true) {
    //   toast.error("user Account under restriction");
    //   return;
    // }
    setloading(true);
    setres("Updating user record");
    try {
      const UniquieNum = getRandomString(20, "1234567890");
      const ref = database.collection("_").doc().id;
      const data = {
        id: ref,
        company: item.card,
        network: item.subcategory,
        customername: "no value",
        address: "no value",
        userID: user.id,
        AccountNumber: user.number,
        amount: item.total,
        phone: user.number,
        type: "Gift-card",
        status: "fail",
        isclosed: true,
        TranRef: UniquieNum,
        created: firebase.firestore.FieldValue.serverTimestamp(),
      };

      const promises = [
        database
          .doc(`/GiftCardRequest/${item.id}`)
          .update({
            isApproved: false,
            isclosed: true,
            message:
              "Kindly be informed that your gift-card information can't be verified, kindly check and try again.",
          })
          .then(async (e) => {
            setres("Finalizing process");
            const homeUrl = "https://kitipay.com/";
            const fullname = `${user.firstname} ${user.lastname}`;
            const logo = "https://i.ibb.co/5xqth9r/logo.png";
            const content = `<div marginheight="0" topmargin="0" marginwidth="0" style="margin: 0px; background-color: #f2f3f8;" leftmargin="0">
        <table cellspacing="0" border="0" cellpadding="0" width="100%" background-color="#f2f3f8"
            style="font-family: 'Open Sans', sans-serif;">
            <tr>
                <td>
                    <table style="background-color: #f2f3f8; max-width:670px;  margin:0 auto;" width="100%" border="0"
                        align="center" cellpadding="0" cellspacing="0">
                        <tr>
                            <td style="height:80px;">&nbsp;</td>
                        </tr>
                        <tr>
                            <td style="text-align:center;">
                              <a href=${homeUrl} title="logo" target="_blank">
                               KITIPAY 
                              </a>
                            </td>
                        </tr>
                        <tr>
                            <td style="height:20px;">&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <table width="95%" border="0" align="center" cellpadding="0" cellspacing="0"
                                    style="max-width:670px;background:#fff; border-radius:3px; text-align:center;-webkit-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);-moz-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);box-shadow:0 6px 18px 0 rgba(0,0,0,.06);">
                                    <tr>
                                        <td style="height:40px;">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td style="padding:0 35px;">
                                            <h1 style="color:#1e1e2d; font-weight:500; margin:0;font-size:32px;font-family:'Rubik',sans-serif;">Failed Confirmation</h1>
                                            <span
                                                style="display:inline-block; vertical-align:middle; margin:29px 0 26px; border-bottom:1px solid #cecece; width:100px;"></span>
                                            <p style="color:#455056; font-size:15px;line-height:24px; margin:0;">
                                            Hope this email meet you well ${fullname} <br/>
                                            kindly be informed that we are unable to confirm your card, if you think this is unusual kindly contact support 
                                            Thanks
                                            <br/>
                                            <br/>
                                            Jennet Kalu <br/>
                                            Sale Manager
                                            </p>
                                            
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="height:40px;">&nbsp;</td>
                                    </tr>
                                </table>
                            </td>
                        <tr>
                            <td style="height:20px;">&nbsp;</td>
                        </tr>
                        <tr>
                            <td style="text-align:center;">
                                <p style="font-size:14px; color:rgba(69, 80, 86, 0.7411764705882353); line-height:18px; margin:0 0 0;">&copy; <strong>${homeUrl}</strong></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="height:80px;">&nbsp;</td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
       
  
        </div>`;
            const CampURL =
              backendUrl + "sendEmailCampkitipay";
            await axios.post(CampURL, {
              content,
              logo,
              fullname,
              email: user.email,
              firstname: user.firstname,
              url: "",
              subject: "Gift-card Confirmation",
            });
            toast.success("Card Request Rejected Successfully");
          }),

        database.collection("Transaction").doc(data.id).set(data),
      ];

      await Promise.all(promises)
      const nofifyID = getRandomString(6, "1234567890")
      const notificationBody = {
        message: `Gift card confirmation fail.. kindly contact admin for more information`,
        created: firebase.firestore.FieldValue.serverTimestamp(),
        isread: false,
        id: nofifyID,
        userID:"",
        isRi: 0
      }
    await SendNotification(notificationBody, user.id, "both") 
        setres("Finalizing process");
      

    } catch (error) {
      console.log(error.message);
    }
    handleClose()
    setloading(false)
  };

  const handleSold = async (e) => {
    e.preventDefault()

    const com = amountsold - sellitem.total
    if (loginuser.role !== "admin") {
      toast.error("Permission Vat-error")
      return
    }

    if (com <= 0) {
      toast.error("Negetive value rejected")
      return
    }

    if (sellitem.isclosed !== true) {
      toast.error("Approval action required")
      return
    }

    if (sellitem.adminsell === true){
      toast.error("Treated")
      return
    }
   
    try {
      setloading(true)
      const promises = [
        database.doc(`/income/vRgRWLBNzwo7IM1pUhcl`).update({
          income: firebase.firestore.FieldValue.increment(parseFloat(com)),
          giftcard: firebase.firestore.FieldValue.increment(parseFloat(com)),
        }),
        database.doc(`/GiftCardRequest/${item.id}`).update({
          sold: amountsold,
          profit: com,
          adminsell:true
        })
      ]
      await Promise.all(promises)
      toast.success("Recipt submited successfully")
      handleClose()
    } catch (error) {
      toast.error(error.message)
    }

    setloading(false)
  }

  return (
    <div>
      <ToastContainer />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="biewproductcontinaer">
            <h1>Gift-card Information</h1>

            <div className="productineofnfjf">
              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Card Name</strong>
                </div>
                <div className="productonfprnf">{item.card}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Card subcategory</strong>
                </div>
                <div className="productonfprnf">{item.subcategory}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Card Type</strong>
                </div>
                <div className="productonfprnf">{item.cardType}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Card unit</strong>
                </div>
                <div className="productonfprnf">
                  {formatNumber(parseFloat(item.amount))}
                </div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Total </strong>
                </div>
                <div className="productonfprnf">
                  &#8358; {formatNumber(item.total)}
                </div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Status </strong>
                </div>
                <div className="productonfprnf">
                  {item.isclosed === true ? "Settled" : "Open"}
                </div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Admin Report </strong>
                </div>
                <div className="productonfprnf">
                  {item.message === "" ? "Request under review" : item.message}
                </div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Code </strong>
                </div>
                <div className="productonfprnf">
                  {item.ecord}
                </div>
              </div>

              <div className="productdetaund" style={{ display: item.cardType === "Physical Card" ? "block" : "none" }}>
                <div className="producttitle">
                  <strong>Card </strong>
                </div>
                <div className="productonfprnf">
                  <img src={item.image} alt="" />
                </div>
              </div>
            </div>
            <center>{res}</center>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loading}
            variant={loading ? "warning" : "primary"}
            onClick={handleApprove}
          >
            {loading ? "Please wait.." : "Approve"}
          </Button>

          <Button
            disabled={loading}
            variant={loading ? "warning" : "danger"}
            onClick={handleReject}
          >
            {loading ? "please wait .." : "Reject"}
          </Button>

          <Button
            variant={loading ? "warning" : "secondary"}
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showsellitem}
        onHide={handleCloseseltietm}
        backdrop="static"
        keyboard={false}
      >

        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Enter Amount</Form.Label>
            <Form.Control type="tel" placeholder="amount"
              onChange={(event) => {
                setsoldamount(event.target.value)
              }}
            />
            <Form.Text className="text-muted">
              Kindly Enter the exact amount sold
            </Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseseltietm}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSold}>Submit Sales Reciept</Button>
        </Modal.Footer>
      </Modal>

      <div className="giftcardocne">
        <div className="cardshowarea">
          <div className="cardvaljsjs">
            <h1>Gift-Card Records</h1>
            <div className="girckahdicbd">
              <button onClick={history.goBack}>Back</button>
              <button className="ms-1">{usecollection.length} Cards</button>

              <Form.Control
                type="text"
                placeholder="Search by Name"
                className="w-25 ms-1"
                onChange={(event) => {
                  setsearch(event.target.value);
                }}
              />
            </div>
            <table>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th className="hidetable">Created</th>
                  <th>Card Name</th>
                  <th className="hidetable">Category</th>
                  <th className="hidetable">Type</th>
                  <th className="hidetable">Unit</th>
                  <th className="hidetable">Buy</th>
                  <th className="hidetable">Sold</th>
                  <th>Status</th>
                  <th>Action</th>
                  <th>Sell</th>
                </tr>
              </thead>
              <tbody>
                {records
                  .filter((value) => {

                    if (value === "") {
                      return value;
                    } else if (
                      value.subcategory
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return value;
                    } else if (
                      value.card.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return value;
                    } else if (
                      value.cardType
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return value;
                    } else if (
                      value.amount.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return value;
                    }
                  })
                  .map((item, id) => {
                    const date = moment(item.created).fromNow();
                    return (
                      <tr key={id}>
                        <td>{id + 1}</td>
                        <td  className="hidetable">
                              {item.created
                                ? format(item.created, "dd/MM/yyyy hh:mm a")
                                : ""}
                            </td>
                        <td>{item.card}</td>
                        <td className="hidetable">{item.subcategory}</td>
                        <td className="hidetable">{item.cardType}</td>
                        <td className="hidetable">
                          {formatNumber(parseFloat(item.amount))}
                        </td>


                        <td className="hidetable">
                          {formatNumber(item.total)}
                        </td>

                        <td className="hidetable">
                          {item.sold ? formatNumber(parseFloat(item.sold)) : 0}
                        </td>

                        <td>
                          {item.isclosed === true ? "Settled" : "Pending"}
                        </td>
                        <td>
                          <ButtonGroup aria-label="Basic example">
                            <Button
                              variant="success"
                              onClick={Togglemodalview(item)}
                            >
                              View
                            </Button>
                            {/* <Button
                              variant="primary"
                              onClick={Togglemodal(item)}
                            >
                              Edit
                            </Button> */}
                          </ButtonGroup>
                        </td>

                        <td>
                          <ButtonGroup aria-label="Basic example">
                            <Button
                              variant="success"
                              onClick={Togglemodalviewsell(item)}
                            >
                             {item.adminsell ===  true ? "Done" : "Sell"} 
                            </Button>
                            {/* <Button
                              variant="primary"
                              onClick={Togglemodal(item)}
                            >
                              Edit
                            </Button> */}
                          </ButtonGroup>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            <nav>
              <ul className="pageination">
                <li className="page-item">
                  <span className="page-link" onClick={prepage}>
                    Prev
                  </span>
                </li>

                {numbers.map((n, i) => {
                  return (
                    <li
                      key={i}
                      className={
                        currentPage === n ? "activenumber" : "page-item"
                      }
                    >
                      <span className="page-link" onClick={() => changecurrentPage(n)}>
                        {n < 1 ? 1 : n}
                      </span>
                    </li>
                  );
                })}

                <li className="page-item">
                  <span className="page-link" onClick={Nextpage}>
                    Next
                  </span>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GiftcardRequest;
