export function detectNetwork(mobileNumber) {
  // Regular expression to match the first four digits of a Nigerian mobile number
  const nigerianPattern =
    /^(0803|0806|0810|0813|0814|0816|0903|0703|0706|0708|0811|0815|0817|0818|0902|0906|0701|0704|0705|0707|0709|0819|0907|0901|0904|0909|0913|0916|0911|0905|0915|0917|0908|0809|0807|0805|0802|0808|0812)/;

  // Check if the mobile number matches the Nigerian pattern
  const match = mobileNumber.match(nigerianPattern);
  if (match) {
    const firstFourDigits = match[0];
    // Determine the network based on the first four digits
    switch (firstFourDigits) {
      case "0803":
      case "0806":
      case "0816":
      case "0703":
      case "0706":
      case "0810":
      case "0814":
      case "0903":
      case "0913":
      case "0916":
      case "0813":
      case "0906":
      case "0704":
        return "MTN";
      case "0701":
      case "0708":
      case "0802":
      case "0808":
      case "0812":
      case "0901":
      case "0902":
      case "0904":
      case "0907":
      case "0912":
      case "0911":
        return "Airtel";
      case "0805":
      case "0807":
      case "0811":
      case "0815":
      case "0705":
      case "0905":
      case "0915":
        return "Glo";
      case "0909":
      case "0917":
      case "0908":
      case "0809":
      case "0817":
      case "0818":
        return "9mobile";
      default:
        return "Unknown network";
    }
  } else {
    return "Invalid Nigerian mobile number";
  }
}
