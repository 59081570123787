import React from 'react'
import GiftcardLayer from './GiftcardLayer'
import Navbaronly from './Navbaronly'

function GiftcardCalculator() {
  return (
    <div>
        <Navbaronly/>
        <GiftcardLayer/>
    </div>
  )
}

export default GiftcardCalculator