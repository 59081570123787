import React, { useState } from "react";
import "./MyCss.css";
import { useHistory, useParams } from "react-router-dom";
import { useAuth } from "../../Services/UserAuth";
import firebase from "firebase";
import { getRandomString } from "../../Services/GetRandomNumber";
import "../Client/Navbaronly";
import Navbar from "../Client/Navbaronly";
import { Alert, Form, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useEffect } from "react";
import { backendUrl } from "../../Services/firebase/firebase";
function Register() {
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [number, setnumber] = useState("");
  const [loading, setloading] = useState(false);
  const [message, setmessage] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");
  const [exsist, setexsist] = useState(true);
  const {loginuser} = useAuth();
  const history = useHistory();
  const { logout} = useAuth();
  const referalcode =  useParams().referral


async function Resetuser()  {
 
 await logout()
    window.location.href = `/user-registration/gifted/${referalcode}`
    return
}

if (loginuser.length !== 0){
  Resetuser()
}


  const handleLogin = () => {
    history.push("login");
  };

  const getphoneNumber = async (e) => {
    const num = e.target.value;
    setnumber(num);
    
  };

  const handleregister = async (e) => {
    e.preventDefault();
    if (number.length < 11 || number === "") {
      toast.error("Unexpected error on mobile number");
      return;
    }
    if (password !== confirmpassword) {
      toast.error("password Mismatch");
      return;
    }

    if (
      password === "" ||
      confirmpassword === "" ||
      firstname === "" ||
      lastname === "" ||
      email === ""
    ) {
      toast.error("required input value missing");
      return;
    }
    setloading(true);
    
  await  axios
      .get(
        backendUrl+ `createReport/report/user/${number}`
      )
      .then((res) => {
        const payload = res.data.length;
        if (payload !== 0) {
          toast.error("Mobile Already in use by another user");
          return;
        }
      });
    const UniquieNum = getRandomString(7, "1234567890");
    const data = {
      UniquieNum,
      email,
      password,
      number,
      firstname,
      lastname,
      wallet: 0,
      coin: 0,
      referalcode,
      created: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // await axios
    //   .post(backendUrl+ "createReferralUser", { data: data })
    //   .then(async (e) => {
     
    //     toast.success("Account Created Successfully...");
    //     const url = `https://kitipay.com/users/${e.data.uid}`;
    //     const homeUrl = "https://kitipay.com";
    //     const fullname = `${firstname} ${lastname}`;
    //     const logo = "https://i.ibb.co/5xqth9r/logo.png";
    //     const content = `<div marginheight="0" topmargin="0" marginwidth="0" style="margin: 0px; background-color: #f2f3f8;" leftmargin="0">
    //       <table cellspacing="0" border="0" cellpadding="0" width="100%" background-color="#f2f3f8"
    //           style="font-family: 'Open Sans', sans-serif;">
    //           <tr>
    //               <td>
    //                   <table style="background-color: #f2f3f8; max-width:670px;  margin:0 auto;" width="100%" border="0"
    //                       align="center" cellpadding="0" cellspacing="0">
    //                       <tr>
    //                           <td style="height:80px;">&nbsp;</td>
    //                       </tr>
    //                       <tr>
    //                           <td style="text-align:center;">
    //                             <a href=${homeUrl} title="logo" target="_blank">
    //                               <img width="130" src=${logo} title="logo" alt="logo">
    //                             </a>
    //                           </td>
    //                       </tr>
    //                       <tr>
    //                           <td style="height:20px;">&nbsp;</td>
    //                       </tr>
    //                       <tr>
    //                           <td>
    //                               <table width="95%" border="0" align="center" cellpadding="0" cellspacing="0"
    //                                   style="max-width:670px;background:#fff; border-radius:3px; text-align:center;-webkit-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);-moz-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);box-shadow:0 6px 18px 0 rgba(0,0,0,.06);">
    //                                   <tr>
    //                                       <td style="height:40px;">&nbsp;</td>
    //                                   </tr>
    //                                   <tr>
    //                                       <td style="padding:0 35px;">
    //                                           <h1 style="color:#1e1e2d; font-weight:500; margin:0;font-size:32px;font-family:'Rubik',sans-serif;">Email Verification</h1>
    //                                           <span
    //                                               style="display:inline-block; vertical-align:middle; margin:29px 0 26px; border-bottom:1px solid #cecece; width:100px;"></span>
    //                                           <p style="color:#455056; font-size:15px;line-height:24px; margin:0;">
    //                                           Nice to meet you ${fullname}!
    //                                           We just need to verify your email address before you can access your account. click on the button below to
    //                                           Verify your email address.
    //                                           </p>
    //                                           <a href=${url}
    //                                               style="background:#01011f;text-decoration:none !important; font-weight:500; margin-top:35px; color:#fff;text-transform:uppercase; font-size:14px;padding:10px 24px;display:inline-block;border-radius:50px;">Verify Email</a>
    //                                       </td>
    //                                   </tr>
    //                                   <tr>
    //                                       <td style="height:40px;">&nbsp;</td>
    //                                   </tr>
    //                               </table>
    //                           </td>
    //                       <tr>
    //                           <td style="height:20px;">&nbsp;</td>
    //                       </tr>
    //                       <tr>
    //                           <td style="text-align:center;">
    //                               <p style="font-size:14px; color:rgba(69, 80, 86, 0.7411764705882353); line-height:18px; margin:0 0 0;">&copy; <strong>${homeUrl}</strong></p>
    //                           </td>
    //                       </tr>
    //                       <tr>
    //                           <td style="height:80px;">&nbsp;</td>
    //                       </tr>
    //                   </table>
    //               </td>
    //           </tr>
    //       </table>
         
    
    //       </div>`;
    //     const CampURL =
    //       backendUrl+ "sendEMAILkitipay";
    //     await axios.post(CampURL, {
    //       content,
    //       logo,
    //       fullname,
    //       email: email,
    //       firstname: firstname,
    //       url,
    //       userid: e.data.uid,
    //     });

    //     window.location.href = "/login";
    //   })
    //   .catch((e) => {
    //     toast.error(e.message, {
    //       position: toast.POSITION.TOP_RIGHT,
    //     });
    //   });

    setloading(false);
  };



  return (
    <div>
      <ToastContainer />
      <Navbar />
      <div className="backgroisns">
        <div className="usercontainerbody">
          <div class="usercontainer">
           <center>
           <div class="d-inline-block auth-logo">
              <img src="/img/logo.png" alt="JSLPS image" height="50px" />
            </div>
            <h3 className="text-dark mt-3  fs-2">Create an Account</h3>

           </center>
            {message ? (
              <Alert variant="info">
                <p className="text-white">{message}</p>
              </Alert>
            ) : (
              ""
            )}

            <Form>
              <div className="useronbording">
                <div className="usercondhahd">
                  <Form.Group className="mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="First name"
                      onChange={(event) => {
                        setfirstname(event.target.value);
                      }}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Last name"
                      onChange={(event) => {
                        setlastname(event.target.value);
                      }}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      onChange={(event) => {
                        setemail(event.target.value);
                      }}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Enter Phone Number</Form.Label>
                    <Form.Control
                      type="tel"
                      max={11}
                      placeholder="+123 ***"
                      onChange={getphoneNumber}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      onChange={(event) => {
                        setpassword(event.target.value);
                      }}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label> Confirm Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      onChange={(event) => {
                        setconfirmpassword(event.target.value);
                      }}
                      required
                    />
                  </Form.Group>
                </div>
              </div>

              <Form.Text className="text-muted">
                <span>Already have an account?</span>{" "}
                <span className="act" onClick={handleLogin}>
                  Login
                </span>
              </Form.Text>

              <Button
                variant={loading ? "warning" : "primary"}
                type="submit"
                onClick={handleregister}
                className="w-100 py-2 my-2"
                disabled={loading}
              >
                {loading ? "Loading..." : "Register"}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
