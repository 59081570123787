import React, { useCallback, useEffect, useState } from "react";
import { Button, ButtonGroup, Form, Modal } from "react-bootstrap";
import {
  getusertransactiondetails,
} from "../../../Services/GetUser.service";
import moment from "moment";
import { useHistory } from "react-router-dom";
import "./User.css";
import { useAuth } from "../../../Services/UserAuth";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { database } from "../../../Services/firebase/firebase";
import { formatNumber, timeStamptoDate } from "../../../Services/Utility";
import { format } from "date-fns";
import { backendUrl } from "../../../Services/firebase/firebase";
let userRef = database.collection("WalletTransaction").orderBy("created", "desc");

function UserLog() {
  const [usecollection, setusercollection] = useState([]);
  const [currentPage, setcurrentpage] = useState(1);
  const [showview, setShowview] = useState(false);
  const { loginuser } = useAuth();
  const handleCloseview = () => setShowview(false);
  const [search, setsearch] = useState("");
  const handleShowview = () => setShowview(true);
  const history = useHistory();
  const [item, setitem] = useState([]);
  const [loading, setloading] = useState(false);
  const [userwallet, setuserwallet] = useState([]);

  const [lastDocs, setlastDocs] = useState();
  const [isEmpty, setisEmpty] = useState(false);

  const sortDataByDate = (resvalue) => {
    return resvalue.sort((first, second) => {
      if (moment(first.created).isSame(second.created)) {
        return -1;
      } else if (moment(first.created).isBefore(second.created)) {
        return -1;
      } else {
        return 1;
      }
    });
  };

  const getuserwallet = async () => {
    await axios.get(backendUrl + "debitSuperAgentAccount/debit/user").then((res) => {
      const resvalue = res.data
      //const v = sortDataByDate(resvalue)
      setusercollection(resvalue)
   
    })
  }


  useEffect(() => {
    getuserwallet()
  }, [])


  const recordpage = 20;
  const lastIndex = currentPage * recordpage;
  const firstIndex = lastIndex - recordpage;
  const records = usecollection.slice(firstIndex, lastIndex);
  const npage = Math.ceil(usecollection.length / recordpage);
  const numbers = [...Array(npage + 1).keys()].slice(1);



  const prepage = () => {
    if (currentPage !== 1) {
      setcurrentpage(currentPage - 1);
    }
  };

  const Nextpage = () => {
    if (currentPage !== npage) {
      setcurrentpage(currentPage + 1);
    }
  };

  const changecurrentPage = (id) => {
    setcurrentpage(id);
  };



  const Togglemodalview = useCallback(
    (item) => () => {
      getusertransactiondetails(item.id,(result) => {
    
        setitem(result);
      })

      setuserwallet(item)
      handleShowview();
    },
    []
  );

  const handlerevser = async (e) => {
    history.push(`/profile/user-manager/${item.userID}`)
  };

  return (
    <div>
      <ToastContainer />

      <Modal
        show={showview}
        onHide={handleCloseview}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton className="modalheader">
          Payment Details
        </Modal.Header>
        <Modal.Body>
          <div className="biewproductcontinaer">
            <div className="productineofnfjf">
              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Payment Details</strong>
                </div>
                <div className="productonfprnf">{item.type}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Customer Name</strong>
                </div>
                <div className="productonfprnf">{item.company}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Amount</strong>
                </div>
                <div className="productonfprnf">{item.amount}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Previous Balance</strong>
                </div>
                <div className="productonfprnf">{userwallet.PreBal}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Wallet Balance</strong>
                </div>
                <div className="productonfprnf">{userwallet.postBal}</div>
              </div>



              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Account Number</strong>
                </div>
                <div className="productonfprnf">{item.AccountNumber}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>status</strong>
                </div>
                <div className="productonfprnf">{item.status}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Date</strong>
                </div>
                <div className="productonfprnf">
                  {item.created
                    ? format(item.created, "dd/MM/yyyy hh:mm a")
                    : ""}
                </div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Row Data</strong>
                </div>
                <div className="productonfprnf">{JSON.stringify(item)}</div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handlerevser}>
            View Transaction
          </Button>

          <Button variant="secondary" onClick={handleCloseview}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="giftcardocne">
        <h1>Payment History</h1>
        <div className="girckahdicbd">
          <button onClick={history.goBack}>Back</button>
          <button className="ms-1">{usecollection.length} Transaction</button>

          <Form.Control
            type="text"
            placeholder="Search by Name"
            className="w-25 ms-1"
            onChange={(event) => {
              setsearch(event.target.value);
            }}
          />
        </div>
        <div
          className="cardshowarea"
        >
          <div className="cardvaljsjs">
            <table>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th className="hidetable">Created</th>
                  <th>Type</th>
                  <th className="">Amount</th>
                  <th className="">Pre-Bal</th>
                  <th className="">Post-Bal</th>
          
                </tr>
              </thead>
              <tbody>
                {records.length === 0 ? <>
                  No Data Found
                </> : <>
                  {records
                    .map((item, id) => {
                      const d = timeStamptoDate(item);
                      const date = moment(d.created).fromNow();
                      return (
                        <tr key={id}>
                          <td>{id + 1}</td>
                          <td className="">{date}</td>
                          <td>{item.type}</td>
                          <td className="">{formatNumber(item.amount)}</td>
                          <td className="">{formatNumber(item.PreBal)}</td>
                          <td className="">{formatNumber(item.postBal)}</td>
                      

                        </tr>
                      );
                    })}
                </>}
              </tbody>
            </table>
            <nav>
              <ul className="pageination">
                <li className="page-item">
                  <span className="page-link" onClick={prepage}>
                    Prev
                  </span>
                </li>

                {numbers.splice(0,10).map((n, i) => {
                  return (
                    <li
                      key={i}
                      className={
                        currentPage === n ? "activenumber" : "page-item"
                      }
                    >
                      <span className="page-link" onClick={() => changecurrentPage(n)}>
                        {n < 1 ? 1 : n}
                      </span>
                    </li>
                  );
                })}

                <li className="page-item">
                  <span className="page-link" onClick={Nextpage}>
                    Next
                  </span>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserLog;
