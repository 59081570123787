import React, { useState } from "react";
import QuickServices from "./QuickServicers";
import Sidemenue from "../ClientComponent/Sidemenue";
import { Button, Form, Modal } from "react-bootstrap";
import "./Vendor.css"
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { getRandomString } from "../../../Services/GetRandomNumber";
import firebase from "firebase";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "../../../Services/UserAuth";
import { backendUrl } from "../../../Services/firebase/firebase";
function SericeCom() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const {loginuser} = useAuth()
  const handleShow = () => setShow(true)
  const [number, setnumber] = useState("");
  const [loading, setloading] =  useState(false)
  const [exsist, setexsist] = useState(true)
  const [firstname, setfirstname]= useState("")
  const [lastname, setlastname] =  useState("")
  const [pass, setpassword] =  useState("")
  const [email, setemail] =useState("")
  const [userpayload, setuserPayload]= useState("")
  const baseURL = backendUrl+ "createNewUserKitiPay";
  const history =  useHistory()
  const getphoneNumber = async(e)=>{
    const num =  e.target.value
    setnumber(num)
    if (num.length === 11){
      setloading(true) 
      axios.put(backendUrl+ `sendnumberkitipay/${num}`).then((res)=>{
        const payload =  res.data.payload.length
        if (payload === 0){
          setloading(false)
          setexsist(false)
          return
        }
        setexsist(true)
        toast.error("Mobile Already in use by another user")
      })
      setloading(false)
    }
   
  }

    const handleregister = async (e) => {
      e.preventDefault();
      if (number.length < 11){
        toast.error("Unexpected error on mobile number")
        return
      }
      const password = getRandomString(6, "1234567890")
      setpassword(password)
      const UniquieNum = getRandomString(7, "1234567890");
      const data = {
        UniquieNum,
        email,
        password,
        number,
        firstname,
        lastname,
        wallet: 0,
        coin: 0,
        created: firebase.firestore.FieldValue.serverTimestamp(),
      };
  
      if (exsist ===  true){
        toast.error("Information in use by another user");
        return;
      }
  
      if (
        firstname === "" ||
        lastname === "" ||
        email === "" 
      ) {
        toast.error("required input value missing");
        return;
      }
      setloading(true);
      await axios
        .put(baseURL, { data: data })
        .then(async (e) => {
        
          toast.success("Account Created Successfully...", {
            });
            const url = `https://kitipay.com/users/${e.data.uid}`;
            const homeUrl = "https://kitipay.com/";
            const fullname = `${firstname} ${lastname}`;
            const logo = "https://i.ibb.co/5xqth9r/logo.png";
            const content = `<div marginheight="0" topmargin="0" marginwidth="0" style="margin: 0px; background-color: #f2f3f8;" leftmargin="0">
            <table cellspacing="0" border="0" cellpadding="0" width="100%" background-color="#f2f3f8"
                style="font-family: 'Open Sans', sans-serif;">
                <tr>
                    <td>
                        <table style="background-color: #f2f3f8; max-width:670px;  margin:0 auto;" width="100%" border="0"
                            align="center" cellpadding="0" cellspacing="0">
                            <tr>
                                <td style="height:80px;">&nbsp;</td>
                            </tr>
                            <tr>
                                <td style="text-align:center;">
                                  <a href=${homeUrl} title="logo" target="_blank">
                                    <img width="130" src="https://i.ibb.co/5xqth9r/logo.png" title="logo" alt="logo">
                                  </a>
                                </td>
                            </tr>
                            <tr>
                                <td style="height:20px;">&nbsp;</td>
                            </tr>
                            <tr>
                                <td>
                                    <table width="95%" border="0" align="" cellpadding="0" cellspacing="0"
                                        style="max-width:670px;background:#fff; border-radius:3px; text-align:center;-webkit-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);-moz-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);box-shadow:0 6px 18px 0 rgba(0,0,0,.06);">
                                        <tr>
                                            <td style="height:40px;">&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td style="padding:0 35px;">
                                                <h1 style="color:#1e1e2d; font-weight:500; margin:0;font-size:32px;font-family:'Rubik',sans-serif;">Account Creation</h1>
                                                <span
                                                    style="display:inline-block; vertical-align:middle; margin:29px 0 26px; border-bottom:1px solid #cecece; width:100px;"></span>
                                                <p style="color:#455056; font-size:15px;line-height:24px; margin:0;">
                                                Hi ${fullname}! <br/>
                                                Welcome to KitiPay  we're excited to have you on board and we'd love to say thank you on behalf of the whole company for chosing us<br/>
                                                To ensure you gain the very best out of our services we have put together some guide on how you can activate your account 
                                                Your account is ready.. kindly login with the following <br/>
                                                Your Login Credentials are As follows <br/>
                                                email : ${email} <br/>
                                                password : ${pass} <br/>
                                                Please do not share this information with anyone. A password reset will be required <br/>
                                                Thanks

                                                Ikenna Nwafor CEO KITIPAY
                                                </p>
                                                <br/>
                                                
                                                <a href=${url}
                                                    style="background:#01011f;text-decoration:none !important; font-weight:500; margin-top:35px; color:#fff;text-transform:uppercase; font-size:14px;padding:10px 24px;display:inline-block;border-radius:50px;">Verify Email</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="height:40px;">&nbsp;</td>
                                        </tr>
                                    </table>
                                </td>
                            <tr>
                                <td style="height:20px;">&nbsp;</td>
                            </tr>
                            <tr>
                                <td style="text-align:center;">
                                    <p style="font-size:14px; color:rgba(69, 80, 86, 0.7411764705882353); line-height:18px; margin:0 0 0;">&copy; <strong>${homeUrl}</strong></p>
                                </td>
                            </tr>
                            <tr>
                                <td style="height:80px;">&nbsp;</td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
           
      
            </div>`;
            const CampURL =backendUrl+ "sendEMAILkitipay"
        await axios
          .post(CampURL, {
            content,
            logo,
            fullname,
            email: email,
            firstname: firstname,
            url,
            userid: e.data.uid
          })
  
         handleClose() 
        })
        .catch((e) => {
          toast.error(e.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        
        });
  
      setloading(false);
    };

    useEffect(() => {
      
    }, [userpayload])
    
    const getuser =async(e)=>{
      e.preventDefault()
      const number =  e.target.value
      if (number === ""){
        setuserPayload("")
      }else{
        setuserPayload("Typing...")
      }
      
      if (number.length === 11){
        setuserPayload("Fetching User Data")
        setloading(true) 
        axios.put(backendUrl+ `sendnumberkitipay/${number}`).then((res)=>{
          const payload =  res.data.payload.length
          if (payload === 0){
            setuserPayload("No user information found")
            return
          }
          const userdata =  res.data.payload[0]
          if (loginuser.id ===  userdata.id){
            setuserPayload("Action Not Permited ")
            return
          }
          history.push(`/vendor/create-new-request/user/${userdata.id}`)
       
        })
       
        setloading(false)
      }
     
      
    }
  return (
    <div>
      <ToastContainer/>
    <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Create New User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form.Group className="mb-3" >
        <Form.Label>First Name</Form.Label>
        <Form.Control type="text" onChange={(event)=>{
          setfirstname(event.target.value)
        }} />
      </Form.Group>

      <Form.Group className="mb-3" >
        <Form.Label>Last Name</Form.Label>
        <Form.Control type="text" onChange={(event)=>{
          setlastname(event.target.value)
        }} />

      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email Address</Form.Label>
        <Form.Control type="email" onChange={(event)=>{
          setemail(event.target.value)
        }}/>
        <Form.Text className="text-muted">
          Make Sure the email is valid. user credientials will be send to this email. note that vendor can't access this account except the user authorize otherwise
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Mobile Number</Form.Label>
        <Form.Control type="text" maxLength={11} onChange={getphoneNumber}/>
        <Form.Text className="text-muted">
          Make Sure the mobile number is valid.
        </Form.Text>
      </Form.Group>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button disabled={loading} variant={loading ? "danger" : "primary"} onClick={handleregister}>Create Account</Button>
        </Modal.Footer>
    </Modal>


      <div className="pagecon">
        <div className="pagecontent">
          <div className="headersisecoadmin">
            <Sidemenue />
          </div>
          <div className="otherssecoth">
            <div className="dashboardconteier">
              <div className="ndsreqyesocnenr">
                <div className="createaccountdfokre">
                  <div className="createaccourconejnr">
                    <Form>
                      <Form.Group className="mb-3">
                        <Form.Label>Enter Customer Mobile Number</Form.Label>
                        <Form.Control type="tel"  maxLength={11} onChange={getuser}/>
                        <Form.Text>
                          This Customer Dont Have account on kitipay ? <span onClick={handleShow} className="creatisersccc">Create Account</span>.
                        </Form.Text>
                      </Form.Group>
                        <center className="text-danger">{userpayload} </center>
      
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SericeCom;
