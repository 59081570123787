import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../Services/UserAuth'
import Sidebar from '../ClientComponent/Sidemenue'
import { formatNumber } from '../../../Services/Utility'
import { gettransactin } from '../../../Services/GetUser.service'
import "../ClientComponent/Complaince.css";
import { Button, Form } from 'react-bootstrap'
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

import { backendUrl } from "../../../Services/firebase/firebase";
import { useHistory } from 'react-router-dom';
function UserprofilePage() {
  const { loginuser } = useAuth()

  const history = useHistory()
  const [loading, setloading] = useState(false);
  const [res, setres] = useState("");

  const [fullname, setfullname] = useState("");
  const [mobilenumber, setmobilenumber] = useState("");
  const [address, setemailaddress] = useState("");
  const [type, settype] = useState("");
  const [BvnData, setBvn] = useState("");
  const [resAddress, setResAddress] = useState("");
  const [nigeriastate, setnigeriastate] = useState([]);
  const [nigeriaregion, setnigeriaregion] = useState([]);
  const [selectedState, setselectedstate] = useState("");
  const [selectedRegion, setselelectedRegion] = useState("");
  const [repscountry, setRescountry] = useState("");
  const [message, setmessage] = useState("");
  const BankUrl =
    backendUrl + "getBankHolder2";




  const getallstate = async () => {
    await axios.get("https://locus.fkkas.com/api/states").then((e) => {
      setnigeriastate(e.data.data);
    });
  };


  useEffect(() => {
    getallstate();
    if (selectedState) {
      axios
        .get(`https://locus.fkkas.com/api/regions/${selectedState}`)
        .then((e) => {
          setnigeriaregion(e.data.data);
        });
    }


    // if (loginuser.userverified === false){
    //   handleShowalert()
    //   return
    // }
  }, [selectedState]);



  const handleRepInfomration = async (e) => {
    e.preventDefault();
    if (BvnData.length !== 11) {
      toast.error("BVN is Incorrect");
      return;
    }
    if (
      fullname === "" ||
      address === "" ||
      mobilenumber === "" ||
      BvnData === "" ||
      resAddress === "" ||
      selectedRegion === "" ||
      selectedState === "" ||
      repscountry === ""
    ) {
      toast.error("Information is missing");
      return;
    }
    if (loginuser.isbusinessInfoSubmited === false) {
      setmessage(
        "Dear Partner Kindly Submit your buinses information before proceeding to bank information or Business Representative information. thanks"
      );
      return;
    }
    const data = {
      id: loginuser.id,
      fullname,
      address,
      mobilenumber,
      resAddress,
      selectedRegion,
      selectedState,
      BvnData,
      repscountry,
    };
    setloading(true);
    setres("Gathering information to process. Please wait");
    try {
      await axios
        .post(
          backendUrl + "getUpatedUserRep/Representative",
          {
            data,
          }
        ).then(()=>{
          history.push("/compliance-verify/bank-information")
        })
  
      //  toast.success(res.data.message);
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setres("");
    setloading(false);
  };


  return (
    <div>
      <div className="pagecon">
        <div className="pagecontent">
          <div className="headersisecoadmin">
            <Sidebar />
          </div>
          <div className="otherssecoth">
            <div className="dashboardconteier">
              <div className="topmenuecontrenr">
                <Button
                  variant={loading ? "warning" : "primary"}

                  onClick={history.goBack}
                >
                  {loading ? "Wait" : "Back"}
                </Button>
                {loginuser.isbusinessRepSubmited === true ? (
                  <>
                    <div className="aformconteinr">
                      <div className="albs">
                        {message ? message : ""}
                        {loginuser.isbusineRepVerify !== true ? (
                          <div className="text-danger"> Awaitn Admin Approval</div>
                        ) : (
                          <div className="text-success">Verified</div>
                        )}
                      </div>
                      <div className="informatronpobody">
                        <div className="inforalteader"> Representative Name :</div>
                        <div className="headerbodycontainer">
                          {loginuser.BusinessRepFullName}
                        </div>
                      </div>

                      <div className="informatronpobody">
                        <div className="inforalteader">
                          Representative Address :
                        </div>
                        <div className="headerbodycontainer">
                          {loginuser.resAddress}
                        </div>
                      </div>

                      <div className="informatronpobody">
                        <div className="inforalteader">Representative Email :</div>
                        <div className="headerbodycontainer">
                          {loginuser.RepEmailaddress}
                        </div>
                      </div>

                      <div className="informatronpobody">
                        <div className="inforalteader">
                          Representative Mobile Number :
                        </div>
                        <div className="headerbodycontainer">
                          {loginuser.Repmobilenumber
                            ? loginuser.Repmobilenumber
                            : ""}
                        </div>
                      </div>

                      <div className="informatronpobody">
                        <div className="inforalteader">Country :</div>
                        <div className="headerbodycontainer">
                          {loginuser.repscountry ? loginuser.repscountry : ""}
                        </div>
                      </div>

                      <div className="informatronpobody">
                        <div className="inforalteader">State :</div>
                        <div className="headerbodycontainer">
                          {loginuser.repselectedState
                            ? loginuser.repselectedState
                            : ""}
                        </div>
                      </div>

                      <div className="informatronpobody">
                        <div className="inforalteader"> Region :</div>
                        <div className="headerbodycontainer">
                          {loginuser.RepselectedRegion
                            ? loginuser.RepselectedRegion
                            : ""}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="aformconteinr">
                    <p>Contact Person Information</p>

                    <Form.Group className="mb-3">
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(event) => {
                          setfullname(event.target.value);
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Mobile Number</Form.Label>
                      <Form.Control
                        type="tel"
                        onChange={(event) => {
                          setmobilenumber(event.target.value);
                        }}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>BVN</Form.Label>
                      <Form.Control
                        type="tel"
                        onChange={(event) => {
                          setBvn(event.target.value);
                        }}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(event) => {
                          setemailaddress(event.target.value);
                        }}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Residential Address</Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(event) => {
                          setResAddress(event.target.value);
                        }}
                      />
                    </Form.Group>
                    <Form.Label>Country</Form.Label>
                    <Form.Select
                      onChange={(event) => {
                        setRescountry(event.target.value);
                      }}
                    >
                      <option>Open this select Country</option>
                      <option value="Nigeria">Nigeria</option>
                    </Form.Select>

                    <Form.Label>State</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(event) => {
                        setselectedstate(event.target.value);
                      }}
                    >
                      <option value="">Open to select state</option>
                      {nigeriastate.map((e) => {
                        return <option value={e.alias}>{e.name}</option>;
                      })}
                    </Form.Select>
                    <Form.Label>Region</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(event) => {
                        setselelectedRegion(event.target.value);
                      }}
                    >
                      {nigeriaregion
                        ? nigeriaregion.map((e) => {
                          return <option value={e.alias}>{e.name}</option>;
                        })
                        : []}
                    </Form.Select>

                    <Button
                      variant={loading ? "warning" : "dark"}
                      className="w-100"
                      onClick={handleRepInfomration}
                    >
                      {loading ? "Wait" : "Send Request"}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  )
}

export default UserprofilePage