import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./Component/Client/Home";
import { AuthProvider } from "./Services/UserAuth";
import Contact from "./Component/Client/Contact";
import Loading from "./Loading";
import About from "./Component/Client/About";
import PageNotFound from "./Component/PageNotFound";
import Services from "./Component/Client/Services";
import Register from "./Component/CreateUser.js/Register";
import Login from "./Component/CreateUser.js/Login";
import Forgetpassword from "./Component/CreateUser.js/Forgetpassword";
import Validator from "./Component/CreateUser.js/Validator";
import Validatoradmin from "./Component/CreateUser.js/Validatoradmin";
import VerifyUser from "./Component/CreateUser.js/VerifyUser";
import PrivateRoute from "./Component/CreateUser.js/PrivateRoute";
import DashboardIndex from "./Component/ClientDashboard/ClientPage/DashboardIndex";
import AdminLogin from "./Component/Admin/Admincreation.js/AdminLogin";
import AdminRegister from "./Component/Admin/Admincreation.js/AdminRegister";
import Dashboradmin from "./Component/Admin/AdminPage/Dashboradmin";
import Logoutrout from "./Component/Logout";
import LogoutrouteAdmin from "./Component/Logoutadmin";
import GiftcardPage from "./Component/Admin/AdminPage/GiftcardPage";
import UserPageadmin from "./Component/Admin/AdminPage/UserPageadmin";
import ComplainceGeneral from "./Component/ClientDashboard/ClientPage/ComplainceGeneral";
import ComplaincePage from "./Component/ClientDashboard/ClientPage/ComplaincePage";
import Verifydocument from "./Component/ClientDashboard/ClientPage/Verifydocument";
import Settlment from "./Component/ClientDashboard/ClientPage/Settlment";
import UserLogapage from "./Component/Admin/AdminPage/UserLogapage";
import VendorManagementPage from "./Component/Admin/AdminPage/VendorManagementPage";
import ServicesPage from "./Component/ClientDashboard/ClientPage/ServicesPage";
import Giftcardpage from "./Component/ClientDashboard/ClientPage/Giftcardpage";
import GiftcardRequestLog from "./Component/ClientDashboard/ClientPage/GiftcardRequestLog";
import AdminCardRequst from "./Component/Admin/AdminPage/AdminCardRequst";
import TransactionLog from "./Component/Admin/AdminPage/TransactionLog";
import UserTransactionLogPage from "./Component/ClientDashboard/ClientPage/UserTransactionLogPage";
import VendorCollectionPage from "./Component/ClientDashboard/ClientPage/VendorCollectionPage";
import CreateVendorRequest from "./Component/ClientDashboard/ClientPage/CreateVendorRequest";
import UserVendorRequestpage from "./Component/ClientDashboard/ClientPage/UserVendorRequestpage";
import VendorTranaction from "./Component/Admin/AdminPage/VendorTranaction";
import VendorDispute from "./Component/Admin/AdminComponent/VendorDispute";
import airtimePage from "./Component/ClientDashboard/ClientPage/airtimePage";
import DataTransaction from "./Component/ClientDashboard/ClientPage/DataTransaction";
import CableTvSubscription from "./Component/ClientDashboard/ClientPage/CableTvSubscription";
import BettingPage from "./Component/ClientDashboard/ClientPage/BettingPage";
import Electricity from "./Component/ClientDashboard/ClientPage/Electricity";
import SettingsPage from "./Component/ClientDashboard/ClientPage/SettingsPage";
import ViewTransactionCom from "./Component/ClientDashboard/ClientComponent/ViewTransactionCom";
import Supports from "./Component/ClientDashboard/ClientPage/Supports";
import WalletTrander from "./Component/ClientDashboard/ClientPage/WalletTrander";
import SetTransactionPin from "./Component/ClientDashboard/ClientPage/SetTransactionPin";
import { getprocessTransaction, getversion } from "./Services/GetUser.service";
import GiftcardCalculator from "./Component/Client/GiftcardCalculator";
import Vendorpoliy from "./Component/Client/Vendorpoliy";
import PaymentLog from "./Component/Admin/AdminPage/PaymentLog";
import CreateNewStaff from "./Component/Admin/AdminPage/CreateNewStaff";
import StaffIndexPage from "./Component/StaffDashboard/StaffIndexPage";
import GiftcardCenter from "./Component/StaffDashboard/GiftcardCenter";
import UserProfilecenter from "./Component/Admin/AdminPage/UserProfilecenter";
import UserwalletTransaction from "./Component/Admin/AdminPage/UserwalletTransaction";
import AdminSupport from "./Component/Admin/AdminPage/AdminSupport";
import Sendmessage from "./Component/Admin/AdminPage/Sendmessage";
import Analysic from "./Component/Admin/AdminPage/Analysic";
import ReferralRegister from "./Component/CreateUser.js/ReferralRegister";
import MezuTransaction from "./Component/Admin/AdminPage/MezuTransaction";
import UserprofilePage from "./Component/ClientDashboard/ClientPage/UserprofilePage";
import BusinessInformationPage from "./Component/ClientDashboard/ClientPage/BusinessInformationPage";
import BankAccountInformation from "./Component/ClientDashboard/ClientPage/BankAccountInformation";
import DocumentUpload from "./Component/ClientDashboard/ClientPage/DocumentUpload";
import BusinessReprPage from "./Component/ClientDashboard/ClientPage/BusinessReprPage";
import { Button, Modal } from "react-bootstrap";
import VendorDashorard from "./Component/ClientDashboard/ClientPage/VendorDashorard";
import CreateProduct from "./Component/ClientDashboard/ClientPage/CreateProduct";
import ProductPage from "./Component/ClientDashboard/ClientPage/ProductPage";
import AdminProduct from "./Component/Admin/AdminPage/AdminProduct";
import Marhangstore from "./Component/Client/Marhangstore";
import SingleProductPage from "./Component/Client/SingleProductPage";
import ClientOrder from "./Component/Client/ClientOrder";
import CreateInvoice from "./Component/ClientDashboard/ClientComponent/CreateInvoice";
import CreateInvoicePage from "./Component/ClientDashboard/ClientPage/CreateInvoicePage";
import OrderCOna from "./Component/ClientDashboard/ClientPage/OrderCOna";
import Ordermessage from "./Component/ClientDashboard/ClientComponent/Ordermessage";
import Ordermessagepage from "./Component/ClientDashboard/ClientPage/Ordermessagepage";
import ReceiptPage from "./Component/ClientDashboard/ClientPage/ReceiptPage";
import {AiOutlineWhatsApp} from "react-icons/ai"
import AuthResetpassword from "./Component/CreateUser.js/AuthResetpassword";
import LogsPage from "./Component/Admin/AdminPage/LogsPage";
import ViewUserLogs from "./Component/Admin/AdminPage/ViewUserLogs";
import Resultchecker from "./Component/ClientDashboard/ClientPage/Resultchecker";
import { version_control } from "./Services/Utility";
function App() {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const version  =  version_control()
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
    getversion((e) => {
      const vers = e[0].version;
      if (vers !== version) {
        handleShow();
        return;
      }
    });
  }, []);

  function emptyCache() {
    if ("caches" in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
        });
      });

      // Makes sure the page reloads. Changes are only visible after you refresh.
      window.location.reload(true);
    }
  }

  return (
    <div className="App">
      {loading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <Modal show={show} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>Update Application</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Your Application is OUTDATED, kindly click on the update button to
              update your application. if you are on using our mobile app and
              still get this error kindly use the link to continue enjoying our
              services. <br />{" "}
              <a href="https://kitipay.com/login" className="apdjdhdhbuton">
                Go to web
              </a>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={emptyCache}>
                Update
              </Button>
            </Modal.Footer>
          </Modal>
          <Router>
            <AuthProvider>
              <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/service" exact component={Services} />
                <Route path="/about" exact component={About} />
                <Route path="/contact" exact component={Contact} />
                <Route path="/register" exact component={Register} />
                <Route
                  path="/market/Place/:agentID"
                  exact
                  component={Register}
                />
                <Route
                  path="/user-registration/gifted/:referral"
                  exact
                  component={ReferralRegister}
                />
                <Route
                  path="/gift-card-calculator"
                  exact
                  component={GiftcardCalculator}
                />
                <Route path="/privacy-policy" exact component={Vendorpoliy} />
                {/* <Route path="/" exact component={Login} /> */}
                <Route path="/login" exact component={Login} />
                <Route
                  path="/forgetpassword"
                  exact
                  component={Forgetpassword}
                />
                <Route path="/logout" exact component={Logoutrout} />
                <Route path="/auth/logout" exact component={LogoutrouteAdmin} />
                <Route path="/auth/user" exact component={Validator} />
                <Route path="/auth/admin" exact component={Validatoradmin} />
                <Route path="/users/:id" exact component={VerifyUser} />
                <Route path="/auth/login" exact component={AdminLogin} />
                <Route path="/valid/auth/resetpassword/:UniquieNum" exact component={AuthResetpassword} />
                {/* <Route path="/auth/register" exact component={AdminRegister} /> */}
                <PrivateRoute
                  path="/user/dashboard/:id"
                  exact
                  component={DashboardIndex}
                />
                <PrivateRoute
                  path="/admin/dashboard"
                  exact
                  component={Dashboradmin}
                />
                <PrivateRoute
                  path="/log/user"
                  exact
                  component={UserPageadmin}
                />
                <PrivateRoute
                  path="/get/mygift-card"
                  exact
                  component={UserPageadmin}
                />
                  <PrivateRoute
                  path="/logs"
                  exact
                  component={LogsPage}
                />
                 <PrivateRoute
                  path="/logs/:id"
                  exact
                  component={ViewUserLogs}
                />
                <PrivateRoute
                  path="/dashboard/Compliance/general"
                  exact
                  component={ComplainceGeneral}
                />
                <PrivateRoute
                  path="/dashboard/Compliance"
                  exact
                  component={ComplaincePage}
                />
                {/* <PrivateRoute path="/dashboard/Compliance/doc/verification" exact component={Verifydocument}/> */}
                <PrivateRoute
                  path="/dashboard/Compliance/settlement"
                  exact
                  component={Settlment}
                />

                <PrivateRoute
                  path="/reciept/view/:id"
                  exact
                  component={ReceiptPage}
                />
                <PrivateRoute path="/log/user" exact component={UserLogapage} />
                <PrivateRoute
                  path="/Vendor-Management"
                  exact
                  component={VendorManagementPage}
                />
                <PrivateRoute path="/services" exact component={ServicesPage} />
                
                {/* <PrivateRoute
                  path="/service/Gift-Card"
                  exact
                  component={Giftcardpage}
                />
                <PrivateRoute
                  path="/service/Gift-Card/list"
                  exact
                  component={GiftcardRequestLog}
                /> */}
                <PrivateRoute
                  path="/gift-card-management"
                  exact
                  component={GiftcardPage}
                />
                <PrivateRoute
                  path="/gift-card-management/history"
                  exact
                  component={AdminCardRequst}
                />
                <PrivateRoute
                  path="/transaction"
                  exact
                  component={TransactionLog}
                />
                <PrivateRoute
                  path="/user/transaction"
                  exact
                  component={UserTransactionLogPage}
                />
                <PrivateRoute
                  path="/vendor/home/:id"
                  exact
                  component={VendorCollectionPage}
                />
                <PrivateRoute
                  path="/vendor/create-new-request"
                  exact
                  component={CreateVendorRequest}
                />
                <PrivateRoute
                  path="/vendor/create-new-request/user/:id"
                  exact
                  component={UserVendorRequestpage}
                />
                <PrivateRoute
                  path="/vendor/Transaction/history/:id"
                  exact
                  component={VendorTranaction}
                />
                <PrivateRoute
                  path="/vendor/dispute/:id"
                  exact
                  component={VendorDispute}
                />
                <PrivateRoute
                  path="/transaction/airtime"
                  exact
                  component={airtimePage}
                />
                <PrivateRoute
                  path="/transaction/data"
                  exact
                  component={DataTransaction}
                />

                <PrivateRoute
                  path="/transaction/exam/result-checker"
                  exact
                  component={Resultchecker}
                />
                <PrivateRoute
                  path="/transaction/cable-tv-subcription"
                  exact
                  component={CableTvSubscription}
                />
                <PrivateRoute
                  path="/transaction/games/betting"
                  exact
                  component={BettingPage}
                />
                <PrivateRoute
                  path="/transaction/vend/electricity"
                  exact
                  component={Electricity}
                />
                <PrivateRoute
                  path="/transaction/:id"
                  exact
                  component={ViewTransactionCom}
                />
                <PrivateRoute path="/setting" exact component={SettingsPage} />
                <PrivateRoute path="/support" exact component={Supports} />
                <PrivateRoute
                  path="/transaction/wallet/transfer"
                  exact
                  component={WalletTrander}
                />
                <PrivateRoute
                  path="/user/set-transaction-pin/:id"
                  exact
                  component={SetTransactionPin}
                />
                <PrivateRoute
                  path="/payment/transaction/dashboard"
                  exact
                  component={PaymentLog}
                />
                <PrivateRoute
                  path="/auth/staff/:id"
                  exact
                  component={StaffIndexPage}
                />
                <PrivateRoute
                  path="/Create-new-staff/admin"
                  exact
                  component={CreateNewStaff}
                />
                <PrivateRoute
                  path="/management/gift-card-manager"
                  exact
                  component={GiftcardCenter}
                />
                <PrivateRoute
                  path="/profile/user-manager/:id"
                  exact
                  component={UserProfilecenter}
                />
                <PrivateRoute
                  path="/profile/user-wallet-manager/:id"
                  exact
                  component={UserwalletTransaction}
                />
                <PrivateRoute
                  path="/admin/support-center/"
                  exact
                  component={AdminSupport}
                />
                <PrivateRoute
                  path="/send-message/:id"
                  exact
                  component={Sendmessage}
                />
                <PrivateRoute
                  path="/control/system-center"
                  exact
                  component={Analysic}
                />
                <PrivateRoute
                  path="/control/Transaction-center"
                  exact
                  component={MezuTransaction}
                />
                <PrivateRoute
                  path="/compliance-verify/profile"
                  exact
                  component={UserprofilePage}
                />
                <PrivateRoute
                  path="/compliance-verify/business-information"
                  exact
                  component={BusinessInformationPage}
                />
                <PrivateRoute
                  path="/compliance-verify/bank-information"
                  exact
                  component={BankAccountInformation}
                />
                <PrivateRoute
                  path="/compliance-verify/document-upload"
                  exact
                  component={DocumentUpload}
                />
                <PrivateRoute
                  path="/compliance-verify/business-representative"
                  exact
                  component={BusinessReprPage}
                />

                <PrivateRoute
                  path="/marchant/profile/:id"
                  exact
                  component={VendorDashorard}
                />
                <Route
                  path="/store/:mart/:udID"
                  exact
                  component={Marhangstore}
                />

                <Route
                  path="/store/product/order/:id"
                  exact
                  component={ClientOrder}
                />

                <Route
                  path="/store/product/item/:id"
                  exact
                  component={SingleProductPage}
                />

                <PrivateRoute
                  path="/marchant/product/create-new"
                  exact
                  component={CreateProduct}
                />

                <PrivateRoute
                  path="/marchant/product"
                  exact
                  component={ProductPage}
                />

                <PrivateRoute
                  path="/admin/product/log"
                  exact
                  component={AdminProduct}
                />

                <PrivateRoute
                  path="/marchant/create-order/request/:id"
                  exact
                  component={CreateInvoicePage}
                />

                <PrivateRoute path="/store/order" exact component={OrderCOna} />

                <PrivateRoute
                  path="/contact/order/message"
                  exact
                  component={Ordermessagepage}
                />
                <Route path="*" component={PageNotFound} />
              </Switch>
            </AuthProvider>
          </Router>
        </>
      )}
            <a
        href="https://wa.me/2349115630280"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <AiOutlineWhatsApp/>
      </a>
    </div>
  );
}

export default App;
