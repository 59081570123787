import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getusertransactiondetails } from "../../../Services/GetUser.service";
import { format } from "date-fns";
import { Button, Form, Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { database } from "../../../Services/firebase/firebase";
import "./Tractionrecipt.css";
import firebase from "firebase";
import { useAuth } from "../../../Services/UserAuth";
import { formatNumber } from "../../../Services/Utility";
function Transactionsdetails() {
  const [item, setitem] = useState([]);
  const { loginuser } = useAuth();
  const [report, setreport] = useState([]);
  const history = useHistory();
  const [showreport, setShowreport] = useState(false);
  const handleClosereport = () => setShowreport(false);
  const handleShowreport = () => setShowreport(true);

  const [message, setmessage] = useState("");

  const id = useParams().id;
  
  useEffect(() => {
    getusertransactiondetails(id, (result) => {
      
      setitem(result[0]);
    });
  }, []);

  const handlesend = async (e) => {
    e.preventDefault();
    const id = database.collection("_").doc().id;
    const data = {
      id,
      report,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      service: item.company,
      amount: item.amount,
      customername: `${loginuser.firstname} ${loginuser.lastname}`,
      Trxt: item.id,
      userID: loginuser.id,
      isTreated: false,
    };

    if (report === "") {
      toast.console.error("Give details of your complain", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    try {
      await database
        .collection("Report")
        .doc(data.id)
        .set(data)
        .then(() => {
          toast.success(
            "Sorry for the inconvenience experience while making this transaction, we will treat and give feeback shortly. Thanks",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          setmessage(
            "Sorry for the inconvenience experience while making this transaction, we will treat and give feeback shortly. Thanks"
          );
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleServesePage = ()=>{
    history.push("/services")
  }

  if (item.type === "Electricity") {
    return (
      <>
        <Modal
          show={showreport}
          onHide={handleClosereport}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Report Issue</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Service Type</Form.Label>
              <Form.Control type="text" value={item.company} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Amount</Form.Label>
              <Form.Control type="text" value={item.amount} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>User Name</Form.Label>
              <Form.Control
                type="text"
                value={`${loginuser.firstname} ${loginuser.lastname}`}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Transaction ID</Form.Label>
              <Form.Control type="text" value={item.id} />
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Give Report Here</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(event) => {
                  setreport(event.target.value);
                }}
              />
            </Form.Group>
          </Modal.Body>
          <center>{message}</center>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClosereport}>
              Close
            </Button>
            <Button variant="success" onClick={handlesend}>
              Send
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="appwropperreciepd">
          <div className="infocontainerrecioe">

            <div className="reciptptitle" style={{backgroundColor:item.status === "success" ? "green" : "red"}}>
              <h1>Transaction Reciept</h1>
              <span>https://kitipay.com/</span>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Company :</div>
              <div className="infodetails">{item.company}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Account Number :</div>
              <div className="infodetails">
                {item.AccountNumber ? item.AccountNumber : item.meter}
              </div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Customer Name :</div>
              <div className="infodetails">
                {item.customername === "no value" ? "" : item.customername}
              </div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Debt :</div>
              <div className="infodetails">
                {item.customername === "no value" ? "" : item.debt}
              </div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Unit :</div>
              <div className="infodetails">
                {item.unit === "no value" ? "" : item.unit}
              </div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Token :</div>
              <div className="infodetails">
                {item.token === "no value" ? "" : item.token}
              </div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Address :</div>
              <div className="infodetails">
                {item.address === "no value" ? "" : item.address}
              </div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Amount :</div>
              <div className="infodetails">
                &#8358; {formatNumber(item.amount)}
              </div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Status :</div>
              <div className="infodetails">{item.status}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Reference No:</div>
              <div className="infodetails">{item.TranRef}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Transaction Time :</div>
              <div className="infodetails">
                {item.created ? format(item.created, "dd/MM/yyyy hh:mm a") : ""}
              </div>
            </div>
            <div className="inforcontenrbuttpon">
              <Button variant="secondary" onClick={handleServesePage}>
                Close
              </Button>
              <Button variant="danger" onClick={handleShowreport}>
                Report Issue
              </Button>
              <Button variant="primary" onClick={() => window.print()}>
                Print Reciept
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (item.type === "Cable TV") {
    return (
      <>
        <Modal
          show={showreport}
          onHide={handleClosereport}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Report Issue</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Service Type</Form.Label>
              <Form.Control type="text" value={item.company} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Amount</Form.Label>
              <Form.Control type="text" value={item.amount} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>User Name</Form.Label>
              <Form.Control
                type="text"
                value={`${loginuser.firstname} ${loginuser.lastname}`}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Transaction ID</Form.Label>
              <Form.Control type="text" value={item.id} />
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Give Report Here</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(event) => {
                  setreport(event.target.value);
                }}
              />
            </Form.Group>
          </Modal.Body>
          <center>{message}</center>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClosereport}>
              Close
            </Button>
            <Button variant="success" onClick={handlesend}>
              Send
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="appwropperreciepd">
          <div className="infocontainerrecioe">

            <div className="reciptptitle" style={{backgroundColor:item.status === "success" ? "green" : "red"}}>
              <h1>Transaction Reciept</h1>
              <span>https://kitipay.com/</span>
            </div>
            <div className="inforcontenr">
              <div className="infotittle">Company :</div>
              <div className="infodetails">{item.company}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Account Number :</div>
              <div className="infodetails">
                {item.AccountNumber ? item.AccountNumber : item.meter}
              </div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Customer Name :</div>
              <div className="infodetails">
                {item.customername === "no value" ? "" : item.customername}
              </div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Amount :</div>
              <div className="infodetails">
                &#8358; {formatNumber(item.amount)}
              </div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Status :</div>
              <div className="infodetails">{item.status}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Reference No:</div>
              <div className="infodetails">{item.TranRef}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Transaction Time :</div>
              <div className="infodetails">
                {item.created ? format(item.created, "dd/MM/yyyy hh:mm a") : ""}
              </div>
            </div>

            <div className="inforcontenrbuttpon">
              <Button variant="secondary" onClick={handleServesePage}>
                Close
              </Button>
              <Button variant="danger" onClick={handleShowreport}>
                Report Issue
              </Button>
              <Button variant="primary" onClick={() => window.print()}>
                Print Reciept
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (item.type === "Betting") {
    return (
      <>
        <Modal
          show={showreport}
          onHide={handleClosereport}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Report Issue</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Service Type</Form.Label>
              <Form.Control type="text" value={item.company} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Amount</Form.Label>
              <Form.Control type="text" value={item.amount} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>User Name</Form.Label>
              <Form.Control
                type="text"
                value={`${loginuser.firstname} ${loginuser.lastname}`}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Transaction ID</Form.Label>
              <Form.Control type="text" value={item.id} />
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Give Report Here</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(event) => {
                  setreport(event.target.value);
                }}
              />
            </Form.Group>
          </Modal.Body>
          <center>{message}</center>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClosereport}>
              Close
            </Button>
            <Button variant="success" onClick={handlesend}>
              Send
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="appwropperreciepd">
          <div className="infocontainerrecioe">

            <div className="reciptptitle"style={{backgroundColor:item.status === "success" ? "green" : "red"}} >
              <h1>Transaction Reciept</h1>
              <span>https://kitipay.com/</span>
            </div>
            <div className="inforcontenr">
              <div className="infotittle">Company :</div>
              <div className="infodetails">{item.company}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">User ID :</div>
              <div className="infodetails">{item.address}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Customer Name :</div>
              <div className="infodetails">
                {item.customername === "no value" ? "" : item.customername}
              </div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Amount :</div>
              <div className="infodetails">
                &#8358; {formatNumber(item.amount)}
              </div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Status :</div>
              <div className="infodetails">{item.status}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Reference No:</div>
              <div className="infodetails">{item.TranRef}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Transaction Time :</div>
              <div className="infodetails">
                {item.created ? format(item.created, "dd/MM/yyyy hh:mm a") : ""}
              </div>
            </div>
            <div className="inforcontenrbuttpon">
              <Button variant="secondary" onClick={handleServesePage}>
                Close
              </Button>
              <Button variant="danger" onClick={handleShowreport}>
                Report Issue
              </Button>
              <Button variant="primary" onClick={() => window.print()}>
                Print Reciept
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
  if (item.type === "Data") {
    return (
      <>
        <Modal
          show={showreport}
          onHide={handleClosereport}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Report Issue</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Service Type</Form.Label>
              <Form.Control type="text" value={item.company} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Amount</Form.Label>
              <Form.Control type="text" value={item.amount} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>User Name</Form.Label>
              <Form.Control
                type="text"
                value={`${loginuser.firstname} ${loginuser.lastname}`}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Transaction ID</Form.Label>
              <Form.Control type="text" value={item.id} />
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Give Report Here</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(event) => {
                  setreport(event.target.value);
                }}
              />
            </Form.Group>
          </Modal.Body>
          <center>{message}</center>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClosereport}>
              Close
            </Button>
            <Button variant="success" onClick={handlesend}>
              Send
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="appwropperreciepd">
          <div className="infocontainerrecioe">
            <div className="reciptptitle" style={{backgroundColor:item.status === "success" ? "green" : "red"}}>
              <h1>Transaction Reciept</h1>
              <span>https://kitipay.com/</span>
            </div>
            <div className="inforcontenr">
              <div className="infotittle">Company :</div>
              <div className="infodetails">{item.company}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Reciver Number :</div>
              <div className="infodetails">
                {item.AccountNumber ? item.AccountNumber : item.meter}
              </div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Amount :</div>
              <div className="infodetails">
                &#8358; {formatNumber(item.amount)}
              </div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Status :</div>
              <div className="infodetails">{item.status}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Reference No:</div>
              <div className="infodetails">{item.TranRef}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Transaction Time :</div>
              <div className="infodetails">
                {item.created ? format(item.created, "dd/MM/yyyy hh:mm a") : ""}
              </div>
            </div>

            <div className="inforcontenrbuttpon">
              <Button variant="secondary" onClick={handleServesePage}>
                Close
              </Button>
              <Button variant="danger" onClick={handleShowreport}>
                Report Issue
              </Button>
              <Button variant="primary" onClick={() => window.print()}>
                Print Reciept
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
  if (item.type === "Transfer") {
    return (
      <>
        <Modal
          show={showreport}
          onHide={handleClosereport}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Report Issue</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Service Type</Form.Label>
              <Form.Control type="text" value={item.company} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Amount</Form.Label>
              <Form.Control type="text" value={item.amount} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>User Name</Form.Label>
              <Form.Control
                type="text"
                value={`${loginuser.firstname} ${loginuser.lastname}`}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Transaction ID</Form.Label>
              <Form.Control type="text" value={item.id} />
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Give Report Here</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(event) => {
                  setreport(event.target.value);
                }}
              />
            </Form.Group>
          </Modal.Body>
          <center>{message}</center>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClosereport}>
              report
            </Button>
            <Button variant="success" onClick={handlesend}>
              Send
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="appwropperreciepd">
          <div className="infocontainerrecioe">

            <div className="reciptptitle" style={{backgroundColor:item.status === "success" ? "green" : "red"}}>
              <h1>Transaction Reciept</h1>
              <span>https://kitipay.com/</span>
            </div>
            <div className="inforcontenr">
              <div className="infotittle">Company :</div>
              <div className="infodetails">{item.company}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Account No:</div>
              <div className="infodetails">{item.AccountNumber}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Customer Name :</div>
              <div className="infodetails">
                {item.customername === "no value" ? "" : item.customername}
              </div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Bank :</div>
              <div className="infodetails">
                {item.Bank}
              </div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Amount :</div>
              <div className="infodetails">
                &#8358; {formatNumber(item.amount)}
              </div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Description :</div>
              <div className="infodetails">
               {item.description}
              </div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Status :</div>
              <div className="infodetails">{item.status}</div>
            </div>

            <div className="inforcontenr">
              <div className="infotittle">Reference No:</div>
              <div className="infodetails">{item.TranRef}</div>
            </div>
            <div className="inforcontenr">
            <div className="infotittle">Date/Time:</div>
            <div className="infodetails">
              {item.created ? format(item.created, "dd/MM/yyyy hh:mm a") : ""}
            </div>
          </div>

            <div className="inforcontenrbuttpon">
              <Button variant="secondary" onClick={handleServesePage}>
                Close
              </Button>
              <Button variant="danger" onClick={handleShowreport}>
                Report Issue
              </Button>
              <Button variant="primary" onClick={() => window.print()}>
                Print Reciept
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }

  // if (item.company === "Wace"){
  //   const paload =  item.payloadres
  //   const pins = paload.pins

  //   return(
  //     <>
  // <div className="appwropperreciepd">
  //      <div className="infocontainerrecioe">

  //         <div className="transactionsheader">
  //           <div className="tranctionreciptoapa">
  //             <img src="/img/logo3.png" alt="" />
  //           </div>
  //           <div className="infodetails">
  //             {item.created ? format(item.created, "dd/MM/yyyy hh:mm a") : ""}
  //           </div>
  //         </div>

  //         <div className="reciptptitle">
  //             <h1>Transaction Reciept</h1>
  //             <span>https://mezuflex.com/</span>
  //           </div>
  //         <div className="infocontainer">
  //               <div className="inforcontenr">
  //                 <div className="infotittle">Company :</div>
  //                 <div className="infodetails">
  //                   {item.company ? item.company.toUpperCase() : ""}
  //                 </div>
  //               </div>

  //               <div className="carconatienr">
  //                 <div className="cordrawwaper">
  //                   {pins.map((item, id) => {

  //                     return (
  //                       <div key={id}>
  //                         <div className="cardcontenr">
  //                           <div className="cardinma">
  //                             <img src="/img/waec.png" alt="" />
  //                           </div>
  //                           <div className="caedtejnss">
  //                             <div className="inforcontenr">
  //                               <div className="infotittle"> Pin:</div>
  //                               <div className="infodetails">{item.pin}</div>
  //                             </div>

  //                             <div className="inforcontenr">
  //                               <div className="infotittle"> Serial Number:</div>
  //                               <div className="infodetails">
  //                                 {item.serialNumber}
  //                               </div>
  //                             </div>
  //                           </div>
  //                         </div>
  //                       </div>
  //                     );
  //                   })}
  //                 </div>
  //               </div>

  //               <div className="inforcontenr">
  //                 <div className="infotittle">Amount :</div>
  //                 <div className="infodetails">
  //                   &#8358; {formatNumber(item.amount)}
  //                 </div>
  //               </div>

  //               <div className="inforcontenr">
  //                 <div className="infotittle">Number of Pin :</div>
  //                 <div className="infodetails">{pins.length}</div>
  //               </div>

  //               <div className="inforcontenr">
  //                 <div className="infotittle">Transaction Time :</div>
  //                 <div className="infodetails">
  //                   {item.created
  //                     ? format(item.created, "dd/MM/yyyy hh:mm a")
  //                     : ""}
  //                 </div>
  //               </div>

  //             </div>

  //         <div className="inforcontenrbuttpon">
  //           <Button variant="secondary" onClick={history.goBack}>
  //             Close
  //           </Button>
  //           <Button variant="danger" onClick={handleShowreport}>
  //             Report Issue
  //           </Button>
  //           <Button variant="primary" onClick={() => window.print()}>
  //             Print Reciept
  //           </Button>
  //         </div>
  //       </div>
  //      </div>

  //     </>
  //   )
  // }
  if (item.type === "resultchecker-waec"){
    const payloads = item.payloadres.payload
    const pinlayer = payloads.payload.data.pins
    return(
      <>
  <div className="appwropperreciepd">
       <div className="infocontainerrecioe">
          <div className="transactionsheader">
            <div className="tranctionreciptoapa">
              
            </div>
            <div className="infodetails">
              {item.created ? format(item.created, "dd/MM/yyyy hh:mm a") : ""} <br />
            
            </div>
          </div>
          <div className="reciptptitle"style={{backgroundColor:item.status === "success" ? "green" : "red"}} >
              <h1>Transaction Reciept</h1>
              <span>https://kitipay.com/</span>
            </div>
          <div className="infocontainer">
                <div className="inforcontenr">
                  <div className="infotittle">Company :</div>
                  <div className="infodetails">
                    {item.company ? `${item.company.toUpperCase()} RESULT CHECKER`  : ""} 
                  </div>
                </div>
            <br />
                <div className="carconatienr">
                  <div className="cordrawwaper">
                    {pinlayer ? pinlayer.map((item, id) => {
                      return (
                        <div key={id}>
                          <div className="cardcontenr">
                            <div className="cardinma">
                              <img src="/img/neco.png" alt="" />
                            </div>
                            <div className="caedtejnss">
                              <div className="inforcontenr">
                                <div className="infotittle"> Pin:</div>
                                <div className="infodetails">{item.pin}</div>
                              </div>
                              <div className="inforcontenr">
                                <div className="infotittle"> Serial Number:</div>
                                <div className="infodetails">{item.serialNumber}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }) : []}
                  </div>
                </div>
                  
                <br />
                <div className="inforcontenr">
                  <div className="infotittle">Amount :</div>
                  <div className="infodetails">
                    &#8358; {formatNumber(item.amount)}
                  </div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Number of Pin :</div>
                  <div className="infodetails">{pinlayer ? pinlayer.length : 0}</div>
                </div>

                <div className="inforcontenr">
                  <div className="infotittle">Transaction Time :</div>
                  <div className="infodetails">
                    {item.created
                      ? format(item.created, "dd/MM/yyyy hh:mm a")
                      : ""}
                  </div>
                </div>

              </div>

          <div className="inforcontenrbuttpon">
            <Button variant="secondary" onClick={history.goBack}>
              Close
            </Button>
            <Button variant="danger" onClick={handleShowreport}>
              Report Issue
            </Button>
            <Button variant="primary" onClick={() => window.print()}>
              Print Reciept
            </Button>
          </div>
        </div>
       </div>

      </>
    )
  }


  return (
    <div>
      <ToastContainer />
      <Modal
        show={showreport}
        onHide={handleClosereport}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Report Issue</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Service Type</Form.Label>
            <Form.Control type="text" value={item.company} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Amount</Form.Label>
            <Form.Control type="text" value={item.amount} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>User Name</Form.Label>
            <Form.Control
              type="text"
              value={`${loginuser.firstname} ${loginuser.lastname}`}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Transaction ID</Form.Label>
            <Form.Control type="text" value={item.id} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Give Report Here</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              onChange={(event) => {
                setreport(event.target.value);
              }}
            />
          </Form.Group>
        </Modal.Body>
        <center>{message}</center>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosereport}>
            Close
          </Button>
          <Button variant="success" onClick={handlesend}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="appwropperreciepd">
        <div className="infocontainerrecioe">
          <div className="reciptptitle" style={{backgroundColor:item.status === "success" ? "green" : "red"}}>
            <h1>Transaction Reciept</h1>
            <span>https://kitipay.com/</span>
          </div>
          <div className="inforcontenr">
            <div className="infotittle">Company :</div>
            <div className="infodetails">{item.company}</div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">Reciver Number :</div>
            <div className="infodetails">
              {item.AccountNumber ? item.AccountNumber : item.meter}
            </div>
          </div>
          <div className="inforcontenr">
            <div className="infotittle">Amount :</div>
            <div className="infodetails">
              &#8358; {formatNumber(item.amount)}
            </div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">Status :</div>
            <div className="infodetails"> {item.status}</div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">Reference No:</div>
            <div className="infodetails">{item.TranRef}</div>
          </div>

          <div className="inforcontenr">
            <div className="infotittle">Date/Time:</div>
            <div className="infodetails">
              {item.created ? format(item.created, "dd/MM/yyyy hh:mm a") : ""}
            </div>
          </div>

          <div className="inforcontenrbuttpon">
            <Button variant="secondary" onClick={handleServesePage}>
              Close
            </Button>
            <Button variant="danger" onClick={handleShowreport}>
              Report Issue
            </Button>
            <Button variant="primary" onClick={() => window.print()}>
              Print Reciept
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Transactionsdetails;
