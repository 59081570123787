import React, { useCallback, useEffect, useState } from "react";
import { Button, ButtonGroup, Form, Modal, Spinner } from "react-bootstrap";
import {
  SendNotification,
  getstauser,
  getuser,
  getuserwallet,
} from "../../../Services/GetUser.service";
import { AiOutlineCloudUpload, AiOutlineClose } from "react-icons/ai";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import "../../Admin/AdminComponent/User.css";
import { useAuth } from "../../../Services/UserAuth";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { database, storage } from "../../../Services/firebase/firebase";
import { timeStamptoDate } from "../../../Services/Utility";
import { format } from "date-fns";
import "../../ClientDashboard/ClientComponent/Chat.css";
import firebase from "firebase";
import { useRef } from "react";
import { getRandomString } from "../../../Services/GetRandomNumber";
import { backendUrl } from "../../../Services/firebase/firebase";

function VendorTransaction() {
  const [usecollection, setusercollection] = useState([]);
  const [showview, setShowview] = useState(false);
  const { loginuser, userwallet } = useAuth();
  const handleCloseview = () => setShowview(false);
  const [search, setsearch] = useState("");
  const handleShowview = () => setShowview(true);
  const history = useHistory();
  const [item, setitem] = useState([]);
  const [loading, setloading] = useState(false);
  const [lastDocs, setlastDocs] = useState();
  const [isEmpty, setisEmpty] = useState(false);
  const [showchat, setShowchat] = useState(false);
  const handleClosechat = () => setShowchat(false);
  const handleShowchar = () => setShowchat(true);
  const [chatmessage, setchatmessage] = useState([]);
  const [message, setmessage] = useState("");
  const [isImage, setisImage] = useState(false);
  const [file, setfile] = useState("");
  const [progress, setProgress] = useState();
  const scroll = useRef();
  const [isloading, setisloading] = useState(false);
  const [showacceptance, setShowacceptance] = useState(false);
  const handleCloseacceptance = () => {
    setShowacceptance(false);
    window.location.reload();
  };

  const [showconfirm, setShowcomfirm] = useState(false);
  const handleCloseconfirm = () => setShowcomfirm(false);
  const handleShowconfirm = () => setShowcomfirm(true);
  const handleShowacceptance = () => setShowacceptance(true);
  const [conloading, setconloading] = useState(false);
  const [otp, setotp] = useState("");
  const [reportmessage, setreportment] = useState("");

  const [showreport, setShowreport] = useState(false);
  const handleClosereport = () => setShowreport(false);
  const handleShowreport = () => setShowreport(true);



  const handleChange = (e) => {
    if (e.target.files[0]) {
      setisImage(true);
      setfile(e.target.files[0]);
      return;
    } else {
      setisImage(false);
    }
  };

  function handleUpload() {
    return new Promise((resolve) => {
      const uploadTask = storage.ref(`messagefile/${file.name}`).put(file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          setmessage(error);
        },
        () => {
          storage
            .ref("messagefile")
            .child(file.name)
            .getDownloadURL()
            .then((url) => {
              resolve(url);
            });
        }
      );
    });
  }

  const id = useParams().id;
  

 
  useEffect(() => {
    axios
      .get(
        backendUrl+ `createVendorRequest/vendor/services/${id}`
      )
      .then((res) => {
        setusercollection(res.data.payload);
      });
  }, [isEmpty]);


  const [currentPage, setcurrentpage] = useState(1);
  const recordpage = 20;
  const lastIndex = currentPage * recordpage;
  const firstIndex = lastIndex - recordpage;
  const records = usecollection.slice(firstIndex, lastIndex);
  const npage = Math.ceil(usecollection.length / recordpage);
  const numbers = [...Array(npage + 1).keys()].slice(1);


  const prepage = () => {
    if (currentPage !== 1) {
      setcurrentpage(currentPage - 1);
    }
  };

  const Nextpage = () => {
    if (currentPage !== npage) {
      setcurrentpage(currentPage + 1);
    }
  };

  const changecurrentPage = (id) => {
    setcurrentpage(id);
  };

  const handlecreatestatf = ()=>{
    history.push("/create-new-staff/admin")
  }



  const Togglemodalview = useCallback(
    (item) => () => {
      setitem(item);
      handleShowview();
    },
    []
  );

  const handlerequest = () => {
    history.push("/vendor/create-new-request");
  };

  const handleopenChat = () => {
    handleShowchar();
    database
      .collection(`VendorRequest/${item.id}/messaging`)
      .orderBy("created")
      .limit(50)
      .onSnapshot((snapshot) => {
        setchatmessage(snapshot.docs.map((doc) => doc.data()));
      });
  };

  const handlesendchatmessage = async (e) => {
    e.preventDefault();
    if (isImage) {
      setloading(true);
      const filename = file.name;
      const imageSize = Math.round(file.size / 1024 / 1024);
      if (imageSize > 200) {
        toast.error("file to large. max : 200");
        return;
      }
      const fileExtension = filename.substring(filename.lastIndexOf(".") + 1);

      const ImageExtension = ["JPG", "JPEG", "PNG", , "jpg", "jpeg", "png"];
      const valeinc = ImageExtension.includes(fileExtension);
      if (!valeinc) {
        toast.error("file not supported");
        setloading(false);
        return;
      }
      const image = await handleUpload();
      database.collection(`VendorRequest/${item.id}/messaging`).add({
        text: message,
        userole: loginuser.role,
        crrentuserID: loginuser.id,
        sendername: `${loginuser.firstname} ${loginuser.lastname}`,
        sendermoble: loginuser.number,
        image,
        created: firebase.firestore.FieldValue.serverTimestamp(),
        type: "double",
      });
      setmessage("");
      setisImage(false);
      setfile(null);
      setloading(false);

      return;
    }
    setloading(true);
    database.collection(`VendorRequest/${item.id}/messaging`).add({
      text: message,
      userole: loginuser.role,
      image: "",
      crrentuserID: loginuser.id,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      type: "single",
    });
    setloading(false);
    setmessage("");
  };

  const scrollToBottom = () => {
    scroll.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatmessage]);

  const handleremovefile = () => {
    setisImage(false);
    setfile(null);
  };

  const handlepayment = async (e) => {
    e.preventDefault();
    if (item.productamount > userwallet.wallet) {
      toast.error("Insuffient user balance");
      return;
    }

    if (loginuser.transactionLimit > item.productamount) {
      toast.error("Request not granted");
      return;
    }

    if (loginuser.userverified === false) {
      toast.error("Request not granted");
      return;
    }

    if (loginuser.isBlock === true) {
      toast.error("Account Restricted");
      return;
    }

    if (loginuser.role !== "user") {
      toast.error("Request not granted");
      return;
    }

    if (item.isaccepted === true) {
      toast.error("Error!");
      return;
    }

    if (item.ispaid === true) {
      toast.error("Error!");
      return;
    }
    if (item.isaccepted === true || item.ispaid === true) {
      toast.error("Request Already Treated");
      return;
    }

    if (item.isClose === true) {
      toast.error("Request Close");
      return;
    }
    const UniquieNum = getRandomString(5, "1234567890");
    const data = {
      cusomerID: item.customerID,
      vendorID: item.VendorID,
      amount: parseFloat(item.productamount),
      TransID: item.id,
      AcceptanceCode: UniquieNum,
    };
    try {
      setisloading(true);
      axios
        .post(
          backendUrl+ "acceptVendorRequest/users/payment/request",
          {
            data,
          }
        )
        .then(async(e) => {
          toast.success(e.data.message + ". Redirecting ");
          const nofifyID = getRandomString(6, "1234567890")
          const notificationBody = {
            message: `${loginuser.firstname} ${loginuser.lastname} just paid for ${item.productname} product successfully.`,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            isread: false,
            id: nofifyID,
            userID:"",
            isRi: 0
          }
        await SendNotification(notificationBody, item.VendorID, "both") 
          setInterval(() => {
            window.location.href = `/vendor/home/${loginuser.id}`;
          }, 3000);
        });
    } catch (error) {}
    setisloading(false);
  };

  const handleconfirmcollection = async (e) => {
    e.preventDefault();
    if (item.ispaid !== true) {
      toast.error("Payment Required");
      return;
    }

    if (item.isDispute === true) {
      toast.error("Unexpected Error");
      return;
    }

    if (item.isClose !== false) {
      toast.error("Unexpected Error");
      return;
    }

    if (item.isDispute === true) {
      toast.error("Unexpected Error");
      return;
    }
    const getisStor = window.localStorage.getItem("isClick");
    const confirmclick = JSON.parse(getisStor);

    if (confirmclick.itemID === item.id) {
      handleShowconfirm();
      return;
    }
    setconloading(true);
    const homeUrl = "https://kitipay.com/";
    const fullname = `${loginuser.firstname} ${loginuser.lastname}`;
    const logo = "https://i.ibb.co/5xqth9r/logo.png";
    const content = `<div marginheight="0" topmargin="0" marginwidth="0" style="margin: 0px; background-color: #f2f3f8;" leftmargin="0">
    <table cellspacing="0" border="0" cellpadding="0" width="100%" background-color="#f2f3f8"
        style="font-family: 'Open Sans', sans-serif;">
        <tr>
            <td>
                <table style="background-color: #f2f3f8; max-width:670px;  margin:0 auto;" width="100%" border="0"
                    align="center" cellpadding="0" cellspacing="0">
                    <tr>
                        <td style="height:80px;">&nbsp;</td>
                    </tr>
                    <tr>
                        <td style="text-align:center;">
                          <a href=${homeUrl} title="logo" target="_blank">
                           KITIPAY 
                          </a>
                        </td>
                    </tr>
                    <tr>
                        <td style="height:20px;">&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            <table width="95%" border="0" align="center" cellpadding="0" cellspacing="0"
                                style="max-width:670px;background:#fff; border-radius:3px; text-align:center;-webkit-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);-moz-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);box-shadow:0 6px 18px 0 rgba(0,0,0,.06);">
                                <tr>
                                    <td style="height:40px;">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td style="padding:0 35px;">
                                        <h1 style="color:#1e1e2d; font-weight:500; margin:0;font-size:32px;font-family:'Rubik',sans-serif;">Confirmation Code</h1>
                                        <span
                                            style="display:inline-block; vertical-align:middle; margin:29px 0 26px; border-bottom:1px solid #cecece; width:100px;"></span>
                                            <p style="color: rgb(1, 1, 122);  font-weight:500; font-size:35px;line-height:24px; margin:0; text-align:center;">
                                            Here's your One-Time PIN 
                                        </p>

                                        <br/>

                                        <br/>

                                        <br/>
                                        <p style="color:#455056;  font-weight:500; font-size:14px;line-height:24px; margin:0; text-align:center;">
                                        You performed an action that requires extra verification on Kitipay. Here's your one-time confirmation PIN.
                                        </p>

                                        <br/>
                                        <br/>

                                            <p style="color:black; font-size:35px;line-height:24px; margin:0; text-align:center;">
                                                ${item.AcceptanceCode}
                                            </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="height:40px;">&nbsp;</td>
                                </tr>
                            </table>
                        </td>
                    <tr>
                        <td style="height:20px;">&nbsp;</td>
                    </tr>
                    <tr>
                        <td style="text-align:center;">
                            <p style="font-size:14px; color:rgba(69, 80, 86, 0.7411764705882353); line-height:18px; margin:0 0 0;">&copy; <strong>${homeUrl}</strong></p>
                        </td>
                    </tr>
                    <tr>
                        <td style="height:80px;">&nbsp;</td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
   

    </div>`;
    const CampURL =
      backendUrl+ "sendEmailCampkitipay";
    await axios.post(CampURL, {
      content,
      logo,
      fullname,
      email: loginuser.email,
      firstname: loginuser.firstname,
      url: "",
      subject: "Confirmation Code",
    });
    const data = {
      confirm: "yes",
      itemID: item.id,
    };
    window.localStorage.setItem("isClick", JSON.stringify(data));
    handleShowconfirm();

    setconloading(false);
  };

  const hanldeOTP = async (e) => {
    e.preventDefault();

    if (otp === "") {
      toast.error("Enter Pin sent to your email");
      return;
    }

    if (otp !== item.AcceptanceCode) {
      toast.error("Enter Pin sent to your email");
      return;
    }

    if (item.customerID !== loginuser.id) {
      toast.error("unexpected Error");
      return;
    }

    if (item.ispaid !== true) {
      toast.error("Payment Required");
      return;
    }

    if (item.isClose !== false) {
      toast.error("Unexpected Error");
      return;
    }

    if (item.isDispute === true) {
      toast.error("Unexpected Error");
      return;
    }
    setloading(true);
    try {
      await axios
        .post(
          backendUrl+ "createRequestsummary",
          {
            id: item.id,
            code: otp,
            fullname: `${loginuser.firstname} ${loginuser.lastname}`,
            phone: loginuser.number,
          }
        )
        .then((e) => {
         
          toast.success(e.data.message);
          handleCloseconfirm();
        });
    } catch (error) {}
    setloading(false);
  };

  const handlereport = async (e) => {
    e.preventDefault();
    if (item.isDispute === true) {
      toast.error("Unexpected Error");
      return;
    }

    if (item.isClose !== false) {
      toast.error("Unexpected Error");
      return;
    }
    if (reportmessage.length > 70) {
      toast.error("Max number of character is 50");
      return;
    }
    setloading(true);
   
    try {
      await axios
        .post(
          `backendUrl+  createReport/report/user`,
          {
            message: reportmessage,
            id: item.id,
          }
        )
        .then(async(res) => {
          toast.success(res.data.message);
          handleClosereport();
          const nofifyID = getRandomString(6, "1234567890")
          const notificationBody = {
            message: `${loginuser.firstname} ${loginuser.lastname} just reported an issue please act on it.`,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            isread: false,
            id: nofifyID,
            userID:"",
            isRi: 0
          }
        await SendNotification(notificationBody, item.VendorID, "admin") 
        });
    } catch (error) {}
    setloading(false);
  };

  const handleDisputcenter = async()=>{
    history.push(`/vendor/dispute/${item.id}`)
  }

  return (
    <div>
      <ToastContainer />

      <Modal
        show={showreport}
        onHide={handleClosereport}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Report Issue Here</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              maxLength={70}
              onChange={(event) => {
                setreportment(event.target.value);
              }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            disabled={loading}
            onClick={handleClosereport}
          >
            Close
          </Button>
          <Button
            variant={loading ? "warning" : "primary"}
            disabled={loading}
            onClick={handlereport}
          >
            {loading ? "sending report" : "Report Issue"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showconfirm}
        onHide={handleCloseconfirm}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <strong>Confirmation</strong>
          <p>
            {" "}
            Before you proceed, kindly confirm all items and make sure it meet
            the required agreement on purchase. Please enter Confirmation code
            sent to your email
          </p>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              onChange={(event) => {
                setotp(event.target.value);
              }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loading}
            variant="secondary"
            onClick={handleCloseconfirm}
          >
            Close
          </Button>
          <Button
            disabled={loading}
            variant={loading ? "warning" : "primary"}
            onClick={hanldeOTP}
          >
            {loading ? "Processing Request" : "Confirm"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showacceptance}
        onHide={handleCloseacceptance}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <strong>Attestation</strong>
          <hr />i {loginuser.firstname} {loginuser.lastname} attest that the
          information about this transaction is correct and accurate and i am
          aware of the terms and conditions of this services. Please Kitipay
          Proceed with the debit from my wallet
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseacceptance}>
            Close
          </Button>
          <Button
            variant={isloading ? "warning" : "primary"}
            disabled={isloading}
            onClick={handlepayment}
          >
            {isloading ? "Please Wait" : "Proceed"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showchat}
        onHide={handleClosechat}
        animation={true}
        className="bachatckdd"
      >
        <Modal.Body className="p-0">
          <div className="shochahshdjdjd">
            <div className="headersectin">
              <div className="imagecoustoemrinf">
                <img
                  style={{
                    width: "40px",
                    borderRadius: "50px",
                    height: "40px",
                  }}
                  src={loginuser.image ? loginuser.image : "/img/i.png"}
                  alt=""
                />
                <div className="customerindldjd">
                  <h1>
                    {loginuser.firstname} {loginuser.lastname}
                  </h1>
                  <span>
                    {loginuser.role === "vendor" ? loginuser.businessname : ""}
                  </span>
                </div>
              </div>
              <div className="conrebndjdjd">
                <h3>ChaT</h3>
                <p>
                  You are chatting with{" "}
                  {loginuser.role === "vendor"
                    ? item.customername
                    : item.vendorName}
                </p>
              </div>
            </div>
            <div className="chagsconhhe msgs">
              {chatmessage.map((item, id) => {
                return (
                  <div className="wrrahdhdconjd" scroll={scroll}>
                    <div className="imageawjdjdjnewalls">
                      <a href={item.image} target="_blank">
                        <img
                          style={{
                            display: item.type === "double" ? "block" : "none",
                          }}
                          src={item.type === "double" ? item.image : ""}
                          alt=""
                        />
                      </a>
                    </div>
                    <br />
                    <div
                      key={id}
                      className={`msg ${
                        loginuser.id === item.crrentuserID ? "sent" : "received"
                      }`}
                      style={{ display: item.text === "" ? "none" : "block" }}
                    >
                      <p> {item.text}</p>
                    </div>
                    <div ref={scroll}></div>
                  </div>
                );
              })}
            </div>
          </div>
          <form>
            <div className="sendMsg">
              <div
                className="abndjdfileshow"
                style={{ display: isImage ? "flex" : "none" }}
              >
                <h1>Attached File : {isImage ? file.name : ""}</h1>
                <p onClick={handleremovefile}>
                  <AiOutlineClose />
                </p>
              </div>
              <Form.Control
                type="text"
                value={message}
                style={{
                  width: "68%",
                  fontSize: "15px",
                  fontWeight: "550",
                  marginLeft: "5px",
                  padding: "10px",
                  marginBottom: "-3px",
                }}
                placeholder="Message..."
                onChange={(event) => {
                  setmessage(event.target.value);
                }}
              />

              <Form.Group className="inpirdkjrjrform">
                <Form.Label for="upl">
                  <AiOutlineCloudUpload />
                </Form.Label>
                <Form.Control
                  type="file"
                  id="upl"
                  style={{ display: "none", visibility: "none" }}
                  onChange={handleChange}
                />
              </Form.Group>

              <Button
                variant="primary"
                disabled={loading}
                onClick={handlesendchatmessage}
                type="submit"
                style={{
                  width: "18%",
                  fontSize: "15px",
                  fontWeight: "550",
                  padding: "10px",

                  // margin: "0px 5% -13px 5%",

                  maxWidth: "200px",
                }}
              >
                {loading ? <Spinner animation="border" size="sm" /> : "Send"}
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showview}
        onHide={handleCloseview}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton className="modalheader">
          Transaction Details
        </Modal.Header>
        <Modal.Body>
          <div className="biewproductcontinaer">
            <div className="productineofnfjf">
              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Transaction Type</strong>
                </div>
                <div className="productonfprnf">Payment Receipt</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>
                    {loginuser.role === "user"
                      ? "Vendor name"
                      : "Customer Name"}
                  </strong>
                </div>
                <div className="productonfprnf">
                  {loginuser.role === "vendor"
                    ? item.customername
                    : item.vendorName}
                </div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Customer Address</strong>
                </div>
                <div className="productonfprnf">{item.customerAddress}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>
                    {loginuser.role === "user"
                      ? "Vendor Email"
                      : "Customer Email"}
                  </strong>
                </div>
                <div className="productonfprnf">
                  {loginuser.role === "vendor"
                    ? item.customerEmail
                    : item.vendemail}
                </div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>
                    {loginuser.role === "user"
                      ? "Vendor Mobile Number"
                      : "Customer Mobile Number"}
                  </strong>
                </div>
                <div className="productonfprnf">
                  {loginuser.role === "vendor"
                    ? item.cusmerMobile
                    : item.vendorMobile}
                </div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Amount</strong>
                </div>
                <div className="productonfprnf">{item.productamount}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Quantity</strong>
                </div>
                <div className="productonfprnf">{item.productquantity}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Payment Details</strong>
                </div>
                <div className="productonfprnf">{item.productdetails}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Delivery Method</strong>
                </div>
                <div className="productonfprnf">{item.deliverymethod}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Delivery by</strong>
                </div>
                <div className="productonfprnf">
                  {item.deliverymethod === "Self Delivery"
                    ? item.personalName
                    : item.logisticCompany}
                </div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Delivery Status</strong>
                </div>
                <div className="productonfprnf">
                  {item.isRecived === true ? "Recieved" : "Not Recieved"}
                </div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>status</strong>
                </div>
                <div className="productonfprnf">
                  {item.isClose === true ? "Closed" : "Open"}
                </div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Date</strong>
                </div>
                <div className="productonfprnf">
                  {item.created
                    ? format(item.created, "dd/MM/yyyy hh:mm a")
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isloading || loading || conloading}
            variant="success"
            onClick={handleopenChat}
            style={{display: item.isDispute ===  true ? "block" : "none"}}
          >
            Chat
          </Button>

          <Button
            disabled={isloading || loading || conloading}
            variant="secondary"
            onClick={handleDisputcenter}
            style={{display: item.isDispute ===  true ? "block" : "none"}}
          >
            Go to Dispute Center
          </Button>
          <Button
            disabled={isloading || loading || conloading}
            variant="danger"
            onClick={handleCloseview}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="giftcardocne">
        <h1>Receipt History</h1>
        <div className="girckahdicbd">
          <button onClick={history.goBack}>Back</button>
          <button className="ms-1">{usecollection.length} Transaction</button>
          <button
            className="ms-1"
            style={{ display: loginuser.role === "vendor" ? "block" : "none" }}
            onClick={handlerequest}
          >
            Create Payment Request
          </button>
          <Form.Control
            type="text"
            placeholder="Search by Name"
            className="w-25 ms-1"
            onChange={(event) => {
              setsearch(event.target.value);
            }}
          />
        </div>
        <div
          className="cardshowarea">
          <div className="cardvaljsjs">
            Any section with <span className="text-danger">red color</span>{" "}
            means , the transaction is on dispute and need attention <br />
            <table>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th className="hidetable">Created</th>
                  <th>
                    {loginuser.role === "user"
                      ? "Vendor name"
                      : "Customer Name"}
                  </th>
                  <th>Product Name</th>
                  <th>Quantity</th>
                  <th>Amount</th>
                  <th>Payment Status</th>
                  <th>Dispute Status</th>
                  <th>Delivery Method</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {records
                  .filter((value) => {
                    if (value === "") {
                      return value;
                    } else if (
                      value.customername
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return value;
                    } else if (
                      value.TrackingID.toLowerCase().includes(
                        search.toLowerCase()
                      )
                    ) {
                      return value;
                    } else if (
                      value.cusmerMobile
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return value;
                    }
                  })
                  .map((item, id) => {
                    const d = timeStamptoDate(item);
                    const date = moment(d.Vendorcreated).fromNow();
                    return (
                      <tr key={id}>
                        <td>{id + 1}</td>

                        <td className="hidetable">{date}</td>
                        <td>
                          {loginuser.role === "vendor"
                            ? item.customername
                            : item.vendorName}
                        </td>
                        <td className="hidetable">{item.productname}</td>
                        <td className="hidetable">{item.productquantity}</td>
                        <td className="hidetable">{item.productamount}</td>
                        <td className="hidetable">
                          {item.ispaid === false ? "Pending" : "Paid"}
                        </td>
                        <td className="hidetable">
                          {item.isDispute === true ? "YES" : "NO"}
                        </td>
                        <td className="hidetable">{item.deliverymethod}</td>
                        <td>{item.isClose === false ? "OPEN" : "CLOSE"}</td>
                        <td>
                          <ButtonGroup aria-label="Basic example">
                            <Button
                              variant={
                                item.isDispute === true ? "danger" : "success"
                              }
                              onClick={Togglemodalview(item)}
                            >
                              View
                            </Button>
                            {/* <Button
                              variant="primary"
                              onClick={Togglemodal(item)}
                            >
                              Edit
                            </Button> */}
                          </ButtonGroup>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            <nav>
              <ul className="pageination">
                <li className="page-item">
                  <span className="page-link" onClick={prepage}>
                    Prev
                  </span>
                </li>

                {numbers.map((n, i) => {
                  return (
                    <li
                      key={i}
                      className={
                        currentPage === n ? "activenumber" : "page-item"
                      }
                    >
                      <span className="page-link" onClick={() => changecurrentPage(n)}>
                        {n<1 ? 1 : n}
                      </span>
                    </li>
                  );
                })}

                <li className="page-item">
                  <span className ="page-link" onClick={Nextpage}>
                    Next
                  </span>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VendorTransaction;
