
import AdminSidemenu from "../AdminComponent/AdminSidemenue"
import React from "react";
import { useHistory } from "react-router-dom";
import "../../Sidemiindex.css";
import VendorMagnamecom from "../AdminComponent/VendorMagnamecom";

function Dashboard() {
const history = useHistory()
  return (
    <div>
    <div className="pagecon">
      <div className="pagecontent">
        <div className="headersisecoadmin">
        <AdminSidemenu/>
        </div>
        <div className="otherssecoth">
        <div className="dashboardconteier">
        <VendorMagnamecom/>
      </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Dashboard;