import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "./Admincreation.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import firebase from "firebase";
import { useHistory } from "react-router-dom";
import axios from "axios";

import { getRandomString } from "../../../Services/GetRandomNumber";
import {storage } from "../../../Services/firebase/firebase";
import { backendUrl } from "../../../Services/firebase/firebase";

function AdminRegister() {
  const [loading, setloading] = useState(false);
  const [firstname, setfirst] = useState("");
  const [lastname, setlast] = useState("");
  const [email, setemail] = useState("");
  const [number, setPhone] = useState("");
  const [password, setpassword] = useState("");
  const [image, setimage] = useState(null);
  const [progress, setProgress] = useState(0);
  const history = useHistory();
  const baseURL = backendUrl+ "createNewAdminHandler";

  const handleimage = (e) => {
    if (e.target.files[0]) {
      setimage(e.target.files[0]);
    }
  };

  function handleUpload() {
    return new Promise((resolve) => {
      const uploadTask = storage.ref(`customerImage/${image.name}`).put(image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          toast.error(error);
        },
        () => {
          storage
            .ref("customerImage")
            .child(image.name)
            .getDownloadURL()
            .then((url) => {
              resolve(url);
            });
        }
      );
    });
  }

  const handleregister = async (e) => {
    e.preventDefault();
    setloading(true);
    const image = await handleUpload();
    const UniquieNum = getRandomString(7, "1234567890");
    const data = {
      UniquieNum,
      email,
      image,
      password,
      number,
      firstname,
      lastname,
      wallet: 0,
      coin: 0,
      isAllowed: true,
      created: firebase.firestore.FieldValue.serverTimestamp(),
    };

    if (
      password === "" ||
      firstname === "" ||
      lastname === "" ||
      email === "" ||
      number === ""
    ) {
      toast.error("required input value missing");
      return;
    }
    axios
      .post(baseURL, { data: data })
      .then(async (e) => {
        toast.success(e.data.messeage, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setInterval(() => {
          history.push(`/auth/login`);
        }, 2000);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      
      });

    setloading(true);
  };

  return (
    <div>
      <ToastContainer />
      <div className="adminconterr">
        <div className="adminrpujd">
          <h1>Hello Admin</h1>
          <p>Please Verify candidate before proceeding</p>
          <div className="adminconter">
            <Form>
              <Row>
                <Col>
                  <Form.Control
                    placeholder="First name"
                    onChange={(event) => {
                      setfirst(event.target.value);
                    }}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                    placeholder="Last name"
                    onChange={(event) => {
                      setlast(event.target.value);
                    }}
                    required
                  />
                </Col>
              </Row>

              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  required
                  onChange={(event) => {
                    setemail(event.target.value);
                  }}
                />
              </Form.Group>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="tel"
                    required
                    onChange={(event) => {
                      setPhone(event.target.value);
                    }}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    required
                    onChange={(event) => {
                      setpassword(event.target.value);
                    }}
                  />
                </Form.Group>
              </Row>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Image</Form.Label>
                <Form.Control type="file" onChange={handleimage} required />
              </Form.Group>

              <Button
                className="w-100"
                onClick={handleregister}
                variant="success"
                disabled={loading}
              >
                {loading ? 
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                : 
                  "Register"
                }
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminRegister;
