
import React, { useCallback, useEffect, useState } from "react";
import { Button, ButtonGroup, Form, Modal } from "react-bootstrap";
import {
  getuserwallet,
} from "../../../Services/GetUser.service";
import "./User.css";
import axios from "axios";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { database } from "../../../Services/firebase/firebase";
import { timeStamptoDate } from "../../../Services/Utility";
import { format } from "date-fns";
import { useHistory, useParams } from 'react-router-dom'
import "./User.css"
import { useAuth } from "../../../Services/UserAuth";
import firebase from "firebase"
import { backendUrl } from "../../../Services/firebase/firebase";
let userRef = database.collection("Transaction").orderBy("created", "desc");



function UserProfiecompaone() {
  const id =  useParams().id
  const history =  useHistory()
  const [usecollection, setusercollection] = useState([]);
  const [showview, setShowview] = useState(false);
  const { loginuser } = useAuth();
  const handleCloseview = () => setShowview(false);
  const [search, setsearch] = useState("");
  const handleShowview = () => setShowview(true);
  const [item, setitem] = useState([]);
  const [loading, setloading] = useState(false);
  const [userwallet, setuserwallet] = useState([]);
 const [userdata, setuserdata] = useState([])
  const [lastDocs, setlastDocs] = useState();
  const [isEmpty, setisEmpty] = useState(false);

  const handlecleidkd = ()=>{
    history.push(`/profile/user-manager/${id}`)
  }

  const handlewallet = ()=>{
    history.push(`/profile/user-wallet-manager/${id}`)
  }
  
  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && !isEmpty) {
      fetchMore();
    }
  };
  
const k =  backendUrl+ `getUserkitipay/users/${id}`
  useEffect(() => {
   axios.get(k).then((user)=>{
    const userrecord = user.data[0]
    if (userrecord){
      setuserdata(userrecord)
    }else {
      setuserdata([])
    }
   

   })
  }, [])
  
  
  useEffect(() => {
    userRef.where("userID","==",id)
      .limit(30)
      .get()
      .then((collections) => {
        updateState(collections);
      });
  }, [isEmpty]);

  const updateState = (collections) => {
    const isItemEmpty = collections.size === 0;
    if (!isItemEmpty) {
      const users = collections.docs.map((user) => user.data());
      const lastDoc = collections.docs[collections.docs.length - 1];
      setusercollection((userCollection) => [...userCollection, ...users]);
      setlastDocs(lastDoc);
    } else {
      setisEmpty(true);
    }
    setloading(false);
  };

  const fetchMore = () => {
    setloading(true);
    userRef
      .startAfter(lastDocs)
      .limit(20)
      .get()
      .then((collections) => {
        updateState(collections);
      });
  };

  const Togglemodalview = useCallback(
    (item) => () => {
     
      getuserwallet(item.id, (res) => {
        setuserwallet(res);
      });
      setitem(item);
      handleShowview();
    },
    []
  );

  const handlresolved = async (e) => {
    e.preventDefault();
    if (item.isclosed ===  true){
        toast.error("already Treated")
        return
    }
    setloading(true);
    try {
        await  database
        .doc(`/Transaction/${item.id}`)
        .update({
          isclosed: true,
         })
         if (item.com){
          await  database.doc(`/income/vRgRWLBNzwo7IM1pUhcl`).update({
            income: firebase.firestore.FieldValue.increment(item.com),
          })
         }
    } catch (error) {
      toast.error(error.message);
    }
    setloading(false);
  };

  const handlerevser = async (e) => {
    e.preventDefault();

    if (loginuser.role !== "admin") {
      toast.error("Auth Error");
      return;
    }
    if (item.isclosed ===  true){
        toast.error("already Treated")
        return
    }

    setloading(true);
    const data = {
      id: item.userID,
      role: loginuser.role,
      amount:item.amount,
    };
    try {
      const b =  backendUrl+ "credituser/users/wallet"
      await axios
        .post(
          b,
          {
            data,
          }
        )
        .then(async(r) => {
          toast.success(r.data.message);
          setloading(true);
        await  database
          .doc(`/Transaction/${item.id}`)
          .update({
            isclosed: true,
           })
        });
    } catch (error) {
      toast.error(error.message);
    }
    setloading(false);
  };
  return (
    <div>
       <ToastContainer />
      <Modal
        show={showview}
        onHide={handleCloseview}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton className="modalheader">
          Transaction Details
        </Modal.Header>
        <Modal.Body>
          <div className="biewproductcontinaer">
            <div className="productineofnfjf">
              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Transaction Type</strong>
                </div>
                <div className="productonfprnf">{item.type}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Service Type</strong>
                </div>
                <div className="productonfprnf">{item.company}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Amount</strong>
                </div>
                <div className="productonfprnf">{item.amount}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Account Number</strong>
                </div>
                <div className="productonfprnf">{item.AccountNumber}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>status</strong>
                </div>
                <div className="productonfprnf">{item.status}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Date</strong>
                </div>
                <div className="productonfprnf">
                  {item.created
                    ? format(item.created, "dd/MM/yyyy hh:mm a")
                    : ""}
                </div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Row Data</strong>
                </div>
                <div className="productonfprnf">{JSON.stringify(item)}</div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handlerevser}>
            Reverse
          </Button>

          <Button variant="primary" onClick={handlresolved}>
            Resolve
          </Button>

          <Button variant="secondary" onClick={handleCloseview}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="usercoletcddjd">
        <div className="useheader">
          <div className="useralkjeadd istheactibeone" onClick={handlecleidkd}>
          <h1>User Transaction</h1>
          </div>
          <div className="useralkjeadd" onClick={handlewallet}>
          <h1>Wallet Transaction</h1>
          </div>
        </div>

        <div className="transactionproper">
        <div className="giftcardocne">
        <h1>User Information Summary</h1> 
         <div className="userinformatuonsumma">
          <p><strong>Name</strong>&nbsp; {userdata.firstname} {userdata.lastname}</p>
          <p><strong>Phone</strong>&nbsp; &nbsp;{userdata.number}</p>
          <p><strong>Email</strong> &nbsp; &nbsp;{userdata.email}</p>
          <p><strong>Status</strong>&nbsp; &nbsp;{userdata.isBlock ===  false ? "Active" : "Suspended"}</p>
          <p><strong>Role</strong>&nbsp; &nbsp;{userdata.role}</p>
          </div>   
        <div className="girckahdicbd">
          <button onClick={history.goBack}>Back</button>
          <button className="ms-1">{usecollection.length} Transaction</button>

          <Form.Control
            type="text"
            placeholder="Search by Name"
            className="w-25 ms-1"
            onChange={(event) => {
              setsearch(event.target.value);
            }}
          />
        </div>
        <div
          className="cardshowarea"
          onScroll={handleScroll}
          style={{ overflowY: "scroll", maxHeight: "500px" }}
        >
          <div className="cardvaljsjs">
            <table>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th className="hidetable">Created</th>
                  <th>Type</th>
                  <th className="hidetable">Service Type</th>
                  <th className="hidetable">Amount</th>
                  <th className="hidetable">Account Number</th>
                  <th>Validity</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {usecollection.length === 0 ? <>
                No Data Found
                </> : <>
                {usecollection
                  .filter((value) => {
                   
                    if (value === "") {
                      return value;
                    } else if (
                      value.company.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return value;
                    } else if (
                      value.type.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return value;
                    }
                  })
                  .map((item, id) => {
                    const d = timeStamptoDate(item);
                    const date = moment(d.created).fromNow();
                    return (
                      <tr key={id}>
                        <td>{id + 1}</td>

                        <td className="hidetable">{date}</td>
                        <td>{item.type}</td>
                        <td className="hidetable">{item.company}</td>
                        <td className="hidetable">{item.amount}</td>
                        <td className="hidetable">{item.AccountNumber}</td>
                        <td>
                          {item.isclosed === true ? "Closed" : "Open"}
                        </td>
                        <td>
                          {item.status === "success" ? "success" : "failed"}
                        </td>
                        <td>
                          <ButtonGroup aria-label="Basic example">
                            <Button
                              variant="success"
                              onClick={Togglemodalview(item)}
                            >
                              View
                            </Button>
                            {/* <Button
                              variant="primary"
                              onClick={Togglemodal(item)}
                            >
                              Edit
                            </Button> */}
                          </ButtonGroup>
                        </td>
                      </tr>
                    );
                  })}
                </>}
              </tbody>
            </table>
          </div>
        </div>
      </div>
        </div>
      </div>
    </div>
  )
}

export default UserProfiecompaone