import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { SendNotification, getuser } from "../../../Services/GetUser.service";
import "../ClientPage/Vendor.css";
import { ToastContainer, toast } from 'react-toastify';
import { useAuth } from "../../../Services/UserAuth";
import { database } from "../../../Services/firebase/firebase";
import { getRandomString } from "../../../Services/GetRandomNumber";
import axios from "axios"
import { backendUrl } from "../../../Services/firebase/firebase";
import firebase from "firebase";
function VendorCreateRequest() {
  const [user, setuser] = useState([]);
  const [deliveryMethod, setdeliveryMethod] = useState("");
  const [loading, setloading] = useState(false);
  const [address, setaddress] = useState("");
  const {loginuser} =  useAuth()
  const [productname, setproductname] = useState("");
  const [quantity, setquantity] = useState("");
  const [amount, setamount] = useState("");
  const [productdetails, setproductdetails] = useState("");
  const [logisticscompany, setlogisticCompnay] = useState("");
  const [trackID, setTrackingID] = useState("");
  const [personal, setDeliverypersonalname] = useState("");
  const [personalMobile, setDeliveryoersonalmobileNumber] = useState("");
  const id = useParams().id;
  useEffect(() => {
    getuser(id, (res) => {
      setuser(res);
    });
  }, []);

  const handlesendReceiop = async (e) => {
    e.preventDefault();

    if (isNaN(amount)){
        toast.error("Invalide amount ")
        return
    }

    if (
      address === "" ||
      productname === " " ||
      quantity === "" ||
      amount === "" ||
      productdetails == ""
    ){
        toast.error("Provide all required information")
        return
    }

    if (deliveryMethod === "Logistics" && logisticscompany === ""){
          toast.error("Please Provide the logistic company name")
          return
      }
      if (deliveryMethod === "Logistics" && trackID === ""){
        toast.error("Please Provide the logistic Tracking Number")
        return
    }

    if (deliveryMethod === "Self Delivery" && personal === ""){
        toast.error("Please Provide logistic personal full name")
        return
    }

    if (deliveryMethod === "Self Delivery" && personalMobile === ""){
        toast.error("Please Provide logistic personal mobile number")
        return
    }
    const id = database.collection("_").doc().id; 
    const UniquieNum = getRandomString(12, "1234567890");
    const data = {
        customername: `${user.firstname} ${user.lastname}` ,
        cusmerMobile:`${user.number}`,
        customerEmail:`${user.email}`,
        customerAddress:address,
        productname:productname,
        productquantity:quantity,
        productamount:parseFloat(amount),
        productdetails:productdetails,
        deliverymethod:deliveryMethod,
        logisticCompany:logisticscompany,
        TrackingID:deliveryMethod === "Logistics" ?trackID : UniquieNum,
        personalName:personal,
        personalMobile:personalMobile,
        customerID: user.id,
        vendorID:loginuser.id,
        vendemail:loginuser.email,
        vendorMobile:loginuser.number,
        vendorfullname: `${loginuser.businessname}`,
        id
    }
      try {
        setloading(true)
        await axios.post(backendUrl+ "createVendorRequest/vendor/services",{
            data
        }).then((res)=>{
         
            toast.success(res.data.message + " Redirecting...")
            setInterval(() => {
             window.location.href = "/vendor/create-new-request"
            }, 3000);
        })
        const nofifyID = getRandomString(6, "1234567890")
        const notificationBody = {
          message: `${data.vendorfullname} just created an invoice for ${data.productname} product`,
          created: firebase.firestore.FieldValue.serverTimestamp(),
          isread: false,
          id: nofifyID,
          userID: "",
          isRi: 0
        }
        await SendNotification(notificationBody, data.customerID, "both")
      } catch (error) {
        toast.error(error.message)
      }
      setproductdetails("")
      setproductname("")
      setquantity("")
      setloading(false)
  };

  return (
    <div>
        <ToastContainer/>
      <div className="vendorqcindhdhf">
        <div className="recojdjojnetehd">
          <div className="headerrequstload">Voucher Form</div>

          <div className="formcontineconehhd">
            <h1>Customer Information</h1>
            <Form.Group className="mb-3">
              <Form.Label>Customer's name</Form.Label>
              <Form.Control
                disabled
                value={`${user.firstname} ${user.lastname}`}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Customer's Mobile Number</Form.Label>
              <Form.Control disabled value={user.number} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Customer's Email</Form.Label>
              <Form.Control disabled value={user.email} />
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Customer's Address</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                required
                onChange={(event) => {
                  setaddress(event.target.value);
                }}
              />
              <Form.Text className="text-muted">
                Share with us the customer full Address
              </Form.Text>
            </Form.Group>

            <hr />
            <h1>Product Information</h1>
            <Form.Group className="mb-3">
              <Form.Label>Product name</Form.Label>
              <Form.Control
                type="text"
                onChange={(event) => {
                  setproductname(event.target.value);
                }}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Quantity</Form.Label>
              <Form.Control
                type="tel"
                onChange={(event) => {
                  setquantity(event.target.value);
                }}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="tel"
                onChange={(event) => {
                  setamount(event.target.value);
                }}
              />
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Product Details</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                required
                onChange={(event) => {
                  setproductdetails(event.target.value);
                }}
              />
              <Form.Text className="text-muted">
                Share with us Berief information of the item
              </Form.Text>
            </Form.Group>

            <hr />

            <h1>Delivery Method</h1>
            <Form.Select
              aria-label="Default select example"
              onChange={(event) => {
                setdeliveryMethod(event.target.value);
              }}
            >
              <option value="">Open this select Delivery Method</option>
              <option value="Logistics">Logistics Company</option>
              <option value="Self Delivery">Self Delivery</option>
            </Form.Select>

            <Form.Group
              className="mb-3"
              style={{
                display: deliveryMethod === "Logistics" ? "block" : "none",
              }}
            >
              <Form.Label>Logistic Company Name</Form.Label>
              <Form.Control
                type="text"
                onChange={(event) => {
                  setlogisticCompnay(event.target.value);
                }}
              />
            </Form.Group>

            <Form.Group
              className="mb-3"
              style={{
                display: deliveryMethod === "Logistics" ? "block" : "none",
              }}
            >
              <Form.Label>Tracking ID </Form.Label>
              <Form.Control
                type="text"
                onChange={(event) => {
                  setTrackingID(event.target.value);
                }}
              />

              <Form.Text className="text-muted">
                Provide the Tracking Number given to you by the logistic company
              </Form.Text>
            </Form.Group>

            <Form.Group
              className="mb-3"
              style={{
                display: deliveryMethod === "Self Delivery" ? "block" : "none",
              }}
            >
              <Form.Label>Delivery Personal Name</Form.Label>
              <Form.Control
                type="email"
                onChange={(event) => {
                  setDeliverypersonalname(event.target.value);
                }}
              />
            </Form.Group>

            <Form.Group
              className="mb-3"
              style={{
                display: deliveryMethod === "Self Delivery" ? "block" : "none",
              }}
            >
              <Form.Label>Delivery personal Mobile Number </Form.Label>
              <Form.Control
                type="email"
                onChange={(event) => {
                  setDeliveryoersonalmobileNumber(event.target.value);
                }}
              />
            </Form.Group>

            <Button
              disabled={loading}
              variant={loading ? "warning" : "primary"}
              onClick={handlesendReceiop}
              className="w-100"
              type="submit"
            >
              {loading ? "Sending Receipt.." : "Send..."}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VendorCreateRequest;
