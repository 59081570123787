import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../Services/UserAuth'
import Sidebar from '../ClientComponent/Sidemenue'

import "../ClientComponent/Complaince.css";
import { Button, Form } from 'react-bootstrap'
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Bank from "../../../Bank.json";
import { backendUrl } from "../../../Services/firebase/firebase";
import { useHistory } from 'react-router-dom';
function UserprofilePage() {
    const { loginuser } = useAuth()
    const history = useHistory()
    const bank = Bank.data;
    const [bankcode, setselectedBank] = useState("");
    const [norecordfund, setnorecord] = useState(true);
    const [message, setmessage] = useState("");
    const [accountnumber, setaccouuntnumber] = useState("")
    const BankUrl =
        backendUrl + "getBankHolder2";
    const [accountHolder, setHolderinfo] = useState("");
    const [res, setres] = useState("")

    // useEffect(() => {
    //     if (
    //       loginuser.isbusinessInfoSubmited !== true ||
    //       loginuser.isbusinessRepSubmited !== true
    //     ) {
    //         console.log("1234567890")
    //       history.push("/dashboard/Compliance");
    //       return;
    //     }
    //   }, []);
    const selectba = bank.find((e) => e.bankCode === bankcode)

    const getAccountNumber = async (e) => {
        const acc = e.target.value;
        setaccouuntnumber(acc)
        setnorecord(true);
        if (
            loginuser.isbusinessInfoSubmited === false ||
            loginuser.isbusinessRepSubmited === false
        ) {
            setmessage(
                "Dear Partner Kindly Submit your buinses information and business representative information before proceeding to bank information. thanks"
            );
            return;
        }
        setHolderinfo("Please enter Account Number");
        if (acc.length === 10) {
            setHolderinfo("Please Wait...");
            await axios
                .post(BankUrl, {
                    bank_code: bankcode,
                    account_number: acc,
                })
                .then((res) => {
                    if (res.data.data.message === "Request failed with status code 400") {
                        setHolderinfo("No Information Found.");
                        setnorecord(true);
                        return;
                    }
                    const resPaload = res.data.data.data;
                    setHolderinfo(resPaload.account_name);
                    setnorecord(false);
                });
        }
    };

    const handleAccouuntInfo = async (e) => {
        e.preventDefault();
        const data = {
            id: loginuser.id,
            BusinessAcccountName: accountHolder,
            BusinessAcccountNumber: accountnumber,
            BusinessBankName: selectba.name,
        };

        setnorecord(true);
        setres("Gathering information to process. Please wait");
        try {
            await axios.post(backendUrl + "getbankInformation/BankInformation",{
                data
            }).then((result)=>{
                if (result.data.message === "Record Summited successfully..awaiting verification from the admin"){
                    history.push("/compliance-verify/document-upload")
                }
                return
            })     

        } catch (error) {
            toast.error(error.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        setres("");
        setnorecord(false);
    };


    return (
        <div>
            <div className="pagecon">
                <div className="pagecontent">
                    <div className="headersisecoadmin">
                        <Sidebar />
                    </div>
                    <div className="otherssecoth">
                        <div className="dashboardconteier">
                            <div className="topmenuecontrenr">
                                <Button
                                    variant="primary"
                                    onClick={history.goBack}
                                >
                                    Back
                                </Button>
                                {loginuser.isbusinessBankSubmited === true ? (
                                    <>
                                        <div className="aformconteinr">
                                            <div className="albs">
                                                {message ? message : ""}
                                                {loginuser.isbusineDocVerify !== true ? (
                                                    <div className="text-danger"> Awaitn Admin Approval</div>
                                                ) : (
                                                    <div className="text-success">Verified</div>
                                                )}
                                            </div>
                                            <div className="informatronpobody">
                                                <div className="inforalteader"> Bank Name :</div>
                                                <div className="headerbodycontainer">
                                                    {loginuser.BusinessBankName}
                                                </div>
                                            </div>

                                            <div className="informatronpobody">
                                                <div className="inforalteader">Account Name :</div>
                                                <div className="headerbodycontainer">
                                                    {loginuser.BusinessAcccountName}
                                                </div>
                                            </div>

                                            <div className="informatronpobody">
                                                <div className="inforalteader">Account Number :</div>
                                                <div className="headerbodycontainer">
                                                    {loginuser.BusinessAcccountNumber}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="aformconteinr">
                                        <p>Bank Information</p>

                                        <Form.Label>Bank</Form.Label>
                                        <Form.Select
                                            aria-label="Default select example"
                                            onChange={(event) => {
                                                setselectedBank(event.target.value);
                                            }}
                                        >
                                            <option value="">Open to select Bank</option>
                                            {bank.map((e) => {
                                                return <option value={e.bankCode}>{e.name}</option>;
                                            })}
                                        </Form.Select>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Account Number</Form.Label>
                                            <Form.Control type="text" onChange={getAccountNumber} />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Account Name</Form.Label>
                                            <Form.Control type="text" disabled value={accountHolder} />
                                        </Form.Group>

                                        <Button
                                            variant={norecordfund ? "warning" : "dark"}
                                            className="w-100"
                                            onClick={handleAccouuntInfo}
                                        >
                                            {norecordfund ? "Waiting..." : "Send Request"}
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default UserprofilePage