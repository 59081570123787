import React, { useCallback, useEffect, useState } from "react";
import { Button, ButtonGroup, Form, Modal } from "react-bootstrap";
import {
    getOrder,
 getuser,
} from "../../../Services/GetUser.service";
import moment from "moment";
import { useHistory } from "react-router-dom";
import "../../Admin/AdminComponent/User.css";
import { useAuth } from "../../../Services/UserAuth";
import { ToastContainer, toast } from "react-toastify";
import { timeStamptoDate } from "../../../Services/Utility";
import { format } from "date-fns";

function UserLog() {
  const [usecollection, setusercollection] = useState([]);
  const [showview, setShowview] = useState(false);
  const handleCloseview = () => setShowview(false);
  const [search, setsearch] = useState("");
  const handleShowview = () => setShowview(true);
  const history = useHistory();
  const [item, setitem] = useState([]);
  const { uid } = useAuth().currentUser;

  const [currentPage, setcurrentpage] = useState(1);
  const recordpage = 20;
  const lastIndex = currentPage * recordpage;
  const firstIndex = lastIndex - recordpage;
  const records = usecollection.slice(firstIndex, lastIndex);
  const npage = Math.ceil(usecollection.length / recordpage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [showedit, setShowedit] = useState(false);
  const handleCloseedit  = () =>{
    setShowedit(false);
    window.location.reload()
  }
  const [user, setuser]= useState([])

  useEffect(() => {

    getuser(uid,(u)=>{
        getOrder((result) => {
            const data = u.role === "vendor"?  result.filter((e) => e.vendorID === uid) : result.filter((e) => e.customerID === uid)
            setusercollection(data)
          })
        setuser(u)
    })
  }, [])



  const prepage = () => {
    if (currentPage !== 1) {
      setcurrentpage(currentPage - 1);
    }
  };

  const Nextpage = () => {
    if (currentPage !== npage) {
      setcurrentpage(currentPage + 1);
    }
  };

  const changecurrentPage = (id) => {
    setcurrentpage(id);
  };


  const Togglemodalview = useCallback(
    (item) => () => {
      setitem(item);
      handleShowview();
    },
    []
  );


  const handleCreateVoucher =()=>{
    history.push(`/marchant/create-order/request/${item.id}`)
  }

  const vendorHom = () => {
    history.push(`/vendor/home/${user.id}`);
  };
  return (
    <div>
      <ToastContainer />
      <Modal className="bg-dark" show={showedit} onHide={handleCloseedit} animation={false} backdrop="static" centered>

      </Modal>
      <Modal
        show={showview}
        onHide={handleCloseview}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton className="modalheader">
          Order  Details
        </Modal.Header>
        <Modal.Body>
          <div className="biewproductcontinaer">
            <div className="productineofnfjf">
              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Product name</strong>
                </div>
                <div className="productonfprnf">{item.productname}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>product Description</strong>
                </div>
                <div className="productonfprnf">{item.productdetails}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Price</strong>
                </div>
                <div className="productonfprnf">{item.productamount}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Contact Number</strong>
                </div>
                <div className="productonfprnf">{user.role === "vendor" ? item.customernumber : item.vendorMobile}</div>
              </div>


              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Contact name</strong>
                </div>
                <div className="productonfprnf">{user.role === "vendor" ? item.customername : item.vendorfullname}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong> Email</strong>
                </div>
                <div className="productonfprnf">{user.role === "vendor" ? item.customerEmail : item.vendemail}</div>
              </div>


              
              <div className="productdetaund">
                <div className="producttitle">
                  <strong> Delivery location</strong>
                </div>
                <div className="productonfprnf">{item.customerAddress}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Price</strong>
                </div>
                <div className="productonfprnf">{item.productamount}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Order Status</strong>
                </div>
                <div className="productonfprnf">{item.is_accepted === false ? "Pending" : "Accepted"}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong> Customer Account Status</strong>
                </div>
                <div className="productonfprnf">{user.role === "vendor" ? `Account Created/${item.isUserverifed}`: `Account Created/${item.isUserverifed}`}</div>
              </div>


            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleCreateVoucher} style={{display : user.role === "vendor" ? "block" : "none" }}>
            Create voucher
          </Button>
          <Button variant="secondary" onClick={handleCloseview} >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="giftcardocne">
        <h1>Order History</h1>
        <div className="girckahdicbd111212">
          <Form.Control
            type="text"
            placeholder="Search by Order_id, Product name"
            className="w-25 ms-1"
            onChange={(event) => {
              setsearch(event.target.value);
            }}
          />

<button onClick={vendorHom}>Voucher History</button>
        </div>
       
        <div
          className="cardshowarea"
        >
          <div className="cardvaljsjs">
            <table>
              <thead>
                <tr>
                  <th className="hidetable">S/N</th>
                  <th>Created</th>
                  <th>Order ID</th>
                  <th style={{display: user.role ==="vendor" ?  "block":"none"}} className="hidetable">Customer Name</th>
                  <th style={{display: user.role ==="vendor" ?  "none":"block"}} className="hidetable">Vendor Name</th>
                  <th>Price</th>
                  <th >product Name</th>
                  <th> Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody >
                {records.length === 0 ? <>

                  No product Created

                </> :
                  <>
                    {records
                      .filter((value) => {
                      
                        if (value === "") {
                          return value;
                        } else if (
                          value.ordercode.toLowerCase().includes(search.toLowerCase())
                        ) {
                          return value;
                        }
                      })
                      .map((item, id) => {
                        const d = timeStamptoDate(item);
                        const date = moment(d.created).fromNow();
                        let col = ""
                        if (item.isApproved === true) {
                          col = "green"
                        }
                        if (item.status === "error") {
                          col = "red"
                        }
                        if (item.status === "Processing" && item.isApproved === false) {
                          col = "orange"
                        }
                        return (
                          <tr key={id}>
                            <td className="hidetable" >{id + 1}</td>
                            <td  className="hidetable">
                              {item.created
                                ? format(item.created, "dd/MM/yyyy hh:mm a")
                                : ""}
                            </td>
                            <td>{item.ordercode}</td>
                            <td  style={{display: user.role ==="vendor" ?  "flex":"none"}}>{item.customername}</td>
                            <td  style={{display: user.role ==="vendor" ?  "none":"flex"}}>{item.vendorfullname}</td>
                            <td>{item.productamount}</td>
                            <td > {item.productname}</td>
                            <td style={{ color: col }} > {item.is_accepted === false ? "Await Approval" : "Approved"}</td>
                            <td>
                              <ButtonGroup aria-label="Basic example" >
                                <Button
                                  variant="success"
                                  onClick={Togglemodalview(item)}
                                >
                                  View
                                </Button>
                                {/* <Button
                              variant="primary"
                              onClick={Togglemodal(item)}
                            >
                              Edit
                            </Button> */}
                              </ButtonGroup>
                            </td>
                          </tr>
                        );
                      })}

                  </>
                }

              </tbody>
            </table>

            <nav>
              <ul className="pageination">
                <li className="page-item">
                  <span className="page-link" onClick={prepage}>
                    Prev
                  </span>
                </li>

                {numbers.slice(0, 10).map((n, i) => {
                  return (
                    <li
                      key={i}
                      className={
                        currentPage === n ? "activenumber" : "page-item"
                      }
                    >
                      <span className="page-link" onClick={() => changecurrentPage(n)}>
                        {n < 1 ? 1 : n}
                      </span>
                    </li>
                  );
                })}

                <li className="page-item">
                  <span className="page-link" onClick={Nextpage}>
                    Next
                  </span>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserLog;
