import React from "react";
import { Link } from "react-router-dom";
import "../Becomeavendor.css";
import {BsCollection, BsCardChecklist} from "react-icons/bs"
import {CiMoneyCheck1} from "react-icons/ci"
import {AiOutlineSolution} from "react-icons/ai"

function Becomevendor() {
  return (
    <div>
      <div className="becomeavendoer">
        <div className="vendorcontainer">
          <div className="vendorcinreinr">
            <div className="vendortextsection">
              <h1>
                Connect your business to the payment tools that suit your <strong>business</strong>
              </h1>
              <p>
                Allow your customer Create flexible payment methods with kitipay
                smart invoicing tools to save time and money. Shop with peace of
                mind, your eligible purchase is protected by us. If it doesn’t
                show up, or shows up different than described, we’ll help sort
                things out with the seller.{" "}
                <Link to="/privacy-policy/vendor">Conditions apply</Link>.
              </p>
            </div>
            <div className="imagesectuns">
              <div className="aboutvendorservices">
                <div className="imagesect">
                    <p><BsCollection/></p>
                    <h1>Secured Payment Processing Solution</h1>
                </div>

                <div className="imagesect">
                    <p><BsCardChecklist/></p>
                    <h1>Robust reporting Dashboard</h1>
                </div>

                <div className="imagesect">
                    <p> <CiMoneyCheck1/></p>
                    <h1>Easy Bill Payment solution</h1>
                </div>

                <div className="imagesect">
                    <p><AiOutlineSolution/></p>
                    <h1>Payment Solution Development</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Becomevendor;
