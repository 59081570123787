import React, { useCallback, useEffect, useState } from "react";
import { Button, ButtonGroup, Form, Modal } from "react-bootstrap";
import {
  getProducts,
  getstauser,
  gettransactin,
  getuser,
  getuserwallet,
} from "../../../Services/GetUser.service";
import moment from "moment";
import { useHistory } from "react-router-dom";
import "../../Admin/AdminComponent/User.css";
import { useAuth } from "../../../Services/UserAuth";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { database, storage } from "../../../Services/firebase/firebase";
import { formatNumber, timeStamptoDate } from "../../../Services/Utility";
import { format, set } from "date-fns";

function UserLog() {
  const [usecollection, setusercollection] = useState([]);
  const [showview, setShowview] = useState(false);
  const { loginuser } = useAuth();
  const handleCloseview = () => setShowview(false);
  const [search, setsearch] = useState("");
  const handleShowview = () => setShowview(true);
  const history = useHistory();
  const [item, setitem] = useState([]);
  const [userwallet, setuserwallet] = useState([]);
  const { uid } = useAuth().currentUser;

  const [currentPage, setcurrentpage] = useState(1);
  const recordpage = 20;
  const lastIndex = currentPage * recordpage;
  const firstIndex = lastIndex - recordpage;
  const records = usecollection.slice(firstIndex, lastIndex);
  const npage = Math.ceil(usecollection.length / recordpage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [showedit, setShowedit] = useState(false);
  const handleCloseedit  = () =>{
    setShowedit(false);
    window.location.reload()
  }
  const handleShowedit = () => setShowedit(true);
  const [editOptions, seteditOption] = useState("")
  const [newproductname, setNewProductName] = useState("")
  const [newproductprice, setNewProductPrice] = useState(0)
  const [newproductdescriptyion, setNewDescriptpion] = useState("")
  const [newproductdiscount, setNewProductdiscount] = useState(0)
  const [file, setfile] = useState(null)
  const [progress, setProgress] = useState()
  const [message, setmessage] = useState("")
  const [extension, setFIleExtension] = useState("")
  const [image, setimage] = useState([])
  const [imageUrl, seturl] = useState([])
  const [loading, setloading] = useState(false)


  useEffect(() => {
    getProducts((result) => {
      const data = result.filter((e) => e.userID === uid)
      setusercollection(data)
    })
  }, [])

  const handlechangeImage = (e) => {
    if (e.target.files[0]) {
      setfile(e.target.files[0]);
      return;
    }
  }
  const handleChange = (e) => {
    if (image === null) {
      toast.error("empty image not allowed")
      return
    } else {
      for (let i = 0; i < e.target.files.length; i++) {
        const newImage = e.target.files[i];
        newImage["id"] = Math.random();
        setimage((prevState) => [...prevState, newImage]);
      }
    }
  };

  const handleUpload = (img) => {
    return new Promise((resolve) => {
      // not working as expected
      const uploadTask = storage.ref(`ProductImage/${img.name}`).put(img);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
        },
        async () => {
          storage
            .ref("ProductImage")
            .child(img.name)
            .getDownloadURL()
            .then((url) => {
              resolve(url);
            });
        }
      );
    });
  };

  function handleUploadimage() {
    return new Promise((resolve) => {
      const uploadTask = storage.ref(`ProductImage/${file.name}`).put(file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          setmessage(error);
        },
        () => {
          storage
            .ref("ProductImage")
            .child(file.name)
            .getDownloadURL()
            .then((url) => {
              resolve(url);
            });
        }
      );
    });
  }

  const prepage = () => {
    if (currentPage !== 1) {
      setcurrentpage(currentPage - 1);
    }
  };

  const Nextpage = () => {
    if (currentPage !== npage) {
      setcurrentpage(currentPage + 1);
    }
  };

  const changecurrentPage = (id) => {
    setcurrentpage(id);
  };


  const Togglemodalview = useCallback(
    (item) => () => {
      setitem(item);
      handleShowview();
    },
    []
  );

  const handleCreatepREOD = () => {
    history.push("/marchant/product/create-new")
  }

  const handleEditProduct = async (e) => {
    e.preventDefault()

    if (editOptions === "1") {
      if (newproductname === "") {
        toast.error("Enter new value")
        return
      }
      setloading(true)
      await database.doc(`product/${item.id}`).update({
        productname: newproductname
      })
      toast.success("Updated Successfully .. Reload required")
      setloading(false)
      return
    }

    if (editOptions === "2") {
      if (newproductdescriptyion === "") {
        toast.error("Enter new value")
        return
      }
      setloading(true)
      await database.doc(`product/${item.id}`).update({
        description: newproductdescriptyion
      })
      toast.success("Updated Successfully .. Reload required")
      setloading(false)
      return
    }

    if (editOptions === "3") {

      setloading(true)
      const coverImage = await handleUploadimage()
      await database.doc(`product/${item.id}`).update({
        coverimage: coverImage
      })
      toast.success("Updated Successfully .. Reload required")
      setloading(false)
      return
    }

    if (editOptions === "4") {

      setloading(true)
      const urls = await Promise.all(image.map((img) => handleUpload(img)));
      image.map((j, i) => {
        const filename = j.name;
        const imageSize = Math.round(j.size / 1024 / 1024);
        if (imageSize > 200) {
          toast.error("file to large. Max:200mb");
          return;
        }
        const fileExtension = filename.substring(filename.lastIndexOf(".") + 1);
        setFIleExtension(fileExtension);
        const ImageExtension = ["JPG", "JPEG", "PNG", , "jpg", "jpeg", "png"];
        const valeinc = ImageExtension.includes(fileExtension);
        if (!valeinc) {
          toast.error("file not supported");
          setloading(false);
          return;
        }
      });
      await database.doc(`product/${item.id}`).update({
        ProductImages: urls
      })
      toast.success("Updated Successfully .. Reload required")
      setloading(false)
      return
    }

    if (editOptions === "6") {
      if (newproductdiscount === 0) {
        setloading(true)
        await database.doc(`product/${item.id}`).update({
          PromoAmount: 0,
          inPromo: false
        })
        toast.success("Discount Removed")
        setloading(false)
        return
      }
      setloading(true)
      await database.doc(`product/${item.id}`).update({
        PromoAmount: newproductdiscount,
        inPromo: true
      })
      toast.success("Discount Added .. Reload required")
      setloading(false)
      return
    }

    if (editOptions === "7") {
      if (newproductprice === 0) {
        toast.error("Enter new value")
        return
      }
      setloading(true)
      await database.doc(`product/${item.id}`).update({
        price: newproductprice
      })
      toast.success("Updated Successfully .. Reload required")
      setloading(false)
      return
    }

  }

  const handleswt = async(e) => {
    e.preventDefault()
     setloading(true)
    await database.doc(`product/${item.id}`).update({
      instock: item.instock === false ? true : false
    })
    toast.success("Updated Successfully .. Reload required")
    setloading(false)
  }
  const coverImage = item.ProductImages ? item.ProductImages : []
  return (
    <div>
      <ToastContainer />
      <Modal className="bg-dark" show={showedit} onHide={handleCloseedit} animation={false} backdrop="static" centered>

        <Modal.Body>
          <button onClick={handleCloseedit}>Back</button>

          <Form.Select aria-label="Default select example" className="my-2" onChange={(event) => seteditOption(event.target.value)}>
            <option>Open </option>
            <option value="1">Edit Product Name</option>
            <option value="2">Edit Product Description</option>
            <option value="7">Edit Product Price</option>
            <option value="3">Edit Product Cover Image</option>
            <option value="4">Edit Products Image</option>
            <option value="6">Add Discount Price </option>
            <option value="5">Product Availability {item.instock === true ? "/True" : "/False"}  </option>


          </Form.Select>

          <div className="allcomaydnjrejddkd">

            <div className="conaytoendkajs" style={{ display: editOptions === "1" ? "block" : "none" }}>
              <Form>
                <Form.Group className="mb-3" >
                  <Form.Label>Product Name</Form.Label>
                  <Form.Control type="text" onChange={(event) => setNewProductName(event.target.value)} />
                  <Form.Text className="text-muted">
                    Be aware that this action will override the existing information
                  </Form.Text>
                </Form.Group>
              </Form>
            </div>

            <div className="conaytoendkajs" style={{ display: editOptions === "2" ? "block" : "none" }}>
              <Form>
                <Form.Group className="mb-3" >
                  <Form.Label>Product Description</Form.Label>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Example textarea</Form.Label>
                    <Form.Control as="textarea" rows={3} onChange={(event) => setNewDescriptpion(event.target.value)} />
                  </Form.Group>
                  <Form.Text className="text-muted">
                    Be aware that this action will override the existing information
                  </Form.Text>
                </Form.Group>
              </Form>
            </div>


            <div className="conaytoendkajs" style={{ display: editOptions === "7" ? "block" : "none" }}>
              <Form>
                <Form.Group className="mb-3" >
                  <Form.Label>Product Price</Form.Label>
                  <Form.Control type="text" onChange={(event) => setNewProductPrice(event.target.value)} />
                  <Form.Text className="text-muted">
                    Be aware that this action will override the existing information
                  </Form.Text>
                </Form.Group>
              </Form>
            </div>

            <div className="conaytoendkajs" style={{ display: editOptions === "3" ? "block" : "none" }}>
              <Form>
                <Form.Group className="mb-3" >
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Upload Cover Image</Form.Label>
                    <Form.Control type="file" onChange={handlechangeImage} />
                  </Form.Group>
                </Form.Group>
              </Form>
            </div>

            <div className="conaytoendkajs" style={{ display: editOptions === "4" ? "block" : "none" }}>
              <Form>
                <Form.Group className="mb-3" >
                  <Form.Group controlId="formFileMultiple" className="mb-3">
                    <Form.Label>Upload Product Images</Form.Label>
                    <Form.Control type="file" multiple onChange={handleChange} />
                  </Form.Group>
                </Form.Group>
              </Form>
            </div>

            <div className="conaytoendkajs" style={{ display: editOptions === "6" ? "block" : "none" }}>
              <Form>
                <Form.Group className="mb-3" >
                  <Form.Label>discount Amount</Form.Label>
                  <Form.Control type="text" onChange={(event) => setNewProductdiscount(event.target.value)} />
                  <Form.Text className="text-muted">
                    Be aware that this action will override the existing information
                  </Form.Text>
                </Form.Group>
              </Form>
            </div>

            <div className="conaytoendkajs" style={{ display: editOptions === "5" ? "block" : "none" }}>
              <Form>
                <Form.Group className="mb-3" >
                  <Form>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      checked={item.instock === true}
                      label={
                        item.instock === true
                          ? "Active Product"
                          : "Disable Product"
                      }
                      onChange={handleswt}
                    />
                  </Form>
                </Form.Group>
              </Form>
            </div>



            <Button variant="primary" disabled={loading} type="submit" onClick={handleEditProduct}>
              {loading? "Updating Information" : "Submit"}
            </Button>
          </div>
        </Modal.Body>

      </Modal>
      <Modal
        show={showview}
        onHide={handleCloseview}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton className="modalheader">
          Product  Details
        </Modal.Header>
        <Modal.Body>
          <div className="biewproductcontinaer">
            <div className="productineofnfjf">
              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Product name</strong>
                </div>
                <div className="productonfprnf">{item.productname}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>product Description</strong>
                </div>
                <div className="productonfprnf">{item.description}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Price</strong>
                </div>
                <div className="productonfprnf">{item.price}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Admin-status</strong>
                </div>
                <div className="productonfprnf">{item.isApproved === false ? "Pending" : "Approved"}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Product Status</strong>
                </div>
                <div className="productonfprnf">{item.instock === false ? "Unavailable" : "Available "}</div>
              </div>

              <div className="productdetaund11">
                <div className="producttitle">
                  <strong>Image</strong>
                </div>
                <div className="productonfprnf">
                  <img src={item.coverimage} alt="" />
                </div>
              </div>

              <div className="productdetaund11">
                <div className="producttitle">
                  <strong>Product Images</strong>
                </div>
                <div className="productonfprnf">
                  <div className="omahealckdjd">

                    {coverImage.length > 0 ? coverImage.map((e) => {
                      return (
                        <div>

                          <img src={e} alt="" />
                        </div>
                      )
                    }) : []}
                  </div>
                </div>
              </div>

              {/* <div className="productdetaund">
                <div className="producttitle">
                  <strong>Date</strong>
                </div>
                <div className="productonfprnf">
                  {item.created}
                </div>
              </div> */}

            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleShowedit}>
            Edit Product
          </Button>
          <Button variant="secondary" onClick={handleCloseview} >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="giftcardocne">
        <h1>Product History</h1>
        <div className="girckahdicbd">
          <button onClick={history.goBack}>Back</button>
          <button className="ms-1">{usecollection.length} Transaction</button>
          <button className="ms-1" onClick={handleCreatepREOD}> Create New Product</button>

          <Form.Control
            type="text"
            placeholder="Search by Name"
            className="w-25 ms-1"
            onChange={(event) => {
              setsearch(event.target.value);
            }}
          />
        </div>
        <div
          className="cardshowarea"
        >
          <div className="cardvaljsjs">
            <table>
              <thead>
                <tr>
                  <th className="hidetable">S/N</th>
                  <th>Created</th>
                  <th>Code</th>
                  <th className="hidetable">Name</th>
                  <th>Price</th>
                  <th >Status</th>
                  <th > Admin-Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody >
                {records.length === 0 ? <>

                  No product Created

                </> :
                  <>
                    {records
                      .filter((value) => {
                        if (value === "") {
                          return value;
                        } else if (
                          value.ProductCode.toLowerCase().includes(search.toLowerCase())
                        ) {
                          return value;
                        } else if (
                          value.productname.toLowerCase().includes(search.toLowerCase())
                        ) {
                          return value;
                        }
                        else if (
                          value.price.includes(search)
                        ) {
                          return value;
                        }
                      })
                      .map((item, id) => {
                        const d = timeStamptoDate(item);
                        const date = moment(d.created).fromNow();
                        let col = ""
                        if (item.isApproved === true) {
                          col = "green"
                        }
                        if (item.status === "error") {
                          col = "red"
                        }
                        if (item.status === "Processing" && item.isApproved === false) {
                          col = "orange"
                        }
                        return (
                          <tr key={id}>
                            <td className="hidetable" >{id + 1}</td>
                            <td>{date}</td>
                            <td>{item.ProductCode}</td>
                            <td className="hidetable">{item.productname}</td>
                            <td>{item.price}</td>
                            <td style={{ color: col }} > {item.status}</td>
                            <td style={{ color: col }} > {item.isApproved === false ? "Await Approval" : "Approved"}</td>
                            <td>
                              <ButtonGroup aria-label="Basic example" >
                                <Button
                                  variant="success"
                                  onClick={Togglemodalview(item)}
                                >
                                  View
                                </Button>
                                {/* <Button
                              variant="primary"
                              onClick={Togglemodal(item)}
                            >
                              Edit
                            </Button> */}
                              </ButtonGroup>
                            </td>
                          </tr>
                        );
                      })}

                  </>
                }

              </tbody>
            </table>

            <nav>
              <ul className="pageination">
                <li className="page-item">
                  <span className="page-link" onClick={prepage}>
                    Prev
                  </span>
                </li>

                {numbers.slice(0, 10).map((n, i) => {
                  return (
                    <li
                      key={i}
                      className={
                        currentPage === n ? "activenumber" : "page-item"
                      }
                    >
                      <span className="page-link" onClick={() => changecurrentPage(n)}>
                        {n < 1 ? 1 : n}
                      </span>
                    </li>
                  );
                })}

                <li className="page-item">
                  <span className="page-link" onClick={Nextpage}>
                    Next
                  </span>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserLog;
