import React, { useState } from "react";
import "./MyCss.css";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../Services/UserAuth";
import { Alert, Form, Button } from "react-bootstrap";
import Navbar from "../Client/Navbaronly";
import axios from "axios"
import firebase from "firebase";
import { getuser } from "../../Services/GetUser.service";
import { getRandomString } from "../../Services/GetRandomNumber";
import { backendUrl } from "../../Services/firebase/firebase";

function Login() {
  const history = useHistory();
  const [loading, setloading] = useState(false);
  const [message, setmessage] = useState("");
  const [password, setpassword] = useState("");
  const [email, setemail] = useState("");
  const [show, setShow] = useState(true);
  const { login } = useAuth();

  const handleLogin = async (e) => {

    e.preventDefault();
        if (password === "" || email === "") {
      setmessage("Please Enter value");
    } else {
      setmessage("");
    }
    try {
      setloading(true);
      await login(email, password).then(async(e)=>{
        const userID = e.user.uid
        if (!userID){
          setmessage("Unexpected Error ")
          const UniquieNum = getRandomString(30, "1234567890");
          axios.post("https://us-central1-kitipay-stagging.cloudfunctions.net/userlogs/user/actions",{
            action:`Alert: ${email} just made an attempt to login and no record was found for this email, be on alert on this email`,
            action_type:"Login",
            process_id:UniquieNum,
            changes:"user login ",
            created:firebase.firestore.FieldValue.serverTimestamp(),
            userid:"null",
            type:"login"
          })
          return
        }   
       await getuser(userID, async(e)=>{
          if (e.isBlock !== false){
            setmessage("Your Account Has Been Suspended")
            const UniquieNum = getRandomString(30, "1234567890");
            axios.post("https://us-central1-kitipay-stagging.cloudfunctions.net/userlogs/user/actions",{
              action:`Alert: ${e.firstname} ${e.firstname} with email ${e.email} just made an attempt to login and Account Has Been Suspended, be on alert on this email`,
              action_type:"Login",
              process_id:UniquieNum,
              changes:"user login ",
              created:firebase.firestore.FieldValue.serverTimestamp(),
              userid:e.id,
              type:"login"
            })
            return
          }
          if (e.permissions === "admin" || e.permissions === "Sale" || e.permissions === 1 ){
            history.push("/auth/login")
            return
          }
          setmessage("Login successful");
          history.push("/auth/user");
          const UniquieNum = getRandomString(30, "1234567890");
          axios.post("https://us-central1-kitipay-stagging.cloudfunctions.net/userlogs/user/actions",{
            action:`user ${e.firstname} ${e.lastname} just login successfully`,
            action_type:"Login",
            process_id:UniquieNum,
            changes:"user login ",
            created:firebase.firestore.FieldValue.serverTimestamp(),
            userid:e.id,
            type:"login"
          })
        })
      });
   
    } catch (error) {
      const UniquieNum = getRandomString(30, "1234567890");
      axios.post(backendUrl +"userlogs/user/actions",{
        action:`${email} Message:`+error.message ,
        action_type:"Login",
        process_id:UniquieNum,
        changes:"user login ",
        created:firebase.firestore.FieldValue.serverTimestamp(),
        userid:"null",
        type:"login"
      })
      setmessage(error.message);
    }
    setloading(false);
  };

  const handleRegister = () => {
    history.push("/register");
  };


  return (
    <>
      <div className="bodyraldhsjs">
      <Navbar />
      {message ? (
        <Alert variant="peimary">
          <p className="text-white">{message}</p>
        </Alert>
      ) : (
        ""
      )}
      <div class="auth-page-wrapper pt-5">
        <div class="auth-page-content">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-8 col-lg-6 col-xl-5">
                <div class="card mt-5 login-bg">
                  <div class="card-body p-4">
                    <div class="text-center">
                      <div class="d-inline-block auth-logo">
                        <img
                          src="/img/logo.png"
                          alt="JSLPS image"
                          height="50"
                        />
                      </div>
                      <h3 className="text-dark mt-3 fs-2">Login to Your Account</h3>
                    </div>

                    <div class="p-2 mt-3">
                      <div class="mb-3">
                        <label for="username" class="form-label">
                          Email
                        </label>
                        <input
                          name="txtusername"
                          type="text"
                          id="txtusername"
                          class="form-control"
                          placeholder="Enter username"
                          onChange={(event) => {
                            setemail(event.target.value);
                          }}
                        />
                      </div>

                      <div class="mb-3">
                        <label class="form-label" for="password-input">
                          Password
                        </label>
                        <div class="position-relative auth-pass-inputgroup mb-3">
                          <input
                            name="txtpassword"
                            type="password"
                            id="txtpassword"
                            class="form-control pe-5 password-input"
                            placeholder="Enter password"
                            onChange={(event) => {
                              setpassword(event.target.value);
                            }}
                            required
                          />
                          <button
                            class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                            type="button"
                            id="password-addon"
                          >
                            <i class="ri-eye-fill align-middle"></i>
                          </button>
                        </div>
                      </div>

                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="auth-remember-check"
                        />
                        <label
                          className="form-check-label"
                          for="auth-remember-check"
                        >
                          Remember me
                        </label>
                        <Link to="/forgetpassword" class="text-danger float-end ">Forgot password?</Link><br/>
                        <Link to="/register" className=" text-dark float-end" > I don't have an account. <span className="text-danger">Register</span></Link>
                      </div>

                      <div class="mt-4">
                        <input
                          type="submit"
                          name="btnlogin"
                          value={loading ? "Please Wait .." : "Login ..."}
                          id="btnlogin"
                          class="btn btn-primary w-100"
                          onClick={handleLogin}
                          disabled={loading}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        className="backgroisns"
        style={{ backgroundImage: "url(/img/b.jpg)" }}
      >
        <div className="usercontainerbody">
          <div class="usercontainer">
            <div class="userheader">Login</div>

            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                 
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
               
                />
              </Form.Group>

              <Form.Text className="text-muted">
                <span>Don't have an account?</span>{" "}
                <span className="act" onClick={handleRegister}>
                  Register
                </span>{" "}
                <br />
              </Form.Text>

              <Form.Text className="text-muted">
                <span>Forget password?</span>{" "}
                <span className="act" onClick={handleResetPassword}>
                  Reset Password
                </span>{" "}
                <br />
              </Form.Text>

              <Button
                variant="dark"
                type="submit"
                onClick={handleLogin}
                disabled={loading}
                className="w-100 py-2 my-2"
              >
              
              </Button>
            </Form>
          </div>
        </div>
      </div> */}
      </div>
    </>
  );
}

export default Login;
