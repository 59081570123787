import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getGiftcard, getstauser, gettransactin } from "../../../Services/GetUser.service";
import { useAuth } from "../../../Services/UserAuth";
import { formatNumber } from "../../../Services/Utility";
import "./Dash.css";
import axios from "axios"
import { backendUrl } from "../../../Services/firebase/firebase";
function Dashboardindex() {
  const { loginuser } = useAuth();
  const [val, setresult] = useState([]);
  const history =  useHistory()
  const [giftcardLengnt, setgiftcardLengt] =  useState([])
  const [giftpayload, setgiftcardpayload]=useState([])
  const [vendorcount, setvendorcour] =  useState([])
  const [transaction, settransactiob]  =  useState([])
  
  const cardUrl = backendUrl + "creategiftHandler/create/gift-card"
  const getcard = async()=>{
    await axios.get(cardUrl).then((res)=>{
    setgiftcardpayload(res.data)
    })
  }
  useEffect(() => {
    getstauser((result) => {
      setresult(result);
      const vendor = result.filter((e)=>e.isverified === true)
      setvendorcour(vendor)
    });

    getGiftcard((e)=>{
      setgiftcardLengt(e)
    })

    getcard()

    gettransactin((e)=>{
      
      settransactiob(e)
      
    })

  }, []);

 
  const isPendingTranasction = transaction.filter((e)=>e.isclosed === false)
  const IsNotVerified = vendorcount.filter((e)=>e.isverified !== true)
  const handleuserroute = ()=>{
    history.push("/log/user")
  }

  const handlegiftcarroute = ()=>{
    history.push("/gift-card-management")
  }

  const handlevendorRoute =()=>{
    history.push("/Vendor-Management")
  }

  const handleTransaction = ()=>{
    history.push("/transaction") 
  }

  const paymentRoute = ()=>{
    history.push("/payment/transaction/dashboard") 
  }

  const handlesupport = ()=>{
    history.push("/admin/support-center/")
  }

  const handlelogs = ()=>{
    history.push("/logs")
  }

  const handleProductRoute=()=>{
    history.push("/admin/product/log")
  }

 
const anaylistics = ()=>{
  history.push(`/control/system-center`)
}


  return (
    <div>
      <div className="admindashboard">
        <div className="admincontainer">
          <div className="admineader">
            <h1>Dashboard</h1>
            <p>
              Welcome Back {loginuser.firstname} {loginuser.lastname}
            </p>
          </div>

          <div className="asminconahdnd">
            <div className="conatnndnd">
              <div className="contandadminrapper" onClick={handleuserroute}>
                <div className="titileconentn">User</div>
                <div className="admindfigr">

                <div className="admindfigr">
                  <span className="text-primary fw-bolder">User Count ({formatNumber(val.length)})</span> <br/>
                  {/* <span className="text-success fw-bolder">Card on request ({formatNumber(giftcardLengnt.length)})</span> */}
                </div>
                </div>
                <p>
                  View All Users, including vendors, users, staff, you can
                  aswell filter users base on roles and permisson
                </p>
              </div>

              <div className="contandadminrapper" onClick={handleTransaction}>
                <div className="titileconentn">Transaction</div>
                <div className="admindfigr">
                  <span className="text-primary fw-bolder">Transaction Count ({formatNumber(transaction.length)})</span> <br/>
                  <span className="text-primary fw-bolder">Pending Count ({formatNumber(isPendingTranasction.length)})</span> <br/>
                  {/* <span className="text-success fw-bolder">Card on request ({formatNumber(giftcardLengnt.length)})</span> */}
                </div>
                <p>
                  View list of transactions as well as details. Resolve/update
                  transaction status and description in-app
                </p>
              </div>

              <div className="contandadminrapper" onClick={handlegiftcarroute}>
                <div className="titileconentn">Gift Card</div>
                <div className="admindfigr">
                  <span className="text-primary fw-bolder">No of Card ({formatNumber(giftpayload.length)})</span> <br/>
                  <span className="text-success fw-bolder">Card on request ({formatNumber(giftcardLengnt.length)})</span>
                </div>
                <p>
                  View list of Gift Card Request who are in a ready state to be
                  verified/approved by Kitipay Administrator.
                </p>
              </div>

              <div className="contandadminrapper" onClick={handlevendorRoute}>
                <div className="titileconentn">Vendor Services Management</div>
                <div className="admindfigr">
                <div className="admindfigr">
                  <span className="text-primary fw-bolder">Vendor Count ({formatNumber(vendorcount.length)})</span> <br/>
                  <span className="text-warning fw-bolder">vendor Awaiting ({formatNumber(IsNotVerified.length)})</span><br/>
                  {/* <span className="text-success fw-bolder">vendor Awaiting ({formatNumber(IsNotVerified.length)})</span> */}
                </div>
                </div>
                <p>
                  View list of Vendor, approve/reject Vendor applications with
                  descriptions and update permissions of Vendor on the fly. also
                  view Vendor activties and act accordingly
                </p>
              </div>

              <div className="contandadminrapper" onClick={handleProductRoute}>
                <div className="titileconentn">Product  Management</div>
                <div className="admindfigr">
                <div className="admindfigr">
                  <span className="text-primary fw-bolder">Product Count ({formatNumber(vendorcount.length)})</span> <br/>
                  {/* <span className="text-success fw-bolder">vendor Awaiting ({formatNumber(IsNotVerified.length)})</span> */}
                </div>
                </div>
                <p>
                  View list of Product created by Marchant, approve/reject Marchange Product crreation applications with
                  descriptions and update permissions of Vendor on the fly. also
                  view Vendor activties and act accordingly
                </p>
              </div>

              <div className="contandadminrapper" onClick={paymentRoute}>
                <div className="titileconentn">Payment Log</div>
                <div className="admindfigr">{val.length}</div>
                <p>
                  Payment log are payloads from users activies in terms of
                  payment activities between users and vendor and also wallet
                  funding history
                </p>
              </div>

              <div className="contandadminrapper" onClick={anaylistics}>
                <div className="titileconentn">Control Pannel</div>
                <div className="admindfigr">{val.length}</div>
                <p>
                  view Services offering that are used across site for
                  efficient and perfomant service delivery to customers. control user activies as well
                </p>
              </div>

              <div className="contandadminrapper" onClick={handlesupport}>
                <div className="titileconentn">Support</div>
                <div className="admindfigr">{val.length}</div>
                <p>
                  Customer care agency, respond to user chats, messages and
                  contact for efficient service delivery.
                </p>
              </div>

              <div className="contandadminrapper" onClick={handlelogs}>
                <div className="titileconentn">System Logs</div>
                <div className="admindfigr">{val.length}</div>
                <p>
                 track all user activity and action in-app. note that this logs will be clear after 30 days
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboardindex;
