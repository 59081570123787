import React, { useEffect } from "react";
import { useState } from "react";
import { Button, FloatingLabel, Form, Modal, Spinner } from "react-bootstrap";
import { useAuth } from "../../../Services/UserAuth";
import "./Transaction.css";
import { ToastContainer, toast } from "react-toastify";
import { format, setWeek } from "date-fns";
import axios from "axios";
import { database } from "../../../Services/firebase/firebase";
import firebase from "firebase";
import { Link, useHistory } from "react-router-dom";
import { getRandomString } from "../../../Services/GetRandomNumber";
import { backendUrl } from "../../../Services/firebase/firebase";
import {
  SendNotification,
  getExamPrice,
} from "../../../Services/GetUser.service";
function Airtimecomponent() {
  const [cardamount, setcardamount] = useState(0);
  const [selectedExamCard, setselectedExamCard] = useState("");
  const [numberofPins, setNumberofPins] = useState(1);
  const [loading, setloading] = useState(false);
  const { loginuser, userwallet } = useAuth();
  const [responspayload, setresponsepayload] = useState("");
  const [show, setShow] = useState(false);
  const [payload, setpayload] = useState([]);
  const [com, setcom] = useState(0);
  const [showprint, setShowprint] = useState(false);
  const [res, setrepayload] = useState([]);
  const [customermisson, setcustomercommssion] = useState(0);
  const history = useHistory();
  const handleClosePrint = () => {
    window.location.reload();
    setShowprint(false);
  };
  const handleShowprint = () => setShowprint(true);
  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {}, []);

  const limit = loginuser.transactionLimit - cardamount;
  const TransactionLimit = limit <= 0 ? 0 : limit;

  const handleShow = () => setShow(true);

  const date = format(new Date(), "dd/MM/yyyy hh:mm a");

  const handleselectdedCard = (e) => {
    const val = e.target.value;
    setselectedExamCard(val)
    getExamPrice((res) => {
      if (val === "waec") {
        setcardamount(res.waec * parseInt(numberofPins));
      } else {
        console.log("1234")
        setcardamount(0);
        setNumberofPins(1)
      }
    });
  };

  const handlepinset = (e) => {
    const val = e.target.value;
    setNumberofPins(parseInt(val))
    if (val){
      getExamPrice((res) => {
        if (selectedExamCard === "waec") {
          setcardamount(res.waec * parseInt(val));
        } else {
          setcardamount(0);
        }
      });
    }
    setNumberofPins(1)
  };

    const handleverifier = () => {
      if (selectedExamCard === "") {
        toast.error("Please select network");
        return;
      }
      if (numberofPins < 0) {
        toast.error("minimum pin  is 1");
        return;
      }

      if (limit <= 0){
        toast.error("Limit Error");
        return;
      }

      if ( cardamount <= 0){
        toast.error(" invalid card request");
        return;
      }
     
      const data = {
        cardamount,
        selectedExamCard,
        numberofPins
      };

      setpayload(data);
      handleShow();
    };

 
    const handlepayment = async (e) => {
      e.preventDefault();
      if (userwallet.wallet < payload.amount) {
        toast.error("Insuffient Fund");
        return;
      }

      if (numberofPins === 0){
        toast.error("Enter Mobile Number")
        return
      }

      if (limit <= 0){
        toast.error("Limit Error");
        return;
      }
      const ref = database.collection("_").doc().id;
        
      try {
        setloading(true);
        setresponsepayload("connecting");
        const UniquieNum = getRandomString(20, "1234567890");
        await axios
          .post(
            backendUrl+ `debitSuperAgentAccount/debit/user`,
            {
              id: loginuser.id,
              amount: payload.cardamount,
              trackNo: UniquieNum,

            }
          )
          
         .then(async (res) => {
            
            const Token = res.data.token
            setresponsepayload("sending value");
            if (res.data.res === "success") {
              await axios
                .post(
                  backendUrl+ "/resultChecker/exam",
                  {
                    selectedExamCard,
                    amount: payload.cardamount,
                    numberOfPins:payload.numberofPins,
                    agentReference: ref,
                    Token
                  }
                )
                .then(async (result) => {
                  console.log(result)
                  setresponsepayload("Finilizing transaction");
                  const data = {
                    id: ref,
                    company: selectedExamCard,
                    customername: "Waec Result Checker",
                    address: "no value",
                    userID: loginuser.id,
                    AccountNumber: loginuser.number,
                    walletbalance: userwallet.wallet,
                    amount: cardamount ,
                    Debitamount: cardamount,
                    phone: loginuser.number,
                    customermisson,
                    mezuflex_id:"",
                    com,
                    payloadres: result.data,
                    TranRef:UniquieNum,
                    type: "resultchecker-waec",
                    status: "success",
                    isToday: true,
                    isClose:true,
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                  };

                  const dataw = {
                    id: ref,
                    company: selectedExamCard,
                    customername: "Waec Result Checker",
                    address: "no value",
                    userID: loginuser.id,
                    AccountNumber: loginuser.number,
                    walletbalance: userwallet.wallet,
                    amount: cardamount ,
                    Debitamount: cardamount,
                    phone: loginuser.number,
                    customermisson,
                    mezuflex_id:result.data.ref,
                    com,
                    TranRef:UniquieNum,
                    payloadres: result.data,
                    type: "resultchecker-waec",
                    status: "error",
                    isToday: true,
                    isClose:false,
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                  }

                  if (result.data.status === "success") {
                    const promises = [
                      axios.post(
                        backendUrl+"userlogsuserlogs/transactions",
                        {
                          data: data,
                        }
                      ),
                      database
                      .doc(`/income/vRgRWLBNzwo7IM1pUhcl`)
                      .update({
                        income: firebase.firestore.FieldValue.increment(com),
                        resultChecker: firebase.firestore.FieldValue.increment(com)
                       })
                    ];
                    setrepayload(data);
                    await Promise.all(promises)
                    history.push(`/reciept/view/${data.id}`)
                  }else{
                   setrepayload(dataw)
                   axios.post(
                    backendUrl+"userlogsuserlogs/transactions",
                    {
                      data: dataw,
                    }
                  )
                   history.push(`/reciept/view/${dataw.id}`)
                   toast.error("Unexpected Error")
                  }
                });
            } else {
              toast.error(res.data.res);
            }
          });
      } catch (error) {
        toast.error(error.message);
      }
      setloading(false);
    };

  const handleprint = () => {
    history.push(`/transaction/${res.id}`);
  };

  return (
    <div>
      <ToastContainer />

       {/* <Modal
        show={showprint}
        onHide={handleClosePrint}
        backdrop="static"
        keyboard={false}
      >
        <div className="tramsactiondetyaions">
          <div className="transactioncokatne">
            <div
              className="transajea"
              style={{
                backgroundColor: res.status === "success" ? "green" : "red",
              }}
            >
              <h1>{res.status === "success" ? "SUCCESS" : "ERROR"}</h1>
              <span>Transaction Reciept</span>
            </div>
            <div className="transactioncontent">
              <div className="transcotebnr">
                <div className="contbename">Company</div>
                <div className="conetnenvakeb sjshdjdhd">{selectednetwork.toUpperCase()}</div>
              </div>
              <div className="transcotebnr">
                <div className="contbename">Amount</div>
                <div className="conetnenvakeb">{amount}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">number</div>
                <div className="conetnenvakeb">{res.AccountNumber}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">Status</div>
                <div className="conetnenvakeb">{res.status}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">Data</div>
                <div className="conetnenvakeb">{date}</div>
              </div>
            </div>
          </div>
        </div>
        <Modal.Footer>
        
          <Link to="/services">
          <Button variant="secondary" >
            Another Transaction
          </Button>
          </Link>
          <Button variant="primary" onClick={handleprint}>Reciept</Button>
          <Button variant="secondary" onClick={handleClosePrint}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="p-0">
          <div className="tramsactiondetyaions">
            <div className="transactioncokatne">
              <div className="transajea">
                <h1>In-Porgress</h1>
                <span>
                  Kindly confirm the information below before you proceed with
                  this payment.
                </span>
              </div>
              <div className="transactioncontent">
                <div className="transcotebnr">
                  <div className="contbename">Company</div>
                  <div className="conetnenvakeb sjshdjdhd">
                    {payload.selectedExamCard}
                  </div>
                </div>
                <div className="transcotebnr">
                  <div className="contbename">Amount</div>
                  <div className="conetnenvakeb">{payload.cardamount}</div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">Number of Pins</div>
                  <div className="conetnenvakeb">{payload.numberofPins}</div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">Status</div>
                  <div className="conetnenvakeb">Processing</div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">Data</div>
                  <div className="conetnenvakeb">{date}</div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" disabled={loading} onClick={handleClose}>
            Close
          </Button>
          <Button
            variant={loading ? "warning" : "primary"}
            disabled={loading}
             onClick={handlepayment}
            type="submit"
          >
            {loading ? (
              <>
                {" "}
                <Spinner animation="border" size="sm" /> {responspayload}
              </>
            ) : (
              "Pay with Wallet"
            )}
          </Button>
        </Modal.Footer>
      </Modal> 

      <div className="transactionconatiner">
        <div className="transactioncontenrt">
          <div className="transactiohead">
            <h2>
              <center className="text-dark">Result Checker</center>
            </h2>
          </div>
          <div className="formtransactionsecton">
            <div className="formtrnacruondjdjd">
              <div className="productsed">
                <FloatingLabel
                  controlId="floatingSelect"
                  onChange={handleselectdedCard}
                >
                  <Form.Select aria-label="Floating label select example">
                    <option>Select Exam type</option>
                    <option value="waec">WAEC Result Checker</option>
                  </Form.Select>
                </FloatingLabel>
                <div className="optionshhd">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className="text-dark my-2">Amount</Form.Label>
                    <Form.Control
                      placeholder="Enter Amount (50 - 50,000)"
                      disabled
                      value={cardamount !== 0 ? cardamount : 0}
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicNumber">
                    <Form.Label className="text-dark my-2">
                      Number of Pins
                    </Form.Label>
                    <Form.Control
                      placeholder="1"
                      type="tel"
                      defaultValue={selectedExamCard === "" ? 1 : numberofPins}
                      maxLength={11}
                      onChange={handlepinset}
                    />
                  </Form.Group>
                  <Form.Text className="text-muted">
                    Transaction Limit: {TransactionLimit ? TransactionLimit : 0}
                  </Form.Text>
                  <Button
                    variant={loading ? "warning" : "primary"}
                    disabled={loading || limit <= 0}
                    onClick={handleverifier}
                    className="my-2 w-100"
                    type="submit"
                  >
                    {loading ? (
                      <>
                        {" "}
                        <Spinner animation="border" size="sm" />{" "}
                        {responspayload}
                      </>
                    ) : (
                      "Proceed.."
                    )}
                  </Button>

                  <Button
                    variant={loading ? "warning" : "danger"}
                    disabled={loading || limit <= 0}
                    onClick={history.goBack}
                    className="w-100"
                  >
                    {loading ? (
                      <>
                        {" "}
                        <Spinner animation="border" size="sm" />{" "}
                        {responspayload}
                      </>
                    ) : (
                      "Back"
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Airtimecomponent;
