import React, { useState } from 'react'
import "./NewsLetter.css"
import { database } from "../Services/firebase/firebase";
import { getUsersEmail } from '../Services/GetUser.service';

function NewsLetter() {

    const [email, setEmail] = useState("");
    const [loader, setLoader] = useState(false);
    const [userEmail, setenterEmail] = useState("")

    function getetemail(event) {
        setEmail(event.target.value);
        const email = event.target.value;
        if (email) {
          getUsersEmail(email).then((newUsers) => {
            // setloading(true);
            const newUser = newUsers[0];
            setenterEmail(newUser ? newUser.email : "");
          });
        }
      }
    
      const handleNewSubmit = (e) => {
        e.preventDefault();
        setLoader(true);
        if (userEmail){
          window.alert("Hi, you have subscribe to this service. Nice to meet you")
          return
        }
      
    
        database
          .collection("newsletter")
          .add({
            email: email,
          })
          .then(() => {
            setLoader(false);
            window.alert("Successful👍");
          })
          .catch((error) => {
            window.alert(error.message);
            setLoader(false);
          });
          setLoader(false);
        setEmail("");
      };
  return (
    <div>
 <div className="cardnews">
  <div className="subscribe">
    <h2>News Letter</h2>
    <p>Subscribe to our email to be part of one of the people that will enjoy our special price.</p>
    <form>
      <input type="email" name="email" id="email" onChange={getetemail} placeholder="Enter your email address" autocomplete="off"/>
      <button type="button" onClick={handleNewSubmit}>Subscribe</button>
    </form>
  </div>
</div>
    </div>
  )
}

export default NewsLetter