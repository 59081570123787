import axios from 'axios'
import React, { useEffect } from 'react'
import { backendUrl } from '../../../Services/firebase/firebase'
import { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useAuth } from '../../../Services/UserAuth'
import "./Amin.css"
import { ToastContainer, toast } from "react-toastify";
import { getGiftcarduser, getincome, gettransactin } from '../../../Services/GetUser.service'
import { getRandomString } from '../../../Services/GetRandomNumber'
import { useHistory } from 'react-router-dom'
import { formatNumber } from '../../../Services/Utility'

function Analysticcompanet() {
  const [mezuwallet, setmexuflexwallet] =  useState([])
  const { loginuser, userwallet } = useAuth();
  const [income, setImcome] = useState([])
  const [trans, Settransaction] =  useState([])
  const [cards, setgifcard] =  useState([])
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const history =  useHistory()
  const [amoutTop, setamountTop] =  useState(0)
  const [loading, setloading] =  useState(false)

  
  useEffect(() => {
    axios.get(backendUrl+ "FetchbvnInformation/user/bvn/data").then((result)=>{
     setmexuflexwallet(result.data.data)
    })

    getincome((income)=>{

      if (income.length > 0 ){
        setImcome(income[1])
        return
      }else {
        setImcome([])
      }
    
    })


    console.log(income)

    gettransactin((trans)=>{
      Settransaction(trans)
    })

    getGiftcarduser("",(card)=>{
      setgifcard(card)
    })

  }, [])


  const handleTopup = async(e)=>{
    e.preventDefault()
    setloading(true)
    if (amoutTop<0){
      toast.error("Null Vat-0")
      return
    }

    if (loginuser.role !== "admin"){
      toast.error("Permission Error")
      return
    }
    setloading(true)
    const UniquieNum = getRandomString(20, "1234567890");
    toast.success("? service unavailble at the moment")
    // try {
    //   await axios
    //   .post(
    //     backendUrl + `debitadnubAccount/debit/user`,
    //     {
    //       id: loginuser.id,
    //       amount: parseFloat(amoutTop),
    //       trackNo: UniquieNum,
    //       type:"credit"
    //     }
    //   ).then((res)=>{
    
    //     if (res.data.res === "success"){
    //       toast.success(res.data.message)
    //     }
    //     toast.error(res.data.message)
    //   })

    // } catch (error) {
    //     toast.error(error.message)
    // }
    setloading(false)
  }

  const handleGetTransactions = ()=>{
    history.push("/control/Transaction-center")
  }



const adminBal = userwallet.admin_totalcredite - userwallet.admin_debit_wallet
  
  return (
    <div>
<ToastContainer />
<Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
      
        <Modal.Body>
        <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Enter Amount</Form.Label>
        <Form.Control type="tell" onChange={(event)=>{
          setamountTop(event.target.value)
        }}/>
        <Form.Text className="text-muted">
          Enter Exact amount balance on Providus bank.. this operation will over-ride the exsisting balance
        </Form.Text>
      </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" disabled={loading} onClick={handleTopup}>{loading ? "processing" : "Top-Up"}</Button>
        </Modal.Footer>
      </Modal>
        <div className="anyslaoctconyaoner">
          <div className="anaysonchss">
            <span>View all Record aggregator</span>
            <div className="aldd">
              <div className="recordscenter">
                <div className="reconconshd">
                  <h1>Mezuflex Bal</h1>
                  <p>&#8358; {mezuwallet.wallet ? formatNumber(mezuwallet.wallet.toFixed(2)) : 0}</p>
                  <Button variant="outline-success" onClick={handleGetTransactions}>Get Transactions</Button>
                </div>
              </div>

              <div className="recordscenter">
                <div className="reconconshd">
                  <h1>Wallet Balance</h1>
                  <p>&#8358; {userwallet.admin_wallet ? formatNumber(userwallet.admin_wallet) : 0}</p>
                </div>
                <Button variant="outline-success" onClick={handleShow}>Top-up</Button>
                
              </div>

              <div className="recordscenter">
                <div className="reconconshd">
                  <h1>Wallet Records</h1>
                  <p>Total-Credit &#8358; {userwallet.admin_totalcredite ? formatNumber(userwallet.admin_totalcredite) : 0}</p>
                  <p>Total-debit &#8358; {userwallet.admin_debit_wallet ? formatNumber(userwallet.admin_debit_wallet) : 0}</p>
                  <p>Balance &#8358; {adminBal? formatNumber(adminBal) :0}</p>
                </div>
              </div>

              <div className="recordscenter">
                <div className="reconconshd">
                  <h1>Profit Records</h1>
                  <p>Airtime &#8358; {income.airtime ? formatNumber(income.airtime) : 0}</p>
                  <p>Electricity &#8358; {income.electricity ? formatNumber(income.electricity) : 0}</p>
                  <p>Gift card &#8358; {income.giftcard? formatNumber(income.giftcard) :0}</p>
                  <p>Marchant  &#8358; {income.Vendor? formatNumber(income.Vendor) :0}</p>
                  <p>Data  &#8358; {income.Data? formatNumber(income.Data) :0}</p>
                  <p>Cable  &#8358; {income.Cable? formatNumber(income.Cable) :0}</p>
                  <p>Betting  &#8358; {income.betting? formatNumber(income.betting) :0}</p>
                  <p>Result Checker  &#8358; {income.resultChecker? formatNumber(income.resultChecker) :0}</p>
                  <p>Transfer  &#8358; {income.Transfer? formatNumber(income.Transfer) :0}</p>
                  <strong>Total  &#8358; {income.income? formatNumber(income.income) :0}</strong>

                </div>
              </div>

              <div className="recordscenter">
                <div className="reconconshd">
                  <h1>Transaction</h1>
                  <p>Transfer  &#8358; {adminBal? adminBal :0}</p>
                </div>
              </div>

              <div className="recordscenter">
                <div className="reconconshd">
                  <h1>Total User</h1>
                  <p>Vendor  &#8358; {adminBal? adminBal :0}</p>
                  <p>customers  &#8358; {adminBal? adminBal :0}</p>
                  <p>staff  &#8358; {adminBal? adminBal :0}</p>
                  <p>Admin  &#8358; {adminBal? adminBal :0}</p>
                </div>
              </div>

              <div className="recordscenter">
                <div className="reconconshd">
                  <h1>Vendor</h1>
                  <p>Settled  &#8358; {adminBal? adminBal :0}</p>
                  <p>Progress  &#8358; {adminBal? adminBal :0}</p>
                  <p>Disputed  &#8358; {adminBal? adminBal :0}</p>
                </div>
              </div>

              <div className="recordscenter">
                <div className="reconconshd">
                  <h1>Gift Card</h1>
                  <p>Success  &#8358; {adminBal? adminBal :0}</p>
                  <p>Failed  &#8358; {adminBal? adminBal :0}</p>
                  <p>Pending  &#8358; {adminBal? adminBal :0}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Analysticcompanet
