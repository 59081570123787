import React from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../Services/UserAuth";
import "./Admincreation.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import { getRandomString } from "../../../Services/GetRandomNumber";
import axios from "axios"
import firebase from "firebase"
import { getuser } from "../../../Services/GetUser.service";
import { backendUrl } from "../../../Services/firebase/firebase";
function AdminLogin() {
  const [password, setpassword] = useState("");
  const [email, setemail] = useState("");
  const [loading, setloading] = useState(false);
  const history = useHistory();
  const { login } = useAuth();
  const [message, setmessage] =  useState("")



  const handlelogin = async (e) => {
    e.preventDefault();
    setloading(true);
    try {
      setloading(true);
      await login(email, password).then(async(e)=>{
        const userID = e.user.uid
        if (!userID){
          setmessage("Unexpected Error ")
          const UniquieNum = getRandomString(30, "1234567890");
          axios.post("https://us-central1-kitipay-stagging.cloudfunctions.net/userlogs/user/actions",{
            action:`Alert: ${email} just made an attempt to login and no record was found for this email, be on alert on this email`,
            action_type:"Login",
            process_id:UniquieNum,
            changes:"user login ",
            created:firebase.firestore.FieldValue.serverTimestamp(),
            userid:"null",
            type:"login"
          })
          return
        }   
       await getuser(userID, async(e)=>{
          if (e.isBlock !== false){
            setmessage("Your Account Has Been Suspended")
            const UniquieNum = getRandomString(30, "1234567890");
            axios.post("https://us-central1-kitipay-stagging.cloudfunctions.net/userlogs/user/actions",{
              action:`Alert: ${e.firstname} ${e.firstname} with email ${e.email} just made an attempt to login and Account Has Been Suspended, be on alert on this email`,
              action_type:"Login",
              process_id:UniquieNum,
              changes:"user login ",
              created:firebase.firestore.FieldValue.serverTimestamp(),
              userid:e.id,
              type:"login"
            })
            return
          }
          if (e.permissions !== "admin"){
            const UniquieNum = getRandomString(30, "1234567890");
            axios.post("https://us-central1-kitipay-stagging.cloudfunctions.net/userlogs/user/actions",{
              action:`user ${e.firstname} ${e.lastname} just access the admin link`,
              action_type:"Login",
              process_id:UniquieNum,
              changes:"user login ",
              created:firebase.firestore.FieldValue.serverTimestamp(),
              userid:e.id,
              type:"login"
            })
            return
          }
          setmessage("Login successful");
          history.push("/auth/admin");
          const UniquieNum = getRandomString(30, "1234567890");
          axios.post("https://us-central1-kitipay-stagging.cloudfunctions.net/userlogs/user/actions",{
            action:`admin ${e.firstname} ${e.lastname} just login successfully`,
            action_type:"Login",
            process_id:UniquieNum,
            changes:"user login ",
            created:firebase.firestore.FieldValue.serverTimestamp(),
            userid:e.id,
            type:"login"
          })
        })
      });
   
    } catch (error) {
      const UniquieNum = getRandomString(30, "1234567890");
      axios.post(backendUrl +"userlogs/user/actions",{
        action:`${email} Message:`+error.message ,
        action_type:"Login",
        process_id:UniquieNum,
        changes:"user login ",
        created:firebase.firestore.FieldValue.serverTimestamp(),
        userid:"null",
        type:"login"
      })
      setmessage(error.message);
    }
    setloading(false);
  };
  return (
    <div>
      <ToastContainer/>
      <div className="adminconterr">
        <div className="adminrpujd">
          <h1>Hello Admin</h1>
          <p>Please Login in to your profile</p>
          <div className="adminconter">
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  onChange={(event) => {
                    setemail(event.target.value);
                  }}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  onChange={(event) => {
                    setpassword(event.target.value);
                  }}
                  required
                />
              </Form.Group>

              <Button
                className="w-100"
                onClick={handlelogin}
                variant="success"
                disabled={loading}
              >
                {loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Login"
                )}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminLogin;
