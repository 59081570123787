import React, { useCallback, useEffect, useState } from "react";
import { Button, ButtonGroup, Form, Modal } from "react-bootstrap";
import {
  SendNotification,
  getstauser,
  getuserwallet,
} from "../../../Services/GetUser.service";
import moment from "moment";
import { useHistory } from "react-router-dom";
import "./User.css";
import { useAuth } from "../../../Services/UserAuth";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { backendUrl } from "../../../Services/firebase/firebase";
import { getRandomString } from "../../../Services/GetRandomNumber";
import firebase from "firebase";
function UserLog() {
  const [usecollection, setusercollection] = useState([]);
  const [showview, setShowview] = useState(false);
  const { loginuser, userwallet } = useAuth();
  const handleCloseview = () => setShowview(false);
  const [search, setsearch] = useState("");
  const handleShowview = () => setShowview(true);
  const history = useHistory();
  const [item, setitem] = useState([]);
  const [loading, setloading] = useState(false);
  const [userwalletss, setuserwallet] = useState([]);
  const [showfund, setShowfund] = useState(false);
  const handleClosefund = () => setShowfund(false);
  const handleShowfund = () => setShowfund(true);
  const [amount, setamount] = useState(0);
  const [bvnloading, setloadingbvn] = useState(false);
  const [message, setmessage] = useState("");
  const [BvnPayload, setdatafund] = useState([]);
  const [currentPage, setcurrentpage] = useState(1);


 
  const recordpage = 20;
  const lastIndex = currentPage * recordpage;
  const firstIndex = lastIndex - recordpage;
  const records =  usecollection.slice(firstIndex, lastIndex);
  const npage = Math.ceil(usecollection.length / recordpage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  useEffect(() => {
    getstauser((result) => {
      const user = result.filter((e) => e.vendorRequest === true);
      setusercollection(user);
    });
  }, []);

  const prepage = () => {
    if (currentPage !== 1) {
      setcurrentpage(currentPage - 1);
    }
  };

  const Nextpage = () => {
    if (currentPage !== npage) {
      setcurrentpage(currentPage + 1);
    }
  };

  const changecurrentPage = (id) => {
    setcurrentpage(id);
  };

  const handlecreatestatf = ()=>{
    history.push("/create-new-staff/admin")
  }


  const Togglemodalview = useCallback(
    (item) => () => {
      getuserwallet(item.id, (res) => {
        setuserwallet(res);
      });
      setitem(item);
      handleShowview();
    },
    []
  );
 

  const dashnoadling = ()=>{
    history.push("/admin/dashboard")
  }
  const handlealow = async (e) => {
    e.preventDefault();
    if (item.userRequstVerificaton !== true) {
      toast.error("Request can't be process");
      return;
    }
    if (
      item.isbuisnessInformationVerified !== true ||
      item.isbusineDocumentVerify !== true ||
      item.isbusineDocVerify !==  true || 
      item.isbusineRepVerify !== true 
    ) {
      toast.error("Doument Not fully Verified");
      return;
    }

    if (loginuser.role !== "admin"){
      return
    }

    try {
      setloading(true);
      await axios
        .put(
          backendUrl+`getUpatedUser/users/${item.id}`
        )
        .then(async (res) => {
          toast.success(res.data.message);
          const homeUrl = "https://kitipay.com";
          const fullname = `${item.firstname} ${item.lastname}`;
          const logo = "https://i.ibb.co/5xqth9r/logo.png";
          const content = `<div marginheight="0" topmargin="0" marginwidth="0" style="margin: 0px; background-color: #f2f3f8;" leftmargin="0">
          <table cellspacing="0" border="0" cellpadding="0" width="100%" background-color="#f2f3f8"
              style="font-family: 'Open Sans', sans-serif;">
              <tr>
                  <td>
                      <table style="background-color: #f2f3f8; max-width:670px;  margin:0 auto;" width="100%" border="0"
                          align="center" cellpadding="0" cellspacing="0">
                          <tr>
                              <td style="height:80px;">&nbsp;</td>
                          </tr>
                          <tr>
                              <td style="text-align:center;">
                                <a href=${homeUrl} title="logo" target="_blank">
                                 KITIPAY 
                                </a>
                              </td>
                          </tr>
                          <tr>
                              <td style="height:20px;">&nbsp;</td>
                          </tr>
                          <tr>
                              <td>
                                  <table width="95%" border="0" align="center" cellpadding="0" cellspacing="0"
                                      style="max-width:670px;background:#fff; border-radius:3px; text-align:center;-webkit-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);-moz-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);box-shadow:0 6px 18px 0 rgba(0,0,0,.06);">
                                      <tr>
                                          <td style="height:40px;">&nbsp;</td>
                                      </tr>
                                      <tr>
                                          <td style="padding:0 35px;">
                                              <h1 style="color:#1e1e2d; font-weight:500; margin:0;font-size:32px;font-family:'Rubik',sans-serif;">Vendor Account Activation</h1>
                                              <span
                                                  style="display:inline-block; vertical-align:middle; margin:29px 0 26px; border-bottom:1px solid #cecece; width:100px;"></span>
                                              <p style="color:#455056; font-size:15px;line-height:24px; margin:0;">
                                              Hope this email meet you well ${fullname} <br/>
                                              kindly be informed that your vendor account request is now active. account setup completed and ready for use base on kitipay terms and condition. For more information on the vendor terms and condition, kindly contact support or account manager.
                                              once again we at Kitipay are happy to have you on-board and ready to work with you. 
                                              Thanks
                                              <br/>
                                              <br/>
                                              Ikenna Nwafor
                                              CEO KITIPAY
                                              </p>
                                              
                                          </td>
                                      </tr>
                                      <tr>
                                          <td style="height:40px;">&nbsp;</td>
                                      </tr>
                                  </table>
                              </td>
                          <tr>
                              <td style="height:20px;">&nbsp;</td>
                          </tr>
                          <tr>
                              <td style="text-align:center;">
                                  <p style="font-size:14px; color:rgba(69, 80, 86, 0.7411764705882353); line-height:18px; margin:0 0 0;">&copy; <strong>${homeUrl}</strong></p>
                              </td>
                          </tr>
                          <tr>
                              <td style="height:80px;">&nbsp;</td>
                          </tr>
                      </table>
                  </td>
              </tr>
          </table>
         
    
          </div>`;
          const CampURL =backendUrl+"sendEmailCampkitipay"
      await axios
        .post(CampURL, {
          content,
          logo,
          fullname,
          email: item.email,
          firstname: item.firstname,
          url:"",
          subject : "Vendor Account Activation"
        })
       
        const nofifyID = getRandomString(6, "1234567890")
        const notificationBody = {
          message: `Marchant application Approved`,
          created: firebase.firestore.FieldValue.serverTimestamp(),
          isread: false,
          id: nofifyID,
          userID:"",
          isRi: 0
        }
      await SendNotification(notificationBody, item.id, "user")  
        });
    } catch (error) {
      toast.error(error.message);
    }
    setloading(false);
  };

  const handlesuspendUser = async (e) => {
    e.preventDefault();
    if (item.userRequstVerificaton !== true) {
      toast.error("Request can't be process");
      return;
    }
    setloading(true);
    try {
      await axios
        .put(
          backendUrl+ `getUpatedUserRep/Representative/${item.id}`
        )
        .then(async(res) => {
          toast.success(res.data.message);
         
          setloading(true);
          
        const nofifyID = getRandomString(6, "1234567890")
        const notificationBody = {
          message: `Marchant application rejected`,
          created: firebase.firestore.FieldValue.serverTimestamp(),
          isread: false,
          id: nofifyID,
          userID:"",
          isRi: 0
        }
      await SendNotification(notificationBody, item.id, "user")  
        });
    } catch (error) {
      toast.error(error.message);
    }
    setloading(false);
  };

  const handlefundwallet = async (e) => {
    e.preventDefault();

    if (isNaN(amount)) {
      toast.error("Negetive value not allowed");
      return;
    }

    if (loginuser.role !== "admin") {
      toast.error("Auth Error");
      return;
    }

    if (amount < 0) {
      toast.error("Negetive value not allowed");
      return;
    }
    if (userwallet.admin_totalcredite < amount){
      toast.error("Low balance");
      return;
    }

    setloading(true);
    const data = {
      id: item.id,
      role: loginuser.role,
      amount,
    };
    const UniquieNum = getRandomString(20, "1234567890");
    try {
      await axios
      .post(
        backendUrl + `debitadnubAccount/debit/user`,
        {
          id: loginuser.id,
          amount: parseFloat(amount),
          trackNo: UniquieNum,
          type:"userfund",
          userID:item.id
        }
      ).then(async(r) => {
          toast.success(r.data.message);
        
          const nofifyID = getRandomString(6, "1234567890")
          const notificationBody = {
            message: `Admin just funded your wallet with ${amount}`,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            isread: false,
            id: nofifyID,
            userID:"",
            isRi: 0
          }
        await SendNotification(notificationBody, item.id, "user")  
          setloading(true);
        });
    } catch (error) {
      toast.error(error.message);
    }
    setloading(false);
  };

  const handlegetBVNRecord = async (e) => {
    e.preventDefault();
    if (loginuser.role !== "admin") {
      toast.error("Permission Error");
      return;
    }

 

    try {
      setloadingbvn(true);
      setmessage("fetching data");
      axios
        .post(
          backendUrl+ "sendUserBVNDATA/user/bvn/data",
          {
            bvn: item.repBvnData,
          }
        )
        .then((e) => {
          const status = e.data.data;
          const va = status.message;
          if (va !== "User data retrived successfully") {
            setmessage("No data found");
            return
          }
          setdatafund(e.data.data[0].payload.data);
          setmessage("Record Retrived");
        });
    } catch (error) {
      console.log(error.message);
    }

    setloadingbvn(false);
  };

  const handleverifybusinessInformation = async (e) => {
    e.preventDefault();
    if (item.isbusinessInfoSubmited ===  false){
      toast.error("No Business Information to verify. either rejected and not re-submited")
      return
    }

    if (loginuser.role !== "admin") {
      toast.error("Permission Error");
      return;
    }
    setloading(true);
    try {
      await axios
        .post(
          backendUrl+ `vendorAction/users/${item.id}`,{
            response :"accept",
            verificationType : "business-information"
          }
        )
        .then((res) => {
          
          toast.success(res.data.message);
         
        });
    } catch (error) {}
    setloading(false)
  };

  const handlerejectbusinessInformation = async (e)=>{
   e.preventDefault()
   setloading(true);

   if (item.isbusinessInfoSubmited ===  false){
    toast.error("No document to verify. either rejected and not re-submited")
    return
  }

  if (loginuser.role !== "admin") {
    toast.error("Permission Error");
    return;
  }
   try {
    await axios
    .post(
      backendUrl+ `vendorAction/users/${item.id}`,{
        response :"rejected",
        verificationType : "business-information"
      }).then(async(res) => {
         toast.success(res.data.message);
         const homeUrl = "https://kitipay.com";
         const fullname = `${item.firstname} ${item.lastname}`;
         const logo = "https://i.ibb.co/5xqth9r/logo.png";
         const content = `<div marginheight="0" topmargin="0" marginwidth="0" style="margin: 0px; background-color: #f2f3f8;" leftmargin="0">
         <table cellspacing="0" border="0" cellpadding="0" width="100%" background-color="#f2f3f8"
             style="font-family: 'Open Sans', sans-serif;">
             <tr>
                 <td>
                     <table style="background-color: #f2f3f8; max-width:670px;  margin:0 auto;" width="100%" border="0"
                         align="center" cellpadding="0" cellspacing="0">
                         <tr>
                             <td style="height:80px;">&nbsp;</td>
                         </tr>
                         <tr>
                             <td style="text-align:center;">
                               <a href=${homeUrl} title="logo" target="_blank">
                                KITIPAY 
                               </a>
                             </td>
                         </tr>
                         <tr>
                             <td style="height:20px;">&nbsp;</td>
                         </tr>
                         <tr>
                             <td>
                                 <table width="95%" border="0" align="center" cellpadding="0" cellspacing="0"
                                     style="max-width:670px;background:#fff; border-radius:3px; text-align:center;-webkit-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);-moz-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);box-shadow:0 6px 18px 0 rgba(0,0,0,.06);">
                                     <tr>
                                         <td style="height:40px;">&nbsp;</td>
                                     </tr>
                                     <tr>
                                         <td style="padding:0 35px;">
                                             <h1 style="color:#1e1e2d; font-weight:500; margin:0;font-size:32px;font-family:'Rubik',sans-serif;">You Got Feedback</h1>
                                             <span
                                                 style="display:inline-block; vertical-align:middle; margin:29px 0 26px; border-bottom:1px solid #cecece; width:100px;"></span>
                                             <p style="color:#455056; font-size:15px;line-height:24px; margin:0;">
                                             Hi ${fullname} <br/>
                                             In order to complete your Marchant KYC verification, changes need to be made to one (or more) of your documents. Please return to your Compliance dashboard to make the necessary adjustments.
                                             <br/>
                                             Compliance Feedback (1)  
                                             We were unable to verify your Business Information based on the information provided.
                                             <br/>
                                             <br/>
                                             jannet 
                                             Business Support Operative KITIPAY
                                             </p>
                                         </td>
                                     </tr>
                                     <tr>
                                         <td style="height:40px;">&nbsp;</td>
                                     </tr>
                                 </table>
                             </td>
                         <tr>
                             <td style="height:20px;">&nbsp;</td>
                         </tr>
                         <tr>
                             <td style="text-align:center;">
                                 <p style="font-size:14px; color:rgba(69, 80, 86, 0.7411764705882353); line-height:18px; margin:0 0 0;">&copy; <strong>${homeUrl}</strong></p>
                             </td>
                         </tr>
                         <tr>
                             <td style="height:80px;">&nbsp;</td>
                         </tr>
                     </table>
                 </td>
             </tr>
         </table>
        
   
         </div>`;
         const CampURL =backendUrl+ "sendEmailCampkitipay"
     await axios
       .post(CampURL, {
         content,
         logo,
         fullname,
         email: item.email,
         firstname: item.firstname,
         url:"",
         subject : "Kitipay Compliance - You Got Feedback"
       })

       
       const nofifyID = getRandomString(6, "1234567890")
       const notificationBody = {
         message: `Complaince FeedBack: Please kindly re-fill your busines information`,
         created: firebase.firestore.FieldValue.serverTimestamp(),
         isread: false,
         id: nofifyID,
         userID:"",
         isRi: 0
       }
     await SendNotification(notificationBody, item.id, "user")  
    
       });
   } catch (error) {}
   setloading(false)
  }

  const handlebusinessRepresentative = async (e) => {
    e.preventDefault();

    if (item.isbusinessRepSubmited === false){
      toast.error("No document to verify. either rejected and not re-submited")
      return
    }

    if (loginuser.role !== "admin") {
      toast.error("Permission Error");
      return;
    }
    try {
      setloading(true);
      axios
         .post(
          backendUrl+ `vendorAction/users/${item.id}`,{
            response :"accept",
            verificationType : "business-representative"
          }).then((res) => {
          toast.success(res.data.message);
      
        });
    } catch (error) {}
    setloading(false);
  };

  const handlerejectbusinessRepresentative = async (e)=>{
    e.preventDefault()
    setloading(true);

    if (item.isbusinessRepSubmited === false){
      toast.error("No document to verify. either rejected and not re-submited")
      return
    }

    if (loginuser.role !== "admin") {
      toast.error("Permission Error");
      return;
    }
    try {
     await axios.post(
      backendUrl+ `vendorAction/users/${item.id}`,{
        response :"rejected",
        verificationType : "business-representative"
      }).then(async(res) => {
          toast.success(res.data.message);
          const homeUrl = "https://kitipay.com";
          const fullname = `${item.firstname} ${item.lastname}`;
          const logo = "https://i.ibb.co/5xqth9r/logo.png";
          const content = `<div marginheight="0" topmargin="0" marginwidth="0" style="margin: 0px; background-color: #f2f3f8;" leftmargin="0">
          <table cellspacing="0" border="0" cellpadding="0" width="100%" background-color="#f2f3f8"
              style="font-family: 'Open Sans', sans-serif;">
              <tr>
                  <td>
                      <table style="background-color: #f2f3f8; max-width:670px;  margin:0 auto;" width="100%" border="0"
                          align="center" cellpadding="0" cellspacing="0">
                          <tr>
                              <td style="height:80px;">&nbsp;</td>
                          </tr>
                          <tr>
                              <td style="text-align:center;">
                                <a href=${homeUrl} title="logo" target="_blank">
                                 KITIPAY 
                                </a>
                              </td>
                          </tr>
                          <tr>
                              <td style="height:20px;">&nbsp;</td>
                          </tr>
                          <tr>
                              <td>
                                  <table width="95%" border="0" align="center" cellpadding="0" cellspacing="0"
                                      style="max-width:670px;background:#fff; border-radius:3px; text-align:center;-webkit-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);-moz-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);box-shadow:0 6px 18px 0 rgba(0,0,0,.06);">
                                      <tr>
                                          <td style="height:40px;">&nbsp;</td>
                                      </tr>
                                      <tr>
                                          <td style="padding:0 35px;">
                                              <h1 style="color:#1e1e2d; font-weight:500; margin:0;font-size:32px;font-family:'Rubik',sans-serif;">You Got Feedback</h1>
                                              <span
                                                  style="display:inline-block; vertical-align:middle; margin:29px 0 26px; border-bottom:1px solid #cecece; width:100px;"></span>
                                              <p style="color:#455056; font-size:15px;line-height:24px; margin:0;">
                                              Hi ${fullname} <br/>
                                              In order to complete your Marchant KYC verification, changes need to be made to one (or more) of your documents. Please return to your Compliance dashboard to make the necessary adjustments.
                                              <br/>
                                              Compliance Feedback (1)  
                                              We were unable to verify your Business Representative Information based on the information provided.
                                              <br/>
                                              <br/>
                                              jannet 
                                              Business Support Operative KITIPAY
                                              </p>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td style="height:40px;">&nbsp;</td>
                                      </tr>
                                  </table>
                              </td>
                          <tr>
                              <td style="height:20px;">&nbsp;</td>
                          </tr>
                          <tr>
                              <td style="text-align:center;">
                                  <p style="font-size:14px; color:rgba(69, 80, 86, 0.7411764705882353); line-height:18px; margin:0 0 0;">&copy; <strong>${homeUrl}</strong></p>
                              </td>
                          </tr>
                          <tr>
                              <td style="height:80px;">&nbsp;</td>
                          </tr>
                      </table>
                  </td>
              </tr>
          </table>
         
    
          </div>`;
          const CampURL =backendUrl+ "sendEmailCampkitipay"
      await axios
        .post(CampURL, {
          content,
          logo,
          fullname,
          email: item.email,
          firstname: item.firstname,
          url:"",
          subject : "Kitipay Compliance - You Got Feedback"
        })
       
        const nofifyID = getRandomString(6, "1234567890")
        const notificationBody = {
          message: `Complaince FeedBack: Please kindly re-fill your busines representative information`,
          created: firebase.firestore.FieldValue.serverTimestamp(),
          isread: false,
          id: nofifyID,
          userID:"",
          isRi: 0
        }
      await SendNotification(notificationBody, item.id, "user")  
        });
    } catch (error) {}
    setloading(false)
   }

  const handleverifydocument = async (e) => {
    e.preventDefault();
  
    if (item.isbusinessDocumentSubmited === false ){
      toast.error("No document to verify. either rejected and not re-submited")
      return
    }

    if (loginuser.role !== "admin") {
      toast.error("Permission Error");
      return;
    }
    try {
      setloading(true);
      axios.post(
        backendUrl+ `vendorAction/users/${item.id}`,{
          response :"accept",
          verificationType : "business-document"
        }).then((res) => {
          toast.success(res.data.message);
    
        });
    } catch (error) {}
    setloading(false);
  };

  const handleRejectverifydocument = async (e) => {
    e.preventDefault();
    try {
      if (item.isbusinessDocumentSubmited === false ){
        toast.error("No document to verify. either rejected and not re-submited")
        return
      }
  
      if (loginuser.role !== "admin") {
        toast.error("Permission Error");
        return;
      }
      setloading(true);
     await axios.post(
      backendUrl+ `vendorAction/users/${item.id}`,{
        response :"rejected",
        verificationType : "business-document"
      }).then(async(res) => {
          toast.success(res.data.message);
          const homeUrl = "https://kitipay.com";
          const fullname = `${item.firstname} ${item.lastname}`;
          const logo = "https://i.ibb.co/5xqth9r/logo.png";
          const content = `<div marginheight="0" topmargin="0" marginwidth="0" style="margin: 0px; background-color: #f2f3f8;" leftmargin="0">
          <table cellspacing="0" border="0" cellpadding="0" width="100%" background-color="#f2f3f8"
              style="font-family: 'Open Sans', sans-serif;">
              <tr>
                  <td>
                      <table style="background-color: #f2f3f8; max-width:670px;  margin:0 auto;" width="100%" border="0"
                          align="center" cellpadding="0" cellspacing="0">
                          <tr>
                              <td style="height:80px;">&nbsp;</td>
                          </tr>
                          <tr>
                              <td style="text-align:center;">
                                <a href=${homeUrl} title="logo" target="_blank">
                                 KITIPAY 
                                </a>
                              </td>
                          </tr>
                          <tr>
                              <td style="height:20px;">&nbsp;</td>
                          </tr>
                          <tr>
                              <td>
                                  <table width="95%" border="0" align="center" cellpadding="0" cellspacing="0"
                                      style="max-width:670px;background:#fff; border-radius:3px; text-align:center;-webkit-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);-moz-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);box-shadow:0 6px 18px 0 rgba(0,0,0,.06);">
                                      <tr>
                                          <td style="height:40px;">&nbsp;</td>
                                      </tr>
                                      <tr>
                                          <td style="padding:0 35px;">
                                              <h1 style="color:#1e1e2d; font-weight:500; margin:0;font-size:32px;font-family:'Rubik',sans-serif;">You Got Feedback</h1>
                                              <span
                                                  style="display:inline-block; vertical-align:middle; margin:29px 0 26px; border-bottom:1px solid #cecece; width:100px;"></span>
                                              <p style="color:#455056; font-size:15px;line-height:24px; margin:0;">
                                              Hi ${fullname} <br/>
                                              In order to complete your Marchant KYC verification, changes need to be made to one (or more) of your documents. Please return to your Compliance dashboard to make the necessary adjustments.
                                              <br/>
                                              Compliance Feedback (1)  
                                              We were unable to verify your Business document based on the information provided Please Contact support for more information on 09115630280.
                                              <br/>
                                              <br/>
                                              jannet 
                                              Business Support Operative KITIPAY
                                              </p>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td style="height:40px;">&nbsp;</td>
                                      </tr>
                                  </table>
                              </td>
                          <tr>
                              <td style="height:20px;">&nbsp;</td>
                          </tr>
                          <tr>
                              <td style="text-align:center;">
                                  <p style="font-size:14px; color:rgba(69, 80, 86, 0.7411764705882353); line-height:18px; margin:0 0 0;">&copy; <strong>${homeUrl}</strong></p>
                              </td>
                          </tr>
                          <tr>
                              <td style="height:80px;">&nbsp;</td>
                          </tr>
                      </table>
                  </td>
              </tr>
          </table>
         
    
          </div>`;
          const CampURL =backendUrl+ "sendEmailCampkitipay"
      await axios
        .post(CampURL, {
          content,
          logo,
          fullname,
          email: item.email,
          firstname: item.firstname,
          url:"",
          subject : "Kitipay Compliance - You Got Feedback"
        })
          
        const nofifyID = getRandomString(6, "1234567890")
        const notificationBody = {
          message: `Compliance FeedBack: Please kindly re-upload your business document as some of the document can not be verified. contact support for more information`,
          created: firebase.firestore.FieldValue.serverTimestamp(),
          isread: false,
          id: nofifyID,
          userID:"",
          isRi: 0
        }
      await SendNotification(notificationBody, item.id, "user")  
        });
    } catch (error) {}
    setloading(false);
  };

  const handleverifyaccount = async (e) => {
    e.preventDefault();

    if (item.isbusinessBankSubmited === false ){
      toast.error("No document to verify. either rejected and not re-submited")
      return
    }

    if (loginuser.role !== "admin") {
      toast.error("Permission Error");
      return;
    }
    try {
      setloading(true);
      axios
      .post(
        backendUrl+ `vendorAction/users/${item.id}`,{
          response :"accept",
          verificationType : "business-Accout"
        }).then((res) => {
        
          toast.success(res.data.message);
        });
    } catch (error) {}
    setloading(false);
  };

  const handleRejectverifyaccount = async (e) => {
    e.preventDefault();
    try {
      if (item.isbusinessBankSubmited === false ){
        toast.error("No document to verify. either rejected and not re-submited")
        return
      }
  
      if (loginuser.role !== "admin") {
        toast.error("Permission Error");
        return;
      }
      setloading(true);
      axios.post(
        backendUrl+ `vendorAction/users/${item.id}`,{
          response :"rejected",
          verificationType : "business-Accout"
        }).then(async(res) => {
          toast.success(res.data.message);
          const homeUrl = "https://kitipay.com";
          const fullname = `${item.firstname} ${item.lastname}`;
          const logo = "https://i.ibb.co/5xqth9r/logo.png";
          const content = `<div marginheight="0" topmargin="0" marginwidth="0" style="margin: 0px; background-color: #f2f3f8;" leftmargin="0">
          <table cellspacing="0" border="0" cellpadding="0" width="100%" background-color="#f2f3f8"
              style="font-family: 'Open Sans', sans-serif;">
              <tr>
                  <td>
                      <table style="background-color: #f2f3f8; max-width:670px;  margin:0 auto;" width="100%" border="0"
                          align="center" cellpadding="0" cellspacing="0">
                          <tr>
                              <td style="height:80px;">&nbsp;</td>
                          </tr>
                          <tr>
                              <td style="text-align:center;">
                                <a href=${homeUrl} title="logo" target="_blank">
                                 KITIPAY 
                                </a>
                              </td>
                          </tr>
                          <tr>
                              <td style="height:20px;">&nbsp;</td>
                          </tr>
                          <tr>
                              <td>
                                  <table width="95%" border="0" align="center" cellpadding="0" cellspacing="0"
                                      style="max-width:670px;background:#fff; border-radius:3px; text-align:center;-webkit-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);-moz-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);box-shadow:0 6px 18px 0 rgba(0,0,0,.06);">
                                      <tr>
                                          <td style="height:40px;">&nbsp;</td>
                                      </tr>
                                      <tr>
                                          <td style="padding:0 35px;">
                                              <h1 style="color:#1e1e2d; font-weight:500; margin:0;font-size:32px;font-family:'Rubik',sans-serif;">You Got Feedback</h1>
                                              <span
                                                  style="display:inline-block; vertical-align:middle; margin:29px 0 26px; border-bottom:1px solid #cecece; width:100px;"></span>
                                              <p style="color:#455056; font-size:15px;line-height:24px; margin:0;">
                                              Hi ${fullname} <br/>
                                              In order to complete your Marchant KYC verification, changes need to be made to one (or more) of your documents. Please return to your Compliance dashboard to make the necessary adjustments.
                                              <br/>
                                              Compliance Feedback (1)  
                                              We were unable to verify your Business Bank Information based on the information provided Please Contact support for more information on 09115630280.
                                              <br/>
                                              <br/>
                                              jannet 
                                              Business Support Operative KITIPAY
                                              </p>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td style="height:40px;">&nbsp;</td>
                                      </tr>
                                  </table>
                              </td>
                          <tr>
                              <td style="height:20px;">&nbsp;</td>
                          </tr>
                          <tr>
                              <td style="text-align:center;">
                                  <p style="font-size:14px; color:rgba(69, 80, 86, 0.7411764705882353); line-height:18px; margin:0 0 0;">&copy; <strong>${homeUrl}</strong></p>
                              </td>
                          </tr>
                          <tr>
                              <td style="height:80px;">&nbsp;</td>
                          </tr>
                      </table>
                  </td>
              </tr>
          </table>
         
    
          </div>`;
          const CampURL =backendUrl+ "sendEmailCampkitipay"
      await axios
        .post(CampURL, {
          content,
          logo,
          fullname,
          email: item.email,
          firstname: item.firstname,
          url:"",
          subject : "Kitipay Compliance - You Got Feedback"
        })
        const nofifyID = getRandomString(6, "1234567890")
        const notificationBody = {
          message: `Compliance FeedBack: Please kindly provide a corresponding bank information. contact support for more information`,
          created: firebase.firestore.FieldValue.serverTimestamp(),
          isread: false,
          id: nofifyID,
          userID:"",
          isRi: 0
        }
      await SendNotification(notificationBody, item.id, "user") 
        });
    } catch (error) {}
    setloading(false);
  };

    const handleTrans= ()=>{
    history.push(`/vendor/Transaction/history/${loginuser.id}`)
  }

  return (
    <div>
      <ToastContainer />
      <Modal
        show={showfund}
        onHide={handleClosefund}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Fund Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicNumber">
            <Form.Label>Enter Amount</Form.Label>
            <Form.Control
              type="tel"
              onChange={(event) => {
                setamount(event.target.value);
              }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosefund}>
            Close
          </Button>
          <Button
            variant={loading ? "warning" : "primary"}
            disabled={loading}
            onClick={handlefundwallet}
          >
            {loading ? "Processing ..." : "Fund Wallet"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showview}
        onHide={handleCloseview}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="biewproductcontinaer">
            <h1>Bussiness Information</h1>

            <div className="productineofnfjf">
              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Business Name</strong>
                </div>
                <div className="productonfprnf">{item.businessname}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Business Registration No.</strong>
                </div>
                <div className="productonfprnf">{item.businessreg}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Business Address</strong>
                </div>
                <div className="productonfprnf">{item.businessaddress}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Business Email</strong>
                </div>
                <div className="productonfprnf">{item.businessemail}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Business Description</strong>
                </div>
                <div className="productonfprnf">{item.businessdiscription}</div>
              </div>
              <Form.Text className="text-muted">
                {item.isbuisnessInformationVerified ===  true ? "Document Approved By Admin" : "Document Yet to be accepted by admin"}
              </Form.Text>
              <div className="productdetaund111">
                <button
                  disabled={loading}
                  onClick={handleverifybusinessInformation}
                >
                  {loading ? "Please wait" : "Approve "}
                </button>

                <button
                  disabled={loading}
                  className="bg-danger"
                  onClick={handlerejectbusinessInformation}
                >
                  {loading ? "Please wait" : "Reject"}
                </button>
              </div>

              <h1>Bussiness Representative</h1>
              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Representative Full Name</strong>
                </div>
                <div className="productonfprnf">{item.BusinessRepFullName}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Representative Mobile Number</strong>
                </div>
                <div className="productonfprnf">{item.Repmobilenumber}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Representative Address</strong>
                </div>
                <div className="productonfprnf">
                  {item.resAddress} {item.repselectedState} {item.repscountry}
                </div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Get BVN Record</strong>
                </div>
                <div className="productonfprnf">
                  <button disabled={bvnloading} onClick={handlegetBVNRecord}>
                    {bvnloading ? "Fetching User Record" : "Get Record"}
                  </button>{" "}
                  <br />
                  <span className="text-info">{message}</span>
                </div>
              </div>
              {BvnPayload.length === 0 ? (
                <>{message}</>
              ) : (
                <>
                  <div className="productdetaund">
                    <div className="producttitle">
                      <strong>User firstname / Lastname</strong>
                    </div>
                    <div className="productonfprnf">{BvnPayload.full_name}</div>
                  </div>

                  <div className="productdetaund">
                    <div className="producttitle">
                      <strong>Address </strong>
                    </div>
                    <div className="productonfprnf">
                      {BvnPayload.address_line_2} <br />
                      {BvnPayload.address_line_3}
                    </div>
                  </div>

                  <div className="productdetaund">
                    <div className="producttitle">
                      <strong>Phone Number </strong>
                    </div>
                    <div className="productonfprnf">{BvnPayload.phone}</div>
                  </div>

                  <div className="productdetaund">
                    <div className="producttitle">
                      <strong>Account Status </strong>
                    </div>
                    <div className="productonfprnf">
                      {BvnPayload.watchlisted === false
                        ? "Account OK"
                        : "Flag User"}
                    </div>
                  </div>
                  <p className="text-danger">
                    If Information of this user is not correct or suspicious,
                    dont act on it. else use the allow button bellow to grant
                    user access to services
                  </p>
                </>
              )}
               {item.isbusineRepVerify ===  true ? "Document Approved By Admin" : "Document Yet to be accepted by admin"}
              <div className="productdetaund111">
                <button
                  disabled={loading}
                  onClick={handlebusinessRepresentative}
                >
                  {loading
                    ? "Server busy"
                    : "Approve"}
                </button>

                <button
                  disabled={loading}
                  className="bg-danger"
                  onClick={handlerejectbusinessRepresentative}
                >
                  {loading ? "Please wait" : "Reject"}
                </button>
              </div>

              <h1>Bussiness Document</h1>
              {item.isbusinessDocumentSubmited === true ? (
                <>
                  <div className="productdetaund">
                    <div className="producttitle">
                      <strong>Certificate of Incoporation</strong>
                    </div>
                    <div className="productonfprnf">
                      {item.businessreg !== "" ? (
                        <a href={item.IncorporationImage} target="_blank">
                          Certificate of Incoporation
                        </a>
                      ) : (
                        "No Document Submited"
                      )}
                    </div>
                  </div>

                  <div className="productdetaund">
                    <div className="producttitle">
                      <strong>Memat Document</strong>
                    </div>
                    <div className="productonfprnf">
                      {item.businessreg !== "" ? (
                        <a href={item.mametImgae} target="_blank">
                          Memat Document
                        </a>
                      ) : (
                        "No Document Submited"
                      )}
                    </div>
                  </div>

                  <div className="productdetaund">
                    <div className="producttitle">
                      <strong>proof of Business Address</strong>
                    </div>
                    <div className="productonfprnf">
                      <a href={item.addressProofImage} target="_blank">
                        Business Address Evidence
                      </a>
                    </div>
                  </div>

                  <div className="productdetaund">
                    <div className="producttitle">
                      <strong>Means of Identification ( Director)</strong>
                    </div>
                    <div className="productonfprnf">
                      <a href={item.identityImage} target="_blank">
                        User Address Evidence
                      </a>
                    </div>
                  </div>

                  <div className="productdetaund">
                    <div className="producttitle">
                      <strong>Board Resolution</strong>
                    </div>
                    <div className="productonfprnf">
                      <a href={item.resolutionImage} target="_blank">
                        Board Resolution
                      </a>
                    </div>
                  </div>
                </>
              ) : (
                "user Yet to submite required document for this process"
              )}
               {item.isbusineRepVerify ===  true ? "Document Approved By Admin" : "Document Yet to be accepted by admin"}
              <div className="productdetaund111">
                <button disabled={loading} onClick={handleverifydocument}>
                  {loading ? "server busy" : "Approve"}
                </button>
                <button
                  disabled={loading}
                  className="bg-danger"
                  onClick={handleRejectverifydocument}
                >
                  {loading ? "Please wait" : "Reject "}
                </button>
              </div>

              <h1>Bussiness Bank Account Information</h1>
              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Bank Name </strong>
                </div>
                <div className="productonfprnf">{item.BusinessBankName}</div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Account Number </strong>
                </div>
                <div className="productonfprnf">
                  {item.BusinessAcccountNumber}
                </div>
              </div>

              <div className="productdetaund">
                <div className="producttitle">
                  <strong>Account Name</strong>
                </div>
                <div className="productonfprnf">
                  {item.BusinessAcccountName}
                </div>
              </div>
              {item.isbusineDocVerify ===  true ? "Document Approved By Admin" : "Document Yet to be accepted by admin"}
              <div className="productdetaund111">
                <button disabled={loading} onClick={handleverifyaccount}>
                  {loading ? "server busy" : "Verify Business Account"}
                </button>

                <button
                  disabled={loading}
                  className="bg-danger"
                  onClick={handleRejectverifyaccount}
                >
                  {loading ? "Please wait" : "Reject "}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {item.isverified === false ? (
            <Button variant="primary" disabled={loading} onClick={handlealow}>
              {loading ? "Processing Request" : "Allow Vendor"}
            </Button>
          ) : (
            <Button variant="danger" onClick={handlesuspendUser}>
              {loading ? "Processing Request" : "Suspend Vendor"}
            </Button>
          )}

          <Button variant="primary" onClick={handleShowfund}>
            Fund Wallet
          </Button>

          <Button variant="secondary" onClick={handleCloseview}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="giftcardocne">
      <h1>Vendor Center</h1>
            <div className="girckahdicbd">
            <button onClick={dashnoadling}>Dashboard</button>
              <button className="ms-1" onClick={history.goBack}>Back</button>
              <button className="ms-1" onClick={handleTrans}>Transaction History</button>
              <button className="ms-1">{usecollection.length} users</button>

              <Form.Control
                type="text"
                placeholder="Search by Name"
                className="w-25 ms-1"
                onChange={(event) => {
                  setsearch(event.target.value);
                }}
              />
            </div>
        <div className="cardshowarea">
          <div className="cardvaljsjs">
          
            <table>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th className="hidetable">Created</th>
                  <th>ID</th>
                  <th>Name</th>
                  <th className="hidetable">Mobile No.</th>
                  <th className="hidetable">Role</th>
                  <th>Vendor Status</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {records
                  .filter((value) => {
                    if (value === "") {
                      return value;
                    } else if (
                      value.agentID
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return value;
                    } else if (
                      value.lastname
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return value;
                    }
                    else if (
                      value.firstname
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return value;
                    }
                    // }else if (
                    //   value.agentID.includes(search)
                    // ) {
                    //   return value;
                    // }
                  })
                  .map((item, id) => {
                    const date = moment(item.created).fromNow();
                    return (
                      <tr key={id}>
                        <td>{id + 1}</td>

                        <td className="hidetable">{date}</td>
                        <td className="hidetable">{item.agentID}</td>
                        <td>
                          {item.firstname} {item.lastname}
                        </td>
                        <td className="hidetable">{item.number}</td>
                        <td className="hidetable">{item.role}</td>
                        <td className="hidetable">
                          {item.isverified === true ? "verified" : "pending"}
                        </td>
                        <td>
                          {item.userverified === true ? "active" : "suspended"}
                        </td>
                        <td>
                          <ButtonGroup aria-label="Basic example">
                            <Button
                              variant="success"
                              onClick={Togglemodalview(item)}
                            >
                              View
                            </Button>
                            {/* <Button
                              variant="primary"
                              onClick={Togglemodal(item)}
                            >
                              Edit
                            </Button> */}
                          </ButtonGroup>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            <nav>
              <ul className="pageination">
                <li className="page-item">
                  <span className="page-link" onClick={prepage}>
                    Prev
                  </span>
                </li>

                {numbers.map((n, i) => {
                  return (
                    <li
                      key={i}
                      className={
                        currentPage === n ? "activenumber" : "page-item"
                      }
                    >
                      <span className="page-link" onClick={() => changecurrentPage(n)}>
                        {n<1 ? 1 : n}
                      </span>
                    </li>
                  );
                })}

                <li className="page-item">
                  <span className ="page-link" onClick={Nextpage}>
                    Next
                  </span>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserLog;
