import React, { useEffect } from "react";
import { useState } from "react";
import { Button, FloatingLabel, Form, Modal, Spinner } from "react-bootstrap";
import { useAuth } from "../../../Services/UserAuth";
import "./Transaction.css";
import { ToastContainer, toast } from "react-toastify";
import { format } from "date-fns";
import axios from "axios";
import { database } from "../../../Services/firebase/firebase";
import firebase from "firebase";
import Gotv from "../../../gotv.json";
import Dstv from "../../../dstv.json";
import Startimes from "../../../startimes.json";
import { formatNumber, version_control } from "../../../Services/Utility";
import { getRandomString } from "../../../Services/GetRandomNumber";
import { Link, useHistory } from "react-router-dom";
import { backendUrl } from "../../../Services/firebase/firebase";
import { SendNotification } from "../../../Services/GetUser.service";
function Airtimecomponent() {
  const [selectedcable, setcableselected] = useState("");
  const [number, setphone] = useState("");
  const [loading, setloading] = useState(false);
  const { loginuser, userwallet } = useAuth();
  const [responspayload, setresponsepayload] = useState("");
  const [show, setShow] = useState(false);
  const [payload, setpayload] = useState([]);
  const [com, setcom] = useState(0);
  const [showprint, setShowprint] = useState(false);
  const [res, setrepayload] = useState([]);
  const [cableboundle, setboundle] = useState([]);
  const [bundleprice, setbundleprice] = useState(0);
  const [boundlePlan, setboudlePlan] = useState("");
  const [startimes] = useState(Startimes);
  const [code, setboundconde] = useState("");
  const [period, setmonperiod] =  useState()
  const [gotv] = useState(Gotv);
  const [dstv] = useState(Dstv);
  const history =  useHistory()
  const [iuc, setIUC] = useState("");

  const handleClosePrint = () => {
    window.location.reload();
    setShowprint(false);
  };
  const handleShowprint = () => setShowprint(true);
  const handleClose = () => {
    setShow(false);
  };
 

  const g = gotv.data;
  const d = dstv.data;
  const s = startimes.data;

  

  const handleprovider = async(e)=>{
    setboudlePlan("")
    const provider =  e.target.value
  
    setcableselected(provider)
   await axios.get(backendUrl+ `getPrice/price/service/cable/${provider}`).then((res)=>{
      const comission = 0.01;
      setcom(comission);
      setboundle(res.data.data);
    })

  }


  useEffect(() => {
    const result = cableboundle.find((name) => name.code === boundlePlan);
    
    if (result) {
      const boundle = result.availablePricingOptions[0].price;
      setbundleprice(boundle);
      const boundlecode = result.code;
      setboundconde(boundlecode);
      const mon =  result.availablePricingOptions[0].monthsPaidFor
      setmonperiod(mon)
    }

    // if (selectedcable === "gotv") {
    //   const comission = 0.01;
     
    //   setcom(comission);
    //   setboundle(g);
    // } else if (selectedcable === "dstv") {
    //   const comission = 0.01;
    //   setcom(comission);
    //   setboundle(d);
    // } else if (selectedcable === "startimes") {
    //   const comission = 0.01;
    //   setcom(comission);
    //   setboundle(s);
    // } else {
    //   setboundle([]);
    // }
  }, [selectedcable, boundlePlan, bundleprice]);

  const handleShow = () => setShow(true);

  const limit = loginuser.transactionLimit - bundleprice
  const TransactionLimit = limit <= 0 ? 0 : limit

  const date = format(new Date(), "dd/MM/yyyy hh:mm a");

  const handleselctprond = (e) => {
    const d = e.target.value;
    setboudlePlan(d);
  };

  const version = version_control()

  const handleverifier = async (e) => {
    e.preventDefault();

    if (boundlePlan === "") {
      toast.error("Bad format", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (limit <= 0){
      toast.error("Limit Error");
      return;
    }
    const num = number 
    if (num === "" || !num){
      toast.error("Enter Mobile number");
      return 
    }
    setloading(true);
    try {
      setresponsepayload("fetching information");
      await axios
        .post(
          backendUrl+ "getnamefindercabletv/cable",
          {
            service_type: selectedcable,
            account_number: iuc,
          }
        )
        .then((e) => {
          if (e.data.message === "Successful") {
            setpayload(e.data.data.user);
            handleShow();
            setresponsepayload("");
          } else {
            toast.error("😔 Invalid Consumer Information", {
              position: toast.POSITION.TOP_RIGHT,
            });
            return;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.log(error);
    }
    setloading(false);
  };


  const handlepayment = async (e) => {
    e.preventDefault();
    if (userwallet.wallet < parseFloat(bundleprice)) {
      toast.error("Insuffient Fund");
      return;
    }
    const ref = database.collection("_").doc().id;
    const UniquieNum = getRandomString(20, "1234567890");
    if (limit <= 0){
      toast.error("Limit Error");
      return;
    }
    try {
      setloading(true);
      setresponsepayload("connecting");
      await axios
        .post(
          backendUrl+ `debitSuperAgentAccount/debit/user`,
          {
            id: loginuser.id,
            amount: parseFloat(bundleprice),
            trackNo: UniquieNum,
            version: version
          }
        )
        .then(async (res) => {
          setresponsepayload("sending value");
          if (res.data.res === "success") {
            const Token = res.data.token
         
            await axios
              .post(
                backendUrl+ "subcabletv/cable",
                {
                  smartcard_number: iuc,
                  total_amount: bundleprice,
                  product_code: code,
                  product_monthsPaidFor: period,
                  service_type: selectedcable,
                  agentReference: ref,
                  customername: payload.name,
                  customerNumber:number,
                  Token

                }
              )
              .then(async (res) => {
                setresponsepayload("Finilizing transaction");
          
                const data = {
                  id: ref,
                  company: selectedcable,
                  customername: payload.name,
                  address: "no value",
                  userID: loginuser.id,
                  AccountNumber: payload.accountNumber,
                  walletbalance: userwallet.wallet -bundleprice,
                  amount: bundleprice,
                  Parkage:boundlePlan,
                  phone: number,
                  payloadres: res.data,
                  mezuflex_id:res.data.ref,
                  Debitamount: bundleprice,
                  customermisson:0,
                  com: parseFloat(bundleprice) * com,
                  TranRef:UniquieNum,
                  type: "Cable TV",
                  status: "success",
                  isToday: true,
                  isClose: true,
                  created: firebase.firestore.FieldValue.serverTimestamp(),
                };
                const dataw = {
                  id: ref,
                  company: selectedcable,
                  customername: payload.name,
                  address: "no value",
                  userID: loginuser.id,
                  AccountNumber: payload.accountNumber,
                  walletbalance: userwallet.wallet - bundleprice,
                  Prewalletbalance: userwallet.wallet,
                  amount: bundleprice,
                  TranRef:UniquieNum,
                  phone: number,
                  mezuflex_id:res.data.ref,
                  Debitamount: bundleprice,
                  customermisson:0,
                  com: parseFloat(bundleprice) * com,
                  Parkage:boundlePlan,
                  payloadres: res.data,
                  type: "Cable TV",
                  status: "error",
                  isToday: true,
                  isClose: false,
                  created: firebase.firestore.FieldValue.serverTimestamp(),
                };
                if (res.data.status === "error") {
                await axios.post(
                   backendUrl +"userlogsuserlogs/transactions",
                  {
                    data: dataw,
                  }
                )
                  setrepayload(dataw);
                  toast.error("Unexpected Error")  
                  history.push(`/reciept/view/${dataw.id}`)

                } else {
                  const promises = [
                    axios.post(
                       backendUrl +"userlogsuserlogs/transactions",
                      {
                        data: data,
                      }
                    ),
                    database.doc(`/income/vRgRWLBNzwo7IM1pUhcl`).update({
                      income: firebase.firestore.FieldValue.increment(parseFloat(bundleprice) * com),
                      Cable: firebase.firestore.FieldValue.increment(parseFloat(bundleprice) * com),
                    }),
                  ];
                  setrepayload(data);
                  await Promise.all(promises)
                  history.push(`/reciept/view/${data.id}`)           
                }
              });
          } else {
            toast.error(res.data.message);
          }
        });
    } catch (error) {
      toast.error(error.message);
    }
    setloading(false);
  };

  const handleprint = ()=>{
    history.push(`/transaction/${res.id}`)
  }
  return (
    <div>
      <ToastContainer />

      <Modal
        show={showprint}
        onHide={handleClosePrint}
        backdrop="static"
        keyboard={false}
      >
        <div className="tramsactiondetyaions">
          <div className="transactioncokatne">
            <div
              className="transajea"
              style={{
                backgroundColor: res.status === "success" ? "green" : "red",
              }}
            >
              <h1>{res.status === "success" ? "SUCCESS" : "ERROR"}</h1>
              <span>Transaction Reciept</span>
            </div>
            <div className="transactioncontent">
              <div className="transcotebnr">
                <div className="contbename">Company</div>
                <div className="conetnenvakeb sjshdjdhd">{res.company}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">Bundle</div>
                <div className="conetnenvakeb">{boundlePlan}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">IUC </div>
                <div className="conetnenvakeb">{iuc}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">Amount</div>
                <div className="conetnenvakeb">{formatNumber(res.amount)}</div>
              </div>

            

              <div className="transcotebnr">
                <div className="contbename">number</div>
                <div className="conetnenvakeb">{number}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">Status</div>
                <div className="conetnenvakeb">{res.status}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">Data</div>
                <div className="conetnenvakeb">{date}</div>
              </div>
            </div>
          </div>
        </div>
        <Modal.Footer>
        <Link to="/services">
          <Button variant="secondary" >
            Another Transaction
          </Button>
          </Link>
          <Button variant="primary" onClick={handleprint}>Reciept</Button>
          <Button variant="secondary" onClick={handleClosePrint}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="p-0">
          <div className="tramsactiondetyaions">
            <div className="transactioncokatne">
              <div className="transajea">
                <h1>In-Porgress</h1>
                <span>
                  Kindly confirm the information below before you proceed with
                  this payment.
                </span>
              </div>
              <div className="transactioncontent">
                <div className="transcotebnr">
                  <div className="contbename">Company</div>
                  <div className="conetnenvakeb sjshdjdhd">
                    {selectedcable ? selectedcable : ""}
                  </div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">Account Name</div>
                  <div className="conetnenvakeb">{payload.name}</div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">IUC / Account Number</div>
                  <div className="conetnenvakeb">{iuc}</div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">Amount</div>
                  <div className="conetnenvakeb">
                    {formatNumber(bundleprice)}
                  </div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">number</div>
                  <div className="conetnenvakeb">{number}</div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">Status</div>
                  <div className="conetnenvakeb">Processing</div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">Data</div>
                  <div className="conetnenvakeb">{date}</div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" disabled={loading} onClick={handleClose}>
            Close
          </Button>
          <Button
            variant={loading ? "warning" : "primary"}
            disabled={loading}
            onClick={handlepayment}
            type="submit"
          >
            {loading ? (
              <>
                {" "}
                <Spinner animation="border" size="sm" /> {responspayload}
              </>
            ) : (
              "Pay with Wallet"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="transactionconatiner">
        <div className="transactioncontenrt">
          <div className="transactiohead">
            <h2>
              <center className="text-dark">Cable TV</center>
            </h2>
          </div>
          <div className="formtransactionsecton">
            <div className="formtrnacruondjdjd">
              <div className="productsed">
                <Form.Label className="text-dark ">Select Company</Form.Label>
                <FloatingLabel
                  controlId="floatingSelect"
                  onChange={handleprovider}
                  // onChange={(event) => setcableselected(event.target.value)}
                >
                 
                  <Form.Select aria-label="Floating label select example">
                  <option value="">Select Preferred Provider</option>
                    <option value="gotv">Gotv</option>
                    <option value="dstv">DSTV</option>
                    <option value="startimes">STARTIMES</option>
                  </Form.Select>
                </FloatingLabel>
                <div className="optionshhd">
                  <Form.Label className="text-dark ">
                    Select your preferred Package
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={handleselctprond}
                    value={ boundlePlan}
                    required
                  >
                     <option key="">Select Package</option>
                    {cableboundle.map((item) => {
                      return <option value={item.code}>{item.name}</option>;
                    })}
                  </Form.Select>
                  <Form.Label className="text-dark my-1">
                    Price : &nbsp; &#8358;{boundlePlan === "" ? 0 :formatNumber(bundleprice)}
                  </Form.Label>

                  <Form.Group controlId="formBasicNumber">
                    <Form.Label className="text-dark my-2">
                      IUC/Account Number
                    </Form.Label>
                    <Form.Control
                      type="tel"
                      onChange={(event) => {
                        setIUC(event.target.value);
                      }}
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicNumber">
                    <Form.Label className="text-dark my-2">
                      Phone Number
                    </Form.Label>
                    <Form.Control
                      placeholder={loginuser.number}
                      type="tel"
                      maxLength={11}
                      onChange={(event) => {
                        setphone(event.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Text className="text-muted">
                   Transaction Limit: {TransactionLimit}
                  </Form.Text>
                  <Button
                    variant={loading ? "warning" : "primary"}
                    disabled={loading || limit <=0}
                    onClick={handleverifier}
                    className="my-2 w-100"
                    type="submit"
                  >
                    {loading ? (
                      <>
                        {" "}
                        <Spinner animation="border" size="sm" />{" "}
                        {responspayload}
                      </>
                    ) : (
                      "Verify Customer"
                    )}
                  </Button>

                  <Button
                    variant={loading ? "warning" : "danger"}
                    disabled={loading || limit <=0}
                    onClick={history.goBack}
                    className="w-100"
                  >
                    {loading ? (
                      <>
                        {" "}
                        <Spinner animation="border" size="sm" />{" "}
                        {responspayload}
                      </>
                    ) : (
                      "Back"
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Airtimecomponent;
