import React from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import "./Support.css"
import { ToastContainer, toast } from "react-toastify";
import { database } from "../../../Services/firebase/firebase";
import firebase from "firebase";
import {FiPhoneCall} from "react-icons/fi"
import {MdEmail} from "react-icons/md"
import { useAuth } from "../../../Services/UserAuth";
function SupportCon() {
    const [report, setreport] =  useState("")
    const [subject, setsubject] =  useState("")
    const {loginuser} = useAuth()
    const [loading, setloading]= useState(false)


    const handlesendmessage = async(e)=>{
        e.preventDefault()

        if (report === "" || subject === ""){
            toast.error("Bad Data")
            return
        }
        setloading(true)
        const id = database.collection("_").doc().id;
        const data = {
          id,
          report,
          created: firebase.firestore.FieldValue.serverTimestamp(),
          customername: `${loginuser.firstname} ${loginuser.lastname}`,
          email:loginuser.email,
          number:loginuser.number,
          userID: loginuser.id,
          isTreated: false,
        };
    
        try {
            await database
              .collection("SupportMessage")
              .doc(data.id)
              .set(data)
              .then(() => {
                toast.success(
                  "Message sent successfully. we will treat and give feeback shortly. Thanks",
                  {
                    position: toast.POSITION.TOP_RIGHT,
                  }
                );
              });
          } catch (error) {
            console.log(error.message);
          }
    setloading(false)

    }
  return (
    <div>
        <ToastContainer/>
      <div className="supportconstiner">
        <div className="supportciereht">
          <div className="supportjeaderr">Support Center</div>

          <div className="suportcined">
            <div className="supporycomd">
              <Form>
    

                <Form.Group className="mb-3" controlId="formBasicText">
                  <Form.Label>Subject</Form.Label>
                  <Form.Control type="text" onChange={(event)=>{
                    setsubject(event.target.value)
                  }}/>
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Enter Message</Form.Label>
                  <Form.Control as="textarea" maxLength={150} rows={8} onChange={(event)=>{
                    setreport(event.target.value)
                  }}/>
                </Form.Group>

                <Button variant={loading ? "danger" : "success"}  type="submit" onClick={handlesendmessage}>
                  {loading? "Sending message" : "Send"}
                </Button>
              </Form>
            </div>

            <div className="contactuseoajd">
            <div className="isfndnhdhd">
                  <div className="imagesidecontact">
                      <h3><FiPhoneCall/></h3>
                      <h6 className="text-primary">Phone</h6>
                      <p>You can Call/Whatsapp us at anytime</p>
                      <span>09115630280 (Whatsapp)</span>
                  </div>

                  <div className="imagesidecontact">
                      <h3><MdEmail/></h3>
                      <h6 className="text-primary">Email</h6>
                      <p>Send us a detailed message.</p>
                      <span>kitipay.official@gmail.com</span>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupportCon;
