import React from "react";
import { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import "./Settings.css";
import { useHistory, useParams } from "react-router-dom";
import { useAuth } from "../../../Services/UserAuth";
import {ToastContainer, toast } from "react-toastify";
import axios from "axios";
import firebase from "firebase";
import { backendUrl } from "../../../Services/firebase/firebase";
import { useEffect } from "react";

function Settransactionpincom() {
  const [otp, setOtp] = useState("");
  const history = useHistory();
  const [loading, setloading] = useState(false);
  const [message, setmessage] = useState("");
  const { loginuser } = useAuth();
  const [confirmOTP, setOtpconfirm] = useState()
  const [password, setpassword] = useState("")

  useEffect(() => {
    if (loginuser.isPinSet ===  true){
      history.push("/transaction/wallet/transfer");
    }
  }, [])
  
  const id = useParams().id;
  var user = firebase.auth().currentUser;
  const handleChane = (e) => {
    e.preventDefault();
    if (confirmOTP !== otp) {
      toast.error("Pin Mismatch", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (otp === "") {
      setmessage("Enter your pin");
      return;
    }

    if (loginuser.isPinSet === true) {
      setmessage("Pin already set, contact admin for assistance");
      return;
    }

    if (otp.length !== 4) {
      setmessage("Enter 4 digit pin ");
      return;
    }

    if (id !== loginuser.id) {
      setmessage("request not process");
      return;
    }
    setloading(true)
    const cred = firebase.auth.EmailAuthProvider.credential(
      loginuser.email,
      password
    );

    if (!cred) {
      setmessage("No Record Found")
      return
    }
    user
      .reauthenticateWithCredential(cred)
      .then(async (e) => {
        setmessage("Validation in progress")
        await axios
          .get(
            backendUrl + `credituser/users/wallet/${otp}${loginuser.id}`
          )
      }).then(()=>{
        toast.success("Success.. Redirecting...");
        setmessage("Success.. Redirecting...");
       setInterval(() => {
        window.location.href ="/transaction/wallet/transfer";
       }, 2000);
      })
    
    setloading(false)
  };



  return (
    <div>
      <ToastContainer/>
      <div className="contendaksjdjd">
        <div className="thweawappercomeuud">
          <div className="row text-center">
            <h1>Create Transaction Pin</h1>
            <div className="col text-center">
              <Form.Group controlId="formBasicPassword">
                <Form.Label className="text-dark my-2">
                  Enter Pin
                </Form.Label>
                <Form.Control
                  placeholder="****"
                  maxLength={4}
                  type="password"
                  className="text-center"
                  onChange={(event) => {
                    setOtp(event.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label className="text-dark my-2">
                  Confirm Pin
                </Form.Label>
                <Form.Control
                  placeholder="****"
                  type="password"
                  maxLength={4}
                  className="text-center"
                  onChange={(event) => {
                    setOtpconfirm(event.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label className="text-dark my-2">
                  Enter Login Password
                </Form.Label>
                <Form.Control
                  placeholder="****"
                  type="password"

                  className="text-center"
                  onChange={(event) => {
                    setpassword(event.target.value);
                  }}
                />
              </Form.Group>
            </div>
          </div>
          <center className="text-success">{message}</center>
          <div className="madnhdhhd text-center">
            <Button
              variant={loading ? "warning" : "primary"}
              disabled={loading}
              onClick={handleChane}
              className="my-2 w-50"
              type="submit"
            >
              {loading ? (
                <>
                  {" "}
                  <Spinner animation="border" size="sm" />
                </>
              ) : (
                "Set Pin"
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settransactionpincom;
