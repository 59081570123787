import React from "react";
import "./MyCss.css";
import { useHistory } from "react-router-dom";
import Navbar from "../Client/Navbaronly";
import { useState } from "react";
import { Alert, Form, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { backendUrl, database } from "../../Services/firebase/firebase";
import axios from "axios";
import { getRandomString } from "../../Services/GetRandomNumber";
function Forgetpassword() {
  const history = useHistory();
  const [message, setmessage] = useState("");
  const [email, setemail] = useState();
  const [loading, setloading] = useState(false);
  const [number, setnumber] =  useState()


  const handleResetPassword = async() => {
    if (!number || !email ){
      toast.error("Mobile number and Email required")
      return
    }
    setloading(true)
    await axios
    .get(
      backendUrl + `createReport/report/user/${number}`
    ).then(async(res)=>{
      const user = res.data[0]
      if (user.isBlock === true){
        toast.error("Please contact admin for assistance e-200")
        setloading(false)
        return
      }

      if(email !== user.email){
        toast.error("Please contact admin for assistance e-100")
        setloading(false)
        return
      }
      const id = database.collection("_").doc().id;
      const userEmail =  email.toLowerCase()
      const UniquieNum = getRandomString(30, "qwertyuiopasdfhjklzxcvbnmQWERTYUIOPASDFHJKLGGZXCVBNM11234567890");
      const code = getRandomString(10, "11234567890");
      await database
      .collection("resetpassword")
      .doc(id)
      .set({
        url:UniquieNum,
        email:userEmail,
        number:number,
        id,
        is_user:true,
        code:code
      })
      .then(async() => { 
        const homeUrl = "https://kitipay.com/";
        const fullname = `${user.firstname} ${user.lastname}`;
        const logo = "https://i.ibb.co/5xqth9r/logo.png";
        const content = `<div marginheight="0" topmargin="0" marginwidth="0" style="margin: 0px; background-color: #f2f3f8;" leftmargin="0">
        <table cellspacing="0" border="0" cellpadding="0" width="100%" background-color="#f2f3f8"
            style="font-family: 'Open Sans', sans-serif;">
            <tr>
                <td>
                    <table style="background-color: #f2f3f8; max-width:670px;  margin:0 auto;" width="100%" border="0"
                        align="center" cellpadding="0" cellspacing="0">
                        <tr>
                            <td style="height:80px;">&nbsp;</td>
                        </tr>
                        <tr>
                            <td style="text-align:center;">
                              <a href=${homeUrl} title="logo" target="_blank">
                               KITIPAY 
                              </a>
                            </td>
                        </tr>
                        <tr>
                            <td style="height:20px;">&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <table width="95%" border="0" align="center" cellpadding="0" cellspacing="0"
                                    style="max-width:670px;background:#fff; border-radius:3px; text-align:center;-webkit-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);-moz-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);box-shadow:0 6px 18px 0 rgba(0,0,0,.06);">
                                    <tr>
                                        <td style="height:40px;">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td style="padding:0 35px;">
                                            <h1 style="color:#1e1e2d; font-weight:500; margin:0;font-size:32px;font-family:'Rubik',sans-serif;">Password Reset</h1>
                                            <span
                                                style="display:inline-block; vertical-align:middle; margin:29px 0 26px; border-bottom:1px solid #cecece; width:100px;"></span>
                                                <p style="color: rgb(1, 1, 122);  font-weight:500; font-size:35px;line-height:24px; margin:0; text-align:center;">
                                                Here's your One-Time PIN 
                                            </p>
    
                                            <br/>
    
                                            <br/>
    
                                            <br/>
                                            <p style="color:#455056;  font-weight:500; font-size:14px;line-height:24px; margin:0; text-align:center;">
                                            You performed an action that requires extra verification on Kitipay. Here's your one-time confirmation PIN.
                                            </p>
    
                                            <br/>
                                            <br/>
    
                                                <p style="color:black; font-size:35px;line-height:24px; margin:0; text-align:center;">
                                                    ${code}
                                                </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="height:40px;">&nbsp;</td>
                                    </tr>
                                </table>
                            </td>
                        <tr>
                            <td style="height:20px;">&nbsp;</td>
                        </tr>
                        <tr>
                            <td style="text-align:center;">
                                <p style="font-size:14px; color:rgba(69, 80, 86, 0.7411764705882353); line-height:18px; margin:0 0 0;">&copy; <strong>${homeUrl}</strong></p>
                            </td>
                        </tr>
                        <tr>
                            <td style="height:80px;">&nbsp;</td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
       
    
        </div>`;
        const CampURL =
          backendUrl+ "sendEmailCampkitipay";
        await axios.post(CampURL, {
          content,
          fullname,
          email: user.email,
          subject: `Password Reset | ${fullname} `,
        });
        history.push(`/valid/auth/resetpassword/${UniquieNum}`)
      });
     
    })
    setloading(false)
  };

  const hanelergsiter = () => {
    history.push("/register");
  };

  const handlelogin = () => {
    history.push("/login");
  };

  return (
    <div>
      <ToastContainer/>
       <Navbar />
      <div div className="backgroisns"  style={{ backgroundImage: "url(/img/b.jpg)" }}>
       
        <div className="usercontainerbody">
          <div class="usercontainer">
          <center className="py-2">
           <div class="d-inline-block auth-logo">
              <img src="/img/logo.png" alt="JSLPS image" height="50" />
            </div>
            <h3 className="text-dark mt-3 fs-2">Reset Password</h3>
           </center>
            {message ? (
              <Alert variant="info">
                <p>{message}</p>
              </Alert>
            ) : (
              ""
            )}

            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  onChange={(event) => {
                    setemail(event.target.value);
                  }}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicNumber">
              <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  type="test"
                  placeholder="Register Number"
                  onChange={(event) => {
                    setnumber(event.target.value);
                  }}
                  required
                />
              </Form.Group>

              <Form.Text className="text-muted">
                <p>
                  I can now remember my password{" "}
                  <span className="act" onClick={handlelogin}>
                    login
                  </span>
                </p>
                <p>
                  {" "}
                  Dont Have an Account?{" "}
                  <span className="act" onClick={hanelergsiter}>
                    Register
                  </span>
                </p>
              </Form.Text>

              <Button
                 variant={loading ? "warning" : "primary"}
                type="submit"
                onClick={handleResetPassword}
                disabled={loading}
                className="w-100 py-2 my-2"
              >
                {loading ? "Please Wait .." : "Proceed ..."}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Forgetpassword;
