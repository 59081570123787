import React from "react";
import "./OneBest.css";
import Navbar from "./Navbar";
import Footer from "./Footer";

function About() {
  return (
    <div>
      <Navbar />

      <div>
        <div className="homevalueparent">
          <div className="homevaluechild">
            <div className="homeparentitem">
              <div className="homevaluedetails">
                <h1>KitiPay Cares !!</h1>
                <span>
                  kitipay is a product of Kitihub which has proven to be trusted
                  and reliable with 3 years experience in gift card and
                  financial services in Nigeria. We buy and sell Gift Card at
                  the best market rate e.g iTunes, Amazon, Steam, Google etc.
                  P2P Payment will solve trust issues with payment to strangers,
                  our system and mode of operation is designed to keep your
                  funds safe while you trade or do business with people. we
                  breathe in trust and breath out instant payment on withdrawal.
                  <br /> <br />
                  We are a team of professionals and we are specialized in Art
                  illustrations, brand designs and animations we put the spark
                  to your imagination and bring your dreams to life.
                  <br /> <br />
                  Our vision is to become a leading Exchange brand  company in the Financial industry; we achieve this by producing quality,
                  standard and word class services
                </span>

                <h1>Our Values</h1>
                <p>
                  Our excellent and dedicated team of professionals focus on
                  Quality. we are  innovative yet highly
                  professional in our attempt to improve and impact our world
                  prositively and this forms our values
                </p>
                <p>
                  <span>Creativity</span> <br />
                  We Think Outside the box.
                </p>
                <p>
                  <span>Quality</span>
                  <br />
                  The best is all what we want
                </p>
                <p>
                  <span>Innovation</span>
                  <br />
                  We never settle, we are always ontop of the trend.
                </p>
                <p>
                  <span>Impact</span>
                  <br />
                  We care about our audience.
                </p>
                <p>
                  <span>Integrity</span>
                  <br />
                  We do it as we say it . Our word is our bond.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
