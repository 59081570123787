import React from "react";
import "./OneBest.css";

function OneBest() {
  return (
    <div>
      <div className="onebestheader">
        <div className="onebesta">
          <div className="reaocinahdd">
          <div className="headerabout">
            <h1>Our Mission is to make Financial Lifestyle Affordable</h1>
          </div>
          <div className="ansodejhdjh">
            <p>
               - Our merchant service offer protection on payment for goods and services, business growth and strengthen trust between you and your customers. 
            </p>
            <p>
              - We offer more affordable services like cheap data plan, with 3% cashback on airtime, fast funding of betting wallet, zero service charge on electricity and cable TV.
            </p>
            {/* <p>
              - Success in every business is a safe transaction and the safer
              the better. That's why we're here to give you confidence as you
              trade with us.
            </p> */}
            <p>
              - 24/7 support 
            </p>
          </div>
          </div>
          <div className="imagesides">
            <img src="/img/bs3.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OneBest;
