import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../../Services/UserAuth";
import "../ClientComponent/Docfile.css";
import { ToastContainer, toast } from "react-toastify";
import { useState } from "react";
import { storage } from "../../../Services/firebase/firebase";
import axios from "axios";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { HiDocument } from "react-icons/hi"
import { backendUrl } from "../../../Services/firebase/firebase";
import { SendNotification } from "../../../Services/GetUser.service";
import firebase from "firebase";
import { getRandomString } from "../../../Services/GetRandomNumber";
function DocVerification() {
  const { loginuser, userwallet } = useAuth();
  const history = useHistory();
  const [incoporationfile, setincoporation] = useState(null);
  const [metat, setmetat] = useState(null);
  const [resolution, setresolution] = useState(null);
  const [proofAddress, setproofAddress] = useState(null);
  const [identification, setidentification] = useState(null);
  const [progressincoporationfile, setProgressincoporationfile] = useState();
  const [progressmetat, setProgressmetat] = useState();
  const [progressproofAddress, setProgressproofAddress] = useState();
  const [progressresolution, setProgressresolution] = useState();
  const [progressidentification, setProgressidentification] = useState();
  const [fileloaded, setloadedfile] = useState("");
  const [fileloadedimage, setloadedfilemat] = useState("");
  const [fileloaded2, setloadedfile2] = useState("");
  const [fileloaded3, setloadedfile3] = useState("");
  const [fileloaded4, setloadedfile4] = useState("");
  const [message, setmessage] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setloading] = useState(false);
  const [voidMessge, setvoidMessage] = useState("")


  const handleincoporation = (e) => {
    if (e.target.files[0]) {
      setloadedfile(e.target.files[0].name);
      setincoporation(e.target.files[0]);
    }
  };

  const handlemetat = (e) => {
    if (e.target.files[0]) {
      setloadedfilemat(e.target.files[0].name);
      setmetat(e.target.files[0]);
    }
  };

  const handlebordResolution = (e) => {
    if (e.target.files[0]) {
      setloadedfile2(e.target.files[0].name);
      setresolution(e.target.files[0]);
    }
  };

  const handleProofAddress = (e) => {
    if (e.target.files[0]) {
      setloadedfile3(e.target.files[0].name);
      setproofAddress(e.target.files[0]);
    }
  };

  const handleIdentification = (e) => {
    if (e.target.files[0]) {
      setloadedfile4(e.target.files[0].name);
      setidentification(e.target.files[0]);
    }
  };

  function handleUploadincoporation() {
    return new Promise((resolve) => {
      const uploadTask = storage
        .ref(`file/${incoporationfile.name}`)
        .put(incoporationfile);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgressincoporationfile(progress);
        },
        (error) => {
          setmessage(error);
        },
        () => {
          storage
            .ref("file")
            .child(incoporationfile.name)
            .getDownloadURL()
            .then((url) => {
              resolve(url);
            });
        }
      );
    });
  }

  function handleUploadMemat() {
    return new Promise((resolve) => {
      const uploadTask = storage.ref(`file/${metat.name}`).put(metat);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgressmetat(progress);
        },
        (error) => {
          setmessage(error);
        },
        () => {
          storage
            .ref("file")
            .child(metat.name)
            .getDownloadURL()
            .then((url) => {
              resolve(url);
            });
        }
      );
    });
  }

  function handleUploadresolution() {
    return new Promise((resolve) => {
      const uploadTask = storage.ref(`file/${resolution.name}`).put(resolution);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgressresolution(progress);
        },
        (error) => {
          setmessage(error);
        },
        () => {
          storage
            .ref("file")
            .child(resolution.name)
            .getDownloadURL()
            .then((url) => {
              resolve(url);
            });
        }
      );
    });
  }

  function handleUploadProofofAddress() {
    return new Promise((resolve) => {
      const uploadTask = storage
        .ref(`file/${proofAddress.name}`)
        .put(proofAddress);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgressproofAddress(progress);
        },
        (error) => {
          setmessage(error);
        },
        () => {
          storage
            .ref("file")
            .child(proofAddress.name)
            .getDownloadURL()
            .then((url) => {
              resolve(url);
            });
        }
      );
    });
  }

  function handleUploadidentification() {
    return new Promise((resolve) => {
      const uploadTask = storage
        .ref(`file/${identification.name}`)
        .put(identification);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgressidentification(progress);
        },
        (error) => {
          setmessage(error);
        },
        () => {
          storage
            .ref("file")
            .child(identification.name)
            .getDownloadURL()
            .then((url) => {
              resolve(url);
            });
        }
      );
    });
  }

  const handleSEndNoReg = async (e) => {
    e.preventDefault();

    if (loginuser.isbusinessBankSubmited !== true) {
      setvoidMessage("Kindly submit your business Bank information before you proceed.")
      return
    }

    if (loginuser.isbusinessInfoSubmited !== true) {
      setvoidMessage("Kindly submit your business information before you proceed.")

      return
    }

    if (loginuser.isbusinessRepSubmited !== true) {
      setvoidMessage("Kindly submit your business Representative information before you proceed.")
      return
    }

    setloading(true);
    const resolutionImage = await handleUploadresolution();
    const identityImage = await handleUploadidentification();
    const addressProofImage = await handleUploadProofofAddress();


    const data = {
      type: loginuser.businessreg,
      id: loginuser.id,
      identityImage,
      resolutionImage,
      addressProofImage,
    };

    try {
      await axios
        .post(
          backendUrl + "getdocument/document",
          {
            data,
          }
        )
      handleShow()
      const nofifyID = getRandomString(6, "1234567890")
      const notificationBody = {
        message: `${loginuser.firstname} ${loginuser.lastname} Marchant registration summited successfully.. await admin approval`,
        created: firebase.firestore.FieldValue.serverTimestamp(),
        isread: false,
        id: nofifyID,
        userID:"",
        isRi: 0
      }
    await SendNotification(notificationBody, loginuser.id, "both")  
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setloading(false);
  };

  const handleReg = async (e) => {
    e.preventDefault();
    if (loginuser.isbusinessBankSubmited !== true) {
      setvoidMessage("Kindly submit your business Bank information before you proceed.")
      return
    }

    if (loginuser.isbusinessInfoSubmited !== true) {
      setvoidMessage("Kindly submit your business information before you proceed.")
      return
    }

    if (loginuser.isbusinessRepSubmited !== true) {
      setvoidMessage("Kindly submit your business Representative information before you proceed.")
      return
    }


    setloading(true)

    const IncorporationImage = await handleUploadincoporation();
    const resolutionImage = await handleUploadresolution();
    const mametImgae = await handleUploadMemat();
    const identityImage = await handleUploadidentification();
    const addressProofImage = await handleUploadProofofAddress();


    const data = {
      type: loginuser.businessreg,
      id: loginuser.id,
      IncorporationImage,
      resolutionImage,
      mametImgae,
      identityImage,
      addressProofImage,
    };

    try {
      await axios
        .post(
          backendUrl + "getdocument/document",
          {
            data,
          }
        )

      handleShow()
      const nofifyID = getRandomString(6, "1234567890")
      const notificationBody = {
        message: `${loginuser.firstname} ${loginuser.lastname} Marchant registration summited successfully.. await admin approval`,
        created: firebase.firestore.FieldValue.serverTimestamp(),
        isread: false,
        id: nofifyID,
        userID:"",
        isRi: 0
      }
    await SendNotification(notificationBody, loginuser.id, "both")  
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setloading(false);
  };

  if (loginuser.isbusinessDocumentSubmited === true) {
    return (
      <>
      <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >

          <Modal.Body>
           <div className="cardBodyss">
           <div class="cardsuccess">
              <div className="alldjjdhavahd">
                <i class="checkmark">✓</i>
              </div>
              <h1>Success</h1>
              <p>We have received your Application;<br /> we'll be in touch shortly!</p>
            </div>
           </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Home
            </Button>

          </Modal.Footer>
        </Modal>
        <div>
          <ToastContainer />
          <div className="buttoncomandndjjd">
            <Button
              variant={loading ? "warning" : "primary"}

              onClick={history.goBack}
            >
              {loading ? "Wait" : "Back"}
            </Button>
          </div>
          {voidMessge ? (
            <Alert variant="warning">
              <p className="text-white">{voidMessge}</p>
            </Alert>
          ) : (
            ""
          )}

          <div className="useridodnsiddnew py-4">
            <p>
              Dear {loginuser.firstname} {loginuser.lastname}, Uploaded
              documents provided based on your business type.
            </p>

            <div className="showdoumentcontainer">
              <div className="showdoucmentconetnt">
                <div className="showdoiekmtconent">
                  <div className="docujmetniconsection">
                    <p className="fs-3"><HiDocument /></p>
                    <span>Certificate of Incoporation</span>
                  </div>
                  <div className="docujmetniconsection">
                    {loginuser.businessreg === "" ? "Not Required" : "Submited"}
                  </div>
                </div>

                <div className="showdoiekmtconent">
                  <div className="docujmetniconsection">
                    <p className="fs-3"><HiDocument /></p>
                    <span>Mamet</span>
                  </div>
                  <div className="docujmetniconsection">
                    {loginuser.businessreg === "" ? "Not Required" : "Submited"}
                  </div>
                </div>

                <div className="showdoiekmtconent">
                  <div className="docujmetniconsection">
                    <p className="fs-3"><HiDocument /></p>
                    <span>Board Resolution</span>
                  </div>
                  <div className="docujmetniconsection">
                    {loginuser.businessreg === "" ? "Submited" : "Submited"}
                  </div>
                </div>


                <div className="showdoiekmtconent">
                  <div className="docujmetniconsection">
                    <p className="fs-3"><HiDocument /></p>
                    <span>Means of Identification</span>
                  </div>
                  <div className="docujmetniconsection">
                    {loginuser.businessreg === "" ? "Submited" : "Submited"}
                  </div>
                </div>


                <div className="showdoiekmtconent">
                  <div className="docujmetniconsection">
                    <p className="fs-3"><HiDocument /></p>
                    <span className="">Proof of Address</span>
                  </div>
                  <div className="docujmetniconsection">
                    {loginuser.businessreg === "" ? "Submited" : "Submited"}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </>
    );
  }


  if (loginuser.businessreg === "") {
    return (
      <>
        <div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >

          <Modal.Body>
           <div className="cardBodyss">
           <div class="cardsuccess">
              <div className="alldjjdhavahd">
                <i class="checkmark">✓</i>
              </div>
              <h1>Success</h1>
              <p>We have received your Application;<br /> we'll be in touch shortly!</p>
            </div>
           </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Home
            </Button>

          </Modal.Footer>
        </Modal>
          <ToastContainer />
          <div className="buttoncomandndjjd">
            <Button
              variant={loading ? "warning" : "primary"}

              onClick={history.goBack}
            >
              {loading ? "Wait" : "Back"}
            </Button>
            {voidMessge ? (
              <Alert variant="warning">
                <p className="text-white">{voidMessge}</p>
              </Alert>
            ) : (
              ""
            )}
          </div>
          <div className="useridodnsidd py-4">
            <center>
              <span className="text-danger">
                Kindly Upload All required document for confirmation
              </span>
            </center>
            <div className="allvary m-4">
              <div
                className="firtvakueallwirh"
                style={{ background: progressresolution ? "grey" : "" }}
              >
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Control type="file" onChange={handlebordResolution} />
                </Form.Group>
                <span>Board Resolution <span className="aksjdjhdmessagha">(The formal document of a business owner's decision to use our solution as a payment Intermediator)</span></span>
              </div>

              <div
                className="firtvakueallwirh"
                style={{ background: progressproofAddress ? "grey" : "" }}
              >
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Control type="file" onChange={handleProofAddress} />
                </Form.Group>
                <span>Proof of Address  <span className="aksjdjhdmessagha">(Utility Bill, Water Bill etc.)</span></span>
              </div>

              <div
                className="firtvakueallwirh"
                style={{ background: progressidentification ? "grey" : "" }}
              >
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Control type="file" onChange={handleIdentification} />
                </Form.Group>
                <span>Means of Identification</span>
              </div>

              <button
                style={{ background: loading ? "grey" : "" }}
                className="text-white p-2"
                disabled={
                  fileloaded2 === "" ||
                  fileloaded3 === "" ||
                  fileloaded4 === "" ||
                  loading
                }
                onClick={handleSEndNoReg}
              >
                {loading ? "Sending Request" : "Submit"}
              </button>
            </div>


          </div>
        </div>
      </>
    );
  }

  return (
    <div>
      <ToastContainer />
      <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >

          <Modal.Body>
           <div className="cardBodyss">
           <div class="cardsuccess">
              <div className="alldjjdhavahd">
                <i class="checkmark">✓</i>
              </div>
              <h1>Success</h1>
              <p>We have received your Application;<br /> we'll be in touch shortly!</p>
            </div>
           </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Home
            </Button>

          </Modal.Footer>
        </Modal>

      <div className="buttoncomandndjjd">
        <Button
          variant={loading ? "warning" : "primary"}

          onClick={history.goBack}
        >
          {loading ? "Wait" : "Back"}
        </Button>
        {voidMessge ? (
          <Alert variant="warning">
            <p className="text-white">{voidMessge}</p>
          </Alert>
        ) : (
          ""
        )}
      </div>
      <div className="useridodnsidd">
        <center>
          <span className="text-danger">
            Kindly Upload All required document for confirmation
          </span>
        </center>
        <div className="allvary m-4">
          <div
            className="firtvakueallwirh"
            style={{ background: progressincoporationfile ? "grey" : "" }}
          >
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Control type="file" onChange={handleincoporation} required />
            </Form.Group>
            <span>Incorporation Certificate</span>
          </div>
          <div
            className="firtvakueallwirh"
            style={{ background: progressmetat ? "grey" : "" }}
          >
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Control type="file" onChange={handlemetat} required />
            </Form.Group>
            <span>Memat</span>
          </div>

          <div
            className="firtvakueallwirh"
            style={{ background: progressresolution ? "grey" : "" }}
          >
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Control type="file" onChange={handlebordResolution} required />
            </Form.Group>
            <span>Board Resolution <span className="aksjdjhdmessagha">(The formal document of a business owner's decision to use our solution as a payment Intermediator)</span></span>

          </div>

          <div
            className="firtvakueallwirh"
            style={{ background: progressproofAddress ? "grey" : "" }}
          >
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Control type="file" onChange={handleProofAddress} required />
            </Form.Group>
            <span>Proof of Address  <span className="aksjdjhdmessagha">(Utility Bill, Water Bill etc.)</span></span>
          </div>

          <div
            className="firtvakueallwirh"
            style={{ background: progressidentification ? "grey" : "" }}
          >
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Control type="file" onChange={handleIdentification} />
            </Form.Group>
            <span>Means of Identification</span>
          </div>
        </div>

        <button
          className="m-4 text-white"
          style={{ background: loading ? "grey" : "btn-primary" }}
          disabled={loading || fileloaded === "" || fileloaded2 === "" || fileloaded3 === "" || fileloaded4 === "" || fileloadedimage === ""}
          onClick={handleReg}
        >
          {loading ? "Sending Request" : "Submit"}
        </button>
      </div>
    </div>
  );
}

export default DocVerification;
