import React from 'react'
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "../Admincreation.js/Admincreation.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import firebase from "firebase";
import { useHistory } from "react-router-dom";
import axios from "axios";

import { getRandomString } from "../../../Services/GetRandomNumber";
import {storage } from "../../../Services/firebase/firebase";
import { useAuth } from "../../../Services/UserAuth";
import {backendUrl} from "../../../Services/firebase/firebase"

function Creatnewstafcook() {
    const [loading, setloading] = useState(false);
    const [firstname, setfirst] = useState("");
    const [lastname, setlast] = useState("");
    const [email, setemail] = useState("");
    const [number, setPhone] = useState("");
    const [role, setRole]= useState("")
    const [image, setimage] = useState(null);
    const [message, setmessage]= useState('')
    const [progress, setProgress] = useState(0);
    const [adminPassword, setadminPassword] =  useState("")
    const history = useHistory();
    const { loginuser } = useAuth();
    const handleimage = (e) => {
      if (e.target.files[0]) {
        setimage(e.target.files[0]);
      }
    };
  
    function handleUpload() {
      return new Promise((resolve) => {
        const uploadTask = storage.ref(`customerImage/${image.name}`).put(image);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgress(progress);
          },
          (error) => {
            toast.error(error);
          },
          () => {
            storage
              .ref("customerImage")
              .child(image.name)
              .getDownloadURL()
              .then((url) => {
                resolve(url);
              });
          }
        );
      });
    }
  
  
  
    const handleregister = async (e) => {
      e.preventDefault();
      setloading(true);
      const image = await handleUpload();
      const UniquieNum = getRandomString(7, "1234567890");
      const password = getRandomString(7, "1234567890")
      setadminPassword(password)
      if (loginuser.role !== "admin"){
        toast.success("Permission error", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setloading(false)
        return
      }
      const data = {
        UniquieNum,
        email,
        image,
        role,
        number,
        adminid:loginuser.id,
        password,
        firstname,
        lastname,
        wallet: 0,
        coin: 0,
        isAllowed: true,
        created: firebase.firestore.FieldValue.serverTimestamp(),
      };
  
      if (
        role === "" ||
        firstname === "" ||
        lastname === "" ||
        email === "" ||
        number === ""
      ) {
        toast.error("required input value missing");
        return;
      }

     await axios
        .post(backendUrl+ "createstaffkitipay", { data: data })
        .then(async (e) => {
          toast.success(e.data.messeage, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setmessage(`Kindly instruct the staff to check email for further instruction. Or Advice the staff to use ${email} and ${password} to login`)
          const url = `https://kitipay.com/auth/login`;
          const homeUrl = "https://kitipay.com/";
          const fullname = [firstname, lastname];
          const logo = "https://i.ibb.co/5xqth9r/logo.png";
          const content = `<div marginheight="0" topmargin="0" marginwidth="0" style="margin: 0px; background-color: #f2f3f8;" leftmargin="0">
          <table cellspacing="0" border="0" cellpadding="0" width="100%" background-color="#f2f3f8"
              style="font-family: 'Open Sans', sans-serif;">
              <tr>
                  <td>
                      <table style="background-color: #f2f3f8; max-width:670px;  margin:0 auto;" width="100%" border="0"
                          align="center" cellpadding="0" cellspacing="0">
                          <tr>
                              <td style="height:80px;">&nbsp;</td>
                          </tr>
                          <tr>
                              <td style="text-align:center;">
                                <a href=${homeUrl} title="logo" target="_blank">
                                  <img width="130" src=${logo} title="logo" alt="logo">
                                </a>
                              </td>
                          </tr>
                          <tr>
                              <td style="height:20px;">&nbsp;</td>
                          </tr>
                          <tr>
                              <td>
                                  <table width="95%" border="0" align="center" cellpadding="0" cellspacing="0"
                                      style="max-width:670px;background:#fff; border-radius:3px; text-align:center;-webkit-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);-moz-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);box-shadow:0 6px 18px 0 rgba(0,0,0,.06);">
                                      <tr>
                                          <td style="height:40px;">&nbsp;</td>
                                      </tr>
                                      <tr>
                                          <td style="padding:0 35px;">
                                              <h1 style="color:#1e1e2d; font-weight:500; margin:0;font-size:32px;font-family:'Rubik',sans-serif;">Account Verification</h1>
                                              <span
                                                  style="display:inline-block; vertical-align:middle; margin:29px 0 26px; border-bottom:1px solid #cecece; width:100px;"></span>
                                              <p style="color:#455056; font-size:25px;line-height:24px; margin:0;">
                                              Welcome to the Team ${fullname}!
                                                Use ${password} to login into your dashboard.
                                                Please Don't share this information to anyone
                                              </p>
                                              <a href=${url}
                                                  style="background:#20e277;text-decoration:none !important; font-weight:500; margin-top:35px; color:#fff;text-transform:uppercase; font-size:14px;padding:10px 24px;display:inline-block;border-radius:50px;">Login</a>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td style="height:40px;">&nbsp;</td>
                                      </tr>
                                  </table>
                              </td>
                          <tr>
                              <td style="height:20px;">&nbsp;</td>
                          </tr>
                          <tr>
                              <td style="text-align:center;">
                                  <p style="font-size:14px; color:rgba(69, 80, 86, 0.7411764705882353); line-height:18px; margin:0 0 0;">&copy; <strong>${homeUrl}</strong></p>
                              </td>
                          </tr>
                          <tr>
                              <td style="height:80px;">&nbsp;</td>
                          </tr>
                      </table>
                  </td>
              </tr>
          </table>
         
    
    </div>`;
    const sendUrl = backendUrl + "sendEmailCampkitipay"
          await axios
            .post(sendUrl, {
              content,
              logo,
              fullname,
              email: email,
              firstname: firstname,
              homeUrl,
              url,
              subject:"Account Verification"
              
            })
        })
        .catch((e) => {
          toast.error(e.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          console.log(e);
        });
      setloading(false);
    };
  return (
    <div>
          <div>
      <ToastContainer />
      <div className="adminconterrnew">
        <div className="adminrpujd">
          <h1>Create New Staff</h1>
          <p>Please Verify ALL CREDENTIALS before proceeding</p>
          <div className="adminconter">
            <Form>
              <Row>
                <Col>
                  <Form.Control
                    placeholder="First name"
                    onChange={(event) => {
                      setfirst(event.target.value);
                    }}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                    placeholder="Last name"
                    onChange={(event) => {
                      setlast(event.target.value);
                    }}
                    required
                  />
                </Col>
              </Row>

              <Form.Group as={Col} controlId="formGridEmail"className="my-2">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  required
                  onChange={(event) => {
                    setemail(event.target.value);
                  }}
                />
              </Form.Group>
              <Form.Label>Admin Role</Form.Label>
              <Form.Select aria-label="Default select example" onChange={(event)=>{
                setRole(event.target.value)
              }}>
                <option>select admin role</option>
                <option value="Sale">Data  Entry Personal</option>
                {/* <option value="Logistics">Logistics</option> */}
              </Form.Select>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="tel"
                    required
                    onChange={(event) => {
                      setPhone(event.target.value);
                    }}
                  />
                </Form.Group>
              </Row>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Image</Form.Label>
                <Form.Control type="file" onChange={handleimage} required />
              </Form.Group>
              <center className="text-success">{message}</center>
              <Button
                className="w-100"
                onClick={handleregister}
                variant="success"
                disabled={loading}
              >
                {loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Register"
                )}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Creatnewstafcook