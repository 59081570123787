import React, { useEffect } from "react";
import { useState } from "react";
import { Button, FloatingLabel, Form, Modal, Spinner } from "react-bootstrap";
import { useAuth } from "../../../Services/UserAuth";
import "./Transaction.css";
import { ToastContainer, toast } from "react-toastify";
import { format } from "date-fns";
import axios from "axios";
import { database } from "../../../Services/firebase/firebase";
import firebase from "firebase";
import { Link, useHistory } from "react-router-dom";
import { getRandomString } from "../../../Services/GetRandomNumber";
import { backendUrl } from "../../../Services/firebase/firebase";
import { SendNotification, getversion } from "../../../Services/GetUser.service";
import { version_control } from "../../../Services/Utility";
import { detectNetwork } from "../../../Services/getMobileNetworkType";
function Airtimecomponent() {
  const [amount, setamount] = useState(0);
  const [selectednetwork, setselectednetwork] = useState("");
  const [number, setphone] = useState("");
  const [loading, setloading] = useState(false);
  const { loginuser, userwallet } = useAuth();
  const [responspayload, setresponsepayload] = useState("");
  const [show, setShow] = useState(false);
  const [payload, setpayload] = useState([]);
  const [com, setcom] = useState(0);
  const [showprint, setShowprint] = useState(false);
  const [res, setrepayload] = useState([]);
  const [customermisson, setcustomercommssion] = useState(0);

  const history = useHistory();
  const handleClosePrint = () => {
    window.location.reload();
    setShowprint(false);
  };
  const handleShowprint = () => setShowprint(true);
  const handleClose = () => {
    setShow(false);
  };

  const version = version_control()

  useEffect(() => {
    
    if (selectednetwork === "mtn") {
      const comission = 0.01 * parseFloat(amount);

      setcom(comission);
      setcustomercommssion(amount * 0.01);
    } else if (selectednetwork === "glo") {
      const comission = 0.02 * parseFloat(amount);
      setcustomercommssion(amount * 0.02);
      setcom(comission);
    } else if (selectednetwork === "airtel") {
      const comission = 0.01 * parseFloat(amount);
      setcustomercommssion(amount * 0.01);
      setcom(comission);
    } else if (selectednetwork === "etisalat") {
      const comission = 0.025 * parseFloat(amount);
      setcustomercommssion(amount * 0.01);
      setcom(comission);
    }
  }, [selectednetwork, com, customermisson, amount]);

  const limit = loginuser.transactionLimit - amount;
  const TransactionLimit = limit <= 0 ? 0 : limit;

  const handleShow = () => setShow(true);
  const date = format(new Date(), "dd/MM/yyyy hh:mm a");

  const handleverifier = () => {
    if (selectednetwork === "") {
      toast.error("Please select network");
      return;
    }
    if (amount < 50) {
      toast.error("minimum vending amount is 50");
      return;
    }

    if (limit <= 0) {
      toast.error("Limit Error");
      return;
    }
    const num = number;
    if (num === "" || !num) {
      toast.error("Enter Mobile number");
      return;
    }

    const firstFourDigits = number.substring(0, 4);
    const network =   detectNetwork(firstFourDigits).toLocaleLowerCase()
    if (network ==="unknown network"){
      toast.error("unknown Network");
      return;
    }
    if(network !== selectednetwork){
      toast.error(`Invalid network type. We dictated ${network} network, Please selected the correct network and try again`);
      return;
    }
    const data = {
      number: num,
      amount: parseFloat(amount),
      selectednetwork: selectednetwork,
    };

    setpayload(data);
    handleShow();
  };
  const handlepayment = async (e) => {
    e.preventDefault();
    if (userwallet.wallet < payload.amount) {
      toast.error("Insuffient Fund");
      return;
    }

    if (number === "") {
      toast.error("Enter Mobile Number");
      return;
    }

    if (limit <= 0) {
      toast.error("Limit Error");
      return;
    }

  
    const ref = database.collection("_").doc().id;

    try {
      setloading(true);
      setresponsepayload("connecting");
      const UniquieNum = getRandomString(20, "1234567890");
      await axios
        .post(backendUrl + `debitSuperAgentAccount/debit/user`, {
          id: loginuser.id,
          amount: amount - customermisson,
          trackNo: UniquieNum,
          version: version
        })
        .then(async (res) => {
          const Token = res.data.token;
          setresponsepayload("sending value");
          if (res.data.res === "success") {
            await axios
              .post(backendUrl + "buyAirtim/airtime", {
                network: payload.selectednetwork,
                amount: payload.amount,
                number: payload.number,
                sellprice: amount - customermisson,
                referrence: ref,
                Token,
              })
              .then(async (result) => {
                setresponsepayload("Finilizing transaction");
                const data = {
                  id: ref,
                  company: selectednetwork,
                  customername: number,
                  address: "no value",
                  userID: loginuser.id,
                  AccountNumber: number,
                  walletbalance: userwallet.wallet,
                  amount: amount,
                  Debitamount: amount - customermisson,
                  phone: number,
                  customermisson,
                  mezuflex_id: result.data.ref,
                  com,
                  payloadres: res.data,
                  TranRef: UniquieNum,
                  type: "airtime",
                  status: "success",
                  isToday: true,
                  isClose: true,
                  created: firebase.firestore.FieldValue.serverTimestamp(),
                };
                const dataw = {
                  id: ref,
                  company: selectednetwork,
                  customername: number,
                  address: "no value",
                  userID: loginuser.id,
                  AccountNumber: number,
                  walletbalance: userwallet.wallet - parseFloat(amount),
                  amount: parseFloat(amount),
                  Debitamount: parseFloat(amount) - customermisson,
                  Prewalletbalance: userwallet.wallet,
                  phone: number,
                  customermisson,
                  mezuflex_id: result.data.ref,
                  com,
                  TranRef: UniquieNum,
                  payloadres: res.data,
                  type: "airtime",
                  status: "error",
                  isToday: true,
                  isClose: false,
                  created: firebase.firestore.FieldValue.serverTimestamp(),
                };

                if (result.data.status === "success") {
                  const promises = [
                    axios.post(
                      backendUrl + "userlogsuserlogs/transactions",
                      {
                        data: data,
                      }
                    ),
                    database.doc(`/income/vRgRWLBNzwo7IM1pUhcl`).update({
                      income: firebase.firestore.FieldValue.increment(com),
                      airtime: firebase.firestore.FieldValue.increment(com),
                    }),
                  ];
                  setrepayload(data);
                  await Promise.all(promises);
                  history.push(`/reciept/view/${data.id}`);
                } else {
                  setrepayload(dataw);
                  //    const nofifyID = getRandomString(6, "1234567890")
                  //    const notificationBody = {
                  //      message: `Transaction #${dataw.id} failed please act on it`,
                  //      created: firebase.firestore.FieldValue.serverTimestamp(),
                  //      isread: false,
                  //      id: nofifyID,
                  //      userID:"",
                  //      isRi: 0
                  //    }
                  //  await SendNotification(notificationBody, loginuser.id, "admin")
                  await axios.post(
                    backendUrl + "userlogsuserlogs/transactions",
                    {
                      data: dataw,
                    }
                  );
                  history.push(`/reciept/view/${dataw.id}`);
                  toast.error("Unexpected Error");
                }
              });
          } else {
            toast.error(res.data.res);
          }
        });
    } catch (error) {
      toast.error(error.message);
    }
    setloading(false);
  };

  const handleprint = () => {
    history.push(`/transaction/${res.id}`);
  };

  const handleSelectedNetwork = (e)=>{
    const value =  e.target.value
    setselectednetwork(value)
  }

  const setPhoneNumber = async(e)=>{
    const value =  e.target.value
    // if(value.length === 4){
    // const network =   await detectNetwork(value)
    // setselectednetwork(network.toLocaleLowerCase())
    // }
    setphone(value)
  }

  return (
    <div>
      <ToastContainer />

      <Modal
        show={showprint}
        onHide={handleClosePrint}
        backdrop="static"
        keyboard={false}
      >
        <div className="tramsactiondetyaions">
          <div className="transactioncokatne">
            <div
              className="transajea"
              style={{
                backgroundColor: res.status === "success" ? "green" : "red",
              }}
            >
              <h1>{res.status === "success" ? "SUCCESS" : "ERROR"}</h1>
              <span>Transaction Reciept</span>
            </div>
            <div className="transactioncontent">
              <div className="transcotebnr">
                <div className="contbename">Company</div>
                <div className="conetnenvakeb sjshdjdhd">
                  {selectednetwork.toUpperCase()}
                </div>
              </div>
              <div className="transcotebnr">
                <div className="contbename">Amount</div>
                <div className="conetnenvakeb">{amount}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">number</div>
                <div className="conetnenvakeb">{res.AccountNumber}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">Status</div>
                <div className="conetnenvakeb">{res.status}</div>
              </div>

              <div className="transcotebnr">
                <div className="contbename">Data</div>
                <div className="conetnenvakeb">{date}</div>
              </div>
            </div>
          </div>
        </div>
        <Modal.Footer>
          <Link to="/services">
            <Button variant="secondary">Another Transaction</Button>
          </Link>
          <Button variant="primary" onClick={handleprint}>
            Reciept
          </Button>
          <Button variant="secondary" onClick={handleClosePrint}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="p-0">
          <div className="tramsactiondetyaions">
            <div className="transactioncokatne">
              <div className="transajea">
                <h1>In-Porgress</h1>
                <span>
                  Kindly confirm the information below before you proceed with
                  this payment.
                </span>
              </div>
              <div className="transactioncontent">
                <div className="transcotebnr">
                  <div className="contbename">Company</div>
                  <div className="conetnenvakeb sjshdjdhd">
                    {payload.selectednetwork}
                  </div>
                </div>
                <div className="transcotebnr">
                  <div className="contbename">Amount</div>
                  <div className="conetnenvakeb">{amount}</div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">number</div>
                  <div className="conetnenvakeb">{payload.number}</div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">Status</div>
                  <div className="conetnenvakeb">Processing</div>
                </div>

                <div className="transcotebnr">
                  <div className="contbename">Data</div>
                  <div className="conetnenvakeb">{date}</div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" disabled={loading} onClick={handleClose}>
            Close
          </Button>
          <Button
            variant={loading ? "warning" : "primary"}
            disabled={loading}
            onClick={handlepayment}
            type="submit"
          >
            {loading ? (
              <>
                {" "}
                <Spinner animation="border" size="sm" /> {responspayload}
              </>
            ) : (
              "Pay with Wallet"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="transactionconatiner">
        <div className="transactioncontenrt">
          <div className="transactiohead">
            <h2>
              <center className="text-dark">Airtime</center>
            </h2>
          </div>
          <div className="formtransactionsecton">
            <div className="formtrnacruondjdjd">
              <div className="productsed">
                <FloatingLabel
                  controlId="floatingSelect"
                  onChange={handleSelectedNetwork}
                  defaultValue={selectednetwork}
                >
                  <Form.Select aria-label="Floating label select example">
                    <option>Select Network</option>
                    <option value="mtn">MTN</option>
                    <option value="glo">GLO</option>
                    <option value="airtel">AIRTEL</option>
                    <option value="etisalat">9Mobile</option>
                  </Form.Select>
                </FloatingLabel>
                <div className="optionshhd">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className="text-dark my-2">Amount</Form.Label>
                    <Form.Control
                      placeholder="Enter Amount (50 - 50,000)"
                      type="tel"
                      onChange={(event) => {
                        setamount(event.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicNumber">
                    <Form.Label className="text-dark my-2">
                      Phone Number
                    </Form.Label>
                    <Form.Control
                      placeholder={loginuser.number}
                      type="tel"
                      maxLength={11}
                      onChange={setPhoneNumber}
                    />
                  </Form.Group>
                  <Form.Text className="text-muted">
                    Transaction Limit: {TransactionLimit ? TransactionLimit : 0}
                  </Form.Text>
                  <Button
                    variant={loading ? "warning" : "primary"}
                    disabled={loading || limit <= 0}
                    onClick={handleverifier}
                    className="my-2 w-100"
                    type="submit"
                  >
                    {loading ? (
                      <>
                        {" "}
                        <Spinner animation="border" size="sm" />{" "}
                        {responspayload}
                      </>
                    ) : (
                      "Buy Airtime"
                    )}
                  </Button>

                  <Button
                    variant={loading ? "warning" : "danger"}
                    disabled={loading || limit <= 0}
                    onClick={history.goBack}
                    className="w-100"
                  >
                    {loading ? (
                      <>
                        {" "}
                        <Spinner animation="border" size="sm" />{" "}
                        {responspayload}
                      </>
                    ) : (
                      "Back"
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Airtimecomponent;
