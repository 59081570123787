import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import {
  FaTh,
  FaBars,
  FaRegBell,

 
} from "react-icons/fa";
import { MdTransferWithinAStation,MdOutlineBusinessCenter, MdSupportAgent, MdOutlineQueryStats } from "react-icons/md";
import { FiLogOut } from "react-icons/fi";
import {AiOutlineSetting} from "react-icons/ai"
import { NavLink, useHistory, useParams } from "react-router-dom";
import { useAuth } from "../../../Services/UserAuth";
import "./Sidemenu.css";
import firebase from "firebase";
import axios from "axios";
import Loading from "../../../Loading";
import { getuser, getuserNotificationr } from "../../../Services/GetUser.service";
import { backendUrl, database } from "../../../Services/firebase/firebase";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Toast from "react-bootstrap/Toast";
import moment from "moment";
import {AiOutlineBell} from "react-icons/ai"

const Sidebar = ({ children }) => {
  const { loginuser, logout } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [message, setmessage] = useState("");
  const history = useHistory();
  var user = firebase.auth().currentUser;
  const handleShowclosr = () => setShow(false);
  const [isChecked, setIsChecked] = useState(false);
  const [enableedite, setenableedite] = useState(true);
  const [loading, setloading] = useState(false);
  const [showpin, setShowin] = useState(false);
  const [users, setuser]= useState([])
  const [shownotify, setShownotification] = useState(false);
  const [notification, setnotification] = useState([]);
  const handleClosepin = () => {
    history.push("/user/dashboard");
    setShowin(false);
  };
  const [otp, setOtp] = useState("");
  const handleShowin = () => setShowin(true);
  const [number, setnumber] = useState("");
  const handleOnChange = () => {
    setIsChecked(!isChecked);
    if (isChecked) {
      setenableedite(true);
    } else {
      setenableedite(false);
    }
  };
  const handleMarkasread = async () => {
    if (notification.length > 0) {
      for (let index = 0; index < notification.length; index++) {
        const element = notification[index];
        await database.doc(`notification/${element.id}`).update({
          isread: true,
        });
      }
      setShownotification(false);
      return;
    }
  };
  const handlecloaseNoid = async () => {
    setShownotification(false);
  };
  const handlesendOTP = async (e) => {
    e.preventDefault();
    setloading(true);

    await axios
      .post(backendUrl+ "KitiSendingkitipay", {
        message: `Verification code ${loginuser.uniquinum}`,
        recipient: number ? number : loginuser.number,
        senderId: "KITIPAY",
      })
      .then((e) => {
        if (e.data.code === 200) {
          handleShowin();
        }
      });
    handleShowin();
    setloading(false);
  };
  const id =  useParams().id
  const  uise =  id ? id : loginuser.id
  useEffect(() => {
    getuser(uise,(result)=>{
     if(result && result.isBlock !== false){
      logout()
     }
      setuser(result)
    })
    
    getuserNotificationr(uise, (result) => {
      const readN = result.filter((e) => e.isread === false);
      setnotification(readN);
    });
  }, []);

  
  const handleverfy = async (e) => {
    e.preventDefault();

    if (otp !== loginuser.uniquinum) {
      setmessage("Invalid Token");
      return;
    }
    setloading(true);
    await axios
      .post(backendUrl+ "sendnumberkitipay", {
        userId: loginuser.id,
      })
      .then((e) => {
        if (e.data.mess === "Great Job! your account is now verified") {
          setmessage(`${e.data.mess} redirecting now...`);
          setInterval(() => {
            handleClosepin();
            handleClose();
          }, 2000);
        }
      });
    setmessage("");
    setloading(false);
  };

  //   const handleupdate = (e) => {
  //     e.preventDefault();

  //     if (
  //       correntpassword === "" ||
  //       newpassord === "" ||
  //       comfirmpassword === "" ||
  //       pin === ""
  //     ) {
  //       return seterror("Missing Value");
  //     }
  //     if (newpassord !== comfirmpassword) {
  //       return seterror("New Password MisMatch");
  //     }
  //     if (pin.length > 4) {
  //       return seterror("Max Pin length is 4 and should be a number");
  //     }
  //     if (isNaN(pin)) {
  //       return seterror("Only Number required");
  //     }
  //     const cred = firebase.auth.EmailAuthProvider.credential(
  //       loginuser.email,
  //       correntpassword
  //     );
  //     user
  //       .reauthenticateWithCredential(cred)
  //       .then((e) => {
  //         // console.log(e)
  //         return user.updatePassword(newpassord);
  //       })
  //       .then(async () => {
  //         await database.doc(`users/${loginuser.id}`).update({
  //           access: true,
  //           pin: pin,
  //           created: firebase.firestore.FieldValue.serverTimestamp(),
  //         });
  //         handleShowclosr();
  //       })
  //       .catch((error) => {
  //         setmessage(error.message);
  //       });
  //     seterror("");
  //     setmessage("");
  //   };

  const menuItem = [
    {
      path: `/user/dashboard/${loginuser.id}`,
      name: "Dashboard",
      icon: <FaTh />,
    },
  

    // {
    //   path: "/service/Gift-Card",
    //   name: "Gift-Card",
    //   icon: <FaRegMoneyBillAlt />,
    // },
    {
      path: "/services",
      name: "Services",
      icon: <MdTransferWithinAStation />,
    },
    {
      path: "/user/transaction",
      name: "Transactions",
      icon: <MdOutlineQueryStats />,
    },
    {
      path: "/dashboard/Compliance/general",
      name: "Compliance",
      icon: <MdOutlineBusinessCenter/>,
    },
    {
      path: "/support",
      name: "Support",
      icon: <MdSupportAgent />,
    },
    {
      path: "/setting",
      name: "Settings",
      icon: <AiOutlineSetting />,
    },
  
    {
      path: "/logout",
      name: "Logout",
      icon: <FiLogOut />,
    },
  ];

  return (
    <>
      <Modal
        show={showpin}
        onHide={handleClosepin}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <p>
            Hello {loginuser.firstname}
            {loginuser.lastname}
          </p>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="tel"
              onChange={(event) => setOtp(event.target.value)}
            />
          </Form.Group>
          <Form.Text className="text-muted">
            Please Enter OTP Send to your mobile number.
          </Form.Text>
        </Modal.Body>
        <center className="text-success">{message}</center>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClosepin}>
            Close
          </Button>
          <Button variant="primary" disabled={loading} onClick={handleverfy}>
            {loading ? "Loading" : "Proceed"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <p>
            Hello {loginuser.firstname}
            {loginuser.lastname}
          </p>
          <p>
            You are amost done setup your account. we just need you to verify
            your mobile number. kindly click on the button below to generate
            your OTP
          </p>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="tel"
              onChange={(event) => setnumber(event.target.value)}
              placeholder={loginuser.number}
              disabled={enableedite}
            />

            <Form.Check
              type="switch"
              id="custom-switch"
              label="Switch to change this number"
              checked={isChecked}
              onChange={handleOnChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handlesendOTP} disabled={loading}>
            {loading ? "Loading.." : "Verify Me"}
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="containersss" onClick={toggle}>
        <div
          style={{ width: isOpen ? "200px" : "50px", zIndex: isOpen ? 3 : 0 }}
          className="sidebaradmin"
        >
          <div className="top_section11">
            <h1
              style={{ display: isOpen ? "block" : "none" }}
              className="logo1"
            >
              <img src="/img/v1.png" alt="" />
            </h1>
            <div
              style={{ marginLeft: isOpen ? "50px" : "0px" }}
              className="bars"
            >
              <FaBars onClick={toggle} />
            </div>
          </div>
          {menuItem.map((item, index) => (
            <NavLink
              to={item.path}
              key={index}
              className="link"
              activeclassName="active"
            >
              <div className="icon">{item.icon}</div>
              <div
                style={{ display: isOpen ? "block" : "none" }}
                className="link_text"
              >
                {item.name}
              </div>
            </NavLink>
          ))}
        </div>
        <div
          style={{ width: isOpen ? "200px" : "0px", zIndex: isOpen ? 3 : 0 }}
          className="sidebaradmindis"
        >
          <div className="top_section11">
            <h1
              style={{ display: isOpen ? "block" : "none" }}
              className="logo1"
            >
              <img src="/img/v1.png" alt="" />
            </h1>
            <div
              style={{ marginLeft: isOpen ? "0px" : "0px" }}
              className="barsmobile"
            >
              <FaBars onClick={toggle} />
            </div>
          </div>
          {menuItem.map((item, index) => (
            <NavLink
              to={item.path}
              key={index}
              activeclassName="active"
              className="link" style={{ display: isOpen ? "flex" : "none" }}
            >
              <div className="icon" style={{ display: isOpen ? "block" : "none" }}>{item.icon}</div>
              <div
                style={{ display: isOpen ? "block" : "none" }}
                className="link_text"
              >
                {item.name}
              </div>
            </NavLink>
          ))}
        </div>
        <main>{children}</main>
        <div className="sidtopbajsjd">
        <div className="tocontaone">
            {/* <button
              type="button"
              class="icon-button"
              onClick={() => setShownotification(true)}
            >
              <span class="material-icons">
                <AiOutlineBell />
              </span>
              <span
                class="icon-button__badge"
                style={{
                  display: notification.length === 0 ? "none" : "block",
                }}
              >
                {/* {notification.length} */}
              {/* </span>
            </button> */} 

            {/* <div className="notficbody">
              <div className="notiicaidod">
                <Col xs={6}>
                  <Toast
                    onClose={handlecloaseNoid}
                    show={shownotify}
                    // delay={3000}
                    // autohide
                    className="backinshs"
                  >
                    <Toast.Header>
                      <strong className="me-auto text-dark">
                        Notification
                      </strong>
                    </Toast.Header>
                    <Toast.Body>
                      {notification.length === 0 ? (
                        <>
                          <center>No message</center>
                        </>
                      ) : (
                        <div className="notifjkchight">
                          {notification.map((item) => {
                            const date = moment(item.created).fromNow();
                            return (
                              <>
                                <p>{item.message}</p>
                                <small className="text-warning">{date}</small>
                                <hr />
                              </>
                            );
                          })}
                          <Button variant="outline-success" className="my-2" onClick={handleMarkasread}>Mark all as read</Button>
                        </div>
                      )}
                    </Toast.Body>
                  </Toast>
                </Col>
              </div>
            </div> */}
            <div className="usesjsd">
              {loginuser.firstname} {loginuser.lastname}
            </div>
            <div className="iconsiexcoj">
              <img src={loginuser.image} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;

